import axios, { AxiosError } from "axios";
import { camelizeKeys } from "humps";
import { auth, isTokenExpired, refreshToken } from "shared/utils/auth";
import { APP_ENV } from "../../app-env";

export const refetchDelayOnError = 5_000;
type TNetworkErrorHandler = () => void
type TNetworkErrorHandlers = TNetworkErrorHandler[];

const networkErrorHandlers: TNetworkErrorHandlers = [];
export const axiosInstance = axios.create();

function handleNetworkError(error: AxiosError) {
  if (error.code === AxiosError.ERR_NETWORK) {
    networkErrorHandlers.forEach(el => el());
  }
}

axiosInstance.interceptors.response.use((response) => {
  if (!response.config.keepSnakeCase && response.data && response.headers["content-type"] === "application/json") {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

// axiosInstance.interceptors.request.use((config: any) => {
//   const newConfig = { ...config };
//   newConfig.url = `api/${config.url}`;
//   if (newConfig.headers['Content-Type'] === 'multipart/form-data')
//     return newConfig;
//   if (config.params) {
//     newConfig.params = decamelizeKeys(config.params);
//   }
//   if (config.data) {
//     newConfig.data = decamelizeKeys(config.data);
//   }
//   return newConfig;
// });

axiosInstance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("access_token");
  config.headers = config.headers ?? {};

  if (accessToken && isTokenExpired(accessToken)) {
    accessToken = await refreshToken();
  }

  config.headers["Content-Type"] = config.headers["Content-Type"] ?? "application/json";

  if (accessToken) {
    const authString = `Bearer ${accessToken}`;
    config.headers.Authorization = authString;
    const currentRole = window?.localStorage.getItem("role");
    if (currentRole !== null) {
      config.headers["X-Role"] = currentRole;
    }
  } else {
    await auth();
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    handleNetworkError(error);
    const originalRequest = error.config;
    if (error.response?.status === 401) {
      if (!originalRequest.retryRequest) {
        originalRequest.retryRequest = true;
        const accessToken = await refreshToken();
        if (!accessToken) {
          return auth();
        }
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      }
      return auth();
    }
    if (error.response?.status > 200 || !error.response?.status) {
      throw error;
    }
    return error;
  },
);

export function SubscribeOnNetworkError(callback: () => void): () => void {
  networkErrorHandlers.push(callback);
  return () => {
    const index = networkErrorHandlers.indexOf(callback);
    networkErrorHandlers.splice(index, 1);
  };
}

export const API_URL = APP_ENV.REACT_APP_BAPI_URL;
export const pathAllGroup = "/swap-admin/api/v1";
