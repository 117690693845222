import { Dropzone } from "@mantine/dropzone";
import { buildApiUrl } from "shared/api/config";
import { axiosInstance } from "shared/definitions/api";
import { ReactComponent as UploadIcon } from "shared/static/icons/adapted/upload.svg";
import { Title, Text } from "@mantine/core";
import cogoToast from "cogo-toast";
import { TOrganizationDocType, TPersonalDocType } from "shared/definitions";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import styles from "./documentDropZone.module.scss";

type TProps = {
  type: TOrganizationDocType | TPersonalDocType;
  isOrganization?: boolean;
  cid: string;
  getDocsHandle: () => void;
};

const DOC_MIME_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

const MIME_TYPES: Record<TOrganizationDocType | TPersonalDocType, string[]> = {
  proof_of_funds: DOC_MIME_TYPES,
  other: [...DOC_MIME_TYPES, "video/*"],
  identity_proof: ["application/pdf", "image/png", "image/jpeg"],
  finance_proof: DOC_MIME_TYPES,
};

export function DocumentDropZone(props: TProps) {
  return (
    <Dropzone
      accept={MIME_TYPES[props.type]}
      className={styles.root}
      onDrop={async (files) => {
        files.forEach((file) => {
          const formData = new FormData();
          formData.set("file", file);
          formData.set("file_name", file.name);
          const toast = cogoToast.loading(`Uploading file ${file.name}`, { hideAfter: 0 });
          axiosInstance
            .post(
              buildApiUrl(`/users/${props.isOrganization ? "organizations/" : ""}${props.cid}/files/${props.type}`),
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            )
            .then(() => {
              cogoToast.success(`Файл ${file.name} загружен`);
              // костыль пока не будет правлен рассинхрон на бэке
              setTimeout(() => {
                props.getDocsHandle();
              }, 3000);
            })
            .catch((e) => {
              cogoToast.error(`Не удалось загрузить ${file.name}`);
              sentryCaptureError(e);
            })
            .finally(() => {
              toast.hide?.();
            });
        });
      }}
      activateOnClick>
      <div className="w-100 display_flex">
        <UploadIcon className="margin_auto" width={64} />
      </div>
      <Title order={4} mb={12} mt={20} align="center">
        Загрузите документ
      </Title>
      <Text align="center">Перетащите или загрузите сюда документы, фото или видео файлы</Text>
    </Dropzone>
  );
}
