import React, { FC, useMemo } from "react";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { Flex, Title } from "@mantine/core";
import { TOrganizationDetails } from "shared/types/organizations";
import { OrganizationClientViewTabs } from "../OrganizationClientViewTabs";

interface IProps {
  clientDetails: TOrganizationDetails;
  tabName: string;
}

export const ClientTabHeader: FC<IProps> = ({ clientDetails, tabName }) => {
  const name = useMemo(() => {
    const nameArr = [clientDetails.fullName];
    if (nameArr.every((e) => !e)) return "Новый клиент";
    return nameArr.filter((v) => v).join(" ");
  }, [clientDetails]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: "Организации", link: "/clients/organizations" },
          { label: tabName, link: "" },
        ]}
      />
      <Spacer size={2} />
      <Flex gap="md" align="center">
        <Title order={2}>{name}</Title>
      </Flex>
      <Spacer size={6} />
      <OrganizationClientViewTabs />
      <Spacer size={5} />
    </>
  );
};
