import createRouter, { Options, Route } from "router5";
import browserPlugin from "router5-plugin-browser";
import listenersPlugin from "router5-plugin-listeners";
import {
  ClientScreenSubRoutes,
  FeesSubRoutes,
  WalletsSubRoutes,
  Routes,
  ClientsScreenSubRoutes,
  TransactionScreenSubRoutes,
  ClientOrganizationScreenSubRoutes,
  TickerOperationsScreenSubRoutes,
} from "shared/definitions";
import { isDev } from "shared/utils";

const routes: Route<Record<string, any>>[] = [
  { name: "home", path: "/", forwardTo: Routes.CLIENTS_SCREEN },
  {
    name: Routes.CLIENTS_SCREEN,
    path: "/clients",
    children: [
      { name: ClientsScreenSubRoutes.COMPANY, path: "/organizations" },
      { name: ClientsScreenSubRoutes.NOTYPE, path: "/notype" },
    ],
  },
  {
    name: Routes.CLIENT_SCREEN,
    path: "/client/:cid",
    children: [
      { name: ClientScreenSubRoutes.LOGS, path: "/logs" },
      { name: ClientScreenSubRoutes.COMMENTS, path: "/service-info" },
      { name: ClientScreenSubRoutes.FINANCES, path: "/finances" },
      { name: ClientScreenSubRoutes.DOCUMENTS, path: "/documents" },
    ],
  },
  {
    name: Routes.ORGANIZATION_SCREEN,
    path: "/organization/:cid",
    children: [
      { name: ClientOrganizationScreenSubRoutes.LOGS, path: "/logs" },
      { name: ClientOrganizationScreenSubRoutes.COMMENTS, path: "/service-info" },
      { name: ClientOrganizationScreenSubRoutes.FINANCES, path: "/finances" },
      { name: ClientOrganizationScreenSubRoutes.DOCUMENTS, path: "/documents" },
    ],
  },
  {
    name: Routes.TRANSACTIONS_SCREEN,
    path: "/transactions",
    children: [{ name: TransactionScreenSubRoutes.EXCHANGES, path: "/exchanges" }],
  },
  { name: Routes.TRANSACTION_SCREEN, path: "/transaction/:tid" },
  {
    name: Routes.FEES_SCREEN,
    path: "/fees",
    children: [
      { name: FeesSubRoutes.DEPOSIT, path: "/deposit" },
      { name: FeesSubRoutes.WITHDRAW, path: "/withdraw" },
      { name: FeesSubRoutes.EXCHANGE, path: "/exchange" },
    ],
  },
  {
    name: Routes.TICKER_OPERATIONS_SCREEN,
    path: "/ticker-operations",
    forwardTo: `${Routes.TICKER_OPERATIONS_SCREEN}.${TickerOperationsScreenSubRoutes.EXCHANGES}`,
    children: [
      { name: TickerOperationsScreenSubRoutes.EXCHANGES, path: "/exchanges" },
      { name: TickerOperationsScreenSubRoutes.WITHDRAW_DEPOSIT, path: "/withdraw-deposits" },
    ],
  },
  {
    name: Routes.WALLETS_SCREEN,
    path: "/wallets",
    children: [
      { name: WalletsSubRoutes.BALANCES, path: "/balances" },
      { name: WalletsSubRoutes.WHITELISTS, path: "/whitelists" },
      { name: WalletsSubRoutes.PAYIN, path: "/payin" },
      { name: WalletsSubRoutes.JOURNAL, path: "/journal" },
    ],
  },

  { name: Routes.RATES_AND_FEES_SCREEN, path: "/rates_and_fees" },
  { name: Routes.MANAGE_ASSETS_SCREEN, path: "/manage_assets" },
  { name: Routes.NOT_FOUND, path: "/not_found" },
];

if (isDev()) {
  routes.push({ name: "playground", path: "/playground" });
}

const params: Options | any = {
  defaultRoute: Routes.NOT_FOUND,
  defaultParams: {},
  queryParamsMode: "loose",
  strictTrailingSlash: true,
};

const router = createRouter(routes, params);

router.usePlugin(
  browserPlugin({
    base: "",
    useHash: false,
    hashPrefix: "",
    mergeState: true,
    preserveHash: false,
    forceDeactivate: true,
  }),
);

router.usePlugin(listenersPlugin());
router.start();

export default router;
