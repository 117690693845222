import { axiosInstance } from "shared/definitions/api";
import { getConfig } from "../config";

// eslint-disable-next-line import/no-mutable-exports
export let fetchUserBalancesAbortController = new AbortController() as AbortController;

export function fetchUserBalances({ id }: { id: string }) {
  fetchUserBalancesAbortController = new AbortController();
  return axiosInstance(
    getConfig(`/users/${id}/balances`, {}, { signal: fetchUserBalancesAbortController.signal }),
  ).then((res) => {
    return res.data.items;
  });
}
