import { TNotypeClient } from "shared/types/notypeUsers";
import { RowField } from "./RowField";

export const Row = ({ el }: { el: TNotypeClient }) => {
  return (
    <tr key={`ClientListView${el.id}`}>
      <td>
        <RowField value={el.publicId} field="publicId" />
      </td>
      <td>
        <RowField value={el.id} field="id" />
      </td>
      <td>
        <RowField value={el.email || "–"} field="email" />
      </td>
      <td>
        <RowField value={el.status} field="status" />
      </td>
    </tr>
  );
};
