import React, { FC } from "react";
import { sanitizeAmount } from "shared/utils/float";
import classNames from "classnames";
import styles from "./AmountInput.module.scss";

interface IProps {
  value?: string;
  onChange: (v?: string) => void;
  currencyNetwork?: string;
  color?: "gray" | "white";
}

export const AmountInput: FC<IProps> = ({ value, onChange, currencyNetwork, color = "gray" }) => {
  return (
    <div className={classNames(styles.inputContainer, { [styles.white]: color === "white" })}>
      <input
        type="number"
        value={value}
        onChange={({ target: { value: v } }) => {
          onChange(sanitizeAmount(v));
        }}
      />
      {currencyNetwork?.toUpperCase()}
    </div>
  );
};
