import { TTransactionDetails } from "shared/types/transactions";
import { Button, Flex, Grid, Group, Modal, Textarea, Title } from "@mantine/core";
import {
  CHANGE_STATUS_REQUEST_SUCCESS_TEXT,
  useStylesTransactionViewBlock,
  useTransactionViewBlocksLogic,
} from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { FieldsWrapper } from "components/ui/fields";
import { useCallback, useMemo, useState } from "react";
import { useRoles } from "shared/hooks/useRoles";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import cogoToast from "cogo-toast";
import { changeTransactionStatus } from "shared/api";
import { setIsShownTransactionPanel, setStatusTransactionById, setStatusTransactionDetail } from "store/slices";
import { useAppDispatch } from "store/store";
import { formatFiat } from "shared/utils/formatNumber";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const WithdrawFiatTransfer = ({
  detail,
  updateList,
}: {
  detail: TTransactionDetails;
  updateList: () => void;
}) => {
  const { classes } = useStylesTransactionViewBlock();
  const {
    blockSimplyTransactionStatus,
    renderTypicalTextField,
    blockUpdatedAt,
    blockComment,
    blockServiceFee,
    blockCreatedAt,
    isDetailPage,
  } = useTransactionViewBlocksLogic(detail, updateList);

  const dispatch = useAppDispatch();
  const [isOpenedModalChangeTransactionStatus, setIsOpenedModalChangeTransactionStatus] = useState(false);
  const [commentOfChangeTransactionStatus, setCommentOfChangeTransactionStatus] = useState("");
  const [typeHelperChangeTransactionStatus, setTypeHelperChangeTransactionStatus] = useState<"completed" | "rejected">(
    "completed",
  );

  const { isOperator } = useRoles();
  const isAllowButtonChangeTransactionStatusAtWithdrawType =
    isOperator && detail.transactionStatus === TransactionStatusKeys.sending;

  const blockAmountWithoutFee = useMemo(
    () =>
      renderTypicalTextField(
        "Сумма до вычета",
        formatFiat({ value: Number(detail.amountFrom), postfix: detail.tickerFrom }),
      ),
    [detail],
  );

  const blockAmountWithFee = useMemo(
    () =>
      renderTypicalTextField(
        "К зачислению",
        formatFiat({ value: Number(detail.amountTo), postfix: detail.tickerFrom }),
      ),
    [detail],
  );

  const handleSetTransactionStatus = useCallback(
    (tid: string, comment: string) => {
      if (!comment.length) return cogoToast.error("Укажите комментарий");
      setIsOpenedModalChangeTransactionStatus(false);
      changeTransactionStatus({
        transactionId: tid,
        data: {
          comment,
          status: typeHelperChangeTransactionStatus,
        },
      })
        .then(() => {
          cogoToast.success(CHANGE_STATUS_REQUEST_SUCCESS_TEXT);
          dispatch(
            setStatusTransactionById({
              transactionId: detail.transactionId,
              transactionStatus: typeHelperChangeTransactionStatus,
            }),
          );
          dispatch(setStatusTransactionDetail("pending"));
          dispatch(setIsShownTransactionPanel(false));
          updateList();
        })
        .catch(sentryCaptureError)
        .finally(() => {
          setCommentOfChangeTransactionStatus("");
        });
    },
    [typeHelperChangeTransactionStatus],
  );

  return (
    <div>
      <Modal
        radius={16}
        title={<Title order={3}>Подтверждение смены статуса</Title>}
        opened={isOpenedModalChangeTransactionStatus}
        onClose={() => setIsOpenedModalChangeTransactionStatus(false)}>
        <Padding horizontal={1} vertical={1}>
          <Textarea
            size="lg"
            value={commentOfChangeTransactionStatus}
            onChange={(event) => setCommentOfChangeTransactionStatus(event.currentTarget.value)}
            radius={12}
            label="Комментарий"
            autosize
            placeholder="Введите текст"
          />
          <Spacer size={6} />
          <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
            <Button
              radius={12}
              size="md"
              color={typeHelperChangeTransactionStatus === "completed" ? "green" : "red"}
              onClick={() => {
                handleSetTransactionStatus(detail?.transactionId, commentOfChangeTransactionStatus);
              }}>
              {typeHelperChangeTransactionStatus === "completed" ? "Завершить вывод" : "Отклонить вывод"}
            </Button>
            <Button
              ml={16}
              radius={12}
              size="md"
              variant="default"
              onClick={() => setIsOpenedModalChangeTransactionStatus(false)}>
              Отмена
            </Button>
          </Flex>
        </Padding>
      </Modal>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <FieldsWrapper>
            {blockSimplyTransactionStatus}
            {blockAmountWithoutFee}
            {blockAmountWithFee}
            {blockServiceFee}
            {blockCreatedAt}
            {blockUpdatedAt}
            {blockComment}
          </FieldsWrapper>
        </Grid.Col>
      </Grid>
      {isAllowButtonChangeTransactionStatusAtWithdrawType && (
        <div>
          <div className={isDetailPage ? classes.footer : classes.fixedFooter}>
            <Padding horizontal={isDetailPage ? 0 : 2} vertical={1}>
              <Title order={5} mb={12}>
                Завершение и отклонение заявки на операции с наличными
              </Title>
              <Group>
                <Button
                  radius={4}
                  size="sm"
                  compact
                  variant="light"
                  color="green"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("completed");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Завершить вывод
                </Button>
                <Button
                  radius={4}
                  size="sm"
                  compact
                  variant="light"
                  color="red"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("rejected");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Отклонить вывод
                </Button>
              </Group>
            </Padding>
          </div>
        </div>
      )}
    </div>
  );
};
