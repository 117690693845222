import React, { FC, useCallback, useState } from "react";
import { Paper, Text, Title } from "@mantine/core";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useCurrencyRates } from "shared/hooks/useCurrencyRates";
import { setRatePair } from "shared/api/currencies/setRatePair";
import cogoToast from "cogo-toast";
import { useRoles } from "shared/hooks/useRoles";
import styles from "./styles.module.scss";
import { TFocusedInput, TSubmitFN } from "./types";
import { RateGroup } from "./RateGroup";

export const RatesAndFees: FC = () => {
  const { isFinManager, isHeadOfFinManager } = useRoles();
  const { rates, fetchCurrencyRates } = useCurrencyRates();

  const [focusedInput, setFocusedInput] = useState<TFocusedInput>();

  const submit: TSubmitFN = useCallback(
    async (type, value) => {
      if (!focusedInput || !Number.isFinite(value)) return false;

      const currentPair = { ...rates.find((r) => r.id === focusedInput.id)! };
      currentPair[type] = value.toString();

      try {
        const res = await setRatePair({
          rateId: focusedInput.id,
          data: { buy: +currentPair.buy, sell: +currentPair.sell },
        });
        await fetchCurrencyRates();

        cogoToast.success("Курс успешно изменен");
        return res.status === "ok";
      } catch {
        cogoToast.error("Ошибка при исполнении запроса");
        return false;
      }
    },
    [focusedInput, rates],
  );

  if (!(isFinManager || isHeadOfFinManager))
    return (
      <Padding vertical={3} horizontal={3}>
        Доступ запрещён
      </Padding>
    );

  return (
    <Padding horizontal={4} vertical={3}>
      <Title order={1} fz={24}>
        Управление курсами
      </Title>
      <Spacer size={4} />

      <Paper shadow="xs" radius={16} p="xl" className={styles.rateCard}>
        {rates
          .filter((el) => el.tickerBase !== el.tickerQuoted)
          .map((r) => (
            <div key={r.id} className={styles.rateRow}>
              <Text fz={20} color={"grayUI-8"} className={styles.rateRowTitle}>
                {r.tickerBase}/{r.tickerQuoted}
              </Text>

              <div className={styles.rateInputsContainer}>
                <RateGroup
                  rateData={r}
                  type={"buy"}
                  defaultValue={r.buy}
                  focusedInput={focusedInput}
                  setFocusedInput={setFocusedInput}
                  submit={submit}
                />

                <RateGroup
                  rateData={r}
                  type={"sell"}
                  defaultValue={r.sell}
                  focusedInput={focusedInput}
                  setFocusedInput={setFocusedInput}
                  submit={submit}
                />
              </div>
            </div>
          ))}
      </Paper>
    </Padding>
  );
};
