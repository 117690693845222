import { TLog } from "shared/types/logs";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetLogsRequestProps = {
  page: number;
  page_size: number;
  newest_first?: boolean;
  from?: string;
  action?: string;
  to?: string;
  word_input?: string;
  actor_role?: string;
};

export type GetLogsResponse = {
  count: number;
  userLogs: TLog[] | null;
};

export async function getLogs(userId: string, params: GetLogsRequestProps) {
  return axiosInstance<GetLogsResponse>(getConfig(`/users/${userId}/logs`, params));
}
