import { createStyles, getStylesRef } from "@mantine/core";
import { ReactNode, memo } from "react";
import { fieldMinHeight } from "components/ui/fields/config";

type FieldWrapperProps = {
  label: string;
  labelWidth?: number;
  labelAfterContent?: ReactNode;
  children?: ReactNode;
};

const useStyles = createStyles((theme, _params) => ({
  wrapper: {
    "width": "100%",
    "minHeight": fieldMinHeight,
    "boxSizing": "border-box",
    "display": "flex",
    "borderBottom": "1px solid #E9ECEF",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  label: {
    marginLeft: 0,
    background: "#F1F3F5",
    display: "flex",
    whiteSpace: "nowrap",
    position: "relative",
    [`&:hover .${getStylesRef("labelContent")}`]: {
      opacity: 1,
    },
  },
  labelText: {
    fontWeight: 700,
    padding: "11px 16px",
    fontSize: 14,
    lineHeight: "24px",
    flex: 1,
  },
  labelContent: {
    cursor: "pointer",
    ref: getStylesRef("labelContent"),
    background: "rgba(241,243,245,0.9)",
    opacity: 0,
    display: "flex",
    flex: 1,
    marginRight: 0,
    marginLeft: "auto",
    minHeight: fieldMinHeight,
    alignContent: "center",
    justifyContent: "center",
    position: "absolute",
    right: 0,
  },
  contentWrapper: {
    flex: "1",
    display: "flex",
  },
}));

export const FieldWrapper = memo((props: FieldWrapperProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.label} style={{ width: props?.labelWidth || 160 }}>
        <div className={classes.labelText}>
          <span>{props.label}</span>
        </div>
        {props?.labelAfterContent && (
          <div className={classes.labelContent}>
            <div className="margin_auto margin_left_auto margin_right_0">{props.labelAfterContent}</div>
          </div>
        )}
      </div>
      <div className={classes.contentWrapper} style={{ width: `calc(100% - ${props?.labelWidth || 160}px)` }}>
        {props.children}
      </div>
    </div>
  );
});
