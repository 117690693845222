import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useAppDispatch, useAppSelector } from "store/store";
import { Badge, Group, Title } from "@mantine/core";
import { useUpdateOnlyWithMouseActions } from "shared/hooks/useUpdateOnlyWithMouseActions";
import { useEffect } from "react";
import { resetOrganizationClientFilters } from "store/slices";
import { ClientsTabs } from "../Tabs";
import { Wrapper } from "../Wrapper";
import { useGetUsers } from "./useGetUsers";
import { List } from "./List";
import { ClientsPagination } from "./ClientsPagination";
import { Filters } from "./Filters";

export function OrganizationClients() {
  const dispatch = useAppDispatch();
  const { clients, getUsersRequest } = useGetUsers();
  const countAll = useAppSelector((state) => state.organizationClients.organizationClientsCountAll);

  useUpdateOnlyWithMouseActions(getUsersRequest);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationClientFilters());
    };
  }, []);

  return (
    <Wrapper>
      <ClientsTabs />
      <Spacer size={6} />
      <Filters />
      <Spacer size={6} />
      <Group>
        <Title order={2} display="flex">
          Все юридические лица
        </Title>
        <Badge variant="outline" color="violet" size="md" radius="sm">
          {countAll}
        </Badge>
      </Group>
      <Spacer size={3} />
      <List data={clients} />
      <Spacer size={3} />
      <ClientsPagination />
    </Wrapper>
  );
}
