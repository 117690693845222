import "dayjs/locale/ru";
import "./index.css";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router5";
import { initSentry } from "shared/utils/environment/sentry";
import App from "./App";
import router from "./router";

initSentry();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const Application = () => (
  <RouterProvider router={router}>
    <App router={router} />
  </RouterProvider>
);

root.render(<Application />);
