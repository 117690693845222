import { TFiatFlow } from "shared/definitions";
import { axiosInstance } from "shared/definitions/api";
import cogoToast from "cogo-toast";
import { postConfig } from "../config";

export type TEstimateFeeRequestParams = {
  amount: string;
  tickerTo?: string;
  tickerFrom?: string;
  fiatFlowType?: TFiatFlow;
};

export type TEstimateFeeResponse = {
  amountFrom: string;
  amountTo: string;
  fee: string;
  feeTicker: string;
};

export type TEstimateFeeData = {
  amountFrom: number;
  amountTo: number;
  fee: number;
  feeTicker: string;
};

/* eslint-disable import/no-mutable-exports */
export let estimateFeeAbortController = new AbortController() as AbortController;

export async function estimateFee(params: TEstimateFeeRequestParams) {
  estimateFeeAbortController = new AbortController();
  return axiosInstance(
    postConfig("/transactions/calculate-amounts-fee", params, {
      signal: estimateFeeAbortController.signal,
    }),
  ).then((res: any) => {
    return {
      amountFrom: Number.parseFloat(res.data.amountFrom),
      amountTo: Number.parseFloat(res.data.amountTo),
      fee: Number.parseFloat(res.data.fee),
      feeTicker: res.data.feeTicker,
    };
  });
}
