import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TWalletsBalanceSortedType, TWalletsBalanceType, TWalletType } from "shared/types/wallets";

type TWalletsBalanceByTickerType = {
  [key: string]: { [key: TWalletType | string]: TWalletsBalanceSortedType };
};

interface WalletsBalancesSlice {
  walletsBalancesData: TWalletsBalanceType[];
  walletsBalancesDataByTicker: TWalletsBalanceByTickerType;
}

const initialState: WalletsBalancesSlice = {
  walletsBalancesData: [],
  walletsBalancesDataByTicker: {},
};

const walletsBalancesSlice = createSlice({
  name: "walletsBalances",
  initialState,
  reducers: {
    setWalletsBalancesData(state, action: PayloadAction<[] | TWalletsBalanceType[]>) {
      const unsortedPayload: TWalletsBalanceSortedType[] = [];
      const sortedPayload: { [key: string]: { [key: TWalletType | string]: TWalletsBalanceSortedType } } = {};

      action.payload.forEach((el: any) => {
        const { walletType, network } = el;
        Object.keys(el.balances).forEach((ticker: string) => {
          unsortedPayload.push({
            count: el.total,
            sum: el.balances[ticker],
            walletType,
            ticker,
            network,
          });
        });
      });

      unsortedPayload.forEach((el: TWalletsBalanceSortedType) => {
        const { ticker: t, network } = el;
        const ticker = `${t}${network ? "_" + network : ""}`;
        sortedPayload[ticker] = { ...sortedPayload[ticker], [el.walletType]: el };
      });

      state.walletsBalancesDataByTicker = sortedPayload;
      state.walletsBalancesData = action.payload;
    },
  },
});

export const { setWalletsBalancesData } = walletsBalancesSlice.actions;
export const walletsBalancesReducer = walletsBalancesSlice.reducer;
