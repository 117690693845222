import React, { FC } from "react";
import { SubmittableNumberInput } from "components/common/SubmittableNumberInput/SubmittableNumberInput";
import styles from "./Input.module.scss";
import { TInputValidatorFN } from "../Deposit/types";

interface IProps {
  id: string;
  focusedInputId: string | undefined;
  setFocusedInputId: (v: string | undefined) => void;
  handleSubmit: (v: string) => any;
  label?: string;
  value?: string;
  ticker?: string;
  error?: string;
  validate: TInputValidatorFN;
}

export const Input: FC<IProps> = ({
  id,
  focusedInputId,
  setFocusedInputId,
  label,
  error,
  value,
  ticker,
  validate,
  handleSubmit,
}) => {
  return (
    <div className={styles.inputFieldContainer}>
      {label && <div className={styles.inputFieldLabel}>{label}</div>}

      {ticker && <div className={styles.inputFieldTicker}>{ticker}</div>}

      <SubmittableNumberInput
        defaultValue={value ?? ""}
        submit={handleSubmit}
        validate={validate}
        inputId={id}
        focusedInputId={focusedInputId}
        setFocusedInputId={setFocusedInputId}
        error={error}
      />
    </div>
  );
};
