import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getNotypeUsers, GetNotypeClientssRequestProps } from "shared/api";
import { treatmentNotypeClientsData } from "shared/utils";
import { setNotypeClients, setNotypeClientsCountAll } from "store/slices/notypeClientsSlice";

export const useGetUsers = () => {
  const dispatch = useAppDispatch();
  const { notypeClientsList, notypeClientsPage, notypeClientsPageSize, notypeClientsSearchInput } = useAppSelector(
    ({ notypeClients }) => notypeClients,
  );
  
  const getUsersRequest = useCallback(() => {
    const params: GetNotypeClientssRequestProps = {
      page: notypeClientsPage,
      page_size: notypeClientsPageSize,
    };
    if (notypeClientsSearchInput) {
      params.search = notypeClientsSearchInput;
    }
    return getNotypeUsers(params);
  }, [notypeClientsPage, notypeClientsPageSize, notypeClientsSearchInput]);

  useEffect(() => {
    getUsersRequest().then((response) => {
      if (response.data.items === null) {
        dispatch(setNotypeClients([]));
        dispatch(setNotypeClientsCountAll(0));
      } else {
        const items = response.data.items.map(treatmentNotypeClientsData);
        dispatch(setNotypeClients(items));
        dispatch(setNotypeClientsCountAll(response.data.count));
      }
    });
  }, [getUsersRequest]);

  return {
    getUsersRequest,
    clients: notypeClientsList,
  };
};
