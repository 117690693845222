import { AxiosRequestConfig, GenericAbortSignal } from "axios";
import { API_URL, pathAllGroup } from "shared/definitions/api";

export function buildApiUrl(path: string) {
  return `${API_URL}${pathAllGroup}${path}`;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    keepSnakeCase?: boolean;
  }
}

export const postConfig = (path: string, params: any, options: any = {}) => {
  const conf = {
    method: "post",
    url: `${API_URL}${pathAllGroup}${path}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(params),
  } as AxiosRequestConfig;

  if ("signal" in options) {
    conf.signal = options.signal;
  }

  return conf;
};

export const deleteConfig = (path: string, params: any, options: any = {}) => {
  const conf = {
    method: "delete",
    url: `${API_URL}${pathAllGroup}${path}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(params),
  } as AxiosRequestConfig;

  if ("signal" in options) {
    conf.signal = options.signal;
  }

  return conf;
};

type TOptions = {
  signal?: GenericAbortSignal;
  keepSnakeCase?: boolean;
};

export const getConfig = (path: string, params: any = {}, options: TOptions = {}): AxiosRequestConfig => {
  const conf: AxiosRequestConfig = {
    method: "get",
    url: `${API_URL}${pathAllGroup}${path}`,
    params,
    headers: {
      "Content-Type": "application/json",
    },
    keepSnakeCase: options.keepSnakeCase,
  };

  if ("signal" in options) {
    conf.signal = options.signal;
  }
  return conf;
};
