import React, { useCallback } from "react";
import { Button } from "@mantine/core";
import { TPersonalClient } from "shared/types/clients";
import { FieldTreatment } from "components/common/FieldTreatment";
import { useAppDispatch } from "store/store";
import { setPersonalClientDetailId } from "store/slices";

export const Row = ({ el }: { el: TPersonalClient }) => {
  const dispatch = useAppDispatch();

  const openClientPreview = useCallback(() => {
    dispatch(setPersonalClientDetailId(el.id));
  }, [el.id]);

  return (
    <tr key={`ClientListView${el.id}`}>
      <td>
        <FieldTreatment name="userPublicId" onClick={openClientPreview}>
          {el.publicId!.toString() ?? ""}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="firstName">
          {`${el.firstName ?? ""}${el.lastName ?? ""}`.length > 0 ? el.firstName + " " + el.lastName : null}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="docNumber">{el.docNumber}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="bDay">{el.bDay}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="email">{el.email}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="phone">{el.phone}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="clientStatus">{el.status}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="balance">{""}</FieldTreatment>
      </td>
      <td>
        <div className="cursor_pointer no_select" onClick={openClientPreview}>
          <Button compact color="violet" variant="subtle">
            Открыть
          </Button>
        </div>
      </td>
    </tr>
  );
};
