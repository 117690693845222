import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  modalsReducer,
  authReducer,
  personalClientsReducer,
  transactionsReducer,
  depositReducer,
  currenciesReducer,
  manageAssetsReducer,
  navbarReducer,
  feesReducer,
  journalTransfersReducer,
  walletsBalancesReducer,
  newTransferReducer,
  clientFinancesReducer,
  organizationClientsReducer,
  payinReducer,
  newPayinTransferReducer,
} from "./slices";
import { logsReducer } from "./slices/logsSlice";
import { notypeClientsReducer } from "./slices/notypeClientsSlice";
import { serviceInfoReducer } from "./slices/serviceInfoSlice";

export const store = configureStore({
  reducer: {
    modals: modalsReducer,
    newTransfer: newTransferReducer,
    newPayinTransfer: newPayinTransferReducer,
    auth: authReducer,
    deposit: depositReducer,
    personalClients: personalClientsReducer, // personalClients
    organizationClients: organizationClientsReducer, // organizationClients
    notypeClients: notypeClientsReducer, // notypeClients
    currencies: currenciesReducer,
    transactions: transactionsReducer,
    logs: logsReducer,
    serviceInfo: serviceInfoReducer,
    manageAssets: manageAssetsReducer,
    navbar: navbarReducer,
    fees: feesReducer,
    journalTransfers: journalTransfersReducer,
    walletsBalances: walletsBalancesReducer,
    clientFinances: clientFinancesReducer,
    payin: payinReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
