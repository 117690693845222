import { TWalletType } from "shared/types/wallets";
import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export type PostNewTransferRequestProps = {
  network?: string;
  wallet_type_from: TWalletType;
  wallet_type_to: TWalletType | "";
  amount: string;
  ticker: string;
  transaction_comment: string;
};

/* eslint-disable import/no-mutable-exports */
export let postNewTransferAbortController = new AbortController() as AbortController;

export function postNewTransfer(params: PostNewTransferRequestProps) {
  postNewTransferAbortController = new AbortController();
  return axiosInstance(
    postConfig("/custody/transfers", params, {
      signal: postNewTransferAbortController.signal,
    }),
  );
}
