import React, { FC, ReactNode } from "react";
import { Loader, Paper } from "@mantine/core";
import cn from "classnames";
import styles from "./financeCard.module.scss";

export interface IFinanceCardProps {
  children?: ReactNode;
  loading?: boolean;
}

export const FinanceCard: FC<IFinanceCardProps> = ({ children, loading }) => {
  return (
    <Paper shadow="xs" radius={8} p="24px" className={cn(styles.card, { [styles.loading]: loading })}>
      {loading ? <Loader /> : children}
    </Paper>
  );
};
