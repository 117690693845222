import React, { useCallback, useMemo } from "react";
import {
  Button,
  Chip,
  Grid,
  Group,
  Text,
  Alert,
  LoadingOverlay,
  Modal,
  Paper,
  Select,
  Textarea,
  Title,
  Skeleton,
  Flex,
} from "@mantine/core";
import cogoToast from "cogo-toast";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";
import { VerificationLevel } from "shared/definitions/levels/verifications";
import { RiskLevel } from "shared/definitions/levels/risk";
import { useDispatch } from "react-redux";
import { ClientStatusKeys } from "shared/definitions/levels/clientStatus";
import {
  setPersonalClientDetailRiskLevelByOperations,
  setPersonalClientDetailRiskLevelByProfile,
  setPersonalClientDetailRiskLevelByRegion,
  setPersonalClientDetailVerificationLevel,
} from "store/slices";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import { blockUser, changeRiskLevel, changeVerificationLevel, unBlockUser } from "shared/api";
import { useRoles } from "shared/hooks/useRoles";
import { switchMatch } from "shared/utils";
import { ValuesOf } from "shared/types/common";
import dayjs from "dayjs";
import { IconAlertCircle } from "@tabler/icons";
import { TBlockReasons } from "shared/types/clients";
import { labelServicesTreatment } from "shared/utils/labelServicesTreatment";
import {
  useBlockClientModalLogic,
  useRiskLevelModalLogic,
  useVerificationLevelModalLogic,
} from "shared/features/ClientsDetail/Verification/useVerificationModalLogic";
import { UpdateClientTypeButton } from "shared/features";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { useGetClientDetails } from "../../useGetClientDetails";

const selectSxStyles: any = {
  "width": "100%",
  "& input": {
    height: "46px",
    fontSize: "14px !important",
    fontFamily: "Roboto",
    lineHeight: "46px",
    boxSizing: "border-box",
    padding: "0 16px",
  },
};

export const Verification = ({ cid }: { cid: string }) => {
  const dispatch = useDispatch();
  const { setIsOpenedBlockClientModal, blockClientModalIsOpened, reasonBlockClient, setReasonBlockClient } =
    useBlockClientModalLogic();

  const { isCompliance, isFinManager, isHeadOfFinManager, isSupport } = useRoles();

  const { setIsOpenedRiskLevelModal, riskLevelModalIsOpened, reasonChangeRiskLevel, setReasonChangeRiskLevel } =
    useRiskLevelModalLogic();

  const {
    setIsOpenedVerificationLevelModal,
    verificationLevelModalIsOpened,
    reasonChangeVerificationLevel,
    setReasonChangeVerificationLevel,
  } = useVerificationLevelModalLogic();

  const { clientDetails, isLoadedClientDetails, getUserHandle } = useGetClientDetails({ cid });

  const verificationLevels = { ...VerificationLevel } as any;
  const verificationLevelsData = useMemo(
    () => [
      ...Object.keys(verificationLevels).map((level: string) => ({
        label: verificationLevels[level],
        value: getEnumKeyByEnumValue(VerificationLevel, verificationLevels[level]),
      })),
    ],
    [],
  );

  const riskLevels = { ...RiskLevel } as any;
  const riskLevelsData = useMemo(
    () => [
      ...Object.keys(riskLevels).map((level: string) => ({
        label: riskLevels[level],
        value: getEnumKeyByEnumValue(RiskLevel, riskLevels[level]),
      })),
    ],
    [],
  );

  const riskRowRender = useCallback((risk: ValuesOf<typeof RiskLevel>) => {
    return <FieldsContentText text={riskLevels[risk]} />;
  }, []);

  if (!isLoadedClientDetails) return <LoadingOverlay visible />;

  let blockReasons: TBlockReasons = [];

  if (clientDetails?.calculatedChecks?.worldCheckOne.blockReasons !== null) {
    blockReasons = [...blockReasons, ...clientDetails.calculatedChecks.worldCheckOne.blockReasons!];
  }

  if (clientDetails?.calculatedChecks?.byKgb.blockReasons !== null) {
    blockReasons = [...blockReasons, ...clientDetails.calculatedChecks.byKgb.blockReasons!];
  }

  if (clientDetails?.calculatedChecks?.sumsub.blockReasons !== null) {
    blockReasons = [...blockReasons, ...clientDetails.calculatedChecks.sumsub.blockReasons!];
  }

  const servicesChecks = {
    sumsub: {
      isChecked: clientDetails.calculatedChecks?.sumsub?.isServiceChecked ?? false,
      isApproved: clientDetails.calculatedChecks?.sumsub?.isApproved,
    },
    bykgb: {
      isChecked: clientDetails.calculatedChecks?.byKgb.isServiceChecked ?? false,
      isApproved: clientDetails.calculatedChecks?.byKgb.isApproved ?? false,
    },
    worldCheckOne: {
      isChecked: clientDetails.calculatedChecks?.worldCheckOne?.isServiceChecked ?? false,
      isApproved: clientDetails.calculatedChecks?.worldCheckOne?.isApproved ?? false,
    },
  };

  return (
    <div>
      <Paper>
        <Title order={4} mb={16}>
          Верификация и риск профиль
        </Title>
        {blockReasons.map(({ additionalInfo, comment, labels }: any, i: number) => (
          <Alert radius={8} icon={<IconAlertCircle size="1rem" />} title="Внимание!" color="red" mb={16}>
            <Text key={`reason${additionalInfo}${i}`} color="red" mb={24}>
              <strong>{comment ?? ""}</strong>
              <div>{additionalInfo ?? ""}</div>
              {labels && <div>{labels.length ? labels.map((label: string) => labelServicesTreatment(label)) : ""}</div>}
            </Text>
          </Alert>
        ))}
        <Group>
          <div>
            <Chip
              key={"verificationChipTest}"}
              checked={clientDetails.passedTheTest}
              color="green"
              variant="filled"
              size="sm"
              radius="sm">
              <strong style={{ color: clientDetails.passedTheTest ? "white" : "red" }}>Тестирование</strong>
            </Chip>
            <div>
              <Skeleton height={16} mt={5} width={100} />
            </div>
          </div>
          {
            <div>
              <Chip
                key="verificationChipSumsub"
                style={
                  !servicesChecks.sumsub.isChecked
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
                checked={servicesChecks.sumsub.isApproved}
                color="green"
                variant="filled"
                size="sm"
                radius="sm">
                <strong style={{ color: servicesChecks.sumsub.isApproved ? "white" : "red" }}>
                  Sumsub:
                  {servicesChecks.sumsub.isChecked ? (
                    <span>{servicesChecks.sumsub.isApproved ? "Approved" : "Rejected"}</span>
                  ) : (
                    "Не проверен"
                  )}
                </strong>
              </Chip>
              <div>
                <small>
                  {servicesChecks.sumsub.isChecked ? (
                    dayjs(clientDetails.calculatedChecks?.sumsub?.date!.toString()).format("DD.MM.YYYY HH:mm:ss")
                  ) : (
                    <Skeleton height={16} mt={5} width={100} />
                  )}
                </small>
              </div>
            </div>
          }
          {
            <div>
              <Chip
                key="verificationChipTerrorist"
                style={
                  !servicesChecks.bykgb.isChecked
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
                checked={servicesChecks.bykgb.isApproved}
                color="green"
                variant="filled"
                size="sm"
                radius="sm">
                <strong style={{ color: servicesChecks.bykgb.isApproved ? "white" : "red" }}>
                  Террорист:
                  {servicesChecks.bykgb.isChecked ? (
                    <span>{servicesChecks.bykgb.isApproved ? "Нет" : "Да"}</span>
                  ) : (
                    "Не проверен"
                  )}
                </strong>
              </Chip>
              <div>
                {servicesChecks.bykgb.isChecked ? (
                  <small>
                    {dayjs(clientDetails.calculatedChecks?.byKgb?.date!.toString()).format("DD.MM.YYYY HH:mm:ss")}
                  </small>
                ) : (
                  <Skeleton height={16} mt={5} width={100} />
                )}
              </div>
            </div>
          }
          {
            <div>
              <Chip
                key="verificationChipPep"
                style={!servicesChecks.worldCheckOne.isChecked ? { opacity: 0.5 } : {}}
                checked={servicesChecks.worldCheckOne.isApproved}
                color="green"
                variant="filled"
                size="sm"
                radius="sm">
                <strong style={{ color: servicesChecks.worldCheckOne.isApproved ? "white" : "red" }}>
                  Worldcheck:
                  {servicesChecks.worldCheckOne.isChecked ? (
                    <span>{servicesChecks.worldCheckOne.isApproved ? "Approved" : "Rejected"}</span>
                  ) : (
                    "Не проверен"
                  )}
                </strong>
              </Chip>
              <div>
                <small>
                  {clientDetails.calculatedChecks?.worldCheckOne?.isServiceChecked ? (
                    dayjs(clientDetails.calculatedChecks?.worldCheckOne?.date!.toString()).format("DD.MM.YYYY HH:mm:ss")
                  ) : (
                    <Skeleton height={16} mt={5} width={100} />
                  )}
                </small>
              </div>
            </div>
          }
        </Group>
        <Spacer size={6} />
        {clientDetails.status === ClientStatusKeys.new && <UpdateClientTypeButton cid={cid} type="individual" />}
        <Spacer size={6} />
        <Grid columns={2}>
          <Grid.Col span={1}>
            <Grid columns={12}>
              <Grid.Col span={12} style={{ cursor: "not-allowed" }}>
                <FieldsWrapper>
                  <FieldWrapper labelWidth={230} label="Итоговый уровень риска">
                    <FieldsContentText
                      isCopiable={false}
                      inline
                      text={riskLevels[clientDetails?.commonRiskLevel || riskLevelsData[0]]}
                    />
                  </FieldWrapper>
                </FieldsWrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <FieldsWrapper>
                  <FieldWrapper labelWidth={230} label="Риск по профилю клиента">
                    {isCompliance ? (
                      <Select
                        size="md"
                        variant="unstyled"
                        radius={4}
                        value={clientDetails?.riskLevelByProfile || riskLevelsData[0]}
                        onChange={(v: any) => dispatch(setPersonalClientDetailRiskLevelByProfile(v))}
                        placeholder="Риск по профилю клиента"
                        sx={selectSxStyles}
                        data={riskLevelsData}
                      />
                    ) : (
                      riskRowRender(clientDetails?.riskLevelByProfile || riskLevelsData[0])
                    )}
                  </FieldWrapper>
                </FieldsWrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <FieldsWrapper>
                  <FieldWrapper labelWidth={230} label="Риск по региону">
                    {isCompliance ? (
                      <Select
                        size="md"
                        variant="unstyled"
                        radius={4}
                        value={clientDetails?.riskLevelByRegion || riskLevelsData[0]}
                        onChange={(v: any) => dispatch(setPersonalClientDetailRiskLevelByRegion(v))}
                        placeholder="Риск по региону"
                        sx={selectSxStyles}
                        data={riskLevelsData}
                      />
                    ) : (
                      riskRowRender(clientDetails?.riskLevelByRegion || riskLevelsData[0])
                    )}
                  </FieldWrapper>
                </FieldsWrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <FieldsWrapper>
                  <FieldWrapper labelWidth={230} label="Риск по операциям">
                    {isCompliance ? (
                      <Select
                        size="md"
                        variant="unstyled"
                        radius={4}
                        value={clientDetails?.riskLevelByOperations || riskLevelsData[0]}
                        onChange={(v: any) => dispatch(setPersonalClientDetailRiskLevelByOperations(v))}
                        placeholder="Риск по операциям"
                        sx={selectSxStyles}
                        data={riskLevelsData}
                      />
                    ) : (
                      riskRowRender(clientDetails?.riskLevelByOperations || riskLevelsData[0])
                    )}
                  </FieldWrapper>
                </FieldsWrapper>
              </Grid.Col>
              {isCompliance && (
                <Grid.Col span={12}>
                  <Button radius={4} size="sm" onClick={() => setIsOpenedRiskLevelModal(true)}>
                    Применить
                  </Button>
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>

          <Grid.Col span={1}>
            <FieldsWrapper>
              <FieldWrapper labelWidth={230} label="Уровень верификации">
                {isCompliance ? (
                  <Select
                    variant="unstyled"
                    value={clientDetails?.verificationLevel || verificationLevelsData[0]}
                    onChange={(v: any) => dispatch(setPersonalClientDetailVerificationLevel(v))}
                    placeholder="Выберите уровень"
                    data={verificationLevelsData}
                    radius={0}
                    size="md"
                    sx={selectSxStyles}
                  />
                ) : (
                  <FieldsContentText
                    text={switchMatch(clientDetails?.verificationLevel ?? verificationLevelsData[0], {
                      level_2: "Продвинутый",
                      default: "Новичок",
                    })}
                  />
                )}
              </FieldWrapper>
            </FieldsWrapper>
            {isCompliance && (
              <Button mt={12} radius={4} size="sm" onClick={() => setIsOpenedVerificationLevelModal(true)}>
                Применить
              </Button>
            )}
          </Grid.Col>
        </Grid>
      </Paper>
      <Spacer size={6} />
      {isCompliance && clientDetails.status === ClientStatusKeys.restricted && (
        <Paper>
          <Button radius={8} size="md" color="green" variant="filled" onClick={() => setIsOpenedBlockClientModal(true)}>
            Разблокировать аккаунт
          </Button>
        </Paper>
      )}
      {clientDetails.status !== ClientStatusKeys.restricted &&
        (isSupport || isCompliance || isHeadOfFinManager || isFinManager) && (
          <Paper>
            <Button radius={8} size="md" color="red" variant="filled" onClick={() => setIsOpenedBlockClientModal(true)}>
              Заблокировать аккаунт
            </Button>
          </Paper>
        )}
      <Modal
        radius={16}
        title={<Title order={3}>Смена показателя риска</Title>}
        opened={riskLevelModalIsOpened}
        onClose={() => setIsOpenedRiskLevelModal(false)}>
        <Padding horizontal={1} vertical={1}>
          <Textarea
            size="lg"
            value={reasonChangeRiskLevel}
            onChange={(event) => setReasonChangeRiskLevel(event.currentTarget.value)}
            radius={12}
            label="Обоснование"
            sx={{
              label: { fontSize: "13px" },
            }}
            autosize
            placeholder="Введите текст"
          />
          <Spacer size={6} />
          <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
            <Button
              radius={12}
              size="md"
              onClick={() => {
                if (!reasonChangeRiskLevel.length) {
                  cogoToast.error("Укажите обоснование");
                  return;
                }
                setIsOpenedRiskLevelModal(false);
                changeRiskLevel(cid, {
                  operations: clientDetails?.riskLevelByOperations as any,
                  profile: clientDetails?.riskLevelByProfile as any,
                  region: clientDetails?.riskLevelByRegion as any,
                  reason: reasonChangeRiskLevel,
                })
                  .then(() => {
                    getUserHandle();
                    cogoToast.success("Уровни риска сохранены!");
                  })
                  .catch((e) => {
                    sentryCaptureError(e);
                    cogoToast.error("При смене уровней риска произошла ошибка");
                  })
                  .finally(() => {
                    setReasonChangeRiskLevel("");
                  });
              }}>
              Сохранить
            </Button>
            <Button ml={16} radius={12} size="md" variant="default" onClick={() => setIsOpenedRiskLevelModal(false)}>
              Отмена
            </Button>
          </Flex>
        </Padding>
      </Modal>
      <Modal
        radius={16}
        title={
          <Title order={3}>
            {clientDetails.status === ClientStatusKeys.restricted ? "Разблокировать" : "Заблокировать"} аккаунт
          </Title>
        }
        opened={blockClientModalIsOpened}
        onClose={() => setIsOpenedBlockClientModal(false)}>
        <Padding horizontal={1} vertical={1}>
          <Textarea
            size="lg"
            value={reasonBlockClient}
            onChange={(event) => setReasonBlockClient(event.currentTarget.value)}
            radius={12}
            label="Укажите причину"
            autosize
            placeholder="Введите текст"
          />
          <Spacer size={6} />
          <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
            <Button
              radius={12}
              size="md"
              color={clientDetails.status === ClientStatusKeys.restricted ? "green" : "red"}
              onClick={() => {
                if (!reasonBlockClient.length) {
                  cogoToast.error("Укажите причину");
                  return;
                }
                setIsOpenedBlockClientModal(false);
                const callMethod = clientDetails.status === ClientStatusKeys.restricted ? unBlockUser : blockUser;
                callMethod({
                  id: cid,
                  data: {
                    reason: reasonBlockClient,
                  },
                })
                  .then(() => {
                    getUserHandle();
                    cogoToast.success(
                      `Клиент ${
                        clientDetails.status === ClientStatusKeys.restricted ? "разблокирован" : "заблокирован"
                      }`,
                    );
                  })
                  .catch((e) => {
                    sentryCaptureError(e);
                    cogoToast.error(
                      `При ${
                        clientDetails.status === ClientStatusKeys.restricted ? "разблокировке" : "блокировке"
                      } произошла ошибка`,
                    );
                  })
                  .finally(() => {
                    setReasonBlockClient("");
                  });
              }}>
              {clientDetails.status === ClientStatusKeys.restricted ? "Разблокировать" : "Заблокировать"}
            </Button>
            <Button ml={16} radius={12} size="md" variant="default" onClick={() => setIsOpenedBlockClientModal(false)}>
              Отмена
            </Button>
          </Flex>
        </Padding>
      </Modal>
      <Modal
        radius={16}
        title={<Title order={3}>Смена уровня верификации</Title>}
        opened={verificationLevelModalIsOpened}
        onClose={() => setIsOpenedVerificationLevelModal(false)}>
        <Padding horizontal={1} vertical={1}>
          <Textarea
            size="lg"
            value={reasonChangeVerificationLevel}
            onChange={(event) => setReasonChangeVerificationLevel(event.currentTarget.value)}
            radius={12}
            label="Причина смены уровня"
            autosize
            placeholder="Введите текст"
          />
          <Spacer size={6} />
          <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
            <Button
              radius={12}
              size="md"
              onClick={() => {
                if (!reasonChangeVerificationLevel.length) {
                  cogoToast.error("Укажите причину");
                  return;
                }
                setIsOpenedVerificationLevelModal(false);
                changeVerificationLevel(cid, {
                  verificationLevel: clientDetails?.verificationLevel as any,
                  reason: reasonChangeVerificationLevel,
                })
                  .then(() => {
                    getUserHandle();
                    cogoToast.success("Уровень изменен!");
                  })
                  .catch((e) => {
                    if (
                      e?.response?.data?.error?.indexOf("the user must receive at least one validation in kyc") > -1
                    ) {
                      cogoToast.error("Пользователь должен получить хотя бы одну валидацию в kyc");
                    } else {
                      sentryCaptureError(e);
                    }
                    cogoToast.error("При смене уровня произошла ошибка");
                  })
                  .finally(() => {
                    setReasonChangeVerificationLevel("");
                  });
              }}>
              Подтвердить
            </Button>
            <Button
              ml={16}
              radius={12}
              size="md"
              variant="default"
              onClick={() => setIsOpenedVerificationLevelModal(false)}>
              Отмена
            </Button>
          </Flex>
        </Padding>
      </Modal>
    </div>
  );
};
