import { useEffect } from "react";
import { Title, Badge, Button, Group, Box } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { resetPersonalClientsFilters } from "store/slices";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { ReactComponent as SCVIcon } from "shared/static/icons/adapted/scv.svg";
import { useUpdateOnlyWithMouseActions } from "shared/hooks/useUpdateOnlyWithMouseActions";
import { useGetUsers } from "./useGetUsers";
import { ClientsTabs } from "../Tabs";
import { Wrapper } from "../Wrapper";
import { List } from "./List";
import { Filters } from "./Filters";
import { ClientsPagination } from "./ClientsPagination";

export const IndividualClients = () => {
  const dispatch = useAppDispatch();
  const { getUsersRequest } = useGetUsers();

  useUpdateOnlyWithMouseActions(getUsersRequest);

  const { clients: data, countAll } = useAppSelector(({ personalClients }) => ({
    clients: personalClients.personalClientsList,
    countAll: personalClients.personalClientsCountAll,
  }));

  const rows = [...data];

  useEffect(() => {
    return () => {
      dispatch(resetPersonalClientsFilters());
    };
  }, []);

  return (
    <Wrapper>
      <ClientsTabs />
      <Spacer size={6} />
      <Filters />
      <Spacer size={6} />
      <Group position="apart">
        <Group>
          <Title order={2} display="flex">
            Все физические лица
          </Title>
          <Badge variant="outline" color="violet" size="md" radius="sm">
            {countAll}
          </Badge>
        </Group>
        <Group>
          <Button
            variant="outline"
            size="xs"
            radius={8}
            color="violet"
            leftIcon={
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                }}>
                <SCVIcon />
              </Box>
            }>
            Скачать CSV
          </Button>
        </Group>
      </Group>
      <Spacer size={3} />
      <List data={rows} />
      <Spacer size={6} />
      <ClientsPagination />
      <Spacer size={6} />
    </Wrapper>
  );
};
