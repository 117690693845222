const nonFloatRe = /[^0-9\.]/g;

export const numberInputFormatToPrecision = (v: string | number | undefined, precision = 2) => {
  if (v === undefined) return "";

  let value = typeof v === "string" ? v : v.toString();
  value = value.replace(nonFloatRe, "");

  if (!value.includes(".")) return value;

  const [intPart, decPart] = value.split(".");
  return [intPart, decPart.slice(0, precision)].join(".");
};
