import { Text } from "@mantine/core";
import { ClientStatusBadge } from "components/common/ClientStatusBadge";
import { CopyActionIcon } from "components/common/CopyActionIcon";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { memo } from "react";
import { TOrganization } from "shared/types/organizations";

type TProps = {
  field: keyof TOrganization;
  value: string;
};

const CopyField = memo(({ value }: { value: string }) => {
  return (
    <div className="display_flex cursor_pointer no_select">
      <Text color="#212529">
        {value}
      </Text>
      <Spacer size={2} direction="horizontal" />
      <CopyActionIcon value={value} />
    </div>
  );
});

export function RowField(props: TProps) {
  switch (props.field) {
    case "id":
    case "publicId":
      return <CopyField value={props.value} />;
    case "status":
      return <ClientStatusBadge value={props.value} />;
    case "email":
    default:
      return (
        <Text color="#212529">
          {props.value}
        </Text>
      );
  }
}
