import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAccessToken } from "shared/utils/auth";

interface AuthSlice {
  tokenData: string | null;
  isLogged: boolean;
  currentRole: string | null;
  listOfRoles: string[];
  listOfMyRoles: string[];
}

const initialState: AuthSlice = {
  tokenData: getAccessToken() ?? null,
  isLogged: false,
  currentRole: window?.localStorage.getItem("role"),
  listOfRoles: [],
  listOfMyRoles: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<any>) {
      state.tokenData = action.payload;
    },
    setListOfRoles(state, action: PayloadAction<string[]>) {
      state.listOfRoles = action.payload;
    },
    setListOfMyRoles(state, action: PayloadAction<string[]>) {
      state.listOfMyRoles = action.payload;
    },
    setIsLogged(state, action: PayloadAction<any>) {
      state.isLogged = action.payload;
    },
    setCurrentRole(state, action: PayloadAction<string>) {
      state.currentRole = action.payload;
      window?.localStorage.setItem("role", action.payload);
    },
  },
});

export const { setToken, setListOfRoles, setListOfMyRoles, setIsLogged, setCurrentRole } = authSlice.actions;
export const authReducer = authSlice.reducer;
