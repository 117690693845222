import { Table } from "@mantine/core";
import { EmptyBlock } from "shared/features";
import { TOrganization } from "shared/types/organizations";
import { Row } from "./Row";
import { DetailsDrawer } from "./details/DetailsDrawer";

const ClientsListViewTableTitles = [
  "Public ID",
  "Наименование",
  "Индентиф. номер",
  "Электронная почта",
  "Статус",
  "Баланс",
  "",
];

export const List = ({ data }: { data: TOrganization[] }) => {
  const rows = data.map((element) => <Row key={element.id} el={element} />);

  if (!data.length) return <EmptyBlock />;

  return (
    <div>
      <DetailsDrawer />
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="md"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            {ClientsListViewTableTitles.map((title) => (
              <th key={`CLVTT${title}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
