import { useCallback, useEffect } from "react";
import { GetLogsRequestProps, getLogs } from "shared/api/logs";
import { LogsSlice, setLogsCountAll, setLogsItems } from "store/slices/logsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

function getLogsFilters(logsState: LogsSlice) {
  const params: GetLogsRequestProps = {
    page_size: logsState.pageSize,
    page: logsState.page,
  };
  if (logsState.filterAction) {
    params.action = logsState.filterAction;
  }
  if (logsState.filterRole) {
    params.actor_role = logsState.filterRole;
  }
  if (logsState.newestFirst) {
    params.newest_first = logsState.newestFirst;
  }
  if (logsState.filterComment) {
    params.word_input = logsState.filterComment;
  }
  if (logsState.filterFromDate) {
    params.from = logsState.filterFromDate;
  }
  if (logsState.filterToDate) {
    params.to = logsState.filterToDate;
  }
  return params;
}

export function useGetLogs() {
  const logsState = useAppSelector(({ logs }) => logs);
  const dispatch = useAppDispatch();

  const search = useCallback(async () => {
    await getLogs(logsState.userId, getLogsFilters(logsState)).then(data => {
      if (data.status === 200) {
        dispatch(setLogsCountAll(data.data.count));
        dispatch(setLogsItems(data.data.userLogs || []));
      } else {
        dispatch(setLogsCountAll(0));
        dispatch(setLogsItems([]));
      }
    });
  }, [
    logsState.pageSize,
    logsState.page,
    logsState.filterAction,
    logsState.newestFirst,
    logsState.filterComment,
    logsState.filterRole,
    logsState.filterFromDate,
    logsState.filterToDate,
    logsState.userId,
  ]);

  useEffect(() => {
    if (logsState.userId) {
      search();
    }
  }, [search, logsState.userId]);

  return { search };
}