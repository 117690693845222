import { Badge, Flex, Pagination, Space, Table, Title } from "@mantine/core";
import { setServiceInfoPage } from "store/slices/serviceInfoSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const COLUMNS = ["Дата и время", "Пользователь", "Комментарий"];

function formatDate(s: string) {
  try {
    return new Date(s).toLocaleString();
  } catch (e) {
    return "–";
  }
}

export function CommentTable() {
  const serviceInfoState = useAppSelector((state) => state.serviceInfo);
  const dispatch = useAppDispatch();
  return (
    <>
      <Flex align="center" gap="sm">
        <Title order={3}>Все комментарии</Title>
        <Badge color="violet">{serviceInfoState.countAll}</Badge>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            {COLUMNS.map((v) => (
              <th key={v}>{v}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {serviceInfoState.comments.map((el, key) => (
            <tr key={key}>
              <td>{formatDate(el.createdAt)}</td>
              <td>{el.authorEmail}</td>
              <td>{el.note}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Space h="md" />
      <Pagination
        value={serviceInfoState.page}
        total={Math.ceil(serviceInfoState.countAll / serviceInfoState.pageSize)}
        onChange={(pageNumber) => {
          dispatch(setServiceInfoPage(pageNumber));
        }}
        radius={8}
        size="md"
      />
    </>
  );
}
