import { TWalletType } from "shared/types/wallets";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

/* eslint-disable import/no-mutable-exports */
export let getInternalTransferDirectionsAbortController = new AbortController() as AbortController;

export function getInternalTransferDirections({ wallet_type_from }: { wallet_type_from: TWalletType }) {
  getInternalTransferDirectionsAbortController = new AbortController();
  return axiosInstance(
    getConfig(
      "/custody/transfers/internal_transfer_directions",
      { wallet_type_from },
      { signal: getInternalTransferDirectionsAbortController.signal },
    ),
  );
}
