import { Table } from "@mantine/core";
import { EmptyBlock } from "shared/features";
import { TNotypeClient } from "shared/types/notypeUsers";
import { Row } from "./Row";

const ClientsListViewTableTitles = ["Public ID", "Internal ID", "Электронная почта", "Статус"];

export const List = ({ data }: { data: TNotypeClient[] }) => {
  const rows = data.map((element) => <Row key={element.id} el={element} />);

  if (!data.length) return <EmptyBlock />;

  return (
    <div>
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="md"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            {ClientsListViewTableTitles.map((title) => (
              <th key={`CLVTT${title}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
