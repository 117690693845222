import React, { FC, useEffect, useState } from "react";
import { Routes } from "shared/definitions";
import { useAppSelector } from "store/store";
import { useRoute } from "react-router5";
import router from "../../../../../router";
import { useStyles } from "./styles";

interface IProps {
  links: { section: Routes; link: string; label: string; icon: any }[];
}

export const NavLinks: FC<IProps> = ({ links }) => {
  const { route } = useRoute();
  const isOpenedNavbar = useAppSelector(({ navbar }) => navbar.isOpenedNavbar);
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");

  useEffect(() => setActive(route.name), [route.name]);

  return (
    <>
      {links.map((item) => {
        const isActive =
          item.section === active ||
          // check if route is '/section/children', and the section link is '/section'
          Object.keys(route.meta?.params ?? {}).find((p) => p === item.section);

        return (
          <a
            className={cx(classes.link, "no_select", { [classes.linkActive]: isActive })}
            href={item.link.replace(".", "/")}
            key={item.label}
            onClick={(event) => {
              event.preventDefault();
              router.navigate(item.link);
              setActive(item.section);
            }}>
            <div className={classes.linkIconWrapper}>
              <item.icon className={classes.linkIcon} />
            </div>
            {isOpenedNavbar && <span>{item.label}</span>}
          </a>
        );
      })}
    </>
  );
};
