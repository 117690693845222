import { Badge } from "@mantine/core";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "shared/static/icons/adapted/close.svg";
import styles from "./FilterBadge.module.scss";

type TProps = {
  field: string;
  value: string;
  type?: "date";
  onDelete?: () => void;
};

export function FilterBadge(props: TProps) {
  return (
    <Badge
      size="lg"
      classNames={{ rightSection: styles.rightSection }}
      className={styles.root}
      rightSection={<CloseIcon className={styles.closeIcon} onClick={props.onDelete} />}>
      <span className={styles.fieldName}>{props.field}: </span>
      <span className={classNames(styles.value, { [styles.date]: props.type === "date" })}>{props.value}</span>
    </Badge>
  );
}
