import {
  TCurrency,
  TDepositTickerOperation,
  TExchangeTickerOperation,
  TWithdrawTickerOperation,
} from "shared/types/currencies";

type TCryptoCurrencyWithNetwork = {
  network: string;
  ticker: string;
};

type TFiatWithdrawDepositState = {
  cashWithdraw: TOperationShortInfo;
  transferWithdraw: TOperationShortInfo;
  cashDeposit: TOperationShortInfo;
  transferDeposit: TOperationShortInfo;
};

type TCryptoWithdrawDepositState = {
  withdraw: TOperationShortInfo;
  deposit: TOperationShortInfo;
};

type TExchangeState = {
  fiatToCrypto: TOperationShortInfo;
  cryptoToFiat: TOperationShortInfo;
  base: string;
  quoted: string;
};

type TWithdrawDepositOperation = TDepositTickerOperation | TWithdrawTickerOperation;

type TOperationShortInfo = {
  allowed: boolean;
  id: number;
};

export function getWithdrawDepositState(currencies: TCurrency[], tickerOperations: TWithdrawDepositOperation[]) {
  const cryptoCurrencies: TCryptoCurrencyWithNetwork[] = [];
  const fiatTickers: string[] = [];
  currencies.forEach((el) => {
    if (!el.network) {
      fiatTickers.push(el.ticker);
    } else {
      cryptoCurrencies.push({ ticker: el.ticker, network: el.network });
    }
  });
  function hasCurrency(ticker: string) {
    return currencies.find((el) => el.ticker === ticker);
  }
  const res = {
    crypto: {} as Record<string, Record<string, TCryptoWithdrawDepositState>>, // by tickers and chain
    fiat: {} as Record<string, TFiatWithdrawDepositState>, // by tickers
  };

  cryptoCurrencies.forEach((el) => {
    if (!res.crypto[el.ticker]) {
      res.crypto[el.ticker] = {};
    }
    res.crypto[el.ticker][el.network] = {
      withdraw: { allowed: false, id: 0 },
      deposit: { allowed: false, id: 0 },
    };
  });

  fiatTickers.forEach((el) => {
    res.fiat[el] = {
      cashWithdraw: { allowed: false, id: 0 },
      transferWithdraw: { allowed: false, id: 0 },
      cashDeposit: { allowed: false, id: 0 },
      transferDeposit: { allowed: false, id: 0 },
    };
  });

  tickerOperations.forEach((operation) => {
    if (operation.transactionType === "withdraw" && hasCurrency(operation.tickerFrom)) {
      if (operation.flowType === "crypto") {
        res.crypto[operation.tickerFrom][operation.network].withdraw.allowed = operation.allowed;
        res.crypto[operation.tickerFrom][operation.network].withdraw.id = operation.id;
      } else if (operation.flowType === "cash") {
        res.fiat[operation.tickerFrom].cashWithdraw.allowed = operation.allowed;
        res.fiat[operation.tickerFrom].cashWithdraw.id = operation.id;
      } else if (operation.flowType === "transfer") {
        res.fiat[operation.tickerFrom].transferWithdraw.allowed = operation.allowed;
        res.fiat[operation.tickerFrom].transferWithdraw.id = operation.id;
      }
    } else if (operation.transactionType === "deposit" && hasCurrency(operation.tickerTo)) {
      if (operation.flowType === "crypto") {
        res.crypto[operation.tickerTo][operation.network].deposit.allowed = operation.allowed;
        res.crypto[operation.tickerTo][operation.network].deposit.id = operation.id;
      } else if (operation.flowType === "cash") {
        res.fiat[operation.tickerTo].cashDeposit.allowed = operation.allowed;
        res.fiat[operation.tickerTo].cashDeposit.id = operation.id;
      } else if (operation.flowType === "transfer") {
        res.fiat[operation.tickerTo].transferDeposit.allowed = operation.allowed;
        res.fiat[operation.tickerTo].transferDeposit.id = operation.id;
      }
    }
  });

  const allFiatOperations: number[] = [];
  Object.values(res.fiat).forEach((el) => {
    allFiatOperations.push(el.cashDeposit.id, el.cashWithdraw.id, el.transferDeposit.id, el.transferWithdraw.id);
  });
  const allCryptoOperations: number[] = [];
  Object.values(res.crypto).forEach((el) => {
    Object.values(el).forEach(inner => {
      allCryptoOperations.push(inner.deposit.id, inner.withdraw.id);
    });
  });
  return {
    withdrawDepositState: res,
    allFiatOperations,
    allCryptoOperations,
  };
}

function getPairString(base: string, quoted: string) {
  return `${base} / ${quoted}`;
}

export function getExchangeState(currencies: TCurrency[], tickerOperations: TExchangeTickerOperation[]) {
  const cryptoTickers: string[] = [];
  const fiatTickers: string[] = [];
  currencies.forEach((el) => {
    if (el.isFiat) {
      fiatTickers.push(el.ticker);
    } else {
      cryptoTickers.push(el.ticker);
    }
  });
  const pairs: { base: string; quoted: string }[] = [];
  fiatTickers.forEach((fiat) => {
    cryptoTickers.forEach((crypto) => {
      pairs.push({
        base: crypto,
        quoted: fiat,
      });
    });
  });

  const res: Record<string, TExchangeState> = {};
  pairs.forEach((pair) => {
    res[getPairString(pair.base, pair.quoted)] = {
      ...pair,
      fiatToCrypto: { allowed: false, id: 0 },
      cryptoToFiat: { allowed: false, id: 0 },
    };
  });

  tickerOperations.forEach((operation) => {
    pairs.forEach((pair) => {
      if (operation.tickerFrom === pair.base && operation.tickerTo === pair.quoted) {
        res[getPairString(pair.base, pair.quoted)].cryptoToFiat.allowed = operation.allowed;
        res[getPairString(pair.base, pair.quoted)].cryptoToFiat.id = operation.id;
      } else if (operation.tickerFrom === pair.quoted && operation.tickerTo === pair.base) {
        res[getPairString(pair.base, pair.quoted)].fiatToCrypto.allowed = operation.allowed;
        res[getPairString(pair.base, pair.quoted)].fiatToCrypto.id = operation.id;
      }
    });
  });
  return res;
}
