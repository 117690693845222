import { ManageAssetsAmountField } from "shared/types/manageAssets";

export interface ITransferModalProps {
  rowIndex?: number;
  dataKey?: ManageAssetsAmountField;
  onClose: () => void;
}
export interface IWithdrawAllModalProps {
  rowIndex?: number;
  dataKey?: ManageAssetsAmountField;
  onClose: () => void;
}

export enum TransferModalStep {
  Initial,
  Final,
}
