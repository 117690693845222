import { axiosInstance } from "shared/definitions/api";
import { TServiceComment } from "shared/types/serviceInfo";
import { getConfig } from "../config";

type TParams = {
  id: string;
  page: number;
  pageSize: number;
};

export async function getServiceInfoComments(params: TParams) {
  const { id, ...rest } = params;
  return axiosInstance<{ items: TServiceComment[]; count: number }>(
    getConfig(`/users/${id}/service-notes`, rest),
  );
}
