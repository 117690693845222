import { ActionIcon, createStyles, getStylesRef } from "@mantine/core";
import React, { memo } from "react";
import { fieldMinHeight } from "components/ui/fields/config";
import { copyText } from "shared/utils/clipboard";
import { ReactComponent as CopyIcon } from "shared/static/icons/adapted/copy.svg";
import cogoToast from "cogo-toast";
import classNames from "classnames";

type FieldsContentTextProps = {
  text: string;
  inline?: boolean;
  isCopiable?: boolean;
  isErrored?: boolean;
  isFocused?: boolean;
};

const useStyles = createStyles((theme, _params) => ({
  wrapper: {
    width: "100%",
    minHeight: fieldMinHeight,
    border: "1px solid transparent",
    background: "transparent",
    boxSizing: "border-box",
    display: "flex",
    position: "relative",
    [`&:hover .${getStylesRef("actionContent")}`]: {
      opacity: 1,
    },
  },
  wrapperErrored: {
    background: "#FFF5F5",
    borderColor: "#FA5252",
    borderRadius: 4,
  },
  wrapperFocused: {
    borderColor: "#7950F2",
    borderRadius: 4,
  },
  textWrapper: {
    padding: "12px 16px",
    boxSizing: "border-box",
    fontSize: "14px",
    lineHeight: "20px",
    wordWrap: "break-word",
    maxWidth: "100%",
  },
  textWrapperInline: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "normal",
  },
  rightContent: {
    cursor: "pointer",
    ref: getStylesRef("actionContent"),
    background: "rgba(241,243,245,0.41)",
    borderRadius: 3,
    transition: "all 300ms ease",
    opacity: 0,
    display: "flex",
    flex: 1,
    marginRight: 0,
    marginLeft: "auto",
    minHeight: fieldMinHeight,
    alignContent: "center",
    justifyContent: "center",
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
  },
}));

export const FieldsContentText = memo((props: FieldsContentTextProps) => {
  const { classes } = useStyles();
  const { isCopiable = true, isErrored = false, inline = false, isFocused = false, text = "" } = props;
  return (
    <div
      className={classNames(classes.wrapper, {
        [classes.wrapperFocused]: isFocused,
        [classes.wrapperErrored]: isErrored,
      })}>
      <div
        className={classNames(classes.textWrapper, {
          [classes.textWrapperInline]: inline,
        })}>
        {text}
      </div>
      {isCopiable && (
        <div
          className={classes.rightContent}
          onClick={() => {
            copyText(text!);
            cogoToast.success("Скопировано!");
          }}>
          <div className="margin_auto margin_left_auto margin_right_0">
            <ActionIcon variant="transparent" size={20} ml={12} mr={12}>
              <CopyIcon color="#868E96" />
            </ActionIcon>
          </div>
        </div>
      )}
    </div>
  );
});
