import React, { memo, useMemo } from "react";
import dayjs from "dayjs";
import { TClientViewProps } from "shared/types/clients";
import { Badge, Grid, Group, LoadingOverlay, Title } from "@mantine/core";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import { FieldTreatment } from "components/common/FieldTreatment";
import { TypicalTextField } from "components/common/TypicalTextField";
import { useGetClientDetails } from "../../../../pages/Client/useGetClientDetails";

export const PersonalClientViewCommon = memo(({ cid, gridColumns = 12 }: TClientViewProps) => {
  const { clientDetails, isLoadedClientDetails } = useGetClientDetails({ cid });

  const blockIds = useMemo(
    () => (
      <Grid.Col span={12}>
        <FieldsWrapper>
          <FieldWrapper label="Public ID">
            <FieldsContentText inline text={clientDetails?.publicId || ""} />
          </FieldWrapper>
          <FieldWrapper label="Internal ID">
            <FieldsContentText inline text={clientDetails?.id || ""} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
    ),
    [clientDetails],
  );

  const blockNames = useMemo(
    () => (
      <Grid.Col span={12}>
        <FieldsWrapper>
          <FieldWrapper label="Фамилия">
            <FieldsContentText inline text={clientDetails?.lastName || "---"} />
          </FieldWrapper>
          <FieldWrapper label="Имя">
            <FieldsContentText inline text={clientDetails?.firstName || "---"} />
          </FieldWrapper>
          <FieldWrapper label="Отчество">
            <FieldsContentText inline text={clientDetails?.patronymic || "---"} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
    ),
    [clientDetails],
  );

  const blockBirthday = useMemo(
    () => (
      <TypicalTextField
        label="Дата рождения"
        text={clientDetails?.bDay ? dayjs(clientDetails?.bDay!.toString()).format("DD.MM.YYYY") : null}
      />
    ),
    [clientDetails],
  );

  const blockCitizenship = useMemo(
    () => <TypicalTextField label="Гражданство" text={clientDetails?.nationality} />,
    [clientDetails],
  );

  const blockEmail = useMemo(() => <TypicalTextField label="E-mail" text={clientDetails?.email} />, [clientDetails]);

  const blockPhone = useMemo(() => <TypicalTextField label="Телефон" text={clientDetails?.phone} />, [clientDetails]);

  const blockRegAddress = useMemo(
    () => <TypicalTextField label="Адрес регистрации" text={clientDetails?.registrationAddress} />,
    [clientDetails],
  );

  const blockActualAddress = useMemo(
    () => <TypicalTextField label="Место жительства" text={clientDetails?.actualAddress} />,
    [clientDetails],
  );

  const blockRegistrationDate = useMemo(
    () => <TypicalTextField label="Дата регистрации" text={clientDetails?.registrationDate} />,
    [clientDetails],
  );

  const blockLastVisit = useMemo(
    () => <TypicalTextField label="Последний вход" text={clientDetails?.lastVisit} />,
    [clientDetails],
  );

  const blockPageHeader = useMemo(
    () => (
      <div>
        <div>
          <Title order={3}>Общая информация о клиенте</Title>
        </div>
        <Group mb={16} mt={12}>
          <FieldTreatment name="clientStatus">{clientDetails?.status || ""}</FieldTreatment>
          <Badge
            size="md"
            className="cursor_default"
            variant="light"
            color="red"
            sx={{ fontSize: "12px", textTransform: "none" }}>
            2FA Выключена
          </Badge>
        </Group>
      </div>
    ),
    [clientDetails],
  );

  if (!isLoadedClientDetails) return <LoadingOverlay visible />;

  return gridColumns === 24 ? (
    <div>
      <div>{blockPageHeader}</div>
      <Grid columns={2}>
        <Grid.Col span={1}>
          <Grid columns={gridColumns / 2}>
            {blockIds}
            {blockNames}
            {blockBirthday}
            {blockCitizenship}
            {blockRegistrationDate}
          </Grid>
        </Grid.Col>

        <Grid.Col span={1}>
          <Grid columns={gridColumns / 2}>
            {blockEmail}
            {blockPhone}
            {blockRegAddress}
            {blockActualAddress}
            {blockLastVisit}
          </Grid>
        </Grid.Col>
      </Grid>
    </div>
  ) : (
    <div>
      <Grid columns={gridColumns}>
        {blockIds}
        {blockNames}
        {blockBirthday}
        {blockCitizenship}
        {blockEmail}
        {blockPhone}
        {blockRegAddress}
        {blockActualAddress}
        {blockRegistrationDate}
        {blockLastVisit}
      </Grid>
    </div>
  );
});
