import { Table } from "@mantine/core";
import { TPersonalClient } from "shared/types/clients";
import { EmptyBlock } from "shared/features";
import { Row } from "./Row";
import { PersonalDrawerDetails } from "./components/PersonalDrawerDetails";

const ClientsListViewTableTitles = [
  "ID Клиента",
  "Фамилия",
  "Номер ДУЛ",
  "Дата рожд.",
  "Почта",
  "Телефон",
  "Статус",
  "Баланс",
  " ",
];

export const List = ({ data }: { data: TPersonalClient[] }) => {
  const rows = data.map((element) => <Row key={element.id} el={element} />);

  if (!data.length) return <EmptyBlock />;

  return (
    <div>
      <PersonalDrawerDetails />
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="md"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            {ClientsListViewTableTitles.map((title) => (
              <th key={`CLVTT${title}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
