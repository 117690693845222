import { switchMatch } from "shared/utils/switchMatch";

export const labelServicesTreatment = (label: string) => {
  return switchMatch(label, {
    FORGERY: "Forgery attempt has been made",
    DOCUMENT_TEMPLATE: "Documents supplied are templates, downloaded from internet",
    LOW_QUALITY: "Documents have low-quality that does not allow definitive conclusions to be made",
    SPAM: "An applicant has been created by mistake or is just a spam user (irrelevant images were supplied)",
    NOT_DOCUMENT: "Documents supplied are not relevant for the verification procedure",
    SELFIE_MISMATCH: "A user photo (profile image) does not match a photo on the provided documents",
    ID_INVALID: "A document that identifies a person (like a passport or an ID card) is not valid",
    FOREIGNER: "When a client does not accept applicants from a different country or e.g. without a residence permit",
    DUPLICATE: "This applicant was already created for this client, and duplicates are not allowed by the regulations",
    BAD_AVATAR: "When avatar does not meet the client's requirements",
    WRONG_USER_REGION: "When applicants from certain regions/countries are not allowed to be registered",
    INCOMPLETE_DOCUMENT: "Some information is missing from the document, or it's partially visible",
    BLACKLIST: "User is blocklisted by our side",
    BLOCKLIST: "User is blocklisted by your side",
    UNSATISFACTORY_PHOTOS: "There were problems with the photos, like poor quality or masked information",
    DOCUMENT_PAGE_MISSING: "Some pages of a document are missing (if applicable)",
    DOCUMENT_DAMAGED: "Document is damaged",
    REGULATIONS_VIOLATIONS: "Regulations violations",
    INCONSISTENT_PROFILE: "Data or documents of different persons were uploaded to one applicant",
    PROBLEMATIC_APPLICANT_DATA: "Applicant data does not match the data in the documents",
    ADDITIONAL_DOCUMENT_REQUIRED: "Additional documents required to pass the check",
    AGE_REQUIREMENT_MISMATCH: "Age requirement is not met (e.g. cannot rent a car to a person below 25yo)",
    EXPERIENCE_REQUIREMENT_MISMATCH: "Not enough experience (e.g. driving experience is not enough)",
    CRIMINAL: "The user is involved in illegal actions",
    WRONG_ADDRESS: "The address from the documents doesn't match the address that the user entered",
    GRAPHIC_EDITOR: "The document has been edited by a graphical editor",
    DOCUMENT_DEPRIVED: "The user has been deprived of the document",
    COMPROMISED_PERSONS: "The user does not correspond to Compromised Person Politics",
    PEP: "The user belongs to the PEP category",
    ADVERSE_MEDIA: "The user was found in the adverse media",
    FRAUDULENT_PATTERNS: "Fraudulent behavior was detected",
    SANCTIONS: "The user was found on sanction lists",
    NOT_ALL_CHECKS_COMPLETED: "All checks were not completed",
    FRONT_SIDE_MISSING: "Front side of the document is missing",
    BACK_SIDE_MISSING: "Back side of the document is missing",
    SCREENSHOTS: "The user uploaded screenshots",
    BLACK_AND_WHITE: "The user uploaded black and white photos of documents",
    INCOMPATIBLE_LANGUAGE: "The user should upload translation of his document",
    EXPIRATION_DATE: "The user uploaded expired document",
    UNFILLED_ID: "The user uploaded the document without signatures and stamps",
    BAD_SELFIE: "The user uploaded a bad selfie",
    BAD_VIDEO_SELFIE: "The user uploaded a bad video selfie",
    BAD_FACE_MATCHING: "Face check between document and selfie failed",
    BAD_PROOF_OF_IDENTITY: "The user uploaded a bad ID document",
    BAD_PROOF_OF_ADDRESS: "The user uploaded a bad proof of address",
    BAD_PROOF_OF_PAYMENT: "The user uploaded a bad proof of payment",
    SELFIE_WITH_PAPER: "The user should upload a special selfie (e.g. selfie with paper and date on it)",
    FRAUDULENT_LIVENESS: "There was an attempt to bypass liveness check",
    OTHER: "Some unclassified reason",
    REQUESTED_DATA_MISMATCH: "Provided info doesn't match with recognized from document data",
    OK: "Custom reject label",
    COMPANY_NOT_DEFINED_STRUCTURE: "Could not establish the entity's control structure",
    COMPANY_NOT_DEFINED_BENEFICIARIES: "Could not identify and duly verify the entity's beneficial owners",
    COMPANY_NOT_VALIDATED_BENEFICIARIES: "Beneficiaries are not validated",
    COMPANY_NOT_DEFINED_REPRESENTATIVES: "Representatives are not defined",
    COMPANY_NOT_VALIDATED_REPRESENTATIVES: "Representatives are not validated",
    APPLICANT_INTERRUPTED_INTERVIEW: "On Video Ident call user refused to finish interview",
    DOCUMENT_MISSING: "On Video Ident call user refused to show or didn't have required documents",
    UNSUITABLE_ENV: "On Video Ident call user is either not alone or nor visible",
    CONNECTION_INTERRUPTED: "Video Ident call connection was interrupted,",
    default: label,
  });
};
