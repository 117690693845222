import { TAnyDocument } from "shared/definitions";

export function getFileLinks(docs: TAnyDocument[]) {
  return docs.reduce((prev, cur) => {
    if (cur?.fileLinks) {
      return [...prev, ...cur.fileLinks.map(el => {
        return {
          name: el,
          isOld: cur.meta?.isOld || false,
        };
      })];
    }
    return prev;
  }, [] as { name: string, isOld: boolean }[]);
}
