import { Grid, LoadingOverlay, Flex } from "@mantine/core";
import { useMemo } from "react";
import { DocumentDropZone } from "components/common/DocumentDropZone/documentDropZone";
import { FilesList } from "components/common/DocumentFileList";
import { DocumentCard } from "components/common/DocumentCard";
import { getFileLinks } from "shared/utils";
import { useGetOrganizationDocuments } from "shared/hooks/useGetOrganizationDocuments";

const FUNDS_SOURCE_DOCTYPES = ["proof_of_funds"];
const KNOWN_DOCTYPES = [...FUNDS_SOURCE_DOCTYPES];

type TProps = {
  cid: string;
};

export const Documents = ({ cid }: TProps) => {
  const { docs, isLoaded, getUserDocsHandle } = useGetOrganizationDocuments({ cid });

  const financeDocuments = useMemo(() => {
    return docs
      .filter(({ type }) => {
        return FUNDS_SOURCE_DOCTYPES.includes(type.toLowerCase());
      })
      .sort((a, b) => {
        return Number(a.createdAt) > Number(b.createdAt) ? -1 : 1;
      });
  }, [docs]);

  const otherDocuments = useMemo(() => {
    return docs
      .filter(({ type }) => !KNOWN_DOCTYPES.includes(type.toLowerCase()))
      .sort((a, b) => {
        return Number(a.createdAt) > Number(b.createdAt) ? -1 : 1;
      });
  }, [docs]);

  if (!isLoaded) return <LoadingOverlay visible />;

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <DocumentCard title="Подтверждение доходов" afterTitleContent={null}>
            <Flex gap="md" wrap="wrap">
              <FilesList cid={cid} list={getFileLinks(financeDocuments)} />
              <DocumentDropZone isOrganization type="proof_of_funds" cid={cid} getDocsHandle={getUserDocsHandle} />
            </Flex>
          </DocumentCard>
        </Grid.Col>
        <Grid.Col>
          <DocumentCard title="Другие документы" afterTitleContent={null}>
            <Flex gap="md" wrap="wrap">
              <FilesList cid={cid} list={getFileLinks(otherDocuments)} />
              <DocumentDropZone isOrganization type="other" cid={cid} getDocsHandle={getUserDocsHandle} />
            </Flex>
          </DocumentCard>
        </Grid.Col>
      </Grid>
    </div>
  );
};
