import { Button, Divider, Paper, Space, Textarea, Title } from "@mantine/core";
import { useState } from "react";
import { postComment } from "shared/api/users/postComment";

type TProps = {
  userId: string;
  onPostComment: (text: string) => void;
};

export function CommentForm(props: TProps) {
  const [text, setText] = useState("");
  return (
    <Paper>
      <Title order={4}>Добавить комментарий по клиенту</Title>
      <Space h="md" />
      <Textarea onChange={(e) => setText(e.target.value)} value={text} minRows={3} label="Комментарий" />
      <Space h="md" />
      <Divider />
      <Space h="md" />
      <Button
        onClick={async () => {
          await postComment(props.userId, text);
          props.onPostComment(text);
          setText("");
        }}
        color="violet">
        Добавить комментарий
      </Button>
    </Paper>
  );
}
