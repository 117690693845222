import { FilterBadge } from "components/common/FilterBadge";
import {
  setLogsFilterAction,
  setLogsFilterComment,
  setLogsFilterFromDate,
  setLogsFilterRole,
  setLogsFilterToDate,
} from "store/slices/logsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import styles from "./ClientLogs.module.scss";

export function AppliedFilters() {
  const logsState = useAppSelector(({ logs }) => logs);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.filtersList}>
      {logsState.filterRole && (
        <FilterBadge field="Роль" value={logsState.filterRole} onDelete={() => dispatch(setLogsFilterRole(""))} />
      )}
      {logsState.filterFromDate && (
        <FilterBadge
          type="date"
          field="От"
          value={new Date(logsState.filterFromDate).toLocaleString()}
          onDelete={() => dispatch(setLogsFilterFromDate(null))}
        />
      )}
      {logsState.filterToDate && (
        <FilterBadge
          type="date"
          field="До"
          value={new Date(logsState.filterToDate).toLocaleString()}
          onDelete={() => dispatch(setLogsFilterToDate(null))}
        />
      )}
      {logsState.filterAction && (
        <FilterBadge
          field="Действие"
          value={logsState.filterAction}
          onDelete={() => dispatch(setLogsFilterAction(""))}
        />
      )}
    </div>
  );
}
