import { useDispatch } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import { setManageAssetsModalIsOpened } from "store/slices";
import { useAppSelector } from "store/store";

export enum ModalType {
  Transfer,
  Withdraw,
}

export const useManageAssetsModals = () => {
  const dispatch = useDispatch();

  const { manageAssetsModalIsOpened } = useAppSelector((state) => state.modals);

  const [modalType, setModalType] = useState<ModalType | undefined>();

  const resetState = useCallback(() => {
    setModalType(undefined);
  }, []);

  const handleTransferModalOpen = useCallback(() => {
    setModalType(ModalType.Transfer);
    dispatch(setManageAssetsModalIsOpened(true));
  }, []);

  const handleWithdrawModalOpen = useCallback(() => {
    setModalType(ModalType.Withdraw);
    dispatch(setManageAssetsModalIsOpened(true));
  }, []);

  const handleModalClose = useCallback(() => {
    resetState();
    dispatch(setManageAssetsModalIsOpened(false));
  }, [resetState]);

  const modalTitle = useMemo(() => {
    if (modalType === ModalType.Transfer) return "Перевод между кошельками";
    if (modalType === ModalType.Withdraw) return "Вывод всех средств на Cold wallet";
    return undefined;
  }, [modalType]);

  return {
    modalType,
    modalTitle,
    isModalOpened: manageAssetsModalIsOpened,
    handleTransferModalOpen,
    handleWithdrawModalOpen,
    handleModalClose,
  };
};
