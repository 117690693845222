import { useAppDispatch, useAppSelector } from "store/store";
import { TClientViewProps } from "shared/types/clients";
import { useCallback, useEffect } from "react";
import { getOrganization, getOrganizationAbortController } from "shared/api";
import { organizationClientDetailDataTreatment } from "shared/utils";
import { setOrganizationClientDetails } from "store/slices/organizationClientsSlice";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

export const useGetOrganizationClientDetails = ({ cid }: TClientViewProps) => {
  const dispatch = useAppDispatch();
  const organizationClientDetails = useAppSelector(
    (state) => state.organizationClients.organizationClientDetails,
  );

  const getOrganizationHandle = useCallback(() => {
    getOrganization({ id: cid })
      .then((detail) => {
        dispatch(setOrganizationClientDetails(organizationClientDetailDataTreatment(detail)));
      })
      .catch(e => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
  }, [cid]);

  useEffect(() => {
    getOrganizationAbortController.abort();
    getOrganizationHandle();
  }, []);

  return {
    organizationClientDetails,
    getOrganizationHandle,
  };
};
