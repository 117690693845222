import { TransactionDetailTypes, TTransactionViewProps } from "shared/types/transactions";
import { LoadingOverlay } from "@mantine/core";
import { useTransactionDetailLogic } from "shared/hooks/useTransactionDetailLogic";
import { TransactionFiatFlowTypes, TransactionTypesKeys } from "shared/definitions/transactions/types";
import React, { useCallback, useEffect } from "react";
import { getTransaction } from "shared/api";
import { setTransactionDetail } from "store/slices";
import { transactionDetailDataTreatment } from "shared/utils";
import { useAppDispatch } from "store/store";
import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";
import { MainTransactionDetailHOC } from "shared/features/Transactions/TransactionView/components/MainTransactionDetailHOC";
import { ValuesOf } from "shared/types/common";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import {
  DepositFiatCashRegister,
  DepositFiatTransfer,
  WithdrawCrypto,
  WithdrawFiatTransfer,
  Exchange,
  DepositCrypto,
} from "./components";

export const TransactionView = (props: TTransactionViewProps) => {
  let ViewComponent: any;
  let transactionMainType: string = "";
  const { transactionDetails, isOperator } = useTransactionDetailLogic(props);
  const dispatch = useAppDispatch();

  const updateDetailHandler = useCallback((id: string) => {
    getTransaction({ id }).then((detail) => {
      dispatch(setTransactionDetail(transactionDetailDataTreatment(detail)));
    }).catch(sentryCaptureError);
  }, []);

  useEffect(() => {
    if (props.tid.length) updateDetailHandler(props.tid);
    const updateInterval = setInterval(() => {
      if (props.tid.length) updateDetailHandler(props.tid);
    }, 10000);
    return () => {
      clearInterval(updateInterval);
    };
  }, [props.tid]);

  const isFiat = transactionDetails.network === "";

  const fiatFlowType =
    transactionDetails?.fiatFlowType ||
    getEnumKeyByEnumValue(TransactionFiatFlowTypes, TransactionFiatFlowTypes.transfer);

  if (
    isFiat &&
    transactionDetails.type === TransactionTypesKeys.deposit &&
    fiatFlowType === getEnumKeyByEnumValue(TransactionFiatFlowTypes, TransactionFiatFlowTypes.cash_register)
  ) {
    transactionMainType = "depositFiatCashRegister";
    ViewComponent = DepositFiatCashRegister;
  } else if (
    isFiat &&
    transactionDetails.type === TransactionTypesKeys.deposit &&
    fiatFlowType === getEnumKeyByEnumValue(TransactionFiatFlowTypes, TransactionFiatFlowTypes.transfer)
  ) {
    transactionMainType = "depositFiatTransfer";
    ViewComponent = DepositFiatTransfer;
  } else if (
    isFiat &&
    transactionDetails.type === TransactionTypesKeys.withdraw &&
    fiatFlowType === getEnumKeyByEnumValue(TransactionFiatFlowTypes, TransactionFiatFlowTypes.transfer)
  ) {
    transactionMainType = "withdrawFiatTransfer";
    ViewComponent = WithdrawFiatTransfer;
  } else if (!isFiat && transactionDetails.type === TransactionTypesKeys.withdraw) {
    transactionMainType = "withdrawCrypto";
    ViewComponent = WithdrawCrypto;
  } else if (transactionDetails.type === TransactionTypesKeys.exchange) {
    transactionMainType = "exchange";
    ViewComponent = Exchange;
  } else if (transactionDetails.type === TransactionTypesKeys.deposit && !isFiat) {
    transactionMainType = "depositCrypto";
    ViewComponent = DepositCrypto;
  }

  if (!Object.keys(transactionDetails).length) return <LoadingOverlay visible />;
  if (!transactionMainType.length || (isOperator && !isFiat))
    return (
      <div>
        <h3>Неподдерживаемый вид транзакции</h3>
      </div>
    );

  return (
    <MainTransactionDetailHOC
      updateList={props.updateList}
      isFiat={isFiat}
      transactionMainType={transactionMainType as ValuesOf<typeof TransactionDetailTypes>}
      detail={transactionDetails}>
      <ViewComponent detail={transactionDetails} updateList={props.updateList} />
    </MainTransactionDetailHOC>
  );
};
