import { ReactElement, useCallback, useMemo, useState } from "react";
import { Button, createStyles, Divider, Grid, Group, Modal, Space, Textarea, Title } from "@mantine/core";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import { TTransactionDetails } from "shared/types/transactions";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { FieldTreatment } from "components/common/FieldTreatment";
import dayjs from "dayjs";
import { useRoute } from "react-router5";
import { Routes } from "shared/definitions";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import cogoToast from "cogo-toast";
import { changeTransactionStatus } from "shared/api";
import { setIsShownTransactionPanel, setStatusTransactionById, setStatusTransactionDetail } from "store/slices";
import { useAppDispatch } from "store/store";
import { useCheckIsFiat } from "shared/hooks/useCheckIsFiat";
import { formatAmount } from "shared/utils/formatNumber";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const CHANGE_STATUS_REQUEST_SUCCESS_TEXT = "Запрос на изменение статуса отправлен";

export const useStylesTransactionViewBlock = createStyles(() => ({
  fixedFooter: {
    position: "fixed",
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "white",
    boxShadow: "0 0 30px 1px rgba(0,0,0,0.1)",
    zIndex: 1,
    height: "100px",
  },
  footer: {
    width: "100%",
    padding: "12px",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "4px",
    margin: "12px 0",
  },
  fixedFooterFake: {
    width: "100%",
    height: "100px",
  },
}));

export const useTransactionViewBlocksLogic = (detail: TTransactionDetails, updateList: () => void) => {
  const { route } = useRoute();
  const dispatch = useAppDispatch();
  const isFiat = useCheckIsFiat();
  const isDetailPage = route.name === Routes.TRANSACTION_SCREEN;

  const [isOpenedModalChangeTransactionStatus, setIsOpenedModalChangeTransactionStatus] = useState(false);
  const [commentOfChangeTransactionStatus, setCommentOfChangeTransactionStatus] = useState("");
  const [typeHelperChangeTransactionStatus, setTypeHelperChangeTransactionStatus] = useState<"completed" | "rejected">(
    "completed",
  );

  const handleSetTransactionStatus = useCallback(
    (tid: string, comment: string) => {
      if (!comment.length) return cogoToast.error("Укажите комментарий");
      setIsOpenedModalChangeTransactionStatus(false);
      changeTransactionStatus({
        transactionId: tid,
        data: {
          comment,
          status: typeHelperChangeTransactionStatus,
        },
      })
        .then(() => {
          cogoToast.success(CHANGE_STATUS_REQUEST_SUCCESS_TEXT);
          dispatch(
            setStatusTransactionById({
              transactionId: detail.transactionId,
              transactionStatus: typeHelperChangeTransactionStatus,
            }),
          );
          dispatch(setStatusTransactionDetail("pending"));
          dispatch(setIsShownTransactionPanel(false));
          updateList();
        })
        .catch(sentryCaptureError)
        .finally(() => {
          setCommentOfChangeTransactionStatus("");
        });
    },
    [typeHelperChangeTransactionStatus],
  );

  const renderTypicalWrapper = useCallback((node: ReactElement) => {
    return (
      <Grid.Col span={12}>
        <FieldsWrapper>{node}</FieldsWrapper>
      </Grid.Col>
    );
  }, []);

  const renderTypicalTextField = useCallback((label: string, text: string | null, isCopiable = false) => {
    return (
      <FieldWrapper label={label}>
        <FieldsContentText text={text || "---"} isCopiable={isCopiable} />
      </FieldWrapper>
    );
  }, []);

  const blockSimplyTransactionStatus = useMemo(
    () => (
      <FieldWrapper label="Статус">
        <div className="w-100">
          <Padding horizontal={2} vertical={1}>
            <div>
              <div>
                <FieldTreatment name="transactionStatus">{detail.transactionStatus}</FieldTreatment>
              </div>
            </div>
          </Padding>
        </div>
      </FieldWrapper>
    ),
    [detail.transactionStatus],
  );

  const blockServiceFee = useMemo(() => {
    let value = "";
    if (detail.tickerFee) {
      value = formatAmount({
        value: Number(detail.fee),
        isFiat: isFiat(detail.tickerFee),
        postfix: `${detail.tickerFee} (${detail.network})`,
      });
    }
    return renderTypicalTextField("Комиссия", value);
  }, [detail.fee, detail.tickerFee, detail.network, isFiat]);

  const blockCreatedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Время создания",
        `${detail.createdAt ? dayjs(detail.createdAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.createdAt],
  );

  const blockComment = useMemo(() => renderTypicalTextField("Комментарий", detail.comment ?? ""), [detail.comment]);

  const blockJustification = useMemo(
    () => renderTypicalTextField("Обоснование", detail.justification ?? ""),
    [detail.justification],
  );

  const blockUpdatedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Обновлена",
        `${detail.updatedAt ? dayjs(detail.updatedAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.updatedAt],
  );

  const blockStatus = (
    <FieldWrapper label="Статус">
      <div className="w-100">
        <Modal
          radius={16}
          zIndex={1000}
          title={<Title order={3}>Подтверждение смены статуса</Title>}
          opened={isOpenedModalChangeTransactionStatus}
          onClose={() => setIsOpenedModalChangeTransactionStatus(false)}>
          <Padding horizontal={1} vertical={1}>
            <Textarea
              size="lg"
              value={commentOfChangeTransactionStatus}
              onChange={(event) => setCommentOfChangeTransactionStatus(event.currentTarget.value)}
              radius={12}
              label="Комментарий"
              autosize
              placeholder="Введите текст"
            />
            <Spacer size={6} />
            <Button
              radius={12}
              size="md"
              color={typeHelperChangeTransactionStatus === "completed" ? "green" : "red"}
              onClick={() => {
                handleSetTransactionStatus(detail?.transactionId, commentOfChangeTransactionStatus);
              }}>
              {typeHelperChangeTransactionStatus === "completed" ? "Подтвердить" : "Отклонить"}
            </Button>
            <Button
              ml={16}
              radius={12}
              size="md"
              variant="default"
              onClick={() => setIsOpenedModalChangeTransactionStatus(false)}>
              Отмена
            </Button>
          </Padding>
        </Modal>
        <Padding horizontal={2} vertical={1}>
          <div>
            <div>
              <FieldTreatment name="transactionStatus">{detail.transactionStatus}</FieldTreatment>
            </div>
            <Space mt={12} />
            <div>
              <Divider />
              <Space mt={12} />
              <Group>
                <Button
                  compact
                  radius={4}
                  size="sm"
                  variant="light"
                  color="green"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("completed");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Подтвердить
                </Button>
                <Button
                  compact
                  radius={4}
                  size="sm"
                  variant="light"
                  color="red"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("rejected");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Отклонить
                </Button>
              </Group>
              <Space mb={4} />
            </div>
          </div>
        </Padding>
      </div>
    </FieldWrapper>
  );

  return {
    renderTypicalWrapper,
    renderTypicalTextField,
    blockCreatedAt,
    blockJustification,
    blockComment,
    blockUpdatedAt,
    blockServiceFee,
    blockSimplyTransactionStatus,
    isDetailPage,
    blockStatus,
    isOpenedModalChangeTransactionStatus,
    typeHelperChangeTransactionStatus,
    commentOfChangeTransactionStatus,
    setIsOpenedModalChangeTransactionStatus,
    setCommentOfChangeTransactionStatus,
    setTypeHelperChangeTransactionStatus,
    handleSetTransactionStatus,
  };
};
