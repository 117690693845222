import React from "react";
import { LoadingOverlay } from "@mantine/core";
import { useClientCid } from "shared/hooks/useCid";
import { Documents } from "../../sections/Documents";
import { ClientTabHeader } from "../components/ClientTabHeader";
import { ClientTabLayout } from "../components/ClientsTabLayout";
import { useGetOrganizationClientDetails } from "../../../Clients/Organizations/useGetOrganizationClientDetails";

export const ClientOrganizationDocuments = () => {
  const { cid } = useClientCid();
  const { organizationClientDetails } = useGetOrganizationClientDetails({ cid });

  if (organizationClientDetails === null) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={organizationClientDetails} tabName={"Документы"} />
      <Documents cid={cid} />
    </ClientTabLayout>
  );
};
