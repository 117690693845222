import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export function changeVerificationLevel(
  id: string,
  params: {
    verificationLevel: "base" | "pending_to_level_2" | "level_2";
    reason: string;
  },
) {
  return axiosInstance(postConfig(`/users/${id}/verification-level`, params));
}
