import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export function updateTickerOperations(ids: number[], allowed: boolean, comment: string) {
  return axiosInstance(postConfig("/currencies/ticker-operations/change-allowance", {
    allowed,
    operation_ids: ids,
    comment
  })).then(
    (res: { data: { status: string } }) => res.data,
  );
}
