import { TClientDetailsShortResponsiblePerson } from "shared/types/clients";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import React from "react";
import { FieldTreatment } from "components/common/FieldTreatment";
import { Routes } from "shared/definitions";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { createStyles, Space, Switch, Text } from "@mantine/core";
import { ReactComponent as RemoveItem } from "shared/static/icons/adapted/remove.svg";
import { useAppDispatch } from "store/store";
import { setRemoveResponsiblePersonModalSelectedId, setUpdateResponsiblePersonModalSelectedId } from "store/slices";
import { useRoles } from "shared/hooks/useRoles";
import router from "../../../../../router";
import styles from "../../../../Currencies/Components/DataTables/DataTables.module.scss";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  wrapperResponsible: {
    width: "calc(100% - 30px)",
  },
  removeButton: {
    "minWidth": "30px",
    "backgroundColor": theme.colors.gray[2],
    "width": "32px",
    "height": "auto",
    "cursor": "pointer",
    "display": "flex",
    "& svg": {
      margin: "8px auto 0",
      color: "gray",
      width: "20px",
      height: "20px",
    },
  },
}));

const toClientScreenHandle = (cid: string) =>
  router.navigate(Routes.CLIENT_SCREEN, {
    cid,
  });

export const ResponsiblePersonItem = ({
  id,
  fullName,
  position,
  isFinancialOperationsAllowed,
  publicId,
}: TClientDetailsShortResponsiblePerson) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { isCompliance } = useRoles();

  return (
    <FieldsWrapper>
      <div className={classes.wrapper}>
        <div className={classes.wrapperResponsible}>
          <FieldWrapper labelWidth={160} label="Public ID">
            <div>
              <Space mt={12} />
              <Padding horizontal={2}>
                <FieldTreatment name="publicId" onClick={() => toClientScreenHandle(id)}>
                  {publicId.toString()}
                </FieldTreatment>
              </Padding>
            </div>
          </FieldWrapper>
          <FieldWrapper labelWidth={160} label="ФИО">
            <FieldsContentText isCopiable={false} inline text={fullName} />
          </FieldWrapper>
          <FieldWrapper labelWidth={160} label="Должность">
            <FieldsContentText isCopiable={false} inline text={position} />
          </FieldWrapper>
          <FieldWrapper labelWidth={160} label="Фин. операции">
            <Padding horizontal={1} vertical={1}>
              {!isCompliance && (
                <div>
                  <Text p={4}>{isFinancialOperationsAllowed ? "Да" : "Нет"}</Text>
                </div>
              )}
              {isCompliance && (
                <Switch
                  onClick={() => dispatch(setUpdateResponsiblePersonModalSelectedId(id))}
                  onLabel="ON"
                  offLabel="OFF"
                  size="lg"
                  color="green"
                  className={styles.switch}
                  checked={isFinancialOperationsAllowed}
                />
              )}
            </Padding>
          </FieldWrapper>
        </div>
        {isCompliance && (
          <div className={classes.removeButton} onClick={() => dispatch(setRemoveResponsiblePersonModalSelectedId(id))}>
            <RemoveItem />
          </div>
        )}
      </div>
    </FieldsWrapper>
  );
};
