import { Pagination } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { setPayinPage } from "store/slices";

export const PayinListPagination = () => {
  const dispatch = useAppDispatch();
  const { payinCountAll, payinPage, payinPageSize } = useAppSelector(({ payin }) => payin);

  return (
    <Pagination
      value={payinPage}
      color="violet"
      total={Math.ceil(payinCountAll / payinPageSize)}
      onChange={(pageNumber) => dispatch(setPayinPage(pageNumber))}
      radius={8}
      size="md"
    />
  );
};
