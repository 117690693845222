import { axiosInstance } from "shared/definitions/api";
import { postConfig } from "shared/api/config";

type UpdateTransferStatusRequestProps = {
  id: string;
  status: "processing" | "sending";
  reason: string;
};
export const updateTransferStatus = ({ id, status, reason }: UpdateTransferStatusRequestProps) =>
  axiosInstance(
    postConfig(`/custody/transactions/${id}/update_status`, {
      status,
      reason,
    }),
  );
