import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setRates } from "store/slices";
import { getRates } from "shared/api/currencies/getRates";
import { TRate } from "shared/types/currencies";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useCurrencyRates = () => {
  const dispatch = useAppDispatch();
  const { rates, ratePricesMatrix } = useAppSelector((state) => state.currencies);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);

  const fetchCurrencyRates = useCallback(async () => {
    setFetchIsLoading(true);
    await getRates()
      .then((res: TRate[]) => {
        dispatch(setRates(res.sort((a, b) => a.id - b.id)));
      })
      .catch(sentryCaptureError)
      .finally(() => setFetchIsLoading(false));
  }, []);

  useEffect(() => {
    if (!rates.length && !fetchIsLoading) {
      fetchCurrencyRates();
    }
  }, [rates, fetchIsLoading]);

  return {
    rates,
    ratePricesMatrix,
    isRatesLoaded: !!rates.length,
    fetchCurrencyRates,
  };
};
