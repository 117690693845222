import { API_URL, axiosInstance } from "shared/definitions/api";
import { ILogOutResponse, IResetPasswordResponse } from "shared/definitions";
import { memoWithTimer } from "../memoWithTimer";

const REFRESH_TOKEN_MEMO_TIMEOUT = 10000;

/**
 * @returns current access_token
 */
export const getAccessToken = () => {
  return window.localStorage.getItem("access_token");
};

export const saveAccessToken = (token: string) => {
  window.localStorage.setItem("access_token", token);
};

export const removeAccessToken = () => {
  return window.localStorage.removeItem("access_token");
};

export const auth = async () => {
  const { protocol, host, pathname } = window.location;
  const urlWithoutParams = encodeURIComponent(protocol + "//" + host + pathname);
  const loginUrlResponse = await (
    await fetch(`${API_URL}/api/v1/auth/login-page-url?redirect_uri=${urlWithoutParams}`)
  ).json();
  const loginUrl = loginUrlResponse.loginPageUrl;
  window.location.href = `${loginUrl}`;
};

/**
 *
 * @param secret get it from url after redirect from keycloak
 * @param redirectUrl must be the same as in /auth/login-page-url?redirect_url=...
 */
export const requestAccessToken = async (secret: string) => {
  const { protocol, host, pathname } = window.location;
  const urlWithoutParams = protocol + "//" + host + pathname;
  const dto = {
    code: secret,
    redirectUri: urlWithoutParams,
  };
  const fetchRes = await fetch(`${API_URL}/api/v1/auth/get-access-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dto),
  });
  return (await fetchRes.json()) as { accessToken: string };
};

export const refreshToken = memoWithTimer(async () => {
  const token = getAccessToken();
  if (!token) {
    return null;
  }
  return (
    await fetch(`${API_URL}/api/v1/auth/refresh-access-token`, {
      method: "POST",
      body: JSON.stringify({
        accessToken: token,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
  )
    .json()
    .then((res) => {
      saveAccessToken(res.accessToken);
      return res.accessToken;
    })
    .catch((e) => {
      removeAccessToken();
      return null;
    });
}, REFRESH_TOKEN_MEMO_TIMEOUT);

export const getLogoutUrl = async () => {
  const { data } = await axiosInstance.get<ILogOutResponse>(`${API_URL}/api/v1/auth/logout-url`);
  return data.logoutUrl;
};

export const getUpdatePasswordUrl = async () => {
  const data = await (await fetch(`${API_URL}/api/v1/auth/login-page-url`)).json();
  return data.loginPageUrl + "&kc_action=UPDATE_PASSWORD";
};

export const getResetPasswordUrl = async () => {
  const { data } = await axiosInstance.get<IResetPasswordResponse>(`${API_URL}/api/v1/auth/reset-password-url`);
  return data.resetPasswordUrl;
};

export function isTokenExpired(token: string) {
  try {
    if (token) {
      const tokenInfo = JSON.parse(window.atob(token.split(".")[1]));
      return tokenInfo.exp <= Date.now() / 1000;
    }
    return true;
  } catch (e) {
    return true;
  }
}

export async function logout() {
  const logoutUrl = await getLogoutUrl();
  const { protocol, host } = window.location;
  const redirectUri = `${protocol}//${host}`;
  const redirectParams = new URLSearchParams();
  redirectParams.append("post_logout_redirect_uri", redirectUri);

  window.localStorage.clear();
  window.location.href = `${logoutUrl}&${redirectParams.toString()}`;
}
