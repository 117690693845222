import { ActionIcon, Loader, TextInput, Tooltip } from "@mantine/core";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { numberInputFormatToPrecision } from "shared/utils/numberInputFormatToPrecision";
import { Check, X } from "tabler-icons-react";
import { TInputValidatorFN } from "../../../pages/Fees/Deposit/types";
import styles from "./SubmittableNumberInput.module.scss";

interface IProps {
  defaultValue: string;
  submit: (v: string) => Promise<boolean>;
  error?: string;
  isLoading?: boolean;

  precision?: number;

  inputId: string;
  focusedInputId: string | undefined;
  setFocusedInputId: (v: string | undefined) => void;

  validate: TInputValidatorFN;

  className?: string;
}

export const SubmittableNumberInput: FC<IProps> = ({
  defaultValue,
  submit,

  error,
  isLoading,

  precision,

  inputId,
  focusedInputId,
  setFocusedInputId,

  validate,

  className,
}) => {
  const prevValueRef = useRef(defaultValue);
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const numValue = useMemo(() => (value !== undefined && Number.isFinite(+value) ? +value : undefined), [value]);

  const [loading, setLoading] = useState<boolean>();
  useEffect(() => setLoading(isLoading ?? false), [isLoading]);

  const [, setFocusedFromInput] = useState(false);

  const [errorText, setErrorText] = useState<string | null>();
  // update error from props
  useEffect(() => setErrorText(error ?? null), [error]);
  // clear the error if value changed
  useEffect(() => setErrorText(null), [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmitRef = useRef<() => void | Promise<void>>(() => undefined);

  const isActive = useMemo(() => {
    return inputId === focusedInputId;
  }, [inputId, focusedInputId]);

  const isSubmitButtonDisabled = useMemo(() => {
    return loading || numValue === +prevValueRef.current;
  }, [numValue, loading]);

  // callbacks
  const handleFocus = useCallback(() => {
    setFocusedFromInput(true);
    setFocusedInputId(inputId);
  }, [setFocusedInputId, inputId]);

  const handleBlur = useCallback(() => {
    setFocusedFromInput(false);
    inputRef.current?.blur();
  }, []);

  const handleCancel = useCallback(() => {
    setFocusedInputId(undefined);
    handleBlur();

    inputRef.current?.blur();

    if (focusedInputId === inputId) {
      setValue(prevValueRef.current);
    }
  }, [setFocusedInputId, handleBlur, focusedInputId, inputId]);

  const handleSubmit = useCallback(async () => {
    if (isSubmitButtonDisabled) return;

    const hasError = validate(value);

    if (hasError) {
      setFocusedInputId(undefined);
      return;
    }

    setLoading(true);
    const res = await submit(value!);
    setLoading(false);

    if (res) {
      prevValueRef.current = numValue!.toString();
      setFocusedInputId(undefined);
      handleBlur();
    }
  }, [isSubmitButtonDisabled, validate, value, setFocusedInputId, submit, inputId, numValue, handleBlur]);

  useEffect(() => {
    handleSubmitRef.current = handleSubmit;
  }, [handleSubmit]);

  useEffect(() => {
    if (!isActive || focusedInputId !== inputId) return;

    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSubmitRef.current();
      } else if (e.key === "Escape") {
        handleCancel();
        window.removeEventListener("keydown", keyPressHandler);
      }
    };

    window.addEventListener("keydown", keyPressHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, [isActive, focusedInputId, inputId]);

  return (
    <Tooltip label={errorText} position="top" withArrow opened={!!errorText && !isActive}>
      <Tooltip
        label={"Значение не сохранено"}
        position="top"
        withArrow
        opened={!errorText && !loading && !isActive && +prevValueRef.current !== numValue}>
        <div
          className={cx(className, styles.inputContainer, "submittable-input__container", {
            [styles.inputContainerError]: !!errorText && !isActive,
            [styles.inputContainerActive]: isActive,
          })}>
          <TextInput
            disabled={loading}
            ref={inputRef}
            value={value}
            onChange={({ target }) => setValue(numberInputFormatToPrecision(target.value, precision))}
            onFocus={handleFocus}
            onClick={handleFocus}
            onBlur={() => setFocusedFromInput(false)}
            styles={{ root: { height: "100%" }, wrapper: { height: "100%" } }}
          />

          <div className={cx(styles.inputControls, "submittable-input__controls")} onClick={(e) => e.stopPropagation()}>
            <ActionIcon size="xs" onClick={() => handleCancel()}>
              <X />
            </ActionIcon>

            <ActionIcon
              disabled={isSubmitButtonDisabled}
              variant="filled"
              color="violet"
              size="xs"
              onClick={handleSubmitRef.current}>
              <Check />
            </ActionIcon>
          </div>

          <div className={cx(styles.loader, "submittable-input__loader", { [styles.loaderActive]: loading })}>
            <Loader size="xs" />
          </div>
        </div>
      </Tooltip>
    </Tooltip>
  );
};
