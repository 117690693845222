import { Container, LoadingOverlay, Space } from "@mantine/core";
import { useGetOrganizationUserId } from "shared/hooks/useGetOrganizationUserId";
import { useClientCid } from "shared/hooks/useCid";
import { CommentTable } from "shared/features/ClientsDetail/ServiceInfo/CommentTable";
import { CommentForm } from "shared/features/ClientsDetail/ServiceInfo/CommentForm";

import { useServiceComments } from "shared/hooks/useServiceComments";
import { useGetOrganizationClientDetails } from "pages/Clients/Organizations/useGetOrganizationClientDetails";
import { ClientTabHeader } from "../components/ClientTabHeader";
import { ClientTabLayout } from "../components/ClientsTabLayout";

export const ClientOrganizationServiceInfo = () => {
  const { organizationUserId: cid } = useGetOrganizationUserId();
  const { cid: organizationId } = useClientCid();
  const { organizationClientDetails } = useGetOrganizationClientDetails({
    cid: organizationId,
  });
  const { isLoaded, getServiceInfoHandle } = useServiceComments(cid);
  
  if (!organizationClientDetails || !isLoaded)
    return (
      <Container>
        <LoadingOverlay visible />
      </Container>
    );

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={organizationClientDetails} tabName={"Служебная информация"} />
      <CommentForm userId={cid} onPostComment={getServiceInfoHandle} />
      <Space h="lg" />
      <CommentTable />
    </ClientTabLayout>
  );
};
