import { Pagination } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { setTransactionsPage } from "store/slices";
import { useMemo } from "react";

export const TransactionsPagination = () => {
  const dispatch = useAppDispatch();
  const transactionsSegmentedControlValue = useAppSelector(
    (state) => state.transactions.transactionsSegmentedControlValue,
  );
  const { countAll, page, pageSize } = useAppSelector(({ transactions }) => transactions);

  return useMemo(
    () => (
      <Pagination
        value={page}
        color="violet"
        total={Math.ceil(countAll / pageSize)}
        onChange={(pageNumber) => dispatch(setTransactionsPage(pageNumber))}
        radius={8}
        size="md"
      />
    ),
    [page, countAll, pageSize, transactionsSegmentedControlValue],
  );
};
