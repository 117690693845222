import { Pagination } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { setJournalTransfersPage } from "store/slices";
import { useMemo } from "react";

export const JournalTransfersPagination = () => {
  const dispatch = useAppDispatch();
  const { journalTransfersCountAll, journalTransfersPage, journalTransfersPageSize } = useAppSelector(
    ({ journalTransfers }) => journalTransfers,
  );

  return useMemo(
    () => (
      <Pagination
        value={journalTransfersPage}
        color="violet"
        total={Math.ceil(journalTransfersCountAll / journalTransfersPageSize)}
        onChange={(pageNumber) => dispatch(setJournalTransfersPage(pageNumber))}
        radius={8}
        size="md"
      />
    ),
    [journalTransfersPage, journalTransfersCountAll, journalTransfersPageSize],
  );
};
