import { TTransactionDetails } from "shared/types/transactions";
import { Grid } from "@mantine/core";
import { useTransactionViewBlocksLogic } from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { FieldsWrapper } from "components/ui/fields";
import { useMemo } from "react";
import { useRoles } from "shared/hooks/useRoles";
import { formatAmount } from "shared/utils/formatNumber";
import dayjs from "dayjs";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { TransactionViewStatusBlock } from "shared/features/Transactions/TransactionView/components/TransactionViewStatusBlock";
import { useCheckIsFiat } from "shared/hooks/useCheckIsFiat";

export const Exchange = ({ detail, updateList }: { detail: TTransactionDetails; updateList: () => void }) => {
  const {
    blockSimplyTransactionStatus,
    renderTypicalTextField,
    blockUpdatedAt,
    blockComment,
    blockServiceFee,
    blockCreatedAt,
    blockStatus,
  } = useTransactionViewBlocksLogic(detail, updateList);
  const checkIsFiat = useCheckIsFiat();
  const { isOperator, isFinManager, isHeadOfFinManager } = useRoles();

  const isAllowChangeTransactionStatus =
    (isHeadOfFinManager || isFinManager) && detail.transactionStatus === TransactionStatusKeys.sending;

  const isAllowChangeTransactionStatusForManagers =
    (isHeadOfFinManager || isFinManager) && detail.transactionStatus === TransactionStatusKeys.hold;

  const blockAmountFrom = useMemo(() => {
    let value = "";
    if (detail.tickerFrom) {
      value = formatAmount({
        value: Number(detail.amountFrom),
        postfix: detail.tickerFrom,
        isFiat: checkIsFiat(detail.tickerFrom),
      });
    }
    return renderTypicalTextField("Сумма отправки", value);
  }, [detail]);

  const blockAmountWithFee = useMemo(() => {
    let value = "";
    if (detail.tickerTo) {
      value = formatAmount({
        value: Number(detail.amountTo),
        postfix: detail.tickerTo,
        isFiat: checkIsFiat(detail.tickerTo),
      });
    }
    return renderTypicalTextField("К зачислению", value);
  }, [detail]);

  const blockHash = useMemo(
    () => renderTypicalTextField("Hash транзакции", detail.transactionHash ?? "", true),
    [detail.transactionHash],
  );

  const blockPayoutAddress = useMemo(
    () => renderTypicalTextField("Payout address", detail.clientAddressFrom ?? "", true),
    [detail.clientAddressFrom],
  );

  const blockClientAddress = useMemo(
    () => renderTypicalTextField("Адрес клиента", detail.clientAddressTo ?? "", true),
    [detail.clientAddressTo],
  );

  const blockConfirmedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Подтверждена",
        `${detail.confirmedAt ? dayjs(detail.confirmedAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.confirmedAt],
  );

  const blockRate = useMemo(() => renderTypicalTextField("Курс", detail?.usedRate ?? "", false), []);

  if (isOperator) return <div>Данный интерфейс недоступен для выбранной роли</div>;

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <FieldsWrapper>
            {isAllowChangeTransactionStatus ? (
              <TransactionViewStatusBlock
                updateList={updateList}
                transactionStatus={detail.transactionStatus}
                transactionId={detail.transactionId}
              />
            ) : (
              <div>{isAllowChangeTransactionStatusForManagers ? blockStatus : blockSimplyTransactionStatus}</div>
            )}
            {blockAmountFrom}
            {blockAmountWithFee}
            {blockServiceFee}
            {blockHash}
            {blockPayoutAddress}
            {blockClientAddress}
            {blockRate}
            {blockCreatedAt}
            {blockUpdatedAt}
            {blockConfirmedAt}
            {blockComment}
          </FieldsWrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
