import { memo, useMemo } from "react";
import { ClientStatus, ClientStatusKeys } from "shared/definitions/levels/clientStatus";
import { Badge } from "@mantine/core";

export const clientTypes2TextTreatment = (value: string) => {
  switch (value) {
    case ClientStatusKeys.new:
      return ClientStatus.new;
    case ClientStatusKeys.active:
      return ClientStatus.active;
    case ClientStatusKeys.potential:
      return ClientStatus.potential;
    case ClientStatusKeys.restricted:
      return ClientStatus.restricted;
    default:
      return value;
  }
};


export const ClientStatusBadge = memo(({ value }: any) => {
  const badgeColor = useMemo(() => {
    switch (value) {
      case ClientStatusKeys.new:
        return "violet";
      case ClientStatusKeys.potential:
        return "yellow";
      case ClientStatusKeys.active:
        return "green";
      case ClientStatusKeys.restricted:
        return "red";
      default:
        return "gray";
    }
  }, [value]);

  const text = useMemo(() => {
    return clientTypes2TextTreatment(value);
  }, [value]);

  return (
    <Badge
      size="md"
      className="cursor_default"
      variant="light"
      color={badgeColor}
      sx={{ fontSize: "12px", textTransform: "none" }}>
      {text}
    </Badge>
  );
});
