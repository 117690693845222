import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TTransaction, TTransactionDetails, TTransactionsSegmentedControlValue } from "shared/types/transactions";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { ValuesOf } from "shared/types/common";

interface TransactionsSlice {
  page: number;
  countAll: number;
  pageSize: number;
  transactions: [] | TTransaction[];
  transactionDetails: TTransactionDetails | {};
  isShownTransactionPanel: boolean;
  transactionsFromDateFilter: any | null;
  transactionsToDateFilter: any | null;
  transactionsSearchFilter: string;
  transactionsSegmentedControlValue: TTransactionsSegmentedControlValue;
  transactionTypeFilter: string;
  transactionsStatusFilter: any; // пока что any, не решили мультиселект будет или строка, как там бэк сделается
  transactionsCurrenciesFilter: string[];
  transactionsIsAscOrderingFilter: boolean;
  transactionsOrderByFilter: string;
}

const initialState: TransactionsSlice = {
  page: 1 as number,
  countAll: 100 as number,
  pageSize: 20 as number,
  transactions: [] as [] | TTransaction[],
  isShownTransactionPanel: false,
  transactionDetails: {} as TTransactionDetails | {},
  transactionsFromDateFilter: null,
  transactionsToDateFilter: null,
  transactionTypeFilter: "withdraw,deposit",
  transactionsSearchFilter: "",
  transactionsSegmentedControlValue: "depositAndWithdraw",
  transactionsStatusFilter: null,
  transactionsCurrenciesFilter: [],
  transactionsIsAscOrderingFilter: false,
  transactionsOrderByFilter: "",
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactionsCountAll(state, action: PayloadAction<number>) {
      state.countAll = action.payload;
    },
    setTransactionsPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setTransactionsPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setTransactions(state, action: PayloadAction<[] | TTransaction[]>) {
      state.transactions = action.payload;
    },
    setTransactionDetail(state, action: PayloadAction<{} | TTransactionDetails>) {
      state.transactionDetails = action.payload;
    },
    setStatusTransactionDetail(state, action: PayloadAction<ValuesOf<typeof TransactionStatusKeys>>) {
      state.transactionDetails = { ...state.transactionDetails, transactionStatus: action.payload };
    },
    setStatusTransactionById(
      { transactions },
      {
        payload: { transactionId, transactionStatus },
      }: PayloadAction<{
        transactionId: string;
        transactionStatus: ValuesOf<typeof TransactionStatusKeys>;
      }>,
    ) {
      const tidInRows = transactions.findIndex(({ transactionId: id }) => id === transactionId);
      if (tidInRows > -1) {
        const mutatedRows = [...transactions];
        mutatedRows[tidInRows].transactionStatus = transactionStatus as any;
        transactions = mutatedRows;
      }
    },
    setTransactionsFromDateFilter(state, action: PayloadAction<any | null>) {
      state.transactionsFromDateFilter = action.payload;
      state.page = 1;
    },
    setTransactionsToDateFilter(state, action: PayloadAction<any | null>) {
      state.transactionsToDateFilter = action.payload;
      state.page = 1;
    },
    setTransactionsTypeFilter(state, action: PayloadAction<string>) {
      state.transactionTypeFilter = action.payload;
      state.page = 1;
    },
    setTransactionsSearchFilter(state, action: PayloadAction<string>) {
      state.transactionsSearchFilter = action.payload;
      state.page = 1;
    },
    setTransactionsCurrenciesFilter(state, action: PayloadAction<string[]>) {
      state.transactionsCurrenciesFilter = action.payload;
      state.page = 1;
    },
    setTransactionsSegmentedControlValue(state, action: PayloadAction<TTransactionsSegmentedControlValue>) {
      state.transactionsSegmentedControlValue = action.payload;
      state.page = 1;
    },
    setTransactionsStatusFilter(state, action: PayloadAction<ValuesOf<typeof TransactionStatusKeys> | null>) {
      state.transactionsStatusFilter = action.payload;
      state.page = 1;
    },
    setTransactionsIsAscOrderingFilter(state, action: PayloadAction<boolean>) {
      state.transactionsIsAscOrderingFilter = action.payload;
    },
    setTransactionsOrderByFilter(state, action: PayloadAction<string>) {
      state.transactionsOrderByFilter = action.payload;
    },
    setIsShownTransactionPanel(state, action: PayloadAction<boolean>) {
      state.isShownTransactionPanel = action.payload;
    },
    resetTransactionsFilters(state) {
      state.transactionsFromDateFilter = null;
      state.transactionsToDateFilter = null;
      state.transactionsIsAscOrderingFilter = false;
      state.transactionsSearchFilter = "";
      state.transactionsOrderByFilter = "";
      state.transactionsCurrenciesFilter = [];
      state.page = 1;
    },
  },
});

export const {
  setTransactionDetail,
  setTransactions,
  setTransactionsPage,
  setTransactionsOrderByFilter,
  setTransactionsCurrenciesFilter,
  setTransactionsTypeFilter,
  setTransactionsIsAscOrderingFilter,
  setTransactionsPageSize,
  setTransactionsCountAll,
  setStatusTransactionById,
  setStatusTransactionDetail,
  setTransactionsFromDateFilter,
  setTransactionsToDateFilter,
  setTransactionsSearchFilter,
  setIsShownTransactionPanel,
  resetTransactionsFilters,
  setTransactionsStatusFilter,
  setTransactionsSegmentedControlValue,
} = transactionsSlice.actions;
export const transactionsReducer = transactionsSlice.reducer;
