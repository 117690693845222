import { Grid, LoadingOverlay, Title, Flex } from "@mantine/core";
import { useMemo } from "react";
import classNames from "classnames";
import { useGetInvididualDocuments } from "shared/hooks/useGetIndividualDocuments";
import { DocumentDropZone } from "components/common/DocumentDropZone";
import { FilesList } from "components/common/DocumentFileList";
import { DocumentCard } from "components/common/DocumentCard";
import { getFileLinks } from "shared/utils";
import styles from "./styles.module.css";
import { ParsedDocDetails } from "./parsedDocDetails";

const IDENTITY_DOCTYPES = ["passport", "drivers", "residence_permit", "identity_proof", "id_card", "utility_bill"];
const FUNDS_SOURCE_DOCTYPES = ["manual", "finance_proof", "funds_proof"];
const KNOWN_DOCTYPES = [...IDENTITY_DOCTYPES, ...FUNDS_SOURCE_DOCTYPES];

type TProps = {
  cid: string;
};

export const Documents = ({ cid }: TProps) => {
  const { docs, isLoaded, getUserDocsHandle } = useGetInvididualDocuments({ cid });

  const identityDocuments = useMemo(() => {
    return docs
      .filter(({ type, typeExternal }: any) => {
        return IDENTITY_DOCTYPES.includes((typeExternal ?? type).toLowerCase());
      })
      .sort((a, b) => {
        return Number(a.createdAt) > Number(b.createdAt) ? -1 : 1;
      });
  }, [docs]);

  const parsedIdentityDoc = useMemo(
    () =>
      identityDocuments.filter(
        (d) => d.typeExternal?.toLowerCase() !== "utility_bill" && d.type?.toLowerCase() !== "utility_bill",
      )[0],
    [identityDocuments],
  );

  const sourceOfFundsDocuments = useMemo(() => {
    return docs
      .filter(({ type, typeExternal }) => {
        return FUNDS_SOURCE_DOCTYPES.includes((typeExternal ?? type).toLowerCase());
      })
      .sort((a, b) => {
        return Number(a.createdAt) > Number(b.createdAt) ? -1 : 1;
      });
  }, [docs]);

  const otherDocuments = useMemo(() => {
    return docs
      .filter(({ type, typeExternal }: any) => {
        return !KNOWN_DOCTYPES.includes((typeExternal ?? type).toLowerCase());
      })
      .sort((a, b) => {
        return Number(a.createdAt) > Number(b.createdAt) ? -1 : 1;
      });
  }, [docs]);

  if (!isLoaded) return <LoadingOverlay visible />;

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <DocumentCard title="Документ удостоверяющий личность" afterTitleContent={null}>
            {parsedIdentityDoc && (
              <div className="w-100 display_flex">
                <ParsedDocDetails document={parsedIdentityDoc} />
              </div>
            )}
            <Title className={classNames("no_select", styles.cardHeader)} order={3} mt={24} mb={16}>
              Вложения
            </Title>
            <Flex gap="md" wrap="wrap">
              <FilesList cid={cid} list={getFileLinks(identityDocuments)} />
              <DocumentDropZone type="identity_proof" cid={cid} getDocsHandle={getUserDocsHandle} />
            </Flex>
          </DocumentCard>
        </Grid.Col>
        <Grid.Col span={12}>
          <DocumentCard title="Документы подтверждающие источник происхождения средств" afterTitleContent={null}>
            <Flex gap="md" wrap="wrap">
              <FilesList cid={cid} list={getFileLinks(sourceOfFundsDocuments)} />
              <DocumentDropZone type="finance_proof" cid={cid} getDocsHandle={getUserDocsHandle} />
            </Flex>
          </DocumentCard>
        </Grid.Col>
        <Grid.Col span={12}>
          <DocumentCard title="Другие документы" afterTitleContent={null}>
            <Flex gap="md" wrap="wrap">
              <FilesList cid={cid} list={getFileLinks(otherDocuments)} />
              <DocumentDropZone type="other" cid={cid} getDocsHandle={getUserDocsHandle} />
            </Flex>
          </DocumentCard>
        </Grid.Col>
      </Grid>
    </div>
  );
};
