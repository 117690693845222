import { TLog } from "shared/types/logs";

function splitByCapital(s: string) {
  return s.split(/(?=[A-Z])/).map(el => el.toLowerCase()).join(" ");
}

export function getLogsAdditionalData(log: TLog) {
  try {
    if (log.payload) {
      const res = Object.entries(log.payload).filter(el => el[0] !== "comment" && el[0] !== "userIp").map(entry => {
        return `${splitByCapital(entry[0])}: ${entry[1] || "–"}`;
      }).join("\n");
      return res || "–";
    }
    return "–";
  } catch (e) {
    console.error(e);
    return "невозможно отобразить данные";
  }
}
