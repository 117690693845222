import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TCurrency, TFee, TRate, TTickerOperation } from "shared/types/currencies";
import { getRatesMatrixFromRateEntries, TMatrix } from "shared/utils/getRatesMatrixFromRateEntries";

interface CurrenciesSlice {
  currencies: TCurrency[];
  cryptoCurrenciesKeys: string[];
  fiatCurrenciesKeys: string[];
  rates: TRate[];
  ratePricesMatrix: null | TMatrix;
  fees: TFee[];
  tickerOperations: TTickerOperation[];
}

const initialState: CurrenciesSlice = {
  cryptoCurrenciesKeys: [],
  fiatCurrenciesKeys: [],
  currencies: [],
  rates: [],
  ratePricesMatrix: null,
  fees: [],
  tickerOperations: [],
};

const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    setCurrencies(state, action: PayloadAction<any>) {
      state.currencies = action.payload.map((currency: TCurrency) => {
        const isFiat = currency?.network === null;
        (isFiat ? state.fiatCurrenciesKeys : state.cryptoCurrenciesKeys).push(currency.ticker);
        return {
          ...currency,
          isFiat,
        };
      });
    },
    setRates(state, action: PayloadAction<TRate[]>) {
      state.rates = action.payload;
      state.ratePricesMatrix = getRatesMatrixFromRateEntries(action.payload);
    },
    setFees(state, action: PayloadAction<TFee[]>) {
      state.fees = action.payload;
    },
    setTickerOperations(state, action: PayloadAction<TTickerOperation[]>) {
      state.tickerOperations = action.payload;
    },
  },
});

export const { setCurrencies, setRates, setFees, setTickerOperations } = currenciesSlice.actions;
export const currenciesReducer = currenciesSlice.reducer;
