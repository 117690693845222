import React, { memo, useMemo } from "react";
import { switchMatch } from "shared/utils";
import dayjs from "dayjs";
import { Skeleton, Text, Tooltip, Badge, Slider } from "@mantine/core";
import { shortId } from "shared/utils/shortId";
import { TransactionTypes, TransactionTypesKeys } from "shared/definitions/transactions/types";
import { TransactionStatus, TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { useRoles } from "shared/hooks/useRoles";
import { Spacer } from "../../ui/universal/Spacer/Spacer";
import { CopyActionIcon } from "../CopyActionIcon";
import { ClientStatusBadge } from "../ClientStatusBadge";

export type TFieldTreatmentProps = {
  name: string;
  children: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const NulledField = <Skeleton height={12} animate={false} radius={0} />;

const isEmptyField = (value: string | null) => value === null || value.trim() === "";

const CopyField = memo(
  ({
    value,
    onClick,
    color = "#212529",
    mtIcon = 0,
  }: {
    value: string;
    color?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    mtIcon?: number;
  }) => {
    return (
      <div className="display_flex cursor_pointer no_select">
        <Text weight={800} color={color} onClick={onClick}>
          {value}
        </Text>
        <Spacer size={2} direction="horizontal" />
        <CopyActionIcon value={value} mt={mtIcon} />
      </div>
    );
  },
);

const ShortId = memo(
  ({
    value,
    onClick,
    color = "#212529",
    mtIcon = 0,
  }: {
    value: string;
    color?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    mtIcon?: number;
  }) => {
    return (
      <div className="display_flex cursor_pointer no_select">
        <Text weight={800} color={color} onClick={onClick}>
          {shortId(value!)}{" "}
        </Text>
        <Spacer size={2} direction="horizontal" />
        <CopyActionIcon value={value || ""} mt={mtIcon} />
      </div>
    );
  },
);

export const types2TextTreatment = (value: string) => {
  switch (value) {
    case TransactionTypesKeys.exchange:
      return TransactionTypes.exchange;
    case TransactionTypesKeys.withdraw:
      return TransactionTypes.withdraw;
    case TransactionTypesKeys.deposit:
      return TransactionTypes.deposit;
    case TransactionStatusKeys.pending:
      return TransactionStatus.pending;
    case TransactionStatusKeys.sending:
      return TransactionStatus.sending;
    case TransactionStatusKeys.hold:
      return TransactionStatus.hold;
    case TransactionStatusKeys.new:
      return TransactionStatus.new;
    case TransactionStatusKeys.user_approving:
      return TransactionStatus.user_approving;
    case TransactionStatusKeys.completed:
      return TransactionStatus.completed;
    case TransactionStatusKeys.rejected:
      return TransactionStatus.rejected;
    default:
      return value;
  }
};

export const FieldBadge = memo(({ value, color = undefined }: any) => {
  const { isOperator } = useRoles();

  const badgeColor = useMemo(() => {
    if (color) return color;
    switch (value) {
      case TransactionTypesKeys.withdraw:
        return isOperator ? "orange" : "violet";
      case TransactionStatusKeys.sending:
        return isOperator ? "yellow" : "violet";
      case TransactionStatusKeys.pending:
        return "violet";
      case TransactionTypesKeys.deposit:
      case TransactionStatusKeys.completed:
        return "green";
      case TransactionStatusKeys.rejected:
        return "white";
      case TransactionTypesKeys.exchange:
        return "orange";
      case TransactionStatusKeys.hold:
        return isOperator ? "red" : "yellow";
      default:
        return "gray";
    }
  }, [value, isOperator, color]);

  const text = useMemo(() => {
    return types2TextTreatment(value);
  }, [value]);

  return (
    <Badge
      size="md"
      className="cursor_default"
      variant="light"
      color={badgeColor}
      sx={{ fontSize: "12px", textTransform: "none" }}>
      {text}
    </Badge>
  );
});

export const FieldTreatment = ({ name, children: value, onClick }: TFieldTreatmentProps) => {
  return switchMatch(name, {
    clientStatus: !isEmptyField(value) ? <ClientStatusBadge value={value!} /> : NulledField,
    transactionStatus: !isEmptyField(value) ? <FieldBadge value={value!} /> : NulledField,
    transactionType: !isEmptyField(value) ? <FieldBadge value={value!} /> : NulledField,
    email: isEmptyField(value) ? (
      NulledField
    ) : (
      <Tooltip label="Написать" position="left" color="#212529" withArrow>
        <a href={`mailto:${value}`}>
          <Text
            maw={100}
            weight={800}
            color="#212529"
            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            {value}
          </Text>
        </a>
      </Tooltip>
    ),
    internalId: !isEmptyField(value) ? (
      <ShortId value={value || ""} color="violet" onClick={onClick} mtIcon={4} />
    ) : (
      NulledField
    ),
    userPublicId: !isEmptyField(value) ? <CopyField value={value || ""} onClick={onClick} /> : NulledField,
    publicId: !isEmptyField(value) ? <CopyField value={value || ""} onClick={onClick} mtIcon={4} /> : NulledField,
    transactionHash: !isEmptyField(value) ? <ShortId value={value || ""} color="gray" /> : NulledField,
    transactionId: !isEmptyField(value) ? <ShortId value={value || ""} onClick={onClick} /> : NulledField,
    id: !isEmptyField(value) ? <ShortId value={value || ""} onClick={onClick} /> : NulledField,
    address: !isEmptyField(value) ? <ShortId value={value || ""} onClick={onClick} /> : NulledField,
    createdAt: isEmptyField(value) ? NulledField : dayjs(value!.toString()).format("DD.MM.YYYY HH:mm:ss"),
    updatedAt: isEmptyField(value) ? NulledField : dayjs(value!.toString()).format("DD.MM.YYYY HH:mm:ss"),
    risk: isEmptyField(value) ? (
      NulledField
    ) : (
      <div className="w-100">
        <Slider
          color="gray"
          radius="xs"
          showLabelOnHover={false}
          value={Number(value as string)}
          labelAlwaysOn
          marks={[
            { value: 20, label: "20%" },
            { value: 50, label: "50%" },
            { value: 80, label: "80%" },
          ]}
        />
        <Spacer size={6} />
      </div>
    ),
    fiatFlowType: isEmptyField(value)
      ? NulledField
      : switchMatch(value!, {
          cash_register: "Касса",
          transfer: "Перевод",
          default: value,
        }),
    journalTransferStatus: isEmptyField(value)
      ? NulledField
      : switchMatch(value!, {
          new: <FieldBadge value="Новый" color="blue" />,
          processing: <FieldBadge value="Процессинг" color="violet" />,
          sending: <FieldBadge value="Отправка" color="orange" />,
          rejected: <FieldBadge value="Отклонена" color="red" />,
          hold: <FieldBadge value="Hold" color="black" />,
          finished: <FieldBadge value="Успешно" color="green" />,
          default: value,
        }),
    default: isEmptyField(value) ? NulledField : value,
  });
};
