import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TOrganization, TOrganizationDetails } from "shared/types/organizations";
import { ValuesOf } from "shared/types/common";
import { VerificationLevel } from "shared/definitions/levels/verifications";
import { RiskLevel } from "shared/definitions/levels/risk";

const initialState: {
  organizationClientsPage: number;
  organizationClientsCountAll: number;
  organizationClientsPageSize: number;
  organizationClientsList: TOrganization[];
  organizationClientsSearch: string;
  organizationClientsStatusFilter: string;
  organizationClientsSortBy: string;
  organizationClientsIsOrderAsc: boolean;
  organizationClientDetails: TOrganizationDetails | null;
  organizationClientDetailsId: string;
} = {
  organizationClientsPage: 1 as number,
  organizationClientsCountAll: 0 as number,
  organizationClientsPageSize: 20 as number,
  organizationClientsList: [],
  organizationClientsSearch: "",
  organizationClientsStatusFilter: "",
  organizationClientsSortBy: "",
  organizationClientsIsOrderAsc: false,
  organizationClientDetails: null,
  organizationClientDetailsId: "",
};

const organizationClientsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizationClientsCountAll(state, action: PayloadAction<number>) {
      state.organizationClientsCountAll = action.payload;
    },
    setOrganizationClientsPage(state, action: PayloadAction<number>) {
      state.organizationClientsPage = action.payload;
    },
    setOrganizationClientsPageSize(state, action: PayloadAction<number>) {
      state.organizationClientsPageSize = action.payload;
    },
    setOrganizationClientsSearch(state, action: PayloadAction<string>) {
      state.organizationClientsSearch = action.payload;
    },
    setOrganizationClientsStatusFilter(state, action: PayloadAction<string>) {
      state.organizationClientsStatusFilter = action.payload;
    },
    setOrganizationClientsSortBy(state, action: PayloadAction<string>) {
      state.organizationClientsSortBy = action.payload;
    },
    setOrganizationClientsIsOrderAsc(state, action: PayloadAction<boolean>) {
      state.organizationClientsIsOrderAsc = action.payload;
    },
    setOrganizationClients(state, action: PayloadAction<TOrganization[]>) {
      state.organizationClientsList = action.payload;
    },
    setOrganizationClientDetails(state, action: PayloadAction<TOrganizationDetails>) {
      state.organizationClientDetails = action.payload;
    },
    setOrganizationClientDetailsId(state, action: PayloadAction<string>) {
      state.organizationClientDetailsId = action.payload;
    },

    setOrganizationClientDetailVerificationLevel(state, action: PayloadAction<ValuesOf<typeof VerificationLevel>>) {
      state.organizationClientDetails = {
        ...state.organizationClientDetails!,
        verificationLevel: action.payload,
      };
    },
    setOrganizationClientDetailRiskLevelByProfile(state, action: PayloadAction<ValuesOf<typeof RiskLevel>>) {
      state.organizationClientDetails = {
        ...state.organizationClientDetails!,
        riskLevelByProfile: action.payload,
      };
    },
    setOrganizationClientDetailRiskLevelByRegion(state, action: PayloadAction<ValuesOf<typeof RiskLevel>>) {
      state.organizationClientDetails = {
        ...state.organizationClientDetails!,
        riskLevelByRegion: action.payload,
      };
    },
    setOrganizationClientDetailRiskLevelByOperations(state, action: PayloadAction<ValuesOf<typeof RiskLevel>>) {
      state.organizationClientDetails = {
        ...state.organizationClientDetails!,
        riskLevelByOperations: action.payload,
      };
    },
    resetOrganizationClientFilters(state) {
      state.organizationClientsSearch = initialState.organizationClientsSearch;
      state.organizationClientsStatusFilter = initialState.organizationClientsStatusFilter;
      state.organizationClientsIsOrderAsc = initialState.organizationClientsIsOrderAsc;
      state.organizationClientsSortBy = initialState.organizationClientsSortBy;
      state.organizationClientsPage = 1;
    },
  },
});

export const {
  setOrganizationClientsPage,
  setOrganizationClientsCountAll,
  setOrganizationClientsPageSize,
  setOrganizationClients,
  setOrganizationClientDetails,
  setOrganizationClientDetailsId,
  setOrganizationClientsSearch,
  setOrganizationClientsStatusFilter,
  setOrganizationClientsSortBy,
  setOrganizationClientsIsOrderAsc,
  setOrganizationClientDetailRiskLevelByProfile,
  setOrganizationClientDetailVerificationLevel,
  setOrganizationClientDetailRiskLevelByRegion,
  setOrganizationClientDetailRiskLevelByOperations,
  resetOrganizationClientFilters,
} = organizationClientsSlice.actions;
export const organizationClientsReducer = organizationClientsSlice.reducer;
