import {
  Alert,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  NativeSelect,
  TextInput,
} from "@mantine/core";
import {
  newPayinTransferSetCurrencyWithNetwork,
  newPayinTransferSetFrom,
  newPayinTransferSetIsApprove,
  newPayinTransferSetTo,
  resetNewPayinTransferSlice,
  setPayinSelectionAddresses,
  setPayinTransferModalIsOpened,
} from "store/slices";
import { TWalletType } from "shared/types/wallets";
import { ReactComponent as ArrowRightIcon } from "shared/static/icons/adapted/arrowRight2.svg";
import { getCurrencyLabel } from "shared/utils/currencyTreatment";
import { IconAlertCircle, IconThumbDown, IconThumbUp } from "@tabler/icons";
import React, { useCallback, useEffect } from "react";
import { useRoles } from "shared/hooks/useRoles";
import { TPayin } from "shared/types/payin";
import { getCustodyWalletsFilteredBalances } from "shared/api";
import { usePayinTransferModalLogic } from "./usePayinTransferModalLogic";

const useStyles = createStyles(() => ({
  icon: {
    "height": "42px",
    "display": "flex",
    "margin": "auto",
    "svg ": {
      margin: "auto",
      width: "24px",
      color: "black",
    },
  },
  currencyIconWrapper: {
    width: "24px",
    height: "24px",
    display: "flex",
    backgroundColor: "black",
    borderRadius: "5px",
    margin: "2px 8px 2px 0",
    svg: {
      margin: "auto",
      color: "white",
    },
  },
}));

export const PayinTransferModal = () => {
  const { classes } = useStyles();
  const {
    dispatch,
    isFilledForm,
    isFilledFormForCheck,
    setErrors,
    payinTransferModalIsOpened,
    newPayinTransferState,
    newPayinTransferRequest,
    amount,
    comment,
    isPendingCheckRequest,
    setComment,
    newPayinTransferFinalCheck,
    payinSelectionAddresses,
  } = usePayinTransferModalLogic();
  const { isHeadOfFinManager } = useRoles();

  const isFinalStep = newPayinTransferState.step === "success" || newPayinTransferState.step === "error";

  useEffect(() => {
    if (isFilledForm && newPayinTransferFinalCheck === "success") {
      newPayinTransferRequest();
    }
  }, [isFilledForm, newPayinTransferFinalCheck]);

  useEffect(() => {
    if (!isHeadOfFinManager) {
      dispatch(setPayinTransferModalIsOpened(false));
      dispatch(resetNewPayinTransferSlice());
    }
  }, [isHeadOfFinManager]);

  const handleRemoveAddress = useCallback(
    (el: TPayin) => {
      dispatch(
        setPayinSelectionAddresses(
          payinSelectionAddresses.filter(({ id }) => id === el.id).length
            ? payinSelectionAddresses.filter(({ id }) => id !== el.id)
            : [...payinSelectionAddresses, el],
        ),
      );
    },
    [payinSelectionAddresses],
  );

  useEffect(() => {
    // пока что возвращает 404 (тк нет в krakend)
    getCustodyWalletsFilteredBalances({
      ticker: "usdt",
      network: "trx",
      addresses: payinSelectionAddresses.map((el) => el.id).join(","),
      wallet_type: "cold",
    });
  }, [payinSelectionAddresses]);

  if (!isHeadOfFinManager) return null;
  if (!newPayinTransferState.possibleToDirectionsResponse.length) return <LoadingOverlay visible />;

  return (
    <Modal
      size="lg"
      padding="xl"
      title="Перевод средств"
      opened={payinTransferModalIsOpened}
      onClose={() => {
        dispatch(setPayinTransferModalIsOpened(false));
        dispatch(resetNewPayinTransferSlice());
      }}>
      {isFinalStep ? (
        <div>
          <div>
            <Alert
              radius={8}
              icon={
                newPayinTransferState.step === "success" ? <IconThumbUp size="1rem" /> : <IconThumbDown size="1rem" />
              }
              color={newPayinTransferState.step === "success" ? "green" : "red"}
              mb={16}>
              {newPayinTransferState.step === "success"
                ? "Операция на перевод успешно создана!"
                : "Операция не создана"}
            </Alert>
          </div>
          <div>
            <Group position="right">
              <Button
                variant="outline"
                onClick={() => {
                  dispatch(setPayinTransferModalIsOpened(false));
                }}>
                Закрыть
              </Button>
            </Group>
          </div>
        </div>
      ) : (
        <div>
          {newPayinTransferState.errors.length > 0 && (
            <Alert
              withCloseButton
              onClose={() => setErrors([])}
              radius={8}
              icon={<IconAlertCircle size="1rem" />}
              title="Что-то пошло не так"
              color="red"
              mb={16}>
              {newPayinTransferState.errors.map((k, i) => (
                <div key={`ntse${i}`}>{k}</div>
              ))}
            </Alert>
          )}
          <Grid>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Откуда → Куда
            </Grid.Col>
            <Grid.Col span={8}>
              <Group>
                <NativeSelect
                  disabled
                  value={newPayinTransferState.from}
                  onChange={({ target: { value } }) => dispatch(newPayinTransferSetFrom(value as TWalletType))}
                  size="xs"
                  data={["payin"]}
                />
                <div className={classes.icon}>
                  <ArrowRightIcon />
                </div>
                <NativeSelect
                  value={newPayinTransferState.to}
                  onChange={({ target: { value } }) => dispatch(newPayinTransferSetTo(value as TWalletType))}
                  size="xs"
                  data={["cold"] || newPayinTransferState.possibleToDirectionsResponse} // временно cold
                />
              </Group>
            </Grid.Col>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Payin адреса
            </Grid.Col>
            <Grid.Col span={8}>
              {payinSelectionAddresses.map((el, k) => {
                return (
                  <div key={`${el.id}_${k}`}>
                    <small>{el.id}</small>
                    {payinSelectionAddresses.length > 1 && (
                      <small className="cursor_pointer" onClick={() => handleRemoveAddress(el)}>
                        {" "}
                        (❌)
                      </small>
                    )}
                  </div>
                );
              })}
            </Grid.Col>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Общая сумма
            </Grid.Col>
            <Grid.Col span={8}>
              <Group grow>
                <TextInput disabled value={amount} size="xs" type="number" />
                <NativeSelect
                  value={newPayinTransferState.currencyWithNetworkString ?? ""}
                  onChange={(e) => dispatch(newPayinTransferSetCurrencyWithNetwork(e.target.value ?? ""))}
                  size="xs"
                  data={["usdt_trx"]
                    // TODO: временно используется один
                    .filter((k) => k === "usdt_trx")
                    .map((k) => {
                      return {
                        label: getCurrencyLabel(k),
                        value: k,
                      };
                    })}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                Комментарий
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput value={comment} onChange={({ target: { value } }) => setComment(value)} size="xs" />
            </Grid.Col>
            <Grid.Col span={12}>
              <Divider my="xs" />
              <Checkbox
                color="violet"
                label="Подтверждаю, что проверил детали перевода"
                checked={newPayinTransferState.isApprove}
                onChange={() => dispatch(newPayinTransferSetIsApprove(!newPayinTransferState.isApprove))}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <div>
                <Group position="right">
                  <Button
                    color="violet"
                    disabled={isPendingCheckRequest || !newPayinTransferState.isChecked || !isFilledFormForCheck}
                    onClick={() => {
                      //
                    }}>
                    Продолжить
                  </Button>
                </Group>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      )}
    </Modal>
  );
};
