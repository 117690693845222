import { Tabs } from "@mantine/core";
import { useRoute } from "react-router5";
import { Routes, TickerOperationsScreenSubRoutes } from "shared/definitions";
import { ReactComponent as CompanyIcon } from "../../../shared/static/icons/company.svg";
import { ReactComponent as OperationsIcon } from "../../../shared/static/icons/adapted/operations.svg";
import styles from "./Tabs.module.scss";

export function OperationsTabs() {
  const { route, router } = useRoute();
  return (
    <Tabs
      color="violet"
      value={route.name}
      onTabChange={(value) => {
        if (value) {
          router.navigate(value);
        }
      }}
      defaultValue="test">
      <Tabs.List>
        <Tabs.Tab value={`${Routes.TICKER_OPERATIONS_SCREEN}.${TickerOperationsScreenSubRoutes.WITHDRAW_DEPOSIT}`}>
          <OperationsIcon className={styles.tabIcon} /> Пополнения и выводы
        </Tabs.Tab>
        <Tabs.Tab value={`${Routes.TICKER_OPERATIONS_SCREEN}.${TickerOperationsScreenSubRoutes.EXCHANGES}`}>
          <CompanyIcon className={styles.tabIcon} /> Обмены
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
}
