import { Button, Group, Paper, Space, Title } from "@mantine/core";
import { TClientDetailsShortResponsiblePerson } from "shared/types/clients";
import React from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setAddResponsiblePersonModalIsOpened } from "store/slices";
import { useRoles } from "shared/hooks/useRoles";
import { ResponsiblePersonsList } from "./ResponsiblePersonsList";
import { AddResponsiblePersonModal } from "./components/AddResponsiblePersonModal";

export const ResponsiblePersons = ({
  cid,
  personsData,
}: {
  cid: string;
  personsData: TClientDetailsShortResponsiblePerson[];
}) => {
  const dispatch = useAppDispatch();
  const { organizationDetail } = useAppSelector((state) => ({
    organizationDetail: state.organizationClients.organizationClientDetails,
  }));

  const { isCompliance } = useRoles();

  return (
    <div>
      <Paper>
        <div>
          <AddResponsiblePersonModal detail={organizationDetail!} />
          <Group position="apart">
            <Title order={4} mb={16} mt={8} ml={8}>
              Сотрудники
            </Title>
            {isCompliance && (
              <Button
                variant="default"
                size="md"
                compact
                onClick={() => dispatch(setAddResponsiblePersonModalIsOpened(true))}>
                Привязать ОДЛ
              </Button>
            )}
          </Group>
        </div>
        <div>
          <ResponsiblePersonsList cid={cid} data={personsData} />
          {!personsData.length && (
            <div>
              <Space mt={12} />
              <Title ml={8} order={3}>
                Нет привязанных
              </Title>
            </div>
          )}
        </div>
      </Paper>
      <Space mt={24} />
    </div>
  );
};
