import { switchMatch } from "shared/utils/switchMatch";

export const getCurrencyNameByTicker = (ticker: string) =>
  switchMatch(ticker.toLowerCase(), {
    rub: "Российский рубль",
    byn: "Белорусский рубль",
    usd: "Доллар США",
    eur: "Евро",
    usdt: "Tether USD",
    trx: "Tron",
    default: ticker,
  });

export const getCurrencyNameByTickerWithNetwork = (value: string) =>
  switchMatch(value.toLowerCase(), {
    usdt_trx: "usdt",
    default: value,
  });

export const getCurrencyLabel = (tickerWithNetwork: string) => {
  return tickerWithNetwork.includes("_")
    ? `${getCurrencyNameByTicker(tickerWithNetwork.split("_")[0].toUpperCase())} (${tickerWithNetwork
        .split("_")[1]
        .toUpperCase()})`
    : getCurrencyNameByTicker(tickerWithNetwork.toUpperCase());
};
