import { useAppDispatch, useAppSelector } from "store/store";
import {
  setBlockClientModalIsOpened,
  setRiskLevelModalIsOpened,
  setVerificationLevelModalIsOpened,
} from "store/slices";
import { useState } from "react";

export const useBlockClientModalLogic = () => {
  const dispatch = useAppDispatch();
  const [reasonBlockClient, setReasonBlockClient] = useState("");
  const { blockClientModalIsOpened } = useAppSelector((store) => ({
    ...store.modals,
  }));

  const setIsOpenedBlockClientModal = (status: boolean) => {
    dispatch(setBlockClientModalIsOpened(status));
  };

  return {
    dispatch,
    reasonBlockClient,
    setReasonBlockClient,
    blockClientModalIsOpened,
    setIsOpenedBlockClientModal,
  };
};

export const useVerificationLevelModalLogic = () => {
  const dispatch = useAppDispatch();
  const [reasonChangeVerificationLevel, setReasonChangeVerificationLevel] = useState("");
  const { verificationLevelModalIsOpened } = useAppSelector((store) => ({
    ...store.modals,
  }));

  const setIsOpenedVerificationLevelModal = (status: boolean) => {
    dispatch(setVerificationLevelModalIsOpened(status));
  };

  return {
    dispatch,
    reasonChangeVerificationLevel,
    setReasonChangeVerificationLevel,
    verificationLevelModalIsOpened,
    setIsOpenedVerificationLevelModal,
  };
};

export const useRiskLevelModalLogic = () => {
  const dispatch = useAppDispatch();
  const [reasonChangeRiskLevel, setReasonChangeRiskLevel] = useState("");
  const { riskLevelModalIsOpened } = useAppSelector((store) => ({
    ...store.modals,
  }));

  const setIsOpenedRiskLevelModal = (status: boolean) => {
    dispatch(setRiskLevelModalIsOpened(status));
  };

  return {
    dispatch,
    reasonChangeRiskLevel,
    setReasonChangeRiskLevel,
    riskLevelModalIsOpened,
    setIsOpenedRiskLevelModal,
  };
};
