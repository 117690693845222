import { ActionIcon, Button, createStyles, Grid, Group, Input } from "@mantine/core";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/store";
import { ReactComponent as SearchIcon } from "shared/static/icons/adapted/search.svg";
import { ReactComponent as SearchCancelIcon } from "shared/static/icons/adapted/searchCancel.svg";
import { FilterBadge } from "components/common/FilterBadge";
import { setNotypeClientsSearchInput } from "store/slices/notypeClientsSlice";

const useStyles = createStyles(() => ({
  inputWrapper: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    borderRadius: 8,
    backgroundColor: "white",
  }
}));

export const Filters = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { notypeClientsSearchInput } = useAppSelector((state) => state.notypeClients);

  const isFiltered = !!notypeClientsSearchInput;

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <div className={classes.inputWrapper}>
            <Input
              id="inputFirstNameFilter"
              placeholder="Поиск по public и internal ID,  электронной почте"
              radius={8}
              size="md"
              variant="unstyled"
              autoComplete="off"
              icon={
                <ActionIcon size={24} ml={16} mr={8}>
                  <SearchIcon color="#868E96" />
                </ActionIcon>
              }
              style={{
                flex: "1",
              }}
              value={notypeClientsSearchInput}
              rightSectionWidth={200}
              rightSection={
                notypeClientsSearchInput ? (
                  <ActionIcon
                    size={24}
                    ml="auto"
                    mr={8}
                    onClick={() => {
                      dispatch(setNotypeClientsSearchInput(""));
                    }}>
                    <SearchCancelIcon color="#868E96" />
                  </ActionIcon>
                ) : (
                  <Button ml="auto" mr={8} compact radius={6} variant="light">
                    Найти
                  </Button>
                )
              }
              onChange={(event: { target: { value: string } }) =>
                dispatch(setNotypeClientsSearchInput(event.target.value))
              }
            />
          </div>
        </Grid.Col>
      </Grid>
      {isFiltered && (
        <Group ml={0} mt={16}>
          {notypeClientsSearchInput !== null && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setNotypeClientsSearchInput(""))}>
              <FilterBadge field="Текст" value={notypeClientsSearchInput} />
            </div>
          )}
        </Group>
      )}
    </div>
  );
};
