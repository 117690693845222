import { useMemo } from "react";
import { Table } from "@mantine/core";
import { EmptyBlock } from "shared/features";
import { CommonListTransactionRow } from "shared/features/Transactions/TransactionsCommonListView/components/CommonListTransactionRow";
import { TTransaction } from "shared/types/transactions";

const TransactionsCommonListViewTableTitles = [
  "Дата и время",
  "ID Транзакции",
  "Операция",
  "Тип",
  "Сумма",
  "Валюта",
  "Public ID",
  "Статус",
  " ",
];

type TProps = {
  data: TTransaction[];
  updateList: () => void;
};

export const TransactionsCommonListView = ({ data, updateList }: TProps) => {
  const rows = useMemo(
    () =>
      data.map((element) => (
        <CommonListTransactionRow
          updateList={updateList}
          key={`TransactionsCommonListView${element.transactionId}`}
          el={element}
        />
      )),
    [data],
  );

  if (!data.length) return <EmptyBlock />;

  return (
    <div>
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="md"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            {TransactionsCommonListViewTableTitles.map((title) => {
              return (
                <th key={`TCLVTT${title}`} className="break_word">
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
