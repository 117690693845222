import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setFeesData } from "store/slices";
import { getFees } from "shared/api/currencies/getFees";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useFees = () => {
  const dispatch = useAppDispatch();
  const { fees, feesByType } = useAppSelector((state) => ({
    fees: state.fees.feesData,
    feesByType: state.fees.feesByType,
  }));
  const [fetchIsLoading, setFetchIsLoading] = useState(false);

  useEffect(() => {
    if (!fees.length && !fetchIsLoading) {
      setFetchIsLoading(true);
      getFees()
        .then((resp: any) => {
          dispatch(setFeesData(resp));
        })
        .catch(sentryCaptureError)
        .finally(() => setFetchIsLoading(false));
    }
  }, [fees]);

  return {
    isLoadedCurrencies: !!fees.length,
    feesByType,
    fees,
  };
};
