import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

/* eslint-disable import/no-mutable-exports */
export let getUserAbortController = new AbortController() as AbortController;

export function getUser({ id }: { id: string }) {
  getUserAbortController = new AbortController();
  return axiosInstance(getConfig(`/users/${id}`, {}, { signal: getUserAbortController.signal })).then((res: any) => {
    return res;
  });
}

export function getUserByPublicId({ publicId }: { publicId: string }) {
  return axiosInstance(getConfig(`/users/public/${publicId}`, {}));
}
