import { ReactNode } from "react";
import { Paper, Title } from "@mantine/core";
import classNames from "classnames";
import styles from "./documentCard.module.scss";

export const DocumentCard = (props: {
  title: string;
  afterTitleContent: ReactNode;
  description?: string;
  children: ReactNode;
}) => {
  return (
    <Paper shadow="xs" radius={16} pt={24} pb={24} pl={28} pr={28} style={{ minHeight: 350 }}>
      <div className="w-100 display_flex">
        <Title className={classNames("no_select", styles.cardHeader)} order={3} mb={12}>
          {props.title}
        </Title>
        {props.afterTitleContent}
      </div>
      {props?.description && (
        <div className="w-100 display_flex">
          <Title className={classNames("no_select", styles.cardHeader)} order={4} mb={12}>
            {props.description}
          </Title>
        </div>
      )}
      <div className="w-100">{props.children}</div>
    </Paper>
  );
};
