import { Badge, Group, Space, Title } from "@mantine/core";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { useUpdateOnlyWithMouseActions } from "shared/hooks/useUpdateOnlyWithMouseActions";
import { resetPayinFilters } from "store/slices";
import { AxiosError } from "axios";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { WalletsPageWrapper } from "../Wrapper";
import { Tabs } from "../Balances/components/Tabs";
import { useGetPayinList } from "./useGetPayinList";
import { PayinListFilters } from "./features/PayinListFilters";
import { PayinListPagination } from "./features/PayinListPagination";
import { PayinListView } from "./features/PayinListView";
import { useIsFiltered } from "./useIsFiltered";
import { PayinTransferModal } from "./components/PayinTransferModal";

export const Payin = () => {
  const dispatch = useAppDispatch();
  const { payinData, payinCountAll } = useAppSelector(({ payin, modals }) => ({
    payinData: payin.payinData,
    payinCountAll: payin.payinCountAll,
  }));

  const { isFiltered, isSearching } = useIsFiltered();

  const { getPayinListRequest } = useGetPayinList();

  useUpdateOnlyWithMouseActions(() => {
    getPayinListRequest().catch((e) => {
      if (e.code !== AxiosError.ERR_CANCELED) {
        sentryCaptureError(e);
      }
    });
  });

  useEffect(() => {
    return () => {
      dispatch(resetPayinFilters());
    };
  }, []);

  return (
    <WalletsPageWrapper>
      <PayinTransferModal />
      <Tabs />
      <Space mt={24} />
      <PayinListFilters />
      <Space mt={24} />
      <Group position="apart">
        <Group>
          <Title order={2} display="flex">
            {isFiltered || isSearching ? "Результаты поиска" : "Все payin адреса"}
          </Title>
          <Badge variant="outline" color="violet" size="md" radius="sm">
            {payinCountAll}
          </Badge>
        </Group>
      </Group>
      <Space mt={24} />
      <PayinListView data={payinData} />
      <Space mt={24} />
      <PayinListPagination />
      <Space mt={24} />
    </WalletsPageWrapper>
  );
};
