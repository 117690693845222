import { createStyles } from "@mantine/core";
import { memo, ReactNode } from "react";
import { fieldMinHeight } from "components/ui/fields/config";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    border: "1px solid #E9ECEF",
    minHeight: fieldMinHeight,
    boxSizing: "border-box",
    borderRadius: "4px",
  },
}));

export const FieldsWrapper = memo((props: { children: ReactNode | ReactNode[] }) => {
  const { classes } = useStyles();
  return <div className={classes.wrapper}>{props.children}</div>;
});
