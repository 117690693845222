import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

/* eslint-disable import/no-mutable-exports */
export let getTransactionAbortController = new AbortController() as AbortController;

export function getTransaction({ id }: { id: string }) {
  getTransactionAbortController = new AbortController();
  return axiosInstance(getConfig(`/transactions/${id}`, {}, { signal: getTransactionAbortController.signal })).then(
    (res: any) => {
      return res.data;
    },
  );
}
