import { switchMatch } from "shared/utils/switchMatch";

type TAddressItem = {
  addressType: "registrationaddress" | "livingaddress";
  country: string;
  postCode: string;
  town: string;
  townEn: string;
  street: string;
  streetEn: string;
  subStreet: string;
  subStreetEn: string;
  state: string;
  stateEn: string;
  buildingName: string;
  flatNumber: string;
  buildingNumber: string;
  startDate: string;
  endDate: string;
  [key: string]: string;
};

export const getAddressValueText = (k: string, val: string): { text: string; position: number } =>
  val.length
    ? switchMatch(k, {
        country: {
          text: `${switchMatch(val, {
            RUS: "Россия",
            BLR: "Беларусь",
            ARM: "Армения",
            AUT: "Австрийская Республика",
            default: val,
          })}`,
          position: 1,
        },
        postCode: {
          text: val,
          position: 0,
        },
        state: {
          text: `рег: ${val}`,
          position: 2,
        },
        street: {
          text: `ул. ${val}`,
          position: 4,
        },
        town: {
          text: `${val}`,
          position: 3,
        },
        buildingNumber: {
          text: `д. ${val}`,
          position: 4,
        },
        buildingName: {
          text: `корп. ${val.toUpperCase()}`,
          position: 5,
        },
        flatNumber: {
          text: `кв. ${val}`,
          position: 6,
        },
        default: {
          text: "",
          position: -1,
        },
      })
    : {
        text: "",
        position: -1,
      };

export const addressesTreatment = (addresses: TAddressItem[]) => {
  const withoutKeys = ["townEn", "addressType", "stateEn", "streetEn", "subStreet", "subStreetEn"];

  const res: { registrationaddress: string; livingaddress: string } = {
    registrationaddress: "",
    livingaddress: "",
  };

  addresses.forEach((address: TAddressItem) => {
    const result: { text: string; position: number }[] = [];
    Object.keys(address).forEach((k) => {
      if (withoutKeys.includes(k) || !address[k].length) return;
      result.push(getAddressValueText(k, address[k]));
    });

    res[address.addressType] = result
      .sort((a, b) => a.position - b.position)
      .filter(({ position, text }) => position > -1 && !!text.length)
      .map(({ text }) => text)
      .join(", ");
  });

  return res;
};
