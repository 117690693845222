import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TNotypeClient } from "shared/types/notypeUsers";

const initialState: {
  notypeClientsPage: number;
  notypeClientsCountAll: number;
  notypeClientsPageSize: number;
  notypeClientsList: TNotypeClient[];
  notypeClientsSearchInput: string;
} = {
  notypeClientsPage: 1,
  notypeClientsCountAll: 0,
  notypeClientsPageSize: 20,
  notypeClientsList: [],
  notypeClientsSearchInput: "",
};

const notypeClientsSlice = createSlice({
  name: "notypeUsers",
  initialState,
  reducers: {
    setNotypeClientsCountAll(state, action: PayloadAction<number>) {
      state.notypeClientsCountAll = action.payload;
    },
    setNotypeClientsPage(state, action: PayloadAction<number>) {
      state.notypeClientsPage = action.payload;
    },
    setNotypeClientsPageSize(state, action: PayloadAction<number>) {
      state.notypeClientsPageSize = action.payload;
    },
    setNotypeClients(state, action: PayloadAction<TNotypeClient[]>) {
      state.notypeClientsList = action.payload;
    },
    setNotypeClientsSearchInput(state, action: PayloadAction<string>) {
      state.notypeClientsSearchInput = action.payload;
      state.notypeClientsPage = 1;
    },
    resetNotypeClientsFilters(state) {
      state.notypeClientsSearchInput = initialState.notypeClientsSearchInput;
      state.notypeClientsPage = 1;
    },
  },
});

export const {
  setNotypeClientsPage,
  setNotypeClientsCountAll,
  setNotypeClientsPageSize,
  setNotypeClients,
  setNotypeClientsSearchInput,
  resetNotypeClientsFilters,
} = notypeClientsSlice.actions;
export const notypeClientsReducer = notypeClientsSlice.reducer;
