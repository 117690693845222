import { Space } from "@mantine/core";
import { Wrapper } from "../Components/Wrapper";
import { DataTables } from "./DataTables";

export function ExchangeTickerOperations() {
  return (
    <Wrapper>
      <Space h="md" />
      <DataTables />
    </Wrapper>
  );
}
