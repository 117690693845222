import { MantineThemeOverride } from "@mantine/core";

const palette: MantineThemeOverride["colors"] = {
  grayUI: [
    "#F8F9FA", // Gray / 00
    "#F1F3F5", // Gray / 01
    "#E9ECEF", // Gray / 02
    "#DEE2E6", // Gray / 03
    "#CED4DA", // Gray / 04
    "#ADB5BD", // Gray / 05
    "#868E96", // Gray / 06
    "#495057", // Gray / 07
    "#343A40", // Gray / 08
    "#212529", // Gray/09 - Main Dark
  ],
};

export const theme: MantineThemeOverride = {
  fontFamily: "Roboto",
  colorScheme: "light",
  primaryShade: 8,
  primaryColor: "gray",
  colors: palette,
  headings: {
    fontFamily: "Roboto",
    fontWeight: "500",
    sizes: {
      h2: {
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "32px",
      },
    },
  },
  components: {
    Button: {
      styles: {
        root: {
          fontFamily: "Roboto",
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
    Tabs: {
      styles: {
        tabLabel: {
          fontWeight: 500,
        },
      },
    },
    Input: {
      styles: {
        input: {
          "fontSize": "14px",
          "fontFamily": "Roboto",
          "&:not(textarea)": {
            "height": "42px",
          },
          "&[disabled]": {
            color: "black",
            fontWeight: 800,
          },
        },
      },
    },
    Paper: {
      defaultProps: {
        shadow: "xs",
        radius: "lg",
        p: "md"
      }
    },
    Select: {
      styles: {
        input: { height: "42px", fontSize: "14px", fontFamily: "Roboto" },
      },
    },
    Menu: {
      styles: {
        dropdown: { padding: 0 },
        item: { height: "36px" },
        itemLabel: { color: palette.grayUI?.[7], fontWeight: 400, fontSize: "14px", lineHeight: "20px" },
      },
    },
    Modal: {
      styles: {
        title: {
          fontSize: "var(--mantine-font-size-xl)",
        }
      }
    }
  },
};
