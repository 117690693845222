import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TJournalTransfer, TJournalTransferDetails } from "shared/types/transfers";

interface JournalTransfersSlice {
  journalTransfersPage: number;
  journalTransfersCountAll: number;
  journalTransfersPageSize: number;
  journalTransfersData: [] | TJournalTransfer[];
  journalTransfersDetails: TJournalTransferDetails | {};
  //
  journalTransfersFromDateFilter: any | null;
  journalTransfersToDateFilter: any | null;
  journalTransfersSearchFilter: string;
  journalTransfersFromTypeFilter: string;
  journalTransfersToTypeFilter: string;
  journalTransfersStatusFilter: any;
  journalTransfersIsAscOrderingFilter: boolean;
  journalTransfersOrderByFilter: string;
}

const initialState: JournalTransfersSlice = {
  journalTransfersPage: 1 as number,
  journalTransfersCountAll: 100 as number,
  journalTransfersPageSize: 20 as number,
  journalTransfersData: [] as [] | TJournalTransfer[],
  journalTransfersDetails: {} as TJournalTransferDetails | {},
  journalTransfersFromDateFilter: null,
  journalTransfersToDateFilter: null,
  journalTransfersFromTypeFilter: "",
  journalTransfersToTypeFilter: "",
  journalTransfersSearchFilter: "",
  journalTransfersStatusFilter: null,
  journalTransfersIsAscOrderingFilter: false,
  journalTransfersOrderByFilter: "",
};

const journalTransfersSlice = createSlice({
  name: "journalTransfers",
  initialState,
  reducers: {
    setJournalTransfersCountAll(state, action: PayloadAction<number>) {
      state.journalTransfersCountAll = action.payload;
    },
    setJournalTransfersPage(state, action: PayloadAction<number>) {
      state.journalTransfersPage = action.payload;
    },
    setJournalTransferPageSize(state, action: PayloadAction<number>) {
      state.journalTransfersPageSize = action.payload;
    },
    setJournalTransfersData(state, action: PayloadAction<[] | TJournalTransfer[]>) {
      state.journalTransfersData = action.payload;
    },
    setJournalTransfersDetail(state, action: PayloadAction<{} | TJournalTransferDetails>) {
      state.journalTransfersDetails = action.payload;
    },
    setJournalTransfersFromDateFilter(state, action: PayloadAction<any | null>) {
      state.journalTransfersFromDateFilter = action.payload;
      state.journalTransfersPage = 1;
    },
    setJournalTransfersToDateFilter(state, action: PayloadAction<any | null>) {
      state.journalTransfersToDateFilter = action.payload;
      state.journalTransfersPage = 1;
    },
    setJournalTransfersFromTypeFilter(state, action: PayloadAction<string>) {
      state.journalTransfersFromTypeFilter = action.payload;
      state.journalTransfersPage = 1;
    },
    setJournalTransfersToTypeFilter(state, action: PayloadAction<string>) {
      state.journalTransfersToTypeFilter = action.payload;
      state.journalTransfersPage = 1;
    },
    setJournalTransfersSearchFilter(state, action: PayloadAction<string>) {
      state.journalTransfersSearchFilter = action.payload;
      state.journalTransfersPage = 1;
    },
    setJournalTransfersStatusFilter(state, action: PayloadAction<string | null>) {
      state.journalTransfersStatusFilter = action.payload;
      state.journalTransfersPage = 1;
    },
    setJournalTransfersIsAscOrderingFilter(state, action: PayloadAction<boolean>) {
      state.journalTransfersIsAscOrderingFilter = action.payload;
    },
    setJournalTransfersOrderByFilter(state, action: PayloadAction<string>) {
      state.journalTransfersOrderByFilter = action.payload;
    },
    resetJournalTransfersFilters(state) {
      state.journalTransfersFromDateFilter = null;
      state.journalTransfersToDateFilter = null;
      state.journalTransfersIsAscOrderingFilter = false;
      state.journalTransfersSearchFilter = "";
      state.journalTransfersOrderByFilter = "";
      state.journalTransfersToTypeFilter = "";
      state.journalTransfersFromTypeFilter = "";
      state.journalTransfersPage = 1;
    },
  },
});

export const {
  setJournalTransfersDetail,
  setJournalTransfersData,
  setJournalTransfersPage,
  setJournalTransfersOrderByFilter,
  setJournalTransfersFromTypeFilter,
  setJournalTransfersToTypeFilter,
  setJournalTransfersIsAscOrderingFilter,
  setJournalTransfersFromDateFilter,
  setJournalTransfersSearchFilter,
  setJournalTransfersStatusFilter,
  setJournalTransfersToDateFilter,
  setJournalTransferPageSize,
  setJournalTransfersCountAll,
  resetJournalTransfersFilters,
} = journalTransfersSlice.actions;
export const journalTransfersReducer = journalTransfersSlice.reducer;
