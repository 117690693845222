import React, { useState } from "react";
import { Paper } from "@mantine/core";
import { TFeeDataItem } from "shared/types/fees";
import { TDepositKey } from "shared/definitions/fees";
import { isDev } from "shared/utils";
import { FeesPageContainer } from "../components/FeesPageContainer";
import { Tabs } from "../components/Tabs";
import { TabContentContainer } from "../components/TabContentContainer";
import styles from "./styles.module.scss";
import { Input } from "../components/Input";
import { useExchangeFeesLogic } from "./useExchangeFeesLogic";
import { useCommonFeesLogic } from "../useCommonFeesLogic";

const TABLE_HEADER_TICKER = "USD"; // TODO Can we get it from backend?

function getHeaderTitle(idx: number, columnTitlesExchangeFees: string[], ticker: string) {
  const interval: { min: string | null; max: string | null } = { min: null, max: null };
  interval.min = idx > 0 ? columnTitlesExchangeFees[idx] : null;
  interval.max = columnTitlesExchangeFees[idx + 1] ?? null;
  if (!interval.min) {
    return `< ${interval.max} ${ticker.toUpperCase()}`;
  }
  if (!interval.max) {
    return `>${interval.min} ${ticker.toUpperCase()}`;
  }
  return `${interval.min} – \n${parseFloat(interval.max) - 0.01} ${ticker.toUpperCase()}`;
}

export const Exchange = () => {
  const [focusedInputId, setFocusedInputId] = useState<string>();
  const { exchangeFeesByTickers, columnTitlesExchangeFees } = useExchangeFeesLogic();
  const { handleValidate, handleSubmit } = useCommonFeesLogic();

  return (
    <FeesPageContainer>
      <Tabs />
      <TabContentContainer>
        <Paper shadow="xs" radius={16} p="xl" className={styles.tableContainer} role={"table"}>
          <div className={styles.table}>
            <div className={styles.tableHead}>
              <div className={styles.tableCell} />
              {columnTitlesExchangeFees.map((v, idx) => (
                <div key={v} className={styles.tableCell}>
                  {getHeaderTitle(idx, columnTitlesExchangeFees, TABLE_HEADER_TICKER)}
                </div>
              ))}
            </div>
            <div>
              {Object.keys(exchangeFeesByTickers).map((customKey: string, rowIndex) => {
                const rows: TFeeDataItem[] = exchangeFeesByTickers[customKey];
                return (
                  <div key={rowIndex} className={styles.tableRow}>
                    <div className={styles.tableCell}>{customKey?.replace("_", "/")}</div>
                    {[...rows].map((item: TFeeDataItem, index) => (
                      <div className={styles.tableCell} key={`${rowIndex}-${item.id}-${index}`}>
                        {isDev() && <span>MAV: {item.minArgValue}</span>}
                        <Input
                          label={"%"}
                          key={[item.customType, item.id.toString(), "percentFee"].join("-")}
                          id={[item.customType, item.id.toString(), "percentFee"].join("-")}
                          handleSubmit={(v) => {
                            return handleSubmit(v, "percentFee" as TDepositKey, item, setFocusedInputId);
                          }}
                          focusedInputId={focusedInputId}
                          setFocusedInputId={setFocusedInputId}
                          value={item.percentFee.toString()}
                          validate={(value) => {
                            return handleValidate(value, "percentFee", item);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>
      </TabContentContainer>
    </FeesPageContainer>
  );
};
