import { AxiosError, AxiosResponse } from "axios";
import { postConfig } from "shared/api/config";
import { TFiatFlow } from "shared/definitions";
import { axiosInstance } from "shared/definitions/api";

export async function createDeposit(
  params: {
    amount: string,
    bankDetails: {
      account: string,
      bankAddress: string,
      bankName: string,
      bicSwift: string
    },
    createdAt: string,
    fiatFlowType: TFiatFlow,
    ticker: string,
    userId: string
  },
): Promise<AxiosResponse | AxiosError> {
  return axiosInstance(postConfig("/transactions/deposit/fiat", params));
}
