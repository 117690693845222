import { FieldsContentText, FieldWrapper } from "components/ui/fields";
import React from "react";

type TFieldsTextViewProps = {
  text: string;
  label: string;
  setTextFn: (val: string) => void;
  onChangeFn?: (val: string) => void;
  handleSave: any;
  inline: boolean;
  textareaProps?: any;
  fieldWrapperProps?: any;
};

export const FieldsTextView = (props: TFieldsTextViewProps) => {
  const { text, label, fieldWrapperProps = {}, inline = false } = props;
  return (
    <FieldWrapper label={label} {...fieldWrapperProps}>
      <FieldsContentText inline={inline} text={text} />
    </FieldWrapper>
  );
};
