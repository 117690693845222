import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "@mantine/core";
import { resetTransactionsFilters, setTransactionsSegmentedControlValue } from "store/slices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/store";
import { TTransactionsSegmentedControlValue } from "shared/types/transactions";
import { useRoles } from "shared/hooks/useRoles";
import { ReactComponent as OperationsIcon } from "shared/static/icons/adapted/operations.svg";
import { ReactComponent as ExchangeIcon } from "shared/static/icons/adapted/exchange.svg";
import { useRoute } from "react-router5";
import { Routes, TransactionScreenSubRoutes } from "shared/definitions";

export const TransactionsCommonTabs = React.memo(() => {
  const { router, route } = useRoute();
  const initialSegmentedData = [
    {
      label: "Пополнения/выводы",
      value: "depositAndWithdraw",
      icon: OperationsIcon,
    },
  ];

  const dispatch = useDispatch();
  const { isOperator, currentRole } = useRoles();
  const [segmentedData, setSegmentedData] = useState(initialSegmentedData);
  const transactionsSegmentedControlValue = useAppSelector(
    (state) => state.transactions.transactionsSegmentedControlValue,
  );

  const setFirstAllowedTab = useCallback(
    () =>
      dispatch(
        setTransactionsSegmentedControlValue(
          isOperator
            ? "depositAndWithdraw"
            : ((route.name === Routes.TRANSACTIONS_SCREEN + "." + TransactionScreenSubRoutes.EXCHANGES
                ? "exchanged"
                : "depositAndWithdraw") as TTransactionsSegmentedControlValue),
        ),
      ),
    [route.name],
  );

  useEffect(() => {
    if (!isOperator) {
      setSegmentedData([...initialSegmentedData, { label: "Обмены", value: "exchanged", icon: ExchangeIcon }]);
    } else {
      setFirstAllowedTab();
      setSegmentedData(initialSegmentedData);
    }
  }, [currentRole]);

  useEffect(() => {
    setFirstAllowedTab();
  }, [route.name]);

  return (
    <Tabs
      color="violet"
      value={transactionsSegmentedControlValue}
      onTabChange={(value: TTransactionsSegmentedControlValue) => {
        router.navigate(
          `${Routes.TRANSACTIONS_SCREEN}${value === "exchanged" ? "." + TransactionScreenSubRoutes.EXCHANGES : ""}`,
        );
        dispatch(resetTransactionsFilters());
        dispatch(setTransactionsSegmentedControlValue(value));
      }}>
      <Tabs.List>
        {segmentedData.map((item) => {
          const Icon = item.icon;
          return (
            <Tabs.Tab key={`tk${item.value}`} value={item.value} icon={<Icon height={16} />}>
              {item.label}
            </Tabs.Tab>
          );
        })}
      </Tabs.List>
    </Tabs>
  );
});
