import { useAppSelector } from "store/store";
import { Button, Flex, Space, Switch, Table, Title, Text } from "@mantine/core";
import { TExchangeTickerOperation } from "shared/types/currencies";
import { getExchangeState } from "shared/utils/currencies/tickerOperations";
import { useTickerOperations } from "shared/hooks/useTickerOperations";
import { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "../Components/DataTables/DataTables.module.scss";
import { UpdateDialog } from "../Components/DataTables/UpdateDialog";

type TDialogParams = {
  allowed: boolean;
  ids: number[];
  title: string;
};

const SwitchProps = {
  size: "md",
  onLabel: "ON",
  offLabel: "OFF",
  color: "green.5",
};

export function DataTables() {
  const [dialogParams, setDialogParams] = useState<TDialogParams | null>(null);
  const allOperations = useAppSelector((state) => state.currencies.tickerOperations);
  const currencies = useAppSelector((state) => state.currencies.currencies);
  const operations = allOperations.filter((el): el is TExchangeTickerOperation => el.transactionType === "exchange");
  const operationsState = getExchangeState(currencies, operations);
  const { updateData } = useTickerOperations();

  const getOnChangeHandler = useCallback(
    (el: { id: number | number[]; allowed: boolean; title: string }) => {
      return async () => {
        setDialogParams({ ids: Array.isArray(el.id) ? el.id : [el.id], allowed: !el.allowed, title: el.title });
      };
    },
    [updateData],
  );

  return (
    <>
      {dialogParams && (
        <UpdateDialog {...dialogParams} onClose={() => setDialogParams(null)} onSubmit={() => setDialogParams(null)} />
      )}
      <Flex justify="space-between">
        <Title order={2}>Все пары</Title>
        <Button
          onClick={getOnChangeHandler({
            id: allOperations.filter((el) => el.transactionType === "exchange").map((el) => el.id),
            allowed: true,
            title: "Все обмены будут отключены",
          })}
          variant="light"
          color="red">
          Выключить весь обмен
        </Button>
      </Flex>
      <Space h="sm" />
      <Table verticalSpacing="md">
        <thead>
          <tr>
            <td className={classNames(styles.headerCell, styles.header)}>Валюта</td>
            <td className={styles.headerCell}>Крипта → Фиат</td>
            <td className={styles.headerCell}>Фиат → Крипта</td>
            <td className={styles.headerCell}>В обе стороны</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(operationsState).map((pairKey) => {
            const state = operationsState[pairKey];
            return (
              <tr key={pairKey}>
                <td>
                  <Text fw="bold">{pairKey}</Text>
                </td>
                <td>
                  <Switch
                    { ...SwitchProps }
                    onChange={getOnChangeHandler({
                      ...state.cryptoToFiat,
                      title: `Обмен Крипта → Фиат для пары ${pairKey} будет ${
                        state.cryptoToFiat.allowed ? "отключен" : "включен"
                      }`,
                    })}
                    className={styles.switch}
                    checked={state.cryptoToFiat.allowed}
                  />
                </td>
                <td>
                  <Switch
                    { ...SwitchProps }
                    onChange={getOnChangeHandler({
                      ...state.fiatToCrypto,
                      title: `Обмен Фиат → Крипта для пары ${pairKey} будет ${
                        state.fiatToCrypto.allowed ? "отключен" : "включен"
                      }`,
                    })}
                    className={styles.switch}
                    checked={state.fiatToCrypto.allowed}
                  />
                </td>
                <td>
                  <Switch
                    { ...SwitchProps }
                    onChange={getOnChangeHandler({
                      id: [state.fiatToCrypto.id, state.cryptoToFiat.id],
                      allowed: state.fiatToCrypto.allowed && state.cryptoToFiat.allowed,
                      title: `Все обмены для пары ${pairKey} будут ${
                        state.fiatToCrypto.allowed && state.cryptoToFiat.allowed ? "отключены" : "включены"
                      }`,
                    })}
                    className={styles.switch}
                    checked={state.fiatToCrypto.allowed && state.cryptoToFiat.allowed}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
