import "./shared/static/styles/common.scss";
import { useRoute } from "react-router5";
import { MantineProvider } from "@mantine/core";
import { theme } from "shared/utils/mantine/theme";
import { store } from "store/store";
import { Provider } from "react-redux";
import { Authentication } from "components/common/Authentication";
import { useEffect } from "react";
import { SubscribeOnNetworkError } from "shared/definitions/api";
import cogoToast from "cogo-toast";
import RouteProvider from "./routeProvider";

export default function App(props: any) {
  const { route } = useRoute();
  useEffect(() => {
    return SubscribeOnNetworkError(() => {
      cogoToast.error("Network error. Check your internet connection", {
        hideAfter: 0,
      });
    });
  }, []);
  return (
    <MantineProvider theme={theme} withCSSVariables withGlobalStyles withNormalizeCSS>
      <Authentication>
        <Provider store={store}>
          <RouteProvider router={route !== undefined ? route : {}} {...props} />
        </Provider>
      </Authentication>
    </MantineProvider>
  );
}
