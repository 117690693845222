import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getOrganizations, GetOrganizationsRequestProps } from "shared/api";
import { treatmentOrganizationClientsData } from "shared/utils";
import { setOrganizationClients, setOrganizationClientsCountAll } from "store/slices/organizationClientsSlice";

export const useGetUsers = () => {
  const dispatch = useAppDispatch();
  const {
    organizationClientsList,
    organizationClientsPage,
    organizationClientsPageSize,
    organizationClientsSearch,
    organizationClientsSortBy,
    organizationClientsStatusFilter,
    organizationClientsIsOrderAsc,
  } = useAppSelector((state) => state.organizationClients);

  // const validatedSearchFilter = searchFilter.length > 2 ? searchFilter : "";

  const getUsersRequest = useCallback(() => {
    const params: GetOrganizationsRequestProps = {
      page: organizationClientsPage,
      page_size: organizationClientsPageSize,
    };

    if (organizationClientsSearch) {
      params.search = organizationClientsSearch;
    }

    if (organizationClientsStatusFilter) {
      params.status = organizationClientsStatusFilter;
    }

    if (organizationClientsSortBy) {
      params.order_by = organizationClientsSortBy;
      if (organizationClientsIsOrderAsc) {
        params.is_asc_ordering = true;
      }
    }

    return getOrganizations(params);
  }, [
    organizationClientsPage,
    organizationClientsPageSize,
    organizationClientsSearch,
    organizationClientsSortBy,
    organizationClientsStatusFilter,
    organizationClientsIsOrderAsc,
  ]);

  useEffect(() => {
    getUsersRequest().then((response) => {
      if (response.data.items === null) {
        dispatch(setOrganizationClients([]));
        dispatch(setOrganizationClientsCountAll(0));
      } else {
        const items = response.data.items.map(treatmentOrganizationClientsData);
        dispatch(setOrganizationClients(items));
        dispatch(setOrganizationClientsCountAll(response.data.count));
      }
    });
  }, [getUsersRequest]);

  return {
    getUsersRequest,
    clients: organizationClientsList,
  };
};
