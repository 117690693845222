import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getCustodyTransactions,
  getCustodyTransactionsAbortController,
  GetCustodyTransactionsRequestProps,
} from "shared/api";
import { setJournalTransfersCountAll, setJournalTransfersData } from "store/slices";
import { switchMatch } from "shared/utils";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

export const useGetJournalTransfers = () => {
  const dispatch = useAppDispatch();

  const {
    journalTransfersPage,
    journalTransfersPageSize,
    journalTransfersFromTypeFilter,
    journalTransfersToTypeFilter,
    journalTransfersSearchFilter,
    journalTransfersIsAscOrderingFilter,
    journalTransfersOrderByFilter,
    journalTransfersStatusFilter,
    journalTransfersToDateFilter,
    journalTransfersFromDateFilter,
  } = useAppSelector(({ journalTransfers }) => journalTransfers);

  const validatedFromCreatedAtFilter =
    journalTransfersFromDateFilter !== null ? new Date(+journalTransfersFromDateFilter).toISOString() : null;

  const validatedToCreatedAtFilter =
    journalTransfersToDateFilter !== null ? new Date(+journalTransfersToDateFilter).toISOString() : null;

  const validatedSearchFilter: string = journalTransfersSearchFilter.length > 2 ? journalTransfersSearchFilter : "";

  const getJournalTransfersRequest = useCallback(() => {
    const params: GetCustodyTransactionsRequestProps = {
      is_asc_ordering: journalTransfersIsAscOrderingFilter,
      order_by: `${!journalTransfersOrderByFilter.length ? "created_at" : journalTransfersOrderByFilter}`,
      page: journalTransfersPage,
      page_size: journalTransfersPageSize,
    };

    if (journalTransfersFromTypeFilter.length) {
      params.wallet_type_from = journalTransfersFromTypeFilter;
    }

    if (journalTransfersToTypeFilter.length) {
      params.wallet_type_to = journalTransfersToTypeFilter;
    }

    if (validatedSearchFilter.length > 2) {
      params.search = validatedSearchFilter;
    }

    if ((journalTransfersStatusFilter ?? "").length) {
      params.status = switchMatch(journalTransfersStatusFilter, {
        default: journalTransfersStatusFilter,
      });
    }

    if (validatedFromCreatedAtFilter !== null) {
      params.created_at_min = validatedFromCreatedAtFilter;
    }

    if (validatedToCreatedAtFilter !== null) {
      params.created_at_max = validatedToCreatedAtFilter;
    }

    getCustodyTransactionsAbortController.abort();
    return getCustodyTransactions(params);
  }, [
    journalTransfersPage,
    journalTransfersFromTypeFilter,
    journalTransfersToTypeFilter,
    journalTransfersOrderByFilter,
    validatedSearchFilter,
    journalTransfersIsAscOrderingFilter,
    journalTransfersStatusFilter,
    validatedFromCreatedAtFilter,
    validatedToCreatedAtFilter,
  ]);

  const treatmentJournalTransfersData = useCallback(
    (data: any) => [
      ...data.map((item: any) => {
        return {
          id: item.id,
          createdAt: item.createdAt,
          blockNumber: item?.blockNumber ?? "",
          blockchainCreatedAt: item?.blockchainCreatedAt ?? "",
          network: item?.network ?? "",
          status: item?.status ?? "",
          amount: item?.amount ?? "",
          ticker: item?.ticker ?? "",
          transactionDirection: item?.transactionDirection ?? "",
          transactionHash: item?.transactionHash ?? "",
          walletTypeFrom: item?.walletTypeFrom ?? "",
          walletTypeTo: item?.walletTypeTo ?? "",
        };
      }),
    ],
    [],
  );

  useEffect(() => {
    getJournalTransfersRequest()
      .then((response) => {
        dispatch(setJournalTransfersData(treatmentJournalTransfersData(response.data.items)));
        dispatch(setJournalTransfersCountAll(response.data.count));
      })
      .catch((e) => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
  }, [getJournalTransfersRequest]);

  return {
    getJournalTransfersRequest,
  };
};
