import React, { FC, useCallback, useState } from "react";
import { Title, Tabs as MantineTabs } from "@mantine/core";
import { Routes, WalletsSubRoutes } from "shared/definitions";
import { ReactComponent as WalletBalanceIcon } from "shared/static/icons/adapted/walletBalance.svg";
import { ReactComponent as WhitelistsIcon } from "shared/static/icons/adapted/whitelists.svg";
import { ReactComponent as PayinIcon } from "shared/static/icons/adapted/payin.svg";
import { ReactComponent as JournalIcon } from "shared/static/icons/adapted/journal.svg";
import { useRoute } from "react-router5";
import styles from "./Tabs.module.scss";

interface IProps {}

export const Tabs: FC<IProps> = () => {
  const { route, router } = useRoute();
  const [selectedTab, setSelectedTab] = useState<string>(route.name);

  const handleTabChange = useCallback((tab: string) => {
    router.navigate(tab);
    setSelectedTab(tab);
  }, []);

  return (
    <div className={styles.tabsContainer}>
      <Title>Управление кошельками</Title>

      <MantineTabs color="violet" value={selectedTab} onTabChange={(v: string) => handleTabChange(v)}>
        <MantineTabs.List>
          <MantineTabs.Tab
            value={`${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.BALANCES}`}
            icon={<WalletBalanceIcon height={16} />}>
            Балансы
          </MantineTabs.Tab>
          <MantineTabs.Tab
            value={`${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.PAYIN}`}
            icon={<PayinIcon height={16} />}>
            Payin адреса
          </MantineTabs.Tab>
          <MantineTabs.Tab
            disabled
            value={`${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.WHITELISTS}`}
            icon={<WhitelistsIcon height={16} />}>
            Вайтлисты
          </MantineTabs.Tab>
          <MantineTabs.Tab
            value={`${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.JOURNAL}`}
            icon={<JournalIcon height={16} />}>
            Журнал переводов
          </MantineTabs.Tab>
        </MantineTabs.List>
      </MantineTabs>
    </div>
  );
};
