import React, { useCallback, useState } from "react";
import { useAppDispatch } from "store/store";
import cogoToast from "cogo-toast";
import { changeTransactionStatus } from "shared/api";
import { setIsShownTransactionPanel, setStatusTransactionById, setStatusTransactionDetail } from "store/slices";
import { CHANGE_STATUS_REQUEST_SUCCESS_TEXT } from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { FieldWrapper } from "components/ui/fields";
import { Button, Divider, Group, Modal, Space, Textarea, Title } from "@mantine/core";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { FieldTreatment } from "components/common/FieldTreatment";
import { ValuesOf } from "shared/types/common";
import { TransactionStatus } from "shared/definitions/transactions/statuses";
import { sentryCaptureError } from "shared/utils/environment/sentry";

type TPropsTransactionViewStatusBlock = {
  transactionId: string;
  transactionStatus: ValuesOf<typeof TransactionStatus>;
  updateList: () => void;
};

export const TransactionViewStatusBlock = (props: TPropsTransactionViewStatusBlock) => {
  const dispatch = useAppDispatch();
  const [isOpenedModalChangeTransactionStatus, setIsOpenedModalChangeTransactionStatus] = useState(false);
  const [commentOfChangeTransactionStatus, setCommentOfChangeTransactionStatus] = useState("");
  const [typeHelperChangeTransactionStatus, setTypeHelperChangeTransactionStatus] = useState<"completed" | "rejected">(
    "completed",
  );

  const handleSetTransactionStatus = useCallback(
    (tid: string, comment: string) => {
      if (!comment.length) return cogoToast.error("Укажите комментарий");
      setIsOpenedModalChangeTransactionStatus(false);
      changeTransactionStatus({
        transactionId: tid,
        data: {
          comment,
          status: typeHelperChangeTransactionStatus,
        },
      })
        .then(() => {
          cogoToast.success(CHANGE_STATUS_REQUEST_SUCCESS_TEXT);
          dispatch(
            setStatusTransactionById({
              transactionId: props.transactionId,
              transactionStatus: typeHelperChangeTransactionStatus,
            }),
          );
          dispatch(setStatusTransactionDetail("pending"));
          dispatch(setIsShownTransactionPanel(false));
          props.updateList();
        })
        .catch(sentryCaptureError)
        .finally(() => {
          setCommentOfChangeTransactionStatus("");
        });
    },
    [typeHelperChangeTransactionStatus],
  );

  return (
    <FieldWrapper label="Статус">
      <div className="w-100">
        <Modal
          radius={16}
          zIndex={1000}
          title={<Title order={3}>Подтверждение смены статуса</Title>}
          opened={isOpenedModalChangeTransactionStatus}
          onClose={() => setIsOpenedModalChangeTransactionStatus(false)}>
          <Padding horizontal={1} vertical={1}>
            <Textarea
              size="lg"
              value={commentOfChangeTransactionStatus}
              onChange={(event) => setCommentOfChangeTransactionStatus(event.currentTarget.value)}
              radius={12}
              label="Комментарий"
              autosize
              placeholder="Введите текст"
            />
            <Spacer size={6} />
            <Button
              radius={12}
              size="md"
              color={typeHelperChangeTransactionStatus === "completed" ? "green" : "red"}
              onClick={() => {
                handleSetTransactionStatus(props?.transactionId, commentOfChangeTransactionStatus);
              }}>
              {typeHelperChangeTransactionStatus === "completed" ? "Подтвердить" : "Отклонить"}
            </Button>
            <Button
              ml={16}
              radius={12}
              size="md"
              variant="default"
              onClick={() => setIsOpenedModalChangeTransactionStatus(false)}>
              Отмена
            </Button>
          </Padding>
        </Modal>
        <Padding horizontal={2} vertical={1}>
          <div>
            <div>
              <FieldTreatment name="transactionStatus">{props.transactionStatus}</FieldTreatment>
            </div>
            <Space mt={12} />
            <div>
              <Divider />
              <Space mt={12} />
              <Group>
                <Button
                  compact
                  radius={4}
                  size="sm"
                  variant="light"
                  color="green"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("completed");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Подтвердить
                </Button>
                <Button
                  compact
                  radius={4}
                  size="sm"
                  variant="light"
                  color="red"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("rejected");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Отклонить
                </Button>
              </Group>
              <Space mb={4} />
            </div>
          </div>
        </Padding>
      </div>
    </FieldWrapper>
  );
};
