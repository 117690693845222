import { getOrganizations, getUsers } from "shared/api";
import { treatmentOrganizationClientsData, treatmentPersonalClientData } from "shared/utils";

export const getClientById = async (cid: string) => {
  try {
    const res = await getUsers({
      search: cid,
      is_asc_ordering: false,
      page: 1,
      page_size: 1,
    });
    if (res.data?.items?.length > 0) {
      return treatmentPersonalClientData(res.data.items[0]);
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getOrganizationClientById = async (cid: string) => {
  try {
    const res = await getOrganizations({
      search: cid,
      is_asc_ordering: false,
      page: 1,
      page_size: 1,
    });
    if (res.data?.items?.length > 0) {
      return treatmentOrganizationClientsData(res.data.items[0]);
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};
