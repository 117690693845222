import { useEffect } from "react";

const IN_SEARCH_TIME = 30000;
const UPDATE_TIME = 8000;

let pageIsViewedStatus: "here" | "wanted" | "gone" = "here";

const onPageLeaveHandler = () => {
  pageIsViewedStatus = "wanted";
  setTimeout(() => {
    if (pageIsViewedStatus === "wanted") {
      pageIsViewedStatus = "gone";
    }
  }, IN_SEARCH_TIME);
};
const onPageEnterHandler = () => {
  pageIsViewedStatus = "here";
};

export const useUpdateOnlyWithMouseActions = (updateFn: () => Promise<any> | void) => {
  useEffect(() => {
    if (pageIsViewedStatus !== "here") {
      pageIsViewedStatus = "here";
    }

    document.documentElement.addEventListener("mouseleave", onPageLeaveHandler);
    document.documentElement.addEventListener("mouseenter", onPageEnterHandler);

    const updateInterval = setInterval(() => {
      if (pageIsViewedStatus !== "gone") updateFn();
    }, UPDATE_TIME);

    return () => {
      document.documentElement.removeEventListener("mouseleave", onPageLeaveHandler);
      document.documentElement.removeEventListener("mouseenter", onPageEnterHandler);
      clearInterval(updateInterval);
      pageIsViewedStatus = "here";
    };
  }, [updateFn]);
};
