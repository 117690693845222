import React, { useCallback, useMemo } from "react";
import { Button, Checkbox, createStyles, rem, Table } from "@mantine/core";
import { EmptyBlock } from "shared/features";
import { TPayin } from "shared/types/payin";
import { ReactComponent as WithdrawIcon } from "shared/static/icons/adapted/withdraw.svg";
import { ReactComponent as CloseIcon } from "shared/static/icons/adapted/close.svg";
import { useAppDispatch, useAppSelector } from "store/store";
import { setPayinSelectionAddresses, setPayinTransferModalIsOpened } from "store/slices";
import { PayinListRow } from "../../components/PayinListRow";

const PayinListViewTableTitles = ["Адреса", "ID", "Баланс"];

const useStyles = createStyles((theme) => ({
  selectedWrapper: {
    position: "fixed",
    bottom: 0,
    minWidth: rem(100),
    minHeight: rem(66),
    backgroundColor: "#F3F0FF",
    borderRadius: "8px 8px 0 0",
    zIndex: 10,
    border: "1px solid #9775FA",
    borderBottomWidth: 0,
    display: "flex",
    paddingRight: rem(16),
  },
  selectedCloseIcon: {
    height: rem(66),
    display: "flex",
    padding: "0 12px",
    cursor: "pointer",
    svg: {
      height: rem(24),
      margin: "auto",
      color: theme.colors.violet[5],
    },
  },
  selectedFooterText: {
    cursor: "pointer",
    color: theme.colors.black,
    fontWeight: 500,
    padding: "0 50px 0 0",
    margin: "auto",
  },
  selectedFooterWithdrawButtonWrapper: {
    margin: "auto",
  },
}));

export const PayinListView = ({ data }: { data: TPayin[] }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { payinSelectionAddresses: selection } = useAppSelector(({ payin }) => ({
    payinSelectionAddresses: payin.payinSelectionAddresses,
  }));

  const setSelection = useCallback((selectionData: TPayin[]) => {
    dispatch(setPayinSelectionAddresses(selectionData));
  }, []);

  const toggleAll = () => setSelection(selection.length === data.length ? [] : data);

  const toggleRow = (el: TPayin) =>
    setSelection(
      selection.filter(({ id }) => id === el.id).length
        ? selection.filter(({ id }) => id !== el.id)
        : [...selection, el],
    );

  const rows = useMemo(
    () =>
      data.map((element) => (
        <PayinListRow key={`PayinListRow${element.id}`} el={element} selection={selection} toggleRow={toggleRow} />
      )),
    [data, selection],
  );

  if (!data.length)
    return <EmptyBlock title="Payin адреса не найдены" description="По такому фильтру не найдено ни одного адреса" />;

  return (
    <div>
      {selection.length ? (
        <div className={classes.selectedWrapper}>
          <div className={classes.selectedCloseIcon} onClick={() => setSelection([])}>
            <CloseIcon color="#868E96" />
          </div>
          <div className={classes.selectedFooterText} onClick={() => setSelection([])}>
            Выбрано {selection.length} payin адреса
          </div>
          <div className={classes.selectedFooterWithdrawButtonWrapper}>
            <Button
              color="violet"
              radius={8}
              leftIcon={<WithdrawIcon height="14px" color="white" />}
              onClick={() => {
                dispatch(setPayinTransferModalIsOpened(true));
              }}>
              Вывести средства
            </Button>
          </div>
        </div>
      ) : null}
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="md"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            <th style={{ width: rem(40) }}>
              <Checkbox
                color="violet"
                onChange={toggleAll}
                checked={selection.length === data.length}
                indeterminate={selection.length > 0 && selection.length !== data.length}
                transitionDuration={0}
              />
            </th>
            {PayinListViewTableTitles.map((title) => {
              return (
                <th key={`PILVTT${title}`} className="break_word">
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
