import "dayjs/locale/ru";
import { DatesProvider, DateTimePicker } from "@mantine/dates";
import {
  ActionIcon,
  Button,
  createStyles,
  Grid,
  Group,
  Input,
  Menu,
  MultiSelect,
  Select,
  Space,
  Switch,
} from "@mantine/core";
import { useDispatch } from "react-redux";
import {
  setTransactionsCurrenciesFilter,
  setTransactionsFromDateFilter,
  setTransactionsIsAscOrderingFilter,
  setTransactionsOrderByFilter,
  setTransactionsSearchFilter,
  setTransactionsStatusFilter,
  setTransactionsToDateFilter,
} from "store/slices";
import { ReactComponent as SearchIcon } from "shared/static/icons/adapted/search.svg";
import { ReactComponent as SearchCancelIcon } from "shared/static/icons/adapted/searchCancel.svg";
import { useAppSelector } from "store/store";
import React, { useEffect, useMemo, useState } from "react";
import { useCurrencies } from "shared/hooks/useCurrencies";
import { TCurrency } from "shared/types/currencies";
import { FilterBadge } from "components/common/FilterBadge";
import { switchMatch } from "shared/utils";
import dayjs from "dayjs";
import { useDebouncedValue } from "@mantine/hooks";
import { ReactComponent as FiltersIcon } from "shared/static/icons/adapted/filters.svg";
import classNames from "classnames";

const useStyles = createStyles((theme) => ({
  inputWrapper: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    borderRadius: 8,
    backgroundColor: "white",
  },
  filtersButton: {
    "height": 42,
    "borderLeft": "1px solid rgba(0,0,0,0.07)",
    "borderRadius": "0 7px 7px 0",
    "color": "black",
    "&.isFiltered": {
      color: theme.colors.violet[6],
    },
  },
}));

const transactionsStatuses = [
  { value: "new", label: "Не подтверждена" },
  { value: "pending", label: "В обработке" },
  { value: "hold", label: "Приостановлена" },
  { value: "user_approving", label: "Подтверждение liveness" },
  { value: "sending", label: "К отправке" },
  { value: "rejected", label: "Отклонена" },
  { value: "completed", label: "Успешно" },
];

export const TransactionsCommonFilters = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { currencies } = useCurrencies();
  const {
    transactionsSearchFilter,
    transactionsCurrenciesFilter,
    transactionsIsAscOrderingFilter,
    transactionsStatusFilter,
    transactionsOrderByFilter,
    transactionsFromDateFilter,
    transactionsToDateFilter,
  } = useAppSelector(({ transactions }) => transactions);

  const isFiltered =
    !!transactionsOrderByFilter.length ||
    transactionsIsAscOrderingFilter ||
    transactionsCurrenciesFilter.length ||
    transactionsToDateFilter !== null ||
    transactionsFromDateFilter !== null ||
    transactionsStatusFilter !== null;

  const currenciesSelectData = useMemo(
    () =>
      currencies.map((item: TCurrency) => ({
        label: item.ticker,
        value: item.ticker,
      })),
    [currencies],
  );

  const transactionsFilterStatusTitle = useMemo(
    () =>
      switchMatch(transactionsStatusFilter ?? "", {
        new: "Не подтверждена",
        hold: "Приостановлена",
        user_approving: "Подтверждение liveness",
        rejected: "Отклонена",
        completed: "Успешно",
        sending: "К отправке",
        pending: "В обработке",
        default: transactionsStatusFilter,
      }),
    [transactionsStatusFilter],
  );

  const [valueDateFrom, setValueDateFrom] = useState<any>(null);
  const [debouncedValueDateFrom] = useDebouncedValue(valueDateFrom, 500);

  const [valueDateTo, setValueDateTo] = useState<any>(null);
  const [debouncedValueDateTo] = useDebouncedValue(valueDateTo, 500);

  useEffect(() => {
    dispatch(setTransactionsFromDateFilter(debouncedValueDateFrom ? (+debouncedValueDateFrom).toString() : null));
    dispatch(setTransactionsToDateFilter(debouncedValueDateTo ? (+debouncedValueDateTo).toString() : null));
  }, [debouncedValueDateFrom, debouncedValueDateTo]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search?.slice(1));
    const id = urlParams.get("id");

    if (id !== null) {
      dispatch(setTransactionsSearchFilter(id));
    }
  }, []);

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <div className={classes.inputWrapper}>
            <Input
              id="inputTransactionFilter"
              placeholder="Поиск ID транзакции, ID клиента"
              radius={8}
              size="md"
              variant="unstyled"
              icon={
                <ActionIcon size={24} ml={16} mr={8}>
                  <SearchIcon color="#868E96" />
                </ActionIcon>
              }
              style={{
                flex: "1",
              }}
              value={transactionsSearchFilter}
              rightSection={
                transactionsSearchFilter.length > 0 ? (
                  <ActionIcon
                    size={24}
                    ml="auto"
                    mr={8}
                    onClick={() => {
                      dispatch(setTransactionsSearchFilter(""));
                    }}>
                    <SearchCancelIcon color="#868E96" />
                  </ActionIcon>
                ) : (
                  <Button ml="auto" mr={40} compact radius={6} variant="light">
                    Найти
                  </Button>
                )
              }
              onChange={(event: { target: { value: string } }) =>
                dispatch(setTransactionsSearchFilter(event.target.value))
              }
            />
            <Menu shadow="md" radius={8} width={400}>
              <Menu.Target>
                <Button
                  leftIcon={<FiltersIcon width={22} />}
                  variant="gradient"
                  gradient={{ from: "#fff", to: "#fff" }}
                  className={classNames(classes.filtersButton, { isFiltered })}>
                  Фильтры
                </Button>
              </Menu.Target>
              <Menu.Dropdown p="8px 16px 16px 16px">
                <div>
                  <Select
                    label="Статус"
                    placeholder="Статус транзакции"
                    data={transactionsStatuses}
                    onChange={(e) => dispatch(setTransactionsStatusFilter(e ?? ""))}
                  />
                </div>
                <div>
                  <MultiSelect
                    placeholder="Валюта"
                    data={currenciesSelectData}
                    label="Валюта"
                    onChange={(data) => dispatch(setTransactionsCurrenciesFilter(data))}
                  />
                </div>
                <div>
                  <Select
                    label="Сортировка"
                    placeholder="Сортировать по"
                    data={[{ value: "created_at", label: "Дате создания" }]}
                    onChange={(e) => dispatch(setTransactionsOrderByFilter(e ?? ""))}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <Switch
                    label="А-Я Порядок"
                    checked={transactionsIsAscOrderingFilter}
                    onChange={(event) => dispatch(setTransactionsIsAscOrderingFilter(event.currentTarget.checked))}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <DatesProvider settings={{ locale: "ru", firstDayOfWeek: 1, weekendDays: [0] }}>
                    <DateTimePicker
                      clearable
                      valueFormat="DD.MM.YYYY hh:mm:ss"
                      label="Созданы ОТ"
                      placeholder="Выберите время"
                      withSeconds
                      onFocus={() => {}}
                      value={valueDateFrom ? new Date(+valueDateFrom) : null}
                      mx="auto"
                      onChange={(e) => {
                        setValueDateFrom(e ? (+e).toString() : null);
                      }}
                    />
                  </DatesProvider>
                </div>
                <div>
                  <Space mt={12} />
                  <DatesProvider settings={{ locale: "ru", firstDayOfWeek: 0, weekendDays: [0] }}>
                    <DateTimePicker
                      clearable
                      valueFormat="DD.MM.YYYY hh:mm:ss"
                      label="Созданы ДО"
                      onFocus={() => {}}
                      placeholder="Выберите время"
                      withSeconds
                      value={valueDateTo ? new Date(+valueDateTo) : null}
                      mx="auto"
                      onChange={(e) => {
                        setValueDateTo(e ? (+e).toString() : null);
                      }}
                    />
                  </DatesProvider>
                </div>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Grid.Col>
      </Grid>
      {isFiltered && (
        <Group ml={0} mt={16}>
          {transactionsCurrenciesFilter.length && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setTransactionsCurrenciesFilter([]))}>
              <FilterBadge field="Валюта" value={transactionsCurrenciesFilter.join(",")} />
            </div>
          )}
          {transactionsStatusFilter !== null && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setTransactionsStatusFilter(null))}>
              <FilterBadge field="Статус" value={transactionsFilterStatusTitle} />
            </div>
          )}
          {transactionsIsAscOrderingFilter && (
            <div
              className="cursor_pointer no_select"
              onClick={() => dispatch(setTransactionsIsAscOrderingFilter(false))}>
              <FilterBadge field="Порядок" value="А-Я" />
            </div>
          )}
          {transactionsFromDateFilter && (
            <div
              className="cursor_pointer no_select"
              onClick={() => {
                setValueDateFrom(null);
                dispatch(setTransactionsFromDateFilter(null));
              }}>
              <FilterBadge
                field="Созданы ОТ"
                value={dayjs(new Date(+transactionsFromDateFilter).toString()).format("DD.MM.YYYY hh:mm")}
              />
            </div>
          )}
          {transactionsToDateFilter && (
            <div
              className="cursor_pointer no_select"
              onClick={() => {
                setValueDateTo(null);
                dispatch(setTransactionsToDateFilter(null));
              }}>
              <FilterBadge
                field="Созданы ДО"
                value={dayjs(new Date(+transactionsToDateFilter).toString()).format("DD.MM.YYYY hh:mm")}
              />
            </div>
          )}
          {transactionsOrderByFilter.length && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setTransactionsOrderByFilter(""))}>
              <FilterBadge
                field="Сортировка по"
                value={switchMatch(transactionsOrderByFilter.toLowerCase(), {
                  created_at: "Дате создания",
                  default: transactionsOrderByFilter,
                })}
              />
            </div>
          )}
        </Group>
      )}
    </div>
  );
};
