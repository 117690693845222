import { TClientDetailsShortOrganization } from "shared/types/clients";
import { Grid, Paper, Space, Title } from "@mantine/core";
import React from "react";
import { AffiliatedOrganizationItem } from "./components/AffiliatedOrganizationItem";

type TPropsAffiliatedOrganizations = {
  data: TClientDetailsShortOrganization[];
};

export const AffiliatedOrganizations = (props: TPropsAffiliatedOrganizations) => {
  return (
    <div>
      <Paper>
        <Title order={4} mb={16}>
          Компании
        </Title>
        <Grid columns={12}>
          {props.data.map((item: TClientDetailsShortOrganization) => {
            return (
              <Grid.Col span={6} key={`TCEDSO_${item.id}`}>
                <AffiliatedOrganizationItem {...item} />
              </Grid.Col>
            );
          })}
        </Grid>
        {!props.data.length && (
          <div>
            <Space mt={12} />
            <Title ml={8} order={3}>
              Нет привязанных
            </Title>
          </div>
        )}
      </Paper>
      <Space mt={24} />
    </div>
  );
};
