import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetOrganizationsRequestProps = {
  search?: string;
  order_by?: string;
  is_asc_ordering?: boolean;
  status?: string;
  page: number;
  page_size: number;
};

export function getOrganizations(params: GetOrganizationsRequestProps) {
  return axiosInstance<{ items: any[]; count: number }>(getConfig("/users/organizations", params));
}
