import React, { FC, useCallback, useState } from "react";
import { Button, Modal, Title } from "@mantine/core";
import { useAppSelector } from "store/store";
import classNames from "classnames";
import { ManageAssetsAmountField } from "shared/types/manageAssets";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Tabs } from "./tabs";
import styles from "./styles.module.scss";
import { TransferableValue } from "./TransferableValue";
import { ModalType, useManageAssetsModals } from "./useManageAssetsModals";
import { TransferModal } from "./TransferModal";
import { WithdrawAllModal } from "./WithdrawAllModal";

interface IProps {}

export const ManageAssets: FC<IProps> = () => {
  const { modalType, modalTitle, isModalOpened, handleTransferModalOpen, handleWithdrawModalOpen, handleModalClose } =
    useManageAssetsModals();
  const { tableData } = useAppSelector((state) => state.manageAssets);

  const [rowIndex, setRowIndex] = useState<number | undefined>();
  const [dataKey, setDataKey] = useState<ManageAssetsAmountField | undefined>();

  const openTransferModal = useCallback(
    (row: number, key: ManageAssetsAmountField) => {
      return () => {
        setRowIndex(row);
        setDataKey(key);
        handleTransferModalOpen();
      };
    },
    [handleTransferModalOpen],
  );

  return (
    <>
      <Padding horizontal={4} vertical={3}>
        <Title order={1}>Управление финансами</Title>
        <Spacer size={4} />
        <Tabs />
        <Spacer size={8} />
        <div className={styles.card}>
          <div className={styles.table}>
            <div className={classNames(styles.tableRow, styles.tableHeader)}>
              {tableData.columns.map((column) => (
                <div key={column.key}>{column.label}</div>
              ))}
            </div>

            {tableData.rows.map(({ currency, payin, payout, fan }, index) => (
              <div key={currency} className={styles.tableRow}>
                <div>{currency}</div>
                <TransferableValue value={payin} onClick={openTransferModal(index, "payin")} />
                <TransferableValue value={payout} onClick={openTransferModal(index, "payout")} />
                <TransferableValue value={fan} onClick={openTransferModal(index, "fan")} />
              </div>
            ))}
          </div>
        </div>

        <Spacer size={8} />

        <div className={styles.withdrawAllContainer}>
          <Button color="red" className={styles.withdrawAllButton} onClick={handleWithdrawModalOpen}>
            Вывести все
          </Button>
        </div>
      </Padding>

      <Modal onClose={handleModalClose} opened={isModalOpened} title={modalTitle}>
        {modalType === ModalType.Transfer && (
          <TransferModal rowIndex={rowIndex} dataKey={dataKey} onClose={handleModalClose} />
        )}
        {modalType === ModalType.Withdraw && <WithdrawAllModal onClose={handleModalClose} />}
      </Modal>
    </>
  );
};
