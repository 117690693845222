import { TClientLimits } from "shared/types/clients";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

// eslint-disable-next-line import/no-mutable-exports
export let fetchUserLimitsAbortController = new AbortController() as AbortController;

export function fetchUserLimits({ id, month, year }: { id: string; month?: number; year?: number }) {
  fetchUserLimitsAbortController = new AbortController();

  const params = month !== undefined && year !== undefined ? { month, year } : {};
  return axiosInstance(getConfig(`/users/${id}/turnover`, params)).then((res: { data: TClientLimits }) => res.data || null);
}
