import { useFees } from "shared/hooks/useFees";
import { useAppDispatch, useAppSelector } from "store/store";
import { useEffect } from "react";
import { setDepositStep } from "store/slices";
import { Confirm } from "./Confirm";
import { DepositFormModal } from "./DepositFormModal";
import { FindClientModal } from "./FindClientModal";
import { Result } from "./Result";

export const Deposit = () => {
  const dispatch = useAppDispatch();
  const { depositStep } = useAppSelector(({ modals }) => ({
    depositStep: modals.depositStep,
  }));

  useFees();

  useEffect(() => {
    return () => {
      dispatch(setDepositStep(null));
    };
  }, []);

  return (
    <>
      {depositStep === 1 && <FindClientModal />}
      {depositStep === 2 && <DepositFormModal />}
      {depositStep === 3 && <Confirm />}
      {depositStep === 4 && <Result />}
    </>
  );
};
