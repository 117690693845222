import { ActionIcon } from "@mantine/core";
import cogoToast from "cogo-toast";
import { memo } from "react";
import { ReactComponent as CopyIcon } from "shared/static/icons/copy.svg";
import { copyText } from "shared/utils/clipboard";

export const CopyActionIcon = memo(({ value, mt = 0 }: { value: string; mt?: number }) => (
  <ActionIcon
    mt={mt}
    size={17}
    color="gray"
    onClick={() => {
      copyText(value!);
      cogoToast.success("Скопировано!");
    }}>
    <CopyIcon color="#868E96" />
  </ActionIcon>
));
