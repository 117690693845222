import { Badge, Button, Text } from "@mantine/core";
import { downloadDoc } from "shared/api/users/downloadDoc";
import classNames from "classnames";
import styles from "./documentFile.module.scss";

type TProps = {
  link: string;
  cid: string;
  empty?: boolean;
  isOld: boolean;
};

function tryGetDocNameAndExt(link: string) {
  const fileInfo = {
    name: link,
    ext: "no extension",
  };
  const parts = link.split("/");
  const fileName = parts.slice(-1)[0];
  if (fileName) {
    fileInfo.name = fileName;
  }
  const ext = fileName.split(".").slice(-1)[0];
  if (ext) {
    fileInfo.ext = ext;
  }
  return fileInfo;
}

export function DocumentFile(props: TProps) {
  const fileInfo = tryGetDocNameAndExt(props.link);
  return (
    <div className={classNames(styles.root, { [styles.empty]: props.empty })}>
      {props.empty ? (
        <Text>Нет загруженный файлов</Text>
      ) : (
        <>
          {" "}
          <Text>{fileInfo.name}</Text>
          <Badge color="violet">{fileInfo.ext}</Badge>
          {props.isOld && <Badge color="red">Неактуальный</Badge>}
          <Button
            onClick={() => {
              downloadDoc(props.cid, props.link, fileInfo.name);
            }}
            variant="default">
            Скачать
          </Button>
        </>
      )}
    </div>
  );
}
