import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useClientCid } from "shared/hooks/useCid";

import { ClientBalances } from "shared/features/ClientsDetail/FinanceBlock/ClientBalances";
import { ClientLimits } from "shared/features/ClientsDetail/FinanceBlock/ClientLimits";
import styles from "shared/features/ClientsDetail/FinanceBlock/ClientFinances.module.scss";
import { useGetOrganizationUserId } from "shared/hooks/useGetOrganizationUserId";
import { useCurrencies } from "shared/hooks/useCurrencies";
import { ClientTabHeader } from "../components/ClientTabHeader";
import { ClientTabLayout } from "../components/ClientsTabLayout";
import { TransactionCard } from "../components/TransactionCard";
import { useGetOrganizationClientDetails } from "../../../Clients/Organizations/useGetOrganizationClientDetails";

export const ClientOrganizationFinances = () => {
  const { cid } = useClientCid();
  const { organizationUserId } = useGetOrganizationUserId();
  const { organizationClientDetails } = useGetOrganizationClientDetails({ cid });
  const { isLoadedCurrencies } = useCurrencies();

  if (organizationClientDetails === null || !organizationUserId.length || !isLoadedCurrencies) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={organizationClientDetails} tabName={"Финансы"} />
      <div className={styles.cardsContainer}>
        <ClientBalances cid={organizationUserId} />
        <ClientLimits cid={organizationUserId} />
      </div>

      <Spacer size={6} />

      {organizationClientDetails.publicId && <TransactionCard cid={organizationClientDetails.publicId} />}
    </ClientTabLayout>
  );
};
