import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";

export enum Roles {
  "Technical support" = "Поддержка",
  "Fin. manager" = "Фин. менеджер",
  "Head of fin. managers" = "Руководитель фин. менеджера",
  "Customers" = "Клиент",
  "Operators" = "Операционист",
  "Compliance managers" = "Комплаенс менеджер",
}

export const RolesKeys = {
  support: getEnumKeyByEnumValue(Roles, Roles["Technical support"]),
  finManager: getEnumKeyByEnumValue(Roles, Roles["Fin. manager"]),
  headOfFinManager: getEnumKeyByEnumValue(Roles, Roles["Head of fin. managers"]),
  customers: getEnumKeyByEnumValue(Roles, Roles.Customers),
  operator: getEnumKeyByEnumValue(Roles, Roles.Operators),
  compliance: getEnumKeyByEnumValue(Roles, Roles["Compliance managers"]),
};
