import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export function additionalData(
  id: string,
  params: {
    prev_last_name: string;
    quardian: {
      doc_id: string;
      doc_type: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      phone: string;
    };
    reason: string;
    social: {
      inst: string;
      ok: string;
      vk: string;
    };
    tax_id: string;
    work: {
      phone: string;
      position: string;
      workplace: string;
    };
  },
) {
  return axiosInstance(postConfig(`/users/${id}/additional-data`, params));
}
