import { useAppSelector } from "store/store";

export const useIsFiltered = () => {
  const { payinIsAscOrderingFilter, payinOrderByFilter, payinByExistFilter, payinSearchFilter } = useAppSelector(
    ({ payin }) => payin,
  );

  return {
    isFiltered: !!payinOrderByFilter.length || payinIsAscOrderingFilter || !!payinByExistFilter.length,
    isSearching: payinSearchFilter.length > 2,
  };
};
