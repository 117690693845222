import { TPersonalDocument } from "shared/definitions";
import { TWalletType } from "shared/types/wallets";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type TRequestParamsGetCustodyWalletsFilteredBalances = {
  ticker: string;
  network: string;
  wallet_type: TWalletType;
  addresses: string;
};

export function getCustodyWalletsFilteredBalances(params: TRequestParamsGetCustodyWalletsFilteredBalances) {
  return axiosInstance<TPersonalDocument[]>(getConfig("/custody/wallets/filtered_balances", params));
}
