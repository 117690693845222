import { ActionIcon, Button, Modal, SegmentedControl, Space, TextInput } from "@mantine/core";
import { ReactComponent as CloseIcon } from "shared/static/icons/adapted/close.svg";
import { useEffect, useState } from "react";
import { TPersonalClient } from "shared/types/clients";
import { TOrganization } from "shared/types/organizations";
import { ClientStatusKeys } from "shared/definitions/levels/clientStatus";
import cogoToast from "cogo-toast";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { getUser } from "shared/api";
import styles from "./FindClientModal.module.scss";
import { getClientById, getOrganizationClientById } from "./getClientById";
import { useDepositModalLogic } from "./useDepositModalLogic";

const ID_LENGTH = 6;

export const FindClientModal = () => {
  const [id, setId] = useState("");
  const [isValidId, setIsValidId] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [findUserType, setFindUserType] = useState<"personal" | "organization">("personal");
  const [localClient, setLocalClient] = useState<(TPersonalClient | TOrganization) | null>(null);
  const { setClient, setDepositStep, resetDeposit } = useDepositModalLogic();

  useEffect(() => {
    if (id.length === ID_LENGTH) {
      setIsValidId(true);
    } else {
      setIsValidId(false);
    }
  }, [id]);

  return (
    <Modal
      title="Пополнение счета клиента"
      opened
      onClose={() => {
        setDepositStep(null);
        resetDeposit();
      }}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (id) {
            const foundClient = await (findUserType === "personal" ? getClientById : getOrganizationClientById)(id);
            if (!foundClient) {
              setNotFoundError(true);
            } else {
              setNotFoundError(false);
              setLocalClient(foundClient);
            }
          }
        }}>
        <SegmentedControl
          title="Тип клиента"
          color="violet"
          value={findUserType}
          onChange={(value: "organization" | "personal") => {
            setFindUserType(value);
            setLocalClient(null);
          }}
          data={[
            { label: "Физическое лицо", value: "personal" },
            { label: "Организация", value: "organization" },
          ]}
        />
        <Space h={24} />
        <TextInput
          placeholder="Public ID клиента"
          value={id}
          classNames={{ rightSection: styles.rightSection }}
          error={id.length > 0 && !isValidId && `ID клиента должен содержать ${ID_LENGTH} символов`}
          onChange={(e) => {
            setId(e.target.value);
            if (localClient) {
              setLocalClient(null);
            }
          }}
          rightSection={
            <>
              <ActionIcon
                onClick={() => {
                  setId("");
                  setLocalClient(null);
                  setNotFoundError(false);
                }}>
                <CloseIcon className={styles.clearIcon} />
              </ActionIcon>
              <Button type="submit" size="xs" color="violet" disabled={!isValidId}>
                Найти
              </Button>
            </>
          }
        />
        {notFoundError && (
          <>
            <Space h="xs" />
            <div className={styles.errorNotFound}>Клиента с таким Public ID не найдено</div>
          </>
        )}
        {localClient && (
          <>
            <Space h="xs" />
            <Button
              size="xl"
              fullWidth
              variant="light"
              color={localClient.status === ClientStatusKeys.restricted ? "red" : "violet"}
              ta="center"
              onClick={() => {
                if (localClient.status === ClientStatusKeys.restricted) {
                  cogoToast.error("Клиент заблокирован");
                  setLocalClient(null);
                  return;
                }
                setClient(localClient);
                setLocalClient(null);
                setDepositStep(2);
              }}>
              {localClient.publicId} {localClient.email}
              <br />
              {localClient?.firstName ?? ""} {localClient?.lastName ?? ""}
              {"fullName" in localClient ? localClient.fullName : ""}
            </Button>
          </>
        )}
      </form>
    </Modal>
  );
};
