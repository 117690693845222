import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getPayinList, GetPayinListRequestProps } from "shared/api";
import { setPayinCountAll, setPayinData } from "store/slices";
import { TPayin } from "shared/types/payin";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

const treatmentPayinData = (data: TPayin[]) => [
  ...data.map((item: TPayin) => {
    return {
      id: item.id,
      address: item.address ?? "",
      balances: item.balances ?? {},
      network: item?.network ?? "",
      ticker: item?.ticker ?? "",
    };
  }),
];

export const useGetPayinList = () => {
  const dispatch = useAppDispatch();

  const {
    payinPage,
    payinPageSize,
    payinSearchFilter,
    payinIsAscOrderingFilter,
    payinOrderByFilter,
    payinByExistFilter,
  } = useAppSelector(({ payin }) => payin);

  const validatedSearchFilter: string = payinSearchFilter.length > 2 ? payinSearchFilter : "";

  const getPayinListRequest = useCallback(() => {
    const params: GetPayinListRequestProps = {
      is_asc_ordering: payinIsAscOrderingFilter,
      order_by: `${!payinOrderByFilter.length ? "created_at" : payinOrderByFilter}`,
      page: payinPage,
      page_size: payinPageSize,
    };

    if (payinByExistFilter.length) {
      params.filter_by_exists_ticker = payinByExistFilter.join(",").toLowerCase();
    }

    if (validatedSearchFilter.length > 2) {
      params.search = validatedSearchFilter;
    }

    const getPayinListAbortController = new AbortController() as AbortController;
    getPayinListAbortController.abort();
    return getPayinList(params, getPayinListAbortController);
  }, [payinPage, payinOrderByFilter, validatedSearchFilter, payinIsAscOrderingFilter, payinByExistFilter]);

  useEffect(() => {
    getPayinListRequest()
      .then((response) => {
        dispatch(setPayinData(treatmentPayinData(response.data.items)));
        dispatch(setPayinCountAll(response.data.count));
      })
      .catch((e) => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
  }, [getPayinListRequest]);

  return {
    getPayinListRequest,
  };
};
