import { TOrganization } from "shared/types/organizations";
import { Button } from "@mantine/core";
import { useAppDispatch } from "store/store";
import { setOrganizationClientDetailsId } from "store/slices/organizationClientsSlice";
import { RowField } from "./RowField";

export const Row = ({ el }: { el: TOrganization }) => {
  const dispatch = useAppDispatch();
  return (
    <tr key={`ClientListView${el.id}`}>
      <td>
        <RowField value={el.publicId} field="publicId" />
      </td>
      <td>
        <RowField value={el.fullName!} field="shortName" />
      </td>
      <td>
        <RowField value={el.registrationNumber!} field="registrationNumber" />
      </td>
      <td>
        <RowField value={el.email || "–"} field="email" />
      </td>
      <td>
        <RowField value={el.status} field="status" />
      </td>
      <td>
        <RowField value="No balance data" field="email" />
      </td>
      <td>
        <Button
          compact
          color="violet"
          variant="subtle"
          onClick={() => {
            dispatch(setOrganizationClientDetailsId(el.id));
          }}>
          Открыть
        </Button>
      </td>
    </tr>
  );
};
