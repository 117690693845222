import React, { FC } from "react";
import { Paper, Title, Button } from "@mantine/core";
import { Routes } from "shared/definitions";
import styles from "./styles.module.scss";

interface IProps {
  cid: string;
}

export const TransactionCard: FC<IProps> = ({ cid }) => {
  return (
    <Paper shadow="xs" radius={8} p="24px" className={styles.card}>
      <Title className={styles.title} order={4}>
        Транзакции клиента
      </Title>

      <a href={`/${Routes.TRANSACTIONS_SCREEN}?id=${cid}`}>
        <Button variant="light" color="indigo">
          Перейти к транзакциям
        </Button>
      </a>
    </Paper>
  );
};
