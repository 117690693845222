import React, { useCallback, useState } from "react";
import { TClientViewProps } from "shared/types/clients";
import { Grid, LoadingOverlay, Input, Button, Group, TextInput } from "@mantine/core";
import cogoToast from "cogo-toast";
import { useAppDispatch } from "store/store";
import { setPersonalClientDetailExtended } from "store/slices";
import { FieldsEditable, FieldsTextView, FieldsWrapper } from "components/ui/fields";
import { additionalData } from "shared/api";
import { useRoles } from "shared/hooks/useRoles";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { useGetClientDetails } from "../../../../pages/Client/useGetClientDetails";

const saveText = "Нажмите сохранить для внесения изменений";

export const PersonalClientExtendedInfo = ({ cid, gridColumns }: TClientViewProps) => {
  const dispatch = useAppDispatch();
  const [isTouchedForm, setIsTouchedForm] = useState(false);
  const [reasonOfEdit, setReasonOfEdit] = useState("");
  const { clientDetails, isLoadedClientDetails, getUserHandle } = useGetClientDetails({ cid });

  const { isCompliance } = useRoles();

  const FieldComponent = isCompliance ? FieldsEditable : FieldsTextView;

  const preSaveHandle = useCallback(() => {
    if (!isTouchedForm) setIsTouchedForm(true);
    cogoToast.info(saveText);
  }, []);

  const cancelNewExtendedDataHandle = useCallback(() => {
    getUserHandle();
    setIsTouchedForm(false);
    cogoToast.info("Редактируемые данные сброшены");
  }, []);

  const saveNewExtendedDataHandle = useCallback(() => {
    additionalData(cid, {
      prev_last_name: clientDetails.previousSurname || "",
      quardian: {
        doc_id: clientDetails.guardianDocNumber ?? "",
        doc_type: clientDetails.guardianDocType ?? "",
        first_name: clientDetails.guardianFirstName ?? "",
        last_name: clientDetails.guardianLastName ?? "",
        middle_name: clientDetails.guardianMiddleName ?? "",
        phone: clientDetails.guardianPhone ?? "",
      },
      reason: reasonOfEdit,
      social: {
        inst: clientDetails.socialNetworkLinkINST ?? "",
        ok: clientDetails.socialNetworkLinkOK ?? "",
        vk: clientDetails.socialNetworkLinkVK ?? "",
      },
      tax_id: clientDetails.taxpayerNumber ?? "",
      work: {
        phone: clientDetails.workPhone ?? "",
        position: clientDetails.workPosition ?? "",
        workplace: clientDetails.workPlace ?? "",
      },
    })
      .then(() => {
        setIsTouchedForm(false);
        cogoToast.success("Данные изменены");
      })
      .catch(sentryCaptureError);
  }, [clientDetails, reasonOfEdit]);

  if (!isLoadedClientDetails) return <LoadingOverlay visible />;

  return (
    <div>
      <Grid columns={gridColumns}>
        <Grid.Col span={gridColumns}>
          <Input.Label>Дополнительные сведения</Input.Label>
        </Grid.Col>

        <Grid.Col span={12}>
          <FieldsWrapper>
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 180 }}
              text={clientDetails.taxpayerNumber ?? ""}
              inline={false}
              setTextFn={(val) => {
                dispatch(
                  setPersonalClientDetailExtended({
                    taxpayerNumber: val,
                  }),
                );
              }}
              label="№ налогоплательщика"
              handleSave={preSaveHandle}
            />
          </FieldsWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <FieldsWrapper>
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 180 }}
              text={clientDetails.previousSurname ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    previousSurname: val,
                  }),
                )
              }
              label="Предыдущая фамилия"
              handleSave={preSaveHandle}
            />
          </FieldsWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <FieldsWrapper>
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.workPlace ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    workPlace: val,
                  }),
                )
              }
              label="Место работы"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.workPosition ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    workPosition: val,
                  }),
                )
              }
              label="Должность"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.workPhone ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    workPhone: val,
                  }),
                )
              }
              label="Рабочий телефон"
              handleSave={preSaveHandle}
            />
          </FieldsWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <FieldsWrapper>
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.socialNetworkLinkVK ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    socialNetworkLinkVK: val,
                  }),
                )
              }
              label="VK"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.socialNetworkLinkOK ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    socialNetworkLinkOK: val,
                  }),
                )
              }
              label="OK"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.socialNetworkLinkINST ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    socialNetworkLinkINST: val,
                  }),
                )
              }
              label="Instagram"
              handleSave={preSaveHandle}
            />
          </FieldsWrapper>
        </Grid.Col>

        <Grid.Col span={gridColumns}>
          <Input.Label>Представитель</Input.Label>
        </Grid.Col>

        <Grid.Col span={12}>
          <FieldsWrapper>
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.guardianLastName ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    guardianLastName: val,
                  }),
                )
              }
              label="Фамилия"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.guardianFirstName ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    guardianFirstName: val,
                  }),
                )
              }
              label="Имя"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.guardianMiddleName ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    guardianMiddleName: val,
                  }),
                )
              }
              label="Отчество"
              handleSave={preSaveHandle}
            />
          </FieldsWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <FieldsWrapper>
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.guardianPhone ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    guardianPhone: val,
                  }),
                )
              }
              label="Номер телефона"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.guardianDocType ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    guardianDocType: val,
                  }),
                )
              }
              label="Тип документа"
              handleSave={preSaveHandle}
            />
            <FieldComponent
              fieldWrapperProps={{ labelWidth: 150 }}
              text={clientDetails.guardianDocNumber ?? ""}
              inline={false}
              setTextFn={(val) =>
                dispatch(
                  setPersonalClientDetailExtended({
                    guardianDocNumber: val,
                  }),
                )
              }
              label="Номер документа"
              handleSave={preSaveHandle}
            />
          </FieldsWrapper>
        </Grid.Col>
      </Grid>
      {isCompliance && isTouchedForm && (
        <Group mt={16} mb={16} className="display_flex">
          <Button variant="default" radius={4} onClick={cancelNewExtendedDataHandle}>
            Сбросить
          </Button>
          <Button radius={4} onClick={saveNewExtendedDataHandle} disabled={!reasonOfEdit.length}>
            Сохранить
          </Button>
          <TextInput
            style={{ flex: 1 }}
            placeholder="Укажите причину"
            value={reasonOfEdit}
            onChange={(event) => setReasonOfEdit(event.currentTarget.value)}
          />
        </Group>
      )}
    </div>
  );
};
