import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetTransactionsRequestProps = {
  id?: string;
  status?: string;
  type?: string;
  ticker?: string;
  search?: string;
  for_role?: string;
  is_asc_ordering: boolean;
  created_at_min?: any;
  created_at_max?: any;
  page: number;
  order_by: string;
  page_size: number;
};

export type TGetTransactionsResponse = { data: { items: any; count: number } };

/* eslint-disable import/no-mutable-exports */
export let getTransactionsAbortController = new AbortController() as AbortController;

export function getTransactions(params: GetTransactionsRequestProps) {
  getTransactionsAbortController = new AbortController();
  return axiosInstance(getConfig("/transactions", params, { signal: getTransactionsAbortController.signal }));
}
