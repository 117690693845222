import { useAppDispatch, useAppSelector } from "store/store";
import { useCallback, useEffect, useState } from "react";
import {
  checkNewTransfer,
  checkNewTransferAbortController,
  CheckNewTransferRequestProps,
  getInternalTransferDirections,
  getInternalTransferDirectionsAbortController,
  postNewTransfer,
  postNewTransferAbortController,
  PostNewTransferRequestProps,
} from "shared/api";
import {
  newPayinTransferSetAmount,
  newPayinTransferSetComment,
  newPayinTransferSetCurrencyWithNetwork,
  newPayinTransferSetErrors,
  newPayinTransferSetIsChecked,
  newPayinTransferSetPossibleToDirectionsResponse,
  newPayinTransferSetStep,
} from "store/slices";
import { switchMatch } from "shared/utils";
import { useDebouncedValue } from "@mantine/hooks";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const usePayinTransferModalLogic = () => {
  const dispatch = useAppDispatch();
  const [isPendingCheckRequest, setIsPendingCheckRequest] = useState(false);
  const { payinSelectionAddresses, payinTransferModalIsOpened, walletsBalancesDataByTicker, ...newPayinTransferState } =
    useAppSelector((state) => ({
      payinTransferModalIsOpened: state.modals.payinTransferModalIsOpened,
      walletsBalancesDataByTicker: state.walletsBalances.walletsBalancesDataByTicker,
      payinSelectionAddresses: state.payin.payinSelectionAddresses,
      ...state.newPayinTransfer,
    }));

  const [newPayinTransferFinalCheck, setNewPayinTransferFinalCheck] = useState<"none" | "init" | "success" | "reject">(
    "none",
  );

  const isFilledFormForCheck =
    Number(newPayinTransferState.amount) > 0 &&
    newPayinTransferState.possibleToDirectionsResponse.length > 0 &&
    newPayinTransferState.comment.length > 1;
  const isFilledForm = isFilledFormForCheck && newPayinTransferState.isApprove;

  const [amount, setAmount] = useState<string>("0");
  const [debouncedAmountValue] = useDebouncedValue(amount, 500);

  const [comment, setComment] = useState<string>("");
  const [debouncedCommentValue] = useDebouncedValue(comment, 500);

  const setErrors = useCallback((errors: string[]) => dispatch(newPayinTransferSetErrors(errors)), []);

  useEffect(() => {
    dispatch(newPayinTransferSetAmount(debouncedAmountValue));
  }, [debouncedAmountValue]);

  useEffect(() => {
    dispatch(newPayinTransferSetComment(debouncedCommentValue));
  }, [debouncedCommentValue]);

  const newPayinTransferRequest = useCallback(() => {
    if (!isFilledForm || newPayinTransferFinalCheck !== "success") return;
    postNewTransferAbortController.abort();

    const params: PostNewTransferRequestProps = {
      wallet_type_from: newPayinTransferState.from,
      wallet_type_to: newPayinTransferState.to,
      amount: newPayinTransferState.amount,
      ticker: newPayinTransferState.currency,
      transaction_comment: newPayinTransferState.comment,
    };

    if (newPayinTransferState.network.length) {
      params.network = newPayinTransferState.network;
    }

    postNewTransfer(params)
      .then((res) => {
        if (res.status !== 200) {
          if (res.data?.error !== null) {
            setErrors([res.data?.error]);
            setNewPayinTransferFinalCheck("none");
          }
        }
        dispatch(newPayinTransferSetStep(res.status !== 200 ? "error" : "success"));
      })
      .catch((e) => {
        sentryCaptureError(e);
        dispatch(newPayinTransferSetStep("error"));
        setErrors(["Ошибка POST запроса: Post New Transfer"]);
      })
      .finally(() => setNewPayinTransferFinalCheck("none"));
  }, [isFilledForm, newPayinTransferState]);

  useEffect(() => {
    getInternalTransferDirectionsAbortController.abort();
    getInternalTransferDirections({ wallet_type_from: newPayinTransferState.from })
      .then((res) => {
        if (res.status !== 200) {
          setErrors(["Ошибка GET запроса: Internal Transfer Directions"]);
        } else {
          dispatch(newPayinTransferSetPossibleToDirectionsResponse(res.data ?? ["cold"]));
        }
      })
      .catch((e) => {
        sentryCaptureError(e);
        setErrors(["Ошибка GET запроса: Internal Transfer Directions"]);
      });
  }, [newPayinTransferState.from]);

  useEffect(() => {
    if (isFilledFormForCheck) {
      checkNewTransferAbortController.abort();

      const params: CheckNewTransferRequestProps = {
        wallet_type_from: newPayinTransferState.from,
        wallet_type_to: newPayinTransferState.to,
        amount: newPayinTransferState.amount,
        ticker: switchMatch(newPayinTransferState.currency, {
          default: newPayinTransferState.currency,
        }),
        transaction_comment: newPayinTransferState.comment,
      };

      if (newPayinTransferState.network.length) {
        params.network = switchMatch(newPayinTransferState.network, {
          default: newPayinTransferState.network,
        });
      }

      setIsPendingCheckRequest(true);
      checkNewTransfer(params)
        .then((res) => {
          if (res?.status !== 200) {
            if (newPayinTransferFinalCheck === "init") {
              setNewPayinTransferFinalCheck("reject");
            }
            setErrors(["Ошибка GET запроса: Check New Transfer"]);
          } else {
            dispatch(newPayinTransferSetIsChecked(res.data?.ok));
            if (res.data?.ok && newPayinTransferState.errors.length) {
              setErrors([]);
              return;
            }
            if (res.data?.errors !== null) {
              if (newPayinTransferFinalCheck === "init") {
                setNewPayinTransferFinalCheck("reject");
              }
              const messages = [...Object.values(res.data?.errors).map((v: any) => v.message)];
              setErrors([...messages]);
            } else if (newPayinTransferFinalCheck === "init") {
              setNewPayinTransferFinalCheck("success");
            }
          }
        })
        .catch((e) => {
          if (newPayinTransferFinalCheck === "init") {
            setNewPayinTransferFinalCheck("reject");
          }
          sentryCaptureError(e);
          dispatch(newPayinTransferSetIsChecked(false));
          setErrors(["Ошибка GET запроса: Check New Transfer (2)"]);
        })
        .finally(() => setIsPendingCheckRequest(false));
    }
  }, [
    isFilledFormForCheck,
    newPayinTransferState.amount,
    newPayinTransferState.network,
    newPayinTransferState.from,
    newPayinTransferState.to,
    newPayinTransferState.currency,
    newPayinTransferState.comment,
    newPayinTransferFinalCheck,
  ]);

  useEffect(() => {
    if (newPayinTransferState.currencyWithNetworkString === "") {
      // TODO: временно использовать 1
      dispatch(
        newPayinTransferSetCurrencyWithNetwork(
          Object.keys(walletsBalancesDataByTicker).filter((k) => k === "usdt_trx")[0],
        ),
      );
    }
  }, [newPayinTransferState.currencyWithNetworkString]);

  return {
    dispatch,
    setErrors,
    isFilledForm,
    newPayinTransferFinalCheck,
    setNewPayinTransferFinalCheck,
    isFilledFormForCheck,
    walletsBalancesDataByTicker,
    payinTransferModalIsOpened,
    newPayinTransferState,
    payinSelectionAddresses,
    newPayinTransferRequest,
    isPendingCheckRequest,
    amount,
    setAmount,
    comment,
    setComment,
  };
};
