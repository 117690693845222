import type { ReactNode } from "react";

export type DefaultType = {
  defaultType: any;
};

export type TCurrency = {
  id: number;
  name: string;
  network?: string;
  ticker: string;
  isFiat?: boolean;
  icon?: ReactNode | null;
};

export type TCryptoCurrency = TCurrency & {
  network: string;
};

export type TTransaction = {
  addressFrom: string;
  addressTo: string;
  amountFrom: number;
  amountTo: number;
  createdAt: string;
  fee: number;
  id: string;
  network: string;
  status: string;
  tickerFee: string;
  tickerFrom: string;
  tickerTo: string;
  type: string;
  userId: string;
};

export interface ILogOutResponse {
  logoutUrl: string;
}

export interface ILogInResponse {
  loginPageUrl: string;
}

export interface IResetPasswordResponse {
  resetPasswordUrl: string;
}

export enum TFiatFlow {
  transfer = 0,
  cash_register = 1,
}

export type TAnyDocument = {
  fileLinks: string[];
  meta?: {
    isOld: boolean;
  };
};

export type TPersonalDocument = TAnyDocument & {
  id: string;
  type: string;
  typeExternal: string | null;
  country: string;
  firstName: string;
  firstNameEn: string;
  middleName: string;
  middleNameEn: string;
  lastName: string;
  lastNameEn: string;
  validUntil: string;
  number: string;
  birthday: string;
  mrzLine1: string;
  mrzLine2: string;
  mrzLine3: string;
  aliasName: string;
  applicantId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  issuedDate: string;
  issueAuthorityCode: string;
  address: {
    postCode: string;
    country: string;
    town: string;
    townEn: string;
    street: string;
    streetEn: string;
    subStreet: string;
    subStreetEn: string;
    state: string;
    stateEn: string;
    buildingName: string;
    flatNumber: string;
    buildingNumber: string;
    startDate: string;
    endDate: string;
  };
};

export type TOrganizationDocument = TAnyDocument & {
  createdAt: string;
  id: string;
  organizationId: string;
  source: string;
  type: string;
  updatedAt: string;
  userId: string;
  typeExternal: string | null;
};

export type TPersonalDocType = "identity_proof" | "finance_proof" | "other";

export type TOrganizationDocType = "proof_of_funds" | "other";
