import { Flex, Loader, Title } from "@mantine/core";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { OperationsTabs } from "pages/Currencies/OperationsTabs";
import { ReactNode } from "react";
import { useCurrencies } from "shared/hooks/useCurrencies";
import { useTickerOperations } from "shared/hooks/useTickerOperations";

export function Wrapper(props: { children: ReactNode }) {
  const { isLoaded: isOperationsLoaded } = useTickerOperations();
  const { isLoadedCurrencies } = useCurrencies();

  if (!isOperationsLoaded || !isLoadedCurrencies) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Loader />
      </Flex>
    );
  }

  return (
    <Padding horizontal={4} vertical={4}>
      <Title order={2} display="flex">
        Управление валютами
      </Title>
      <Spacer size={6} />
      <OperationsTabs />
      {props.children}
    </Padding>
  );
}
