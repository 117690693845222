import { TransactionFiatFlowTypes, TransactionTypes } from "shared/definitions/transactions/types";
import { ValuesOf } from "shared/types/common";
import { TransactionStatus } from "shared/definitions/transactions/statuses";

export type TTransactionType = "withdraw" | "deposit" | "exchange";

export type TTransactionsSegmentedControlValue = "depositAndWithdraw" | "exchanged";

export type TTransaction = {
  transactionId: string;
  type: ValuesOf<typeof TransactionTypes>;
  currencyString: string;
  amount: string;
  amountFrom?: string;
  amountTo?: string;
  tickerFrom?: string;
  tickerTo?: string;
  commonFeeString: string | null;
  createdAt: string;
  transactionCode: string | null;
  transactionStatus: ValuesOf<typeof TransactionStatus>;
  fiatFlowType: ValuesOf<typeof TransactionFiatFlowTypes>;
  id: string;
  userPublicId: string;
  amountBefore?: string;
  amountAfter?: string;
  isFiat?: boolean;
  reasonMessage?: string;
};

export type TTransactionDetails = TTransaction & {
  riskScore: number;
  clientAddressFrom?: string;
  clientAddressTo?: string;
  updatedAt: string;
  confirmedAt?: string;
  network: string;
  networkFeeTicker: string;
  custodyTransactionId: string;
  walletRiskScore?: string | null;
  networkFee: string | null;
  reasonMessage: string;
  usedRate?: string;
  fee?: string;
  comment?: string;
  transactionHash?: string | null;
  justification?: string;
  tickerFee?: string;
  userFullName?: string;
  bankDetails?: any;
};

export type TTransactionViewProps = {
  tid: string; // transaction id
  updateList: () => void;
};

export enum TransactionDetailTypes {
  depositFiatCashRegister = "Пополнение фиата наличными",
  depositFiatTransfer = "Пополнение фиата по реквизитам",
  withdrawFiatTransfer = "Вывод фиата по реквизитам",
  withdrawCrypto = "Вывод криптовалюты",
  depositCrypto = "Пополнение криптовалюты",
  exchange = "Обмен",
}

export type TTransactionDetailType =
  | TransactionDetailTypes.depositFiatCashRegister
  | TransactionDetailTypes.depositFiatTransfer
  | TransactionDetailTypes.withdrawFiatTransfer
  | TransactionDetailTypes.exchange
  | TransactionDetailTypes.withdrawCrypto;
