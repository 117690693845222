import { useRoute } from "react-router5";
import { useAppDispatch, useAppSelector } from "store/store";
import { useEffect, useState } from "react";
import { getOrganization, getOrganizationAbortController } from "shared/api";
import { setOrganizationClientDetails } from "store/slices";
import { organizationClientDetailDataTreatment } from "shared/utils";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

export const useGetOrganizationUserId = () => {
  const { route } = useRoute();
  const dispatch = useAppDispatch();
  const { organizationClientDetails } = useAppSelector(({ organizationClients }) => organizationClients);

  const [organizationUserId, setOrganizationUserId] = useState(
    "id" in (organizationClientDetails ?? {}) ? organizationClientDetails!.id : "",
  );

  useEffect(() => {
    if (organizationUserId === "" && "id" in (organizationClientDetails ?? {})) {
      setOrganizationUserId(organizationClientDetails!.id);
    } else if (!("id" in (organizationClientDetails ?? {}))) {
      getOrganizationAbortController.abort();
      getOrganization({ id: route.params.cid })
        .then((detail) => {
          const afterTreatmentDetail = organizationClientDetailDataTreatment(detail);
          dispatch(setOrganizationClientDetails(afterTreatmentDetail));
          setOrganizationUserId(afterTreatmentDetail.id);
        })
        .catch((e) => {
          if (e.code !== AxiosError.ERR_CANCELED) {
            sentryCaptureError(e);
          }
        });
    }
  }, [organizationUserId, organizationClientDetails]);

  return { organizationUserId };
};
