import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TWalletType } from "shared/types/wallets";
import { LimitedTransferDirections } from "shared/definitions";

interface NewTransferSlice {
  amount: string;
  currencyWithNetworkString: string;
  currency: string;
  network: string;
  comment: string;
  from: TWalletType;
  to: TWalletType | "";
  possibleToDirectionsResponse: TWalletType[];
  errors: string[];
  isApprove: boolean;
  step: "init" | "check" | "success" | "error";
  isChecked: boolean;
}

const initialState: NewTransferSlice = {
  amount: "",
  currencyWithNetworkString: "",
  currency: "",
  network: "",
  comment: "",
  from: LimitedTransferDirections[0] as TWalletType,
  to: "",
  possibleToDirectionsResponse: [],
  errors: [],
  isApprove: false,
  step: "init",
  isChecked: false,
};

const newTransferSlice = createSlice({
  name: "newTransfer",
  initialState,
  reducers: {
    newTransferSetIsChecked(state, action: PayloadAction<boolean>) {
      state.isChecked = action.payload;
    },
    newTransferSetAmount(state, action: PayloadAction<string>) {
      state.amount = action.payload;
    },
    newTransferSetStep(state, action: PayloadAction<"init" | "check" | "success" | "error">) {
      state.step = action.payload;
    },
    newTransferSetComment(state, action: PayloadAction<string>) {
      state.comment = action.payload;
    },
    newTransferSetFrom(state, action: PayloadAction<TWalletType>) {
      state.from = action.payload;
    },
    newTransferSetTo(state, action: PayloadAction<TWalletType>) {
      state.to = action.payload;
    },
    newTransferSetIsApprove(state, action: PayloadAction<boolean>) {
      state.isApprove = action.payload;
    },
    newTransferSetErrors(state, action: PayloadAction<string[]>) {
      state.errors = action.payload;
    },
    newTransferSetPossibleToDirectionsResponse(state, action: PayloadAction<TWalletType[]>) {
      state.possibleToDirectionsResponse = action.payload;
      state.to = action.payload[0];
    },
    newTransferSetCurrencyWithNetwork(state, action: PayloadAction<string>) {
      state.currencyWithNetworkString = (action.payload ?? "").toLowerCase();
      if ((action.payload ?? []).includes("_")) {
        const [currency, network] = action.payload.split("_");
        state.currency = currency;
        state.network = network;
      } else {
        state.currency = action.payload;
        state.network = "";
      }
    },
    resetNewTransferSlice: (state) => {
      state.amount = initialState.amount;
      state.comment = initialState.comment;
      state.isApprove = initialState.isApprove;
      state.errors = initialState.errors;
      state.step = initialState.step;
      state.isChecked = initialState.isChecked;
    },
  },
});

export const {
  newTransferSetAmount,
  newTransferSetCurrencyWithNetwork,
  newTransferSetErrors,
  newTransferSetIsChecked,
  newTransferSetIsApprove,
  newTransferSetPossibleToDirectionsResponse,
  newTransferSetFrom,
  newTransferSetComment,
  newTransferSetTo,
  resetNewTransferSlice,
  newTransferSetStep,
} = newTransferSlice.actions;
export const newTransferReducer = newTransferSlice.reducer;
