import { useRoute } from "react-router5";
import { Center, Paper, Title } from "@mantine/core";
import { TransactionView } from "shared/features/Transactions/TransactionView";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Currencies } from "shared/hoc";

export const Transaction = () => {
  const {
    route: {
      params: { tid },
    },
  } = useRoute();

  return (
    <Currencies>
      <Padding horizontal={4} vertical={3}>
        <Title order={4}>Просмотр транзакции</Title>
        <Center maw={800} display="block">
          <div>
            <Spacer size={6} />
            <Paper >
              <TransactionView updateList={() => undefined} tid={tid} />
            </Paper>
            <Spacer size={6} />
          </div>
        </Center>
      </Padding>
    </Currencies>
  );
};
