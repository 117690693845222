import { axiosInstance } from "shared/definitions/api";
import { buildApiUrl } from "../config";

export async function downloadDoc(cid: string, link: string, fileWithExt: string) {
  const response = await axiosInstance.get(buildApiUrl(`/users/${cid}/files`), { params: { path: link }, responseType: "blob" });
  if (response.status === 200) {
    const href = window.URL.createObjectURL(response.data);
    const downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.setAttribute("download", fileWithExt);
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(href);
  }
}
