import { useCallback } from "react";
import { useAppSelector } from "store/store";

export function useCheckIsFiat() {
  const { fiatCurrenciesKeys } = useAppSelector(state => state.currencies);
  return useCallback((ticker: string) => {
    if (!fiatCurrenciesKeys.length) {
      console.error("Trying to check if currency is fiat, but currencies slice wasn't initialized.");
    }
    return !!fiatCurrenciesKeys.find(el => el === ticker);
  }, [fiatCurrenciesKeys]);
}