import { Text, Modal, Textarea, Title, Button } from "@mantine/core";
import React, { useCallback, useState } from "react";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { resetTurnover } from "shared/api/users/resetTurnover";
import cogoToast from "cogo-toast";
import styles from "./ClientFinances.module.scss";

interface IProps {
  isModalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  onTurnoverReset: () => void;
  cid: string;
}

export const ResetLimitsModal = ({ cid, isModalOpen, setModalOpen, onTurnoverReset }: IProps) => {
  const [reason, setReason] = useState<string | undefined>();
  const [pending, setPending] = useState(false);

  const handleClose = useCallback(() => {
    setReason(undefined);
    setModalOpen(false);
  }, [setModalOpen]);

  const handleSubmit = useCallback(async () => {
    if (!reason || reason.length <= 1) {
      return cogoToast.error("Необходимо указать причину сброса лимитов");
    }

    try {
      setPending(true);
      const res = await resetTurnover({ id: cid, reason });
      setPending(false);

      if (res.ok) {
        cogoToast.success("PoF лимиты сброшены");
        onTurnoverReset();
        handleClose();
        return;
      }
    } catch {
      cogoToast.error("Ошибка при выполнении запроса");
    }
  }, [cid, reason, handleClose, onTurnoverReset]);

  return (
    <Modal opened={isModalOpen} onClose={handleClose} radius={16} title={<Title order={3}>Сбросить лимит PoF</Title>}>
      <Text fz={"14px"}>Причина сброса лимита</Text>
      <Textarea disabled={pending} value={reason} onChange={(e) => setReason(e.target.value)} minRows={2} autosize />

      <Spacer size={4} />

      <div className={styles.modalButtonRow}>
        <Button variant={"default"} disabled={pending} onClick={handleClose}>
          Отмена
        </Button>

        <Button onClick={handleSubmit} variant={"filled"} color={"violet"} loading={pending}>
          Сбросить
        </Button>
      </div>
    </Modal>
  );
};
