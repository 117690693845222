import {
  Button,
  Grid,
  Group,
  Modal,
  NativeSelect,
  SegmentedControl,
  Space,
  Text,
  TextInput,
  Alert,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { removeTrailingZeroes } from "shared/utils/removeTrailingZeroes";
import { subtractCommission } from "shared/utils/subtractCommission";
import { TFiatFlow } from "shared/definitions";
import { estimateFee, estimateFeeAbortController } from "shared/api/currencies/estimateFee";
import { formatFiat } from "shared/utils/formatNumber";
import { AxiosError } from "axios";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import styles from "./DepositFormModal.module.scss";
import { TransferForm } from "./TransferForm";
import { useDepositModalLogic } from "./useDepositModalLogic";

export const DepositFormModal = () => {
  const {
    client,
    handleValidateDepositFormState,
    formState,
    setDepositStep,
    amount,
    setAmount,
    setCurrency,
    setFee,
    setType,
    resetDeposit,
    currencies,
  } = useDepositModalLogic();
  const [isShownErrorAlert, setIsShownErrorAlert] = useState(false);
  const [isAllowNextStep, setIsAllowNextStep] = useState(false);
  const [isNotAllowNextStepReason, setIsNotAllowNextStepReason] = useState("Укажите большую, чем комиссия сумму");

  useEffect(() => {
    if (formState.amount) {
      estimateFeeAbortController.abort();
      estimateFee({
        amount: formState.amount,
        tickerTo: formState.currency,
        fiatFlowType: formState.type,
      }).then((response) => {
        setFee(response.fee);
      }).catch(e => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
    } else {
      setFee(0);
    }
  }, [formState.currency, formState.amount, formState.type]);

  useEffect(() => {
    const validate = handleValidateDepositFormState(formState);
    setIsAllowNextStep(validate.result);
    setIsNotAllowNextStepReason(validate.reason);
    setIsShownErrorAlert(false);
  }, [formState]);

  return (
    <Modal
      size="lg"
      padding="xl"
      title="Пополнение счета клиента"
      opened
      onClose={() => {
        setDepositStep(null);
        resetDeposit();
      }}>
      {client ? (
        <div>
          <div className={styles.selectedClientContainer}>
            <div className={styles.selectedClientHeader}>
              <Text size="sm" inline>
                ID клиента:{" "}
              </Text>
              <Button
                onClick={() => {
                  resetDeposit();
                  setDepositStep(1);
                }}
                size="xs"
                color="violet"
                variant="subtle"
                compact>
                Выбрать другого
              </Button>
            </div>
            <Text size="xl">{client.publicId}</Text>
          </div>
          <Space h="xl" />
          <Group position="apart">
            <SegmentedControl
              color="violet"
              value={formState.type === TFiatFlow.cash_register ? "cash_register" : "transfer"}
              onChange={(value: "cash_register" | "transfer") =>
                setType(value === "cash_register" ? TFiatFlow.cash_register : TFiatFlow.transfer)
              }
              data={[
                { label: "Наличные", value: "cash_register" },
                { label: "Банковский перевод", value: "transfer" },
              ]}
            />
            <Button disabled variant="outline">
              Заполнить из файла
            </Button>
          </Group>
          <Space h="xl" />
          {isShownErrorAlert && <Alert color="red">{isNotAllowNextStepReason}</Alert>}

          <Space h="lg" />
          <Grid>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Сумма пополнения
            </Grid.Col>
            <Grid.Col span={8}>
              <Group>
                <TextInput
                  value={amount}
                  onBlur={(e) => setAmount(removeTrailingZeroes(e.target.value))}
                  onChange={(e) => setAmount(e.target.value)}
                  size="xs"
                  type="number"
                />
                <NativeSelect
                  value={formState.currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  size="xs"
                  data={currencies}
                />
              </Group>
            </Grid.Col>
            <Grid.Col fz="sm" span={4}>
              Комиссия
            </Grid.Col>
            <Grid.Col fz="xl" span={8}>
              {formatFiat({ value: formState.fee, postfix: formState.currency })}
            </Grid.Col>
            <Grid.Col fz="sm" span={4}>
              Сумма к начислению
            </Grid.Col>
            <Grid.Col fz="xl" span={8}>
              {formatFiat({ value: subtractCommission(formState.amount, formState.fee), postfix: formState.currency })}
            </Grid.Col>
          </Grid>
          {formState.type === TFiatFlow.transfer && <TransferForm />}
          <div
            onMouseEnter={() => {
              if (!isAllowNextStep && !isShownErrorAlert) {
                setIsShownErrorAlert(true);
              }
            }}>
            <Group position="right">
              <Button
                onClick={() => {
                  setDepositStep(1);
                  resetDeposit();
                }}
                variant="default">
                Назад
              </Button>
              <Button
                disabled={!isAllowNextStep || Number(formState.fee) === 0}
                onClick={() => setDepositStep(3)}
                color="violet">
                Продолжить
              </Button>
            </Group>
          </div>
        </div>
      ) : (
        "Ошибка при выборе клиента"
      )}
    </Modal>
  );
};
