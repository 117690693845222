import { axiosInstance } from "shared/definitions/api";
import { getConfig } from "shared/api/config";
import { GetTransactionsRequestProps } from "shared/api";

export function getTransactionsCSV(params: GetTransactionsRequestProps) {
  return axiosInstance(getConfig("/transactions/csv", params)).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", (+new Date()).toString() + "_transactions.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}
