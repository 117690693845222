import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export function changeRiskLevel(
  id: string,
  params: {
    operations: "low" | "high";
    profile: "low" | "high";
    region: "low" | "high";
    reason: string;
  },
) {
  return axiosInstance(postConfig(`/users/${id}/risk-level`, params));
}
