import { Pagination } from "@mantine/core";
import { setNotypeClientsPage } from "store/slices/notypeClientsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

export function ClientsPagination() {
  const { organizationClientsPage, organizationClientsPageSize, organizationClientsCountAll } = useAppSelector(
    (state) => state.organizationClients,
  );
  const dispatch = useAppDispatch();
  
  return (
    <Pagination
      value={organizationClientsPage}
      total={Math.ceil(organizationClientsCountAll / organizationClientsPageSize)}
      onChange={(pageNumber) => dispatch(setNotypeClientsPage(pageNumber))}
      radius={8}
      size="md"
    />
  );
}
