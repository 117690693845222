import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLog } from "shared/types/logs";

const PAGE_SIZE = 20;

export interface LogsSlice {
  page: number;
  countAll: number;
  pageSize: number;
  logs: [] | TLog[];
  userId: string;
  filterFromDate: string | null;
  filterToDate: string | null;
  filterComment: string;
  filterRole: string;
  filterAction: string;
  newestFirst: boolean;
  actionTypesList: string[];
  rolesList: string[];
  orderBy: string;
}

const initialState: LogsSlice = {
  page: 1,
  countAll: 0,
  pageSize: PAGE_SIZE,
  logs: [],
  userId: "",
  filterFromDate: null,
  filterToDate: null,
  filterComment: "",
  filterRole: "",
  filterAction: "",
  newestFirst: true,
  actionTypesList: [],
  rolesList: [],
  orderBy: "",
};

const logsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setLogsCountAll(state, action: PayloadAction<number>) {
      state.countAll = action.payload;
    },
    setLogsPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setLogsUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    setLogsPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setLogsItems(state, action: PayloadAction<TLog[]>) {
      state.logs = action.payload;
    },
    setLogsFilterFromDate(state, action: PayloadAction<string | null>) {
      state.filterFromDate = action.payload;
    },
    setLogsFilterToDate(state, action: PayloadAction<string | null>) {
      state.filterToDate = action.payload;
    },
    setLogsFilterComment(state, action: PayloadAction<string>) {
      state.filterComment = action.payload;
    },
    setLogsFilterRole(state, action: PayloadAction<string>) {
      state.filterRole = action.payload;
    },
    setLogsFilterAction(state, action: PayloadAction<string>) {
      state.filterAction = action.payload;
    },
    setActionTypesList(state, action: PayloadAction<string[]>) {
      state.actionTypesList = action.payload;
    },
    setRolesList(state, action: PayloadAction<string[]>) {
      state.rolesList = action.payload;
    },
    setNewestFirst(state, action: PayloadAction<boolean>) {
      state.newestFirst = action.payload;
    },
    resetLogsFilters(state) {
      state.filterFromDate = "";
      state.filterToDate = "";
      state.filterComment = "";
      state.filterRole = "";
      state.filterAction = "";
      state.page = 1;
    },
  },
});

export const {
  setLogsCountAll,
  setLogsUserId,
  setLogsPage,
  setLogsPageSize,
  setLogsItems,
  setLogsFilterFromDate,
  setLogsFilterToDate,
  setLogsFilterComment,
  setLogsFilterRole,
  setLogsFilterAction,
  setNewestFirst,
  setActionTypesList,
  setRolesList,
  resetLogsFilters,
} = logsSlice.actions;
export const logsReducer = logsSlice.reducer;
