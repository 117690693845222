import { Pagination } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { setPersonalClientsPage } from "store/slices";
import { useMemo } from "react";

export const ClientsPagination = () => {
  const dispatch = useAppDispatch();
  const { personalClientsCountAll, personalClientPage, personalClientsPageSize } = useAppSelector(
    ({ personalClients }) => personalClients,
  );

  return useMemo(
    () => (
      <Pagination
        value={personalClientPage}
        total={Math.ceil(personalClientsCountAll / personalClientsPageSize)}
        onChange={(pageNumber) => dispatch(setPersonalClientsPage(pageNumber))}
        radius={8}
        size="md"
      />
    ),
    [personalClientPage, personalClientsCountAll, personalClientsPageSize],
  );
};
