import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalsSlice {
  blockClientModalIsOpened: boolean;
  verificationLevelModalIsOpened: boolean;
  riskLevelModalIsOpened: boolean;
  depositStep: 1 | 2 | 3 | 4 | null;
  manageAssetsModalIsOpened: boolean;
  newTransferModalIsOpened: boolean;
  payinTransferModalIsOpened: boolean;
  addResponsiblePersonModalIsOpened: boolean;
  removeResponsiblePersonModalSelectedId: string;
  updateResponsiblePersonModalSelectedId: string;
  updateTransferStatusModalSelectedId: string;
}

const initialState: ModalsSlice = {
  blockClientModalIsOpened: false,
  verificationLevelModalIsOpened: false,
  riskLevelModalIsOpened: false,
  depositStep: null,
  manageAssetsModalIsOpened: false,
  newTransferModalIsOpened: false,
  payinTransferModalIsOpened: false,
  addResponsiblePersonModalIsOpened: false,
  removeResponsiblePersonModalSelectedId: "",
  updateResponsiblePersonModalSelectedId: "",
  updateTransferStatusModalSelectedId: "",
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setBlockClientModalIsOpened(state, action: PayloadAction<boolean>) {
      state.blockClientModalIsOpened = action.payload;
    },
    setNewTransferModalIsOpened(state, action: PayloadAction<boolean>) {
      state.newTransferModalIsOpened = action.payload;
    },
    setPayinTransferModalIsOpened(state, action: PayloadAction<boolean>) {
      state.payinTransferModalIsOpened = action.payload;
    },
    setAddResponsiblePersonModalIsOpened(state, action: PayloadAction<boolean>) {
      state.addResponsiblePersonModalIsOpened = action.payload;
    },
    setRemoveResponsiblePersonModalSelectedId(state, action: PayloadAction<string>) {
      state.removeResponsiblePersonModalSelectedId = action.payload;
    },
    setUpdateTransferStatusModalSelectedId(state, action: PayloadAction<string>) {
      state.updateTransferStatusModalSelectedId = action.payload;
    },
    setUpdateResponsiblePersonModalSelectedId(state, action: PayloadAction<string>) {
      state.updateResponsiblePersonModalSelectedId = action.payload;
    },
    setVerificationLevelModalIsOpened(state, action: PayloadAction<boolean>) {
      state.verificationLevelModalIsOpened = action.payload;
    },
    setRiskLevelModalIsOpened(state, action: PayloadAction<boolean>) {
      state.riskLevelModalIsOpened = action.payload;
    },
    setDepositStep(state, action: PayloadAction<ModalsSlice["depositStep"]>) {
      state.depositStep = action.payload;
    },
    setManageAssetsModalIsOpened(state, action: PayloadAction<boolean>) {
      state.manageAssetsModalIsOpened = action.payload;
    },
  },
});

export const {
  setBlockClientModalIsOpened,
  setVerificationLevelModalIsOpened,
  setRiskLevelModalIsOpened,
  setManageAssetsModalIsOpened,
  setDepositStep,
  setNewTransferModalIsOpened,
  setAddResponsiblePersonModalIsOpened,
  setRemoveResponsiblePersonModalSelectedId,
  setUpdateResponsiblePersonModalSelectedId,
  setUpdateTransferStatusModalSelectedId,
  setPayinTransferModalIsOpened,
} = modalsSlice.actions;
export const modalsReducer = modalsSlice.reducer;
