import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TServiceComment } from "shared/types/serviceInfo";

const PAGE_SIZE = 20;

export interface ServiceInfoSlice {
  page: number;
  countAll: number;
  pageSize: number;
  comments: TServiceComment[];
  userId: string;
}

const initialState: ServiceInfoSlice = {
  page: 1,
  countAll: 0,
  pageSize: PAGE_SIZE,
  comments: [],
  userId: ""
};

const serviceInfoSlice = createSlice({
  name: "serviceInfoTab",
  initialState,
  reducers: {
    setServiceInfoCountAll(state, action: PayloadAction<number>) {
      state.countAll = action.payload;
    },
    setServiceInfoPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setServiceInfoUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    setServiceInfoPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setServiceInfoComments(state, action: PayloadAction<TServiceComment[]>) {
      state.comments = action.payload;
    },
  },
});

export const {
  setServiceInfoCountAll,
  setServiceInfoUserId,
  setServiceInfoPage,
  setServiceInfoPageSize,
  setServiceInfoComments,
} = serviceInfoSlice.actions;
export const serviceInfoReducer = serviceInfoSlice.reducer;
