import React, { FC, useCallback, useState } from "react";
import { Title, Tabs as MantineTabs } from "@mantine/core";
import { FeesSubRoutes, Routes } from "shared/definitions";
import { ReactComponent as ExchangeIcon } from "shared/static/icons/adapted/exchange.svg";
import { ReactComponent as ArrowUpRightIcon } from "shared/static/icons/adapted/arrowUpRight.svg";
import { ReactComponent as ArrowDownLeftIcon } from "shared/static/icons/adapted/arrowDownLeft.svg";
import { useRoute } from "react-router5";
import styles from "./Tabs.module.scss";

interface IProps {}

export const Tabs: FC<IProps> = () => {
  const { route, router } = useRoute();
  const [selectedTab, setSelectedTab] = useState<string>(route.name);

  const handleTabChange = useCallback((tab: string) => {
    router.navigate(tab);
    setSelectedTab(tab);
  }, []);

  return (
    <div className={styles.tabsContainer}>
      <Title>Комиссии</Title>

      <MantineTabs color="violet" value={selectedTab} onTabChange={(v: string) => handleTabChange(v)}>
        <MantineTabs.List>
          <MantineTabs.Tab
            value={`${Routes.FEES_SCREEN}.${FeesSubRoutes.DEPOSIT}`}
            icon={<ArrowDownLeftIcon height={16} />}>
            Пополнение
          </MantineTabs.Tab>
          <MantineTabs.Tab
            value={`${Routes.FEES_SCREEN}.${FeesSubRoutes.WITHDRAW}`}
            icon={<ArrowUpRightIcon height={16} />}>
            Выводы
          </MantineTabs.Tab>
          <MantineTabs.Tab
            value={`${Routes.FEES_SCREEN}.${FeesSubRoutes.EXCHANGE}`}
            icon={<ExchangeIcon height={16} />}>
            Обмены
          </MantineTabs.Tab>
        </MantineTabs.List>
      </MantineTabs>
    </div>
  );
};
