import { useAppDispatch, useAppSelector } from "store/store";
import { useCallback, useEffect } from "react";
import { getWalletsBalances } from "shared/api";
import { setWalletsBalancesData } from "store/slices";

export const useGetWalletsBalances = () => {
  const dispatch = useAppDispatch();
  const { walletsBalancesDataByTicker, walletsBalancesData } = useAppSelector(({ walletsBalances }) => walletsBalances);

  const getWalletsBalancesRequest = useCallback(() => {
    return getWalletsBalances();
  }, []);

  const updateWalletsBalances = useCallback(() => {
    getWalletsBalancesRequest().then((response) => {
      dispatch(setWalletsBalancesData(response));
    });
  }, []);

  useEffect(() => {
    if (walletsBalancesData.length) return;
    updateWalletsBalances();
  }, []);

  return {
    walletsBalancesData,
    getWalletsBalancesRequest,
    walletsBalancesDataByTicker,
    updateWalletsBalances,
  };
};
