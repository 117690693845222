import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPersonalClient, TClientDetails, TClientExtendedDetails } from "shared/types/clients";
import { RiskLevel } from "shared/definitions/levels/risk";

import { VerificationLevel } from "shared/definitions/levels/verifications";
import { ValuesOf } from "shared/types/common";
import { ClientStatusKeys } from "shared/definitions/levels/clientStatus";

const initialState: {
  // Client details page
  personalClientDetails: TClientDetails | {};
  personalClientDetailsId: string;
  // Clients list page
  personalClientPage: number;
  personalClientsCountAll: number;
  personalClientsPageSize: number;
  personalClientsList: [] | TPersonalClient[];
  personalClientsSearchFilter: string;
  personalClientsLastNameFilter: string;
  personalClientsBDayFilter: Date | null;
  personalClientsIdFilter: string;
  personalClientsOrderBy: string;
  personalClientsIsAscOrdering: boolean;
  personalClientsStatusFilter: null | any;
} = {
  personalClientDetails: {} as TClientDetails,
  personalClientDetailsId: "",
  personalClientPage: 1 as number,
  personalClientsCountAll: 0 as number,
  personalClientsPageSize: 20 as number,
  personalClientsList: [],
  personalClientsSearchFilter: "" as string,
  personalClientsLastNameFilter: "" as string,
  personalClientsBDayFilter: null as Date | null,
  personalClientsIdFilter: "" as string,
  personalClientsOrderBy: "" as string,
  personalClientsIsAscOrdering: false,
  personalClientsStatusFilter: null,
};

const personalClientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setPersonalClientsCountAll(state, action: PayloadAction<number>) {
      state.personalClientsCountAll = action.payload;
    },
    setPersonalClientsPage(state, action: PayloadAction<number>) {
      state.personalClientPage = action.payload;
    },
    setPersonalClientsPageSize(state, action: PayloadAction<number>) {
      state.personalClientsPageSize = action.payload;
    },
    setPersonalClientsOrderBy(state, action: PayloadAction<string>) {
      state.personalClientsOrderBy = action.payload;
    },
    setPersonalClientsStatusFilter(state, action: PayloadAction<ValuesOf<typeof ClientStatusKeys> | null>) {
      state.personalClientsStatusFilter = action.payload;
      state.personalClientPage = 1;
    },
    setPersonalClientsIsAscOrdering(state, action: PayloadAction<boolean>) {
      state.personalClientsIsAscOrdering = action.payload;
    },
    setPersonalClients(state, action: PayloadAction<[] | TPersonalClient[]>) {
      state.personalClientsList = action.payload;
    },
    setPersonalClientDetail(state, action: PayloadAction<{} | TClientDetails>) {
      state.personalClientDetails = action.payload;
    },
    setPersonalClientDetailId(state, action: PayloadAction<string>) {
      state.personalClientDetailsId = action.payload;
    },
    setPersonalClientDetailExtended(state, action: PayloadAction<{} | TClientExtendedDetails>) {
      state.personalClientDetails = {
        ...state.personalClientDetails,
        ...action.payload,
      };
    },
    setPersonalClientDetailVerificationLevel(state, action: PayloadAction<ValuesOf<typeof VerificationLevel>>) {
      state.personalClientDetails = {
        ...state.personalClientDetails,
        verificationLevel: action.payload,
      };
    },
    setPersonalClientDetailRiskLevelByProfile(state, action: PayloadAction<ValuesOf<typeof RiskLevel>>) {
      state.personalClientDetails = {
        ...state.personalClientDetails,
        riskLevelByProfile: action.payload,
      };
    },
    setPersonalClientDetailRiskLevelByRegion(state, action: PayloadAction<ValuesOf<typeof RiskLevel>>) {
      state.personalClientDetails = {
        ...state.personalClientDetails,
        riskLevelByRegion: action.payload,
      };
    },
    setPersonalClientDetailRiskLevelByOperations(state, action: PayloadAction<ValuesOf<typeof RiskLevel>>) {
      state.personalClientDetails = {
        ...state.personalClientDetails,
        riskLevelByOperations: action.payload,
      };
    },
    setPersonalClientsSearchFilter(state, action: PayloadAction<string>) {
      state.personalClientsSearchFilter = action.payload;
      state.personalClientPage = 1;
    },
    resetPersonalClientsFilters(state) {
      state.personalClientsBDayFilter = null;
      state.personalClientsIdFilter = "";
      state.personalClientsLastNameFilter = "";
      state.personalClientsStatusFilter = initialState.personalClientsStatusFilter;
      state.personalClientsOrderBy = initialState.personalClientsOrderBy;
      state.personalClientsIsAscOrdering = initialState.personalClientsIsAscOrdering;
      state.personalClientsSearchFilter = "";
      state.personalClientPage = 1;
    },
  },
});

export const {
  setPersonalClients,
  setPersonalClientsOrderBy,
  setPersonalClientsStatusFilter,
  setPersonalClientsPage,
  setPersonalClientDetail,
  setPersonalClientDetailId,
  setPersonalClientsIsAscOrdering,
  setPersonalClientsSearchFilter,
  setPersonalClientsCountAll,
  setPersonalClientsPageSize,
  resetPersonalClientsFilters,
  setPersonalClientDetailExtended,
  setPersonalClientDetailRiskLevelByRegion,
  setPersonalClientDetailVerificationLevel,
  setPersonalClientDetailRiskLevelByProfile,
  setPersonalClientDetailRiskLevelByOperations,
} = personalClientsSlice.actions;
export const personalClientsReducer = personalClientsSlice.reducer;
