import { Divider, Grid, Space, Textarea, TextInput } from "@mantine/core";
import styles from "./TransferForm.module.scss";
import { useDepositModalLogic } from "./useDepositModalLogic";

export const TransferForm = () => {
  const { formState, setBankAddress, setBicOrSwift, setAccountNumber, setBankName } = useDepositModalLogic();
  return (
    <>
      <Space h="xl" />
      <Divider />
      <Space h="xl" />
      <Grid>
        <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
          Наименование банка
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput value={formState.bankName} onChange={(e) => setBankName(e.target.value)} />
        </Grid.Col>
        <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
          Адрес банка
        </Grid.Col>
        <Grid.Col span={8}>
          <Textarea
            value={formState.bankAddress}
            onChange={(e) => setBankAddress(e.target.value)}
            classNames={{ input: styles.textarea }}
          />
        </Grid.Col>
        <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
          BIC/SWIFT
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput value={formState.bicOrSwift} onChange={(e) => setBicOrSwift(e.target.value)} />
        </Grid.Col>
        <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
          Номер счета
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput value={formState.accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
        </Grid.Col>
      </Grid>
      <Space h="xl" />
    </>
  );
};
