import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TClientBalanceEntry, TClientLimits } from "shared/types/clients";

const initialState: {
  clientBalances: TClientBalanceEntry[] | null;
  clientLimits: TClientLimits | null;
} = {
  clientBalances: null,
  clientLimits: null,
};

const clientFinancesSlice = createSlice({
  name: "clientFinances",
  initialState,
  reducers: {
    setClientBalances(state, action: PayloadAction<TClientBalanceEntry[]>) {
      state.clientBalances = action.payload;
    },
    setClientLimits(state, action: PayloadAction<TClientLimits | null>) {
      state.clientLimits = action.payload;
    },
  },
});

export const { setClientBalances, setClientLimits } = clientFinancesSlice.actions;

export const clientFinancesReducer = clientFinancesSlice.reducer;
