import { Tabs } from "@mantine/core";
import { useRoute } from "react-router5";
import { ClientsScreenSubRoutes, Routes } from "shared/definitions";
import { ReactComponent as NotypeIcon } from "../../../shared/static/icons/notype.svg";
import { ReactComponent as IndividualIcon } from "../../../shared/static/icons/individual.svg";
import { ReactComponent as CompanyIcon } from "../../../shared/static/icons/company.svg";
import styles from "./Tabs.module.scss";

export function ClientsTabs() {
  const { route, router } = useRoute();
  return (
    <Tabs
      color="violet"
      value={route.name}
      onTabChange={(value) => {
        if (value) {
          router.navigate(value);
        }
      }}
      defaultValue="test">
      <Tabs.List>
        <Tabs.Tab value={`${Routes.CLIENTS_SCREEN}`}>
          <IndividualIcon className={styles.tabIcon} /> Физические лица
        </Tabs.Tab>
        <Tabs.Tab value={`${Routes.CLIENTS_SCREEN}.${ClientsScreenSubRoutes.COMPANY}`}>
          <CompanyIcon className={styles.tabIcon} /> Юридические лица
        </Tabs.Tab>
        <Tabs.Tab value={`${Routes.CLIENTS_SCREEN}.${ClientsScreenSubRoutes.NOTYPE}`}>
          <NotypeIcon className={styles.tabIcon} /> Без типа
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
}
