import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getTransactions, getTransactionsAbortController, GetTransactionsRequestProps } from "shared/api";
import { setTransactions, setTransactionsCountAll } from "store/slices";
import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";
import { TransactionStatus } from "shared/definitions/transactions/statuses";
import { switchMatch } from "shared/utils";
import { useRoles } from "shared/hooks/useRoles";
import { AxiosError } from "axios";
import { sentryCaptureError } from "shared/utils/environment/sentry";

function treatmentTransactionsData(data: any) {
  return [
    ...data.map((item: any) => {
      return {
        transactionId: item.id,
        type: item.type,
        userPublicId: item?.userPublicId ?? "",
        currencyString: item.tickerFrom ?? item.tickerTo ?? "",
        amount: item.amountFrom,
        amountFrom: item.amountFrom ?? "",
        fiatFlowType: item.fiatFlowType ?? "",
        amountTo: item.amountTo ?? "",
        tickerFrom: item.tickerFrom ?? "",
        tickerTo: item.tickerTo ?? "",
        transactionHash: null,
        commonFeeString: `${item?.fee ?? ""} ${item?.tickerFee}`,
        createdAt: item.createdAt,
        transactionCode: "---",
        transactionStatus:
          item?.status ?? (getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.rejected) as any),
        id: item.userId,
      };
    }),
  ];
}

export const useGetTransactions = () => {
  const dispatch = useAppDispatch();
  const [currentParamsOfGetTransactions, setCurrentParamsOfGetTransactions] =
    useState<GetTransactionsRequestProps | null>(null);
  const {
    page,
    pageSize,
    transactionTypeFilter,
    transactionsSearchFilter,
    transactionsCurrenciesFilter,
    transactionsIsAscOrderingFilter,
    transactionsOrderByFilter,
    transactionsStatusFilter,
    transactionsToDateFilter,
    transactionsFromDateFilter,
  } = useAppSelector(({ transactions }) => transactions);
  const { currentRole } = useRoles();

  const validatedFromCreatedAtFilter =
    transactionsFromDateFilter !== null ? new Date(+transactionsFromDateFilter).toISOString() : null;
  const validatedToCreatedAtFilter =
    transactionsToDateFilter !== null ? new Date(+transactionsToDateFilter).toISOString() : null;

  const validatedSearchFilter: string = transactionsSearchFilter.length > 2 ? transactionsSearchFilter : "";
  const validatedCurrenciesFilter: string = transactionsCurrenciesFilter.length
    ? transactionsCurrenciesFilter.join(",")
    : "";

  const getTransactionsRequest = useCallback(() => {
    const params: GetTransactionsRequestProps = {
      is_asc_ordering: transactionsIsAscOrderingFilter,
      order_by: `${!transactionsOrderByFilter.length ? "" : transactionsOrderByFilter}`,
      type: transactionTypeFilter,
      page,
      page_size: pageSize,
    };

    if (validatedSearchFilter.length > 2) {
      params.search = validatedSearchFilter;
    }

    if ((transactionsStatusFilter ?? "").length) {
      params.status = switchMatch(transactionsStatusFilter, {
        default: transactionsStatusFilter,
      });
    }

    if (validatedCurrenciesFilter.length) {
      params.ticker = validatedCurrenciesFilter;
    }

    if (validatedFromCreatedAtFilter !== null) {
      params.created_at_min = validatedFromCreatedAtFilter;
    }

    if (validatedToCreatedAtFilter !== null) {
      params.created_at_max = validatedToCreatedAtFilter;
    }

    if (currentRole) {
      params.for_role = currentRole;
    }

    setCurrentParamsOfGetTransactions(params);
    getTransactionsAbortController.abort();
    return getTransactions(params);
  }, [
    page,
    transactionTypeFilter,
    transactionsOrderByFilter,
    validatedSearchFilter,
    validatedCurrenciesFilter,
    transactionsIsAscOrderingFilter,
    transactionsStatusFilter,
    validatedFromCreatedAtFilter,
    validatedToCreatedAtFilter,
    currentRole,
  ]);

  const updateList = useCallback(async () => {
    return getTransactionsRequest()
      .then((response) => {
        dispatch(setTransactions(treatmentTransactionsData(response.data.items)));
        dispatch(setTransactionsCountAll(response.data.count));
      })
      .catch((e) => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
  }, [getTransactionsRequest]);

  useEffect(() => {
    updateList();
  }, [getTransactionsRequest]);

  return {
    currentParamsOfGetTransactions,
    updateList,
  };
};
