import { AxiosResponse } from "axios";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

/* eslint-disable import/no-mutable-exports */
export let getOrganizationAbortController = new AbortController() as AbortController;

export function getOrganization({ id }: { id: string }) {
  getOrganizationAbortController = new AbortController();
  return axiosInstance(
    getConfig(`/users/organizations/${id}`, {}, { signal: getOrganizationAbortController.signal }),
  ).then((res: AxiosResponse) => {
    return res.data;
  });
}
