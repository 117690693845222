import React from "react";
import { LoadingOverlay } from "@mantine/core";
import { useClientCid } from "shared/hooks/useCid";
import { Documents } from "../../sections/Documents";
import { useGetClientDetails } from "../../useGetClientDetails";
import { ClientTabHeader } from "../components/ClientTabHeader";
import { ClientTabLayout } from "../components/ClientsTabLayout";

export const ClientDocuments = () => {
  const { cid } = useClientCid();
  const { clientDetails, isLoadedClientDetails } = useGetClientDetails({ cid });

  if (!isLoadedClientDetails) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={clientDetails} tabName={"Документы"} />
      <Documents cid={cid} />
    </ClientTabLayout>
  );
};
