export const FLOAT_REGEXP = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;

export const trimFloat = (val: string, limitInteger: number = 9, limitDecimals: number = 9) => {
  let i = 0;
  const v = val.replace(" ", "");

  const removeDots = v.replace(/[.%]/g, (match) => {
    // eslint-disable-next-line no-nested-ternary
    return match === "." ? (i++ === 0 ? "." : "") : "";
  });

  let [ip, dp] = removeDots.split(".");

  if (dp && dp.length > limitDecimals) {
    dp = dp.substring(0, limitDecimals);
    return `${ip}.${dp}`;
  }

  if (ip && ip.length > limitInteger) {
    ip = ip.slice(0, limitInteger);
    return `${ip}${v.includes(".") ? `.${dp ?? ""}` : ""}`;
  }
  return v;
};

export const sanitizeAmount = (amount: string) => {
  const value = amount.match(FLOAT_REGEXP)?.[0];
  if (!value && amount.length > 0) return "";

  return value;
};
