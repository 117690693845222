import { useAppDispatch, useAppSelector } from "store/store";
import { useCallback, useEffect } from "react";
import { setStatusTransactionById, setStatusTransactionDetail } from "store/slices";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { ValuesOf } from "shared/types/common";
import { TTransactionDetails, TTransactionViewProps } from "shared/types/transactions";
import { useRoute } from "react-router5";
import { useRoles } from "shared/hooks/useRoles";
import { Routes } from "shared/definitions";

export const useTransactionDetailLogic = ({ tid }: TTransactionViewProps) => {
  const dispatch = useAppDispatch();
  const transactionDetails = useAppSelector((state) => state.transactions.transactionDetails as TTransactionDetails);
  const { isCompliance, isOperator, isFinManager } = useRoles();
  const { route } = useRoute();
  const isDetail = route.name === Routes.TRANSACTION_SCREEN;

  const isAllowButtonChangeTransactionStatusForCompliance =
    (isCompliance || isFinManager) && transactionDetails.transactionStatus === TransactionStatusKeys.hold;

  const isAllowButtonChangeTransactionStatusForOperator =
    isOperator && transactionDetails.transactionStatus === TransactionStatusKeys.hold;

  const setTransactionStatus = useCallback(
    (status: ValuesOf<typeof TransactionStatusKeys>) => {
      dispatch(setStatusTransactionDetail(status));
      dispatch(
        setStatusTransactionById({ transactionStatus: status, transactionId: transactionDetails.transactionId }),
      );
    },
    [transactionDetails],
  );

  const handleConfirmButtonClick = useCallback(() => {
    return setTransactionStatus(TransactionStatusKeys.completed);
  }, []);

  const handleRejectButtonClick = useCallback(() => {
    return setTransactionStatus(TransactionStatusKeys.rejected);
  }, []);

  const handleConfirmButtonClickByOperator = useCallback(() => {
    return setTransactionStatus(TransactionStatusKeys.completed);
  }, []);

  const handleRejectButtonClickByOperator = useCallback(() => {
    return setTransactionStatus(TransactionStatusKeys.rejected);
  }, []);

  return {
    isDetail,
    isOperator,
    isCompliance,
    transactionDetails,
    handleConfirmButtonClick,
    handleRejectButtonClick,
    handleConfirmButtonClickByOperator,
    handleRejectButtonClickByOperator,
    isAllowButtonChangeTransactionStatusForCompliance,
    isAllowButtonChangeTransactionStatusForOperator,
  };
};
