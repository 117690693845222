import { TTransactionDetails } from "shared/types/transactions";
import { Button, Divider, Grid, Group, Modal, Space, Textarea, Title } from "@mantine/core";
import { useTransactionViewBlocksLogic } from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { FieldsWrapper, FieldWrapper } from "components/ui/fields";
import { useMemo } from "react";
import dayjs from "dayjs";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { FieldTreatment } from "components/common/FieldTreatment";
import { useRoles } from "shared/hooks/useRoles";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { formatCrypto } from "shared/utils/formatNumber";

export const DepositCrypto = ({ detail, updateList }: { detail: TTransactionDetails; updateList: () => void }) => {
  const {
    blockSimplyTransactionStatus,
    renderTypicalTextField,
    blockUpdatedAt,
    blockComment,
    blockServiceFee,
    blockCreatedAt,
    commentOfChangeTransactionStatus,
    setCommentOfChangeTransactionStatus,
    typeHelperChangeTransactionStatus,
    setTypeHelperChangeTransactionStatus,
    isOpenedModalChangeTransactionStatus,
    setIsOpenedModalChangeTransactionStatus,
    handleSetTransactionStatus,
  } = useTransactionViewBlocksLogic(detail, updateList);

  const { isHeadOfFinManager, isFinManager } = useRoles();
  const isAllowChangeTransactionStatus =
    (isHeadOfFinManager || isFinManager) && detail.transactionStatus === TransactionStatusKeys.hold;

  const blockAmountWithoutFee = useMemo(
    () =>
      renderTypicalTextField(
        "Сумма до вычета",
        formatCrypto({
          value: Number(detail.amountFrom),
          postfix: `${detail.tickerFrom} (${detail.network})`,
        }),
      ),
    [detail],
  );

  const blockNetworkFee = useMemo(
    () =>
      renderTypicalTextField(
        "Комиссия сети",
        formatCrypto({
          value: Number(detail.networkFee),
          postfix: detail.networkFeeTicker,
        }),
      ),
    [detail],
  );

  const blockConfirmedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Подтверждена",
        `${detail.confirmedAt ? dayjs(detail.confirmedAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.confirmedAt],
  );

  const blockAmountWithFee = useMemo(
    () =>
      renderTypicalTextField(
        "К зачислению",
        `${formatCrypto({ value: Number(detail.amountTo) })} ${detail.tickerFrom ?? ""} (${detail?.network})`,
      ),
    [detail],
  );

  const blockHash = useMemo(
    () => renderTypicalTextField("Hash транзакции", detail.transactionHash ?? "", true),
    [detail.transactionHash],
  );

  const blockPayoutAddress = useMemo(
    () => renderTypicalTextField("Payout address", detail.clientAddressFrom ?? "", true),
    [detail.clientAddressFrom],
  );

  const blockClientAddress = useMemo(
    () => renderTypicalTextField("Адрес клиента", detail.clientAddressTo ?? "", true),
    [detail.clientAddressTo],
  );

  const blockStatus = (
    <FieldWrapper label="Статус">
      <div className="w-100">
        <Modal
          radius={16}
          zIndex={1000}
          title={<Title order={3}>Подтверждение статуса транзакции</Title>}
          opened={isOpenedModalChangeTransactionStatus}
          onClose={() => setIsOpenedModalChangeTransactionStatus(false)}>
          <Padding horizontal={1} vertical={1}>
            <Textarea
              size="lg"
              value={commentOfChangeTransactionStatus}
              onChange={(event) => setCommentOfChangeTransactionStatus(event.currentTarget.value)}
              radius={12}
              label="Комментарий"
              autosize
              placeholder="Введите текст"
            />
            <Spacer size={6} />
            <Button
              radius={12}
              size="md"
              color={typeHelperChangeTransactionStatus === "completed" ? "green" : "red"}
              onClick={() => {
                handleSetTransactionStatus(detail?.transactionId, commentOfChangeTransactionStatus);
              }}>
              {typeHelperChangeTransactionStatus === "completed" ? "Подтвердить" : "Отклонить"}
            </Button>
            <Button
              ml={16}
              radius={12}
              size="md"
              variant="default"
              onClick={() => setIsOpenedModalChangeTransactionStatus(false)}>
              Отмена
            </Button>
          </Padding>
        </Modal>
        <Padding horizontal={2} vertical={1}>
          <div>
            <div>
              <FieldTreatment name="transactionStatus">{detail.transactionStatus}</FieldTreatment>
            </div>
            <Space mt={12} />
            <div>
              <Divider />
              <Space mt={12} />
              <Group>
                <Button
                  compact
                  radius={4}
                  size="sm"
                  variant="light"
                  color="green"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("completed");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Подтвердить
                </Button>
                <Button
                  compact
                  radius={4}
                  size="sm"
                  variant="light"
                  color="red"
                  onClick={() => {
                    setTypeHelperChangeTransactionStatus("rejected");
                    setIsOpenedModalChangeTransactionStatus(true);
                  }}>
                  Отклонить
                </Button>
              </Group>
              <Space mb={4} />
            </div>
          </div>
        </Padding>
      </div>
    </FieldWrapper>
  );

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <FieldsWrapper>
            {isAllowChangeTransactionStatus ? blockStatus : blockSimplyTransactionStatus}
            {blockAmountWithoutFee}
            {blockAmountWithFee}
            {blockServiceFee}
            {blockNetworkFee}
            {blockHash}
            {blockPayoutAddress}
            {blockClientAddress}
            {blockCreatedAt}
            {blockUpdatedAt}
            {blockConfirmedAt}
            {blockComment}
          </FieldsWrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
