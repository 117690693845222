import { ReactElement, useMemo } from "react";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { Grid } from "@mantine/core";
import { TransactionDetailTypes, TTransactionDetails } from "shared/types/transactions";
import { useTransactionViewBlocksLogic } from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { types2TextTreatment } from "components/common/FieldTreatment";
import { FieldsWrapper } from "components/ui/fields";
import { BankDetails } from "shared/features/Transactions/TransactionView/components/BankDetails";
import { switchMatch } from "shared/utils";
import { ValuesOf } from "shared/types/common";
import { useRoles } from "shared/hooks/useRoles";
import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";

export const MainTransactionDetailHOC = ({
  children,
  detail,
  transactionMainType,
  isFiat,
  updateList,
}: {
  children: ReactElement;
  detail: TTransactionDetails;
  transactionMainType?: ValuesOf<typeof TransactionDetailTypes>;
  isFiat?: boolean;
  updateList: () => void;
}) => {
  const { renderTypicalTextField, blockJustification } = useTransactionViewBlocksLogic(detail, updateList);

  const { isOperator } = useRoles();

  const blockTransactionId = useMemo(
    () => renderTypicalTextField("Transaction ID", detail?.transactionId ?? "", true),
    [detail?.transactionId],
  );

  const blockTransferId = useMemo(
    () => renderTypicalTextField("ID перевода", detail?.custodyTransactionId ?? "", true),
    [detail?.custodyTransactionId],
  );

  const blockUserPublicId = useMemo(
    () => renderTypicalTextField("User public ID", detail?.userPublicId ?? "", true),
    [detail?.userPublicId],
  );

  const blockOperation = useMemo(
    () => renderTypicalTextField("Операция", types2TextTreatment(detail?.type ?? "")),
    [detail?.type],
  );

  const blockRiskScore = useMemo(
    () => renderTypicalTextField("Risk score", types2TextTreatment(detail?.walletRiskScore ?? "")),
    [detail?.walletRiskScore],
  );

  const blockFiatFlowType = useMemo(
    () =>
      renderTypicalTextField(
        "Тип",
        detail?.fiatFlowType
          ? switchMatch(detail?.fiatFlowType, {
              cash_register: "Касса",
              transfer: "Перевод",
              default: detail?.fiatFlowType,
            })
          : "",
      ),
    [detail?.fiatFlowType],
  );

  return (
    <div className="w-100">
      <div className="w-100" style={{ paddingBottom: "100px", maxWidth: "600px" }}>
        <Spacer size={6} direction="horizontal" />
        <Grid columns={12}>
          <Grid.Col span={12}>
            <FieldsWrapper>
              {blockTransactionId}
              {blockUserPublicId}
              {detail?.custodyTransactionId && blockTransferId}
              {blockOperation}
              {isFiat && blockFiatFlowType}
              {detail.justification !== "" && blockJustification}
              {detail.walletRiskScore !== "" && blockRiskScore}
            </FieldsWrapper>
          </Grid.Col>
        </Grid>
        {children}
        {detail?.bankDetails !== null &&
          isOperator &&
          transactionMainType !==
            getEnumKeyByEnumValue(TransactionDetailTypes, TransactionDetailTypes.depositFiatCashRegister) && (
            <Grid columns={12}>
              <BankDetails detail={detail} bankDetails={detail.bankDetails} updateList={updateList} />
            </Grid>
          )}
      </div>
    </div>
  );
};
