import { ValuesOf } from "shared/types/common";
import { ClientStatusKeys } from "shared/definitions/levels/clientStatus";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetPersonalClientsRequestProps = {
  search?: string;
  order_by?: string;
  is_asc_ordering?: boolean;
  status?: ValuesOf<typeof ClientStatusKeys>;
  page: number;
  page_size: number;
};

export function getUsers(params: GetPersonalClientsRequestProps) {
  return axiosInstance<{ items: any[], count: number }>(getConfig("/users", params));
}
