import { useAppDispatch, useAppSelector } from "store/store";
import { useCallback, useEffect, useState } from "react";
import {
  checkNewTransfer,
  checkNewTransferAbortController,
  CheckNewTransferRequestProps,
  getInternalTransferDirections,
  getInternalTransferDirectionsAbortController,
  postNewTransfer,
  postNewTransferAbortController,
  PostNewTransferRequestProps,
} from "shared/api";
import {
  newTransferSetAmount,
  newTransferSetComment,
  newTransferSetCurrencyWithNetwork,
  newTransferSetErrors,
  newTransferSetIsChecked,
  newTransferSetPossibleToDirectionsResponse,
  newTransferSetStep,
} from "store/slices";
import { switchMatch } from "shared/utils";
import { useDebouncedValue } from "@mantine/hooks";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

export const useTransferModalLogic = () => {
  const dispatch = useAppDispatch();
  const [isPendingCheckRequest, setIsPendingCheckRequest] = useState(false);
  const { newTransferModalIsOpened, walletsBalancesDataByTicker, ...newTransferState } = useAppSelector((state) => ({
    newTransferModalIsOpened: state.modals.newTransferModalIsOpened,
    walletsBalancesDataByTicker: state.walletsBalances.walletsBalancesDataByTicker,
    ...state.newTransfer,
  }));

  const [newTransferFinalCheck, setNewTransferFinalCheck] = useState<"none" | "init" | "success" | "reject">("none");

  const isFilledFormForCheck =
    Number(newTransferState.amount) > 0 &&
    newTransferState.possibleToDirectionsResponse.length > 0 &&
    newTransferState.comment.length > 1;
  const isFilledForm = isFilledFormForCheck && newTransferState.isApprove;

  const [amount, setAmount] = useState<string>("");
  const [debouncedAmountValue] = useDebouncedValue(amount, 500);

  const [comment, setComment] = useState<string>("");
  const [debouncedCommentValue] = useDebouncedValue(comment, 500);

  const setErrors = useCallback((errors: string[]) => dispatch(newTransferSetErrors(errors)), []);

  useEffect(() => {
    dispatch(newTransferSetAmount(debouncedAmountValue));
  }, [debouncedAmountValue]);

  useEffect(() => {
    dispatch(newTransferSetComment(debouncedCommentValue));
  }, [debouncedCommentValue]);

  const newTransferRequest = useCallback(() => {
    if (!isFilledForm || newTransferFinalCheck !== "success") return;
    postNewTransferAbortController.abort(); // TODO: Why we need it here? 

    const params: PostNewTransferRequestProps = {
      wallet_type_from: newTransferState.from,
      wallet_type_to: newTransferState.to,
      amount: newTransferState.amount,
      ticker: newTransferState.currency,
      transaction_comment: newTransferState.comment,
    };

    if (newTransferState.network.length) {
      params.network = newTransferState.network;
    }

    postNewTransfer(params)
      .then((res) => {
        if (res.data?.error !== null) {
          setErrors([res.data?.error]);
          setNewTransferFinalCheck("none");
        }
        newTransferSetStep("success");
      })
      .catch((e) => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
          dispatch(newTransferSetStep("error"));
          setErrors(["Ошибка POST запроса: Post New Transfer"]);
        }
      })
      .finally(() => setNewTransferFinalCheck("none"));
  }, [isFilledForm, newTransferState]);

  useEffect(() => {
    getInternalTransferDirectionsAbortController.abort();
    getInternalTransferDirections({ wallet_type_from: newTransferState.from })
      .then((res) => {
        if (res.status !== 200) {
          setErrors(["Ошибка GET запроса: Internal Transfer Directions"]);
        } else {
          dispatch(newTransferSetPossibleToDirectionsResponse(res.data ?? ["cold"]));
        }
      })
      .catch((e) => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
          setErrors(["Ошибка GET запроса: Internal Transfer Directions"]);
        }
      });
  }, [newTransferState.from]);

  useEffect(() => {
    if (isFilledFormForCheck) {
      checkNewTransferAbortController.abort();

      const params: CheckNewTransferRequestProps = {
        wallet_type_from: newTransferState.from,
        wallet_type_to: newTransferState.to,
        amount: newTransferState.amount,
        ticker: switchMatch(newTransferState.currency, {
          default: newTransferState.currency,
        }),
        transaction_comment: newTransferState.comment,
      };

      if (newTransferState.network.length) {
        params.network = switchMatch(newTransferState.network, {
          default: newTransferState.network,
        });
      }

      setIsPendingCheckRequest(true);
      checkNewTransfer(params)
        .then((res) => {
          dispatch(newTransferSetIsChecked(res.data?.ok));
          if (res.data?.ok && newTransferState.errors.length) {
            setErrors([]);
            return;
          }
          if (res.data?.errors !== null) {
            if (newTransferFinalCheck === "init") {
              setNewTransferFinalCheck("reject");
            }
            const messages = [...Object.values(res.data?.errors).map((v: any) => v.message)];
            setErrors([...messages]);
          } else if (newTransferFinalCheck === "init") {
            setNewTransferFinalCheck("success");
          }
        })
        .catch((e) => {
          if (e.code !== AxiosError.ERR_CANCELED) {
            if (newTransferFinalCheck === "init") {
              setNewTransferFinalCheck("reject");
            }
            sentryCaptureError(e);
            dispatch(newTransferSetIsChecked(false));
            setErrors(["Ошибка GET запроса: Check New Transfer (2)"]);
          }
        })
        .finally(() => setIsPendingCheckRequest(false));
    }
  }, [
    isFilledFormForCheck,
    newTransferState.amount,
    newTransferState.network,
    newTransferState.from,
    newTransferState.to,
    newTransferState.currency,
    newTransferState.comment,
    newTransferFinalCheck,
  ]);

  useEffect(() => {
    if (newTransferState.currencyWithNetworkString === "") {
      // TODO: временно использовать 1
      dispatch(
        newTransferSetCurrencyWithNetwork(Object.keys(walletsBalancesDataByTicker).filter((k) => k === "usdt_trx")[0]),
      );
    }
  }, [newTransferState.currencyWithNetworkString]);

  return {
    dispatch,
    setErrors,
    isFilledForm,
    newTransferFinalCheck,
    setNewTransferFinalCheck,
    isFilledFormForCheck,
    walletsBalancesDataByTicker,
    newTransferModalIsOpened,
    newTransferState,
    newTransferRequest,
    isPendingCheckRequest,
    amount,
    setAmount,
    comment,
    setComment,
  };
};
