import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TFiatFlow } from "shared/definitions";
import { TPersonalClient } from "shared/types/clients";
import { TOrganization } from "shared/types/organizations";

interface DepositSlice {
  client: TPersonalClient | TOrganization | null;
  formState: TDepositFormState;
}

export type TDepositFormState = {
  type: TFiatFlow;
  bankName: string;
  bankAddress: string;
  bicOrSwift: string;
  accountNumber: string;
  currency: string;
  amount: string;
  fee: number;
};

const initialState: DepositSlice = {
  client: null,
  formState: {
    type: TFiatFlow.cash_register,
    bankName: "",
    bankAddress: "",
    bicOrSwift: "",
    accountNumber: "",
    currency: "BYN",
    amount: "",
    fee: 0,
  },
};

const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    setDepositClient(state, action: PayloadAction<TPersonalClient | TOrganization | null>) {
      state.client = action.payload;
    },
    setFormState(state, action: PayloadAction<TDepositFormState>) {
      state.formState = action.payload;
    },
    setFormAmount(state, action: PayloadAction<string>) {
      state.formState.amount = action.payload;
    },
    setFormCurrency(state, action: PayloadAction<string>) {
      state.formState.currency = action.payload;
    },
    setFormType(state, action: PayloadAction<TFiatFlow>) {
      state.formState.type = action.payload;
    },
    setFormFee(state, action: PayloadAction<number>) {
      state.formState.fee = action.payload;
    },
    setFormBankName(state, action: PayloadAction<string>) {
      state.formState.bankName = action.payload;
    },
    setFormBankAddress(state, action: PayloadAction<string>) {
      state.formState.bankAddress = action.payload;
    },
    setFormAccountNumber(state, action: PayloadAction<string>) {
      state.formState.accountNumber = action.payload;
    },
    setFormBicOrSwift(state, action: PayloadAction<string>) {
      state.formState.bicOrSwift = action.payload;
    },
    resetDepositSlice: () => initialState,
  },
});

export const {
  setDepositClient,
  resetDepositSlice,
  setFormState,
  setFormAmount,
  setFormCurrency,
  setFormFee,
  setFormType,
  setFormAccountNumber,
  setFormBankAddress,
  setFormBankName,
  setFormBicOrSwift,
} = depositSlice.actions;
export const depositReducer = depositSlice.reducer;
