import { LoadingOverlay, Space } from "@mantine/core";
import { FC } from "react";
import { useClientCid } from "shared/hooks/useCid";
import { CommentForm } from "shared/features/ClientsDetail/ServiceInfo/CommentForm";
import { CommentTable } from "shared/features/ClientsDetail/ServiceInfo/CommentTable";
import { useServiceComments } from "shared/hooks/useServiceComments";
import { ClientTabLayout } from "../components/ClientsTabLayout";
import { ClientTabHeader } from "../components/ClientTabHeader";

import { useGetClientDetails } from "../../useGetClientDetails";

export const ClientServiceInfo: FC = () => {
  const { cid } = useClientCid();
  const { clientDetails } = useGetClientDetails({ cid });
  const { isLoaded, getServiceInfoHandle } = useServiceComments(cid);

  if (!isLoaded) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={clientDetails} tabName={"Служебная информация"} />
      <CommentForm userId={cid} onPostComment={getServiceInfoHandle} />
      <Space h="lg" />
      <CommentTable />
    </ClientTabLayout>
  );
};
