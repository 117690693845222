import { useCallback, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setClientLimits } from "store/slices";
import { fetchUserLimits, fetchUserLimitsAbortController } from "shared/api";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

type TClientLimitFetchParams =
  | {
      month: number;
      year: number;
    }
  | null
  | undefined;

export const useGetClientLimits = (cid: string) => {
  const dispatch = useAppDispatch();
  const clientLimits = useAppSelector((state) => state.clientFinances.clientLimits);
  const [clientLimitsLoading, setClientLimitsLoaded] = useState(false);

  const prevParamsRef = useRef<TClientLimitFetchParams>();

  const getUserLimits = useCallback(
    (params?: TClientLimitFetchParams) => {
      if (JSON.stringify(params) === JSON.stringify(prevParamsRef.current)) return;

      fetchUserLimitsAbortController.abort();

      dispatch(setClientLimits(null));
      setClientLimitsLoaded(true);
      fetchUserLimits({ id: cid, ...(params ?? {}) })
        .then((data) => {
          prevParamsRef.current = params;
          dispatch(setClientLimits(data));
        })
        .catch(e => {
          if (e.code !== AxiosError.ERR_CANCELED) {
            sentryCaptureError(e);
          }
        })
        .finally(() => {
          setClientLimitsLoaded(false);
        });
    },
    [cid],
  );

  return { clientLimits, getUserLimits, clientLimitsLoading };
};
