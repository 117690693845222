import React, { FC } from "react";
import { useAppSelector } from "store/store";
import { Tabs } from "../components/Tabs";
import { FeesPageContainer } from "../components/FeesPageContainer";
import { TabContentContainer } from "../components/TabContentContainer";
import { FeesSection } from "../components/FeesSection";

export const Deposit: FC = () => {
  const { feesByType } = useAppSelector(({ fees }) => ({ feesByType: fees.feesByType }));

  return (
    <FeesPageContainer>
      <Tabs />
      <TabContentContainer>
        <FeesSection sectionTitle="Пополнение наличными" rows={feesByType.depositFiatCashRegister} />
        <FeesSection sectionTitle="Пополнение переводом" rows={feesByType.depositFiatTransfer} />
        {/* Временно не используется <FeesSection sectionTitle="Пополнение криптовалюты" rows={feesByType.depositCrypto} /> */}
      </TabContentContainer>
    </FeesPageContainer>
  );
};
