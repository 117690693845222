import { LoadingOverlay } from "@mantine/core";
import React, { FC } from "react";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useClientCid } from "shared/hooks/useCid";
import { ClientBalances } from "shared/features/ClientsDetail/FinanceBlock/ClientBalances";
import { ClientLimits } from "shared/features/ClientsDetail/FinanceBlock/ClientLimits";
import styles from "shared/features/ClientsDetail/FinanceBlock/ClientFinances.module.scss";
import { useCurrencies } from "shared/hooks/useCurrencies";
import { ClientTabLayout } from "../components/ClientsTabLayout";
import { ClientTabHeader } from "../components/ClientTabHeader";

import { useGetClientDetails } from "../../useGetClientDetails";
import { TransactionCard } from "../components/TransactionCard";

export const ClientFinances: FC = () => {
  const { cid } = useClientCid();
  const { clientDetails, isLoadedClientDetails } = useGetClientDetails({ cid });
  const { isLoadedCurrencies } = useCurrencies();


  if (!isLoadedClientDetails || !isLoadedCurrencies) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={clientDetails} tabName={"Финансы"} />
      <div className={styles.cardsContainer}>
        <ClientBalances cid={cid} />
        <ClientLimits cid={cid} />
      </div>

      <Spacer size={6} />

      {clientDetails.publicId && <TransactionCard cid={clientDetails.publicId} />}
    </ClientTabLayout>
  );
};
