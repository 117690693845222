import { Anchor, Breadcrumbs as BreadcrumbsMantine, Text } from "@mantine/core";
import { ReactComponent as ChevronRightIcon } from "shared/static/icons/arrowRight.svg";
import { ReactComponent as HomeIcon } from "shared/static/icons/home.svg";

type TItem = {
  link: string;
  label: string;
};

type TProps = {
  items: TItem[];
  hasHome?: boolean;
};

export function Breadcrumbs({ items, hasHome = true }: TProps) {
  return (
    <div className="display_flex">
      <BreadcrumbsMantine  separator={<ChevronRightIcon />}>
        {hasHome && (
          <Text color="violet" inline>
            <HomeIcon />
          </Text>
        )}
        {items.map((el, key) => (
          <Anchor key={key} href={key <= items.length - 1 ? el.link : undefined}>{el.label}</Anchor>
        ))}
      </BreadcrumbsMantine>
    </div>
  );
}
