import React from "react";
import { ReactComponent as Search2Icon } from "shared/static/icons/adapted/search2.svg";
import { createStyles, Title } from "@mantine/core";

const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    backgroundColor: "white",
    justifyContent: "center",
    flexDirection: "column",
    padding: "24px 0",
    borderRadius: "12px",
    boxShadow: "0 0 0 1px rgba(0,0,0,0.05)",
  },
}));

type TEmptyBlockProps = {
  title?: string;
  description?: string;
};

export const EmptyBlock = (props: TEmptyBlockProps) => {
  const { title = "Здесь ничего нет :(", description = null } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className="margin_auto">
        <Search2Icon width={64} />
      </div>
      <div className="margin_auto">
        <Title order={4} mt={12} mb={12}>
          {title}
        </Title>
      </div>
      {description !== null && (
        <div className="margin_auto">
          <Title order={5} color="grey" weight={300}>
            {description}
          </Title>
        </div>
      )}
    </div>
  );
};
