import React from "react";
import { FieldTreatment } from "components/common/FieldTreatment";
import { TPayin } from "shared/types/payin";
import { Checkbox, createStyles } from "@mantine/core";
import cx from "classnames";

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor: `${theme.colors.violet[0]} !important`,
  },
}));

export const PayinListRow = ({
  el,
  selection = [],
  toggleRow = () => {},
}: {
  el: TPayin;
  selection: TPayin[];
  toggleRow: (id: TPayin) => void;
}) => {
  const { classes } = useStyles();
  return (
    <tr className={cx({ [classes.rowSelected]: !!selection.filter(({ id }) => id === el.id).length })}>
      <td>
        <Checkbox
          color="violet"
          checked={!!selection.filter(({ id }) => id === el.id).length}
          onChange={() => toggleRow(el)}
          transitionDuration={0}
        />
      </td>
      <td>
        <div className="cursor_pointer" onClick={() => toggleRow(el)}>
          {el.address}
        </div>
      </td>
      <td>
        <FieldTreatment name="id">{el.id}</FieldTreatment>
      </td>
      <td>
        {Object.keys(el.balances ?? {}).map((item: string, k: number) => (
          <div key={`${item}${k}`}>
            {el.balances[item]} {item.toUpperCase()}
          </div>
        ))}{" "}
        ({el.network?.toUpperCase()})
      </td>
    </tr>
  );
};
