import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetCustodyTransactionsRequestProps = {
  search?: string;
  status?: string;
  created_at_min?: any;
  created_at_max?: any;
  page: number;
  order_by: string;
  page_size: number;
  is_asc_ordering: boolean;
  wallet_type_from?: string;
  wallet_type_to?: string;
};

/* eslint-disable import/no-mutable-exports */
export let getCustodyTransactionsAbortController = new AbortController() as AbortController;

export function getCustodyTransactions(params: GetCustodyTransactionsRequestProps) {
  getCustodyTransactionsAbortController = new AbortController();
  return axiosInstance(
    getConfig("/custody/transactions", params, {
      signal: getCustodyTransactionsAbortController.signal,
    }),
  ).then((res: any) => res);
}
