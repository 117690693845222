import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetPayinListRequestProps = {
  search?: string;
  page: number;
  order_by: string;
  page_size: number;
  filter_by_exists_ticker?: string;
  is_asc_ordering: boolean;
};

export function getPayinList(params: GetPayinListRequestProps, controller: AbortController = new AbortController()) {
  controller = new AbortController();
  return axiosInstance(
    getConfig("/custody/wallets/payin", params, {
      signal: controller.signal,
    }),
  );
}
