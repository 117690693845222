import { Select } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { setCurrentRole } from "store/slices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/store";

export const SelectRole = () => {
  const dispatch = useDispatch();
  const { currentRole, listOfMyRoles } = useAppSelector(({ auth }) => ({
    currentRole: auth.currentRole,
    listOfMyRoles: auth.listOfMyRoles,
  }));

  const rolesData = useMemo(
    () => [
      ...listOfMyRoles.map((level: string) => ({
        label: level,
        value: level,
      })),
    ],
    [],
  );

  useEffect(() => {
    if (currentRole === null) {
      dispatch(setCurrentRole(rolesData[0].value));
    }
  }, [currentRole]);

  return (
    <Select
      label="Роль"
      w="100%"
      radius={4}
      variant="filled"
      value={currentRole}
      onChange={(d: string) => {
        dispatch(setCurrentRole(d));
      }}
      placeholder="Выберите роль"
      data={rolesData}
    />
  );
};
