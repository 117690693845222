import { TClientDetailsShortResponsiblePerson } from "shared/types/clients";
import { Button, Flex, Grid, Modal, Textarea, Title } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setRemoveResponsiblePersonModalSelectedId, setUpdateResponsiblePersonModalSelectedId } from "store/slices";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import cogoToast from "cogo-toast";
import { deleteResponsiblePerson, updateResponsiblePerson } from "shared/api";
import { useRoles } from "shared/hooks/useRoles";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { ResponsiblePersonItem } from "./components/ResponsiblePersonItem";
import { useGetOrganizationClientDetails } from "../../../Clients/Organizations/useGetOrganizationClientDetails";

type TPropsResponsiblePersonsList = {
  cid: string;
  data: TClientDetailsShortResponsiblePerson[];
};

const useRemoveResponsiblePersonModalLogic = () => {
  const dispatch = useAppDispatch();
  const [reasonRemoveResponsiblePerson, setReasonRemoveResponsiblePerson] = useState("");
  const { removeResponsiblePersonModalSelectedId } = useAppSelector((store) => ({
    ...store.modals,
  }));

  useEffect(() => {
    if (removeResponsiblePersonModalSelectedId === "" && !!reasonRemoveResponsiblePerson.length) {
      setReasonRemoveResponsiblePerson("");
    }
  }, [removeResponsiblePersonModalSelectedId]);

  const setSelectedIdRemoveResponsiblePersonModal = (id: string) => {
    dispatch(setRemoveResponsiblePersonModalSelectedId(id));
  };

  return {
    dispatch,
    reasonRemoveResponsiblePerson,
    setReasonRemoveResponsiblePerson,
    removeResponsiblePersonModalSelectedId,
    setSelectedIdRemoveResponsiblePersonModal,
  };
};

const useUpdateResponsiblePersonModalLogic = () => {
  const dispatch = useAppDispatch();
  const [reasonUpdateResponsiblePerson, setReasonUpdateResponsiblePerson] = useState("");
  const { updateResponsiblePersonModalSelectedId } = useAppSelector((store) => ({
    ...store.modals,
  }));

  const setSelectedIdUpdateResponsiblePersonModal = (id: string) => {
    dispatch(setUpdateResponsiblePersonModalSelectedId(id));
  };

  useEffect(() => {
    if (updateResponsiblePersonModalSelectedId === "" && !!reasonUpdateResponsiblePerson.length) {
      setReasonUpdateResponsiblePerson("");
    }
  }, [updateResponsiblePersonModalSelectedId]);

  return {
    dispatch,
    reasonUpdateResponsiblePerson,
    setReasonUpdateResponsiblePerson,
    updateResponsiblePersonModalSelectedId,
    setSelectedIdUpdateResponsiblePersonModal,
  };
};

export const ResponsiblePersonsList = (props: TPropsResponsiblePersonsList) => {
  const { isCompliance } = useRoles();
  const dispatch = useAppDispatch();
  const { getOrganizationHandle } = useGetOrganizationClientDetails({ cid: props.cid });
  const { removeResponsiblePersonModalSelectedId, setSelectedIdRemoveResponsiblePersonModal, ...logicRemove } =
    useRemoveResponsiblePersonModalLogic();
  const { updateResponsiblePersonModalSelectedId, setSelectedIdUpdateResponsiblePersonModal, ...logicUpdate } =
    useUpdateResponsiblePersonModalLogic();

  const selectedPerson = props.data.filter(({ id }) => id === updateResponsiblePersonModalSelectedId);

  const removeButtonHandler = useCallback(() => {
    if (!isCompliance) return;
    if (!logicRemove.reasonRemoveResponsiblePerson.length) {
      cogoToast.error("Укажите обоснование");
      return;
    }
    setSelectedIdRemoveResponsiblePersonModal("");

    deleteResponsiblePerson({
      id: props.cid,
      responsible_person_user_id: removeResponsiblePersonModalSelectedId,
      body: {
        reason: logicRemove.reasonRemoveResponsiblePerson,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          getOrganizationHandle();
          cogoToast.success("Сотрудник успешно отвязан");
        } else {
          cogoToast.error("При операции отвязки сотрудника произошла ошибка");
        }
      })
      .catch((e) => {
        sentryCaptureError(e);
        cogoToast.error("При операции отвязки сотрудника произошла ошибка");
      })
      .finally(() => {
        logicRemove.setReasonRemoveResponsiblePerson("");
      });
  }, [isCompliance, logicRemove, getOrganizationHandle, props.cid, removeResponsiblePersonModalSelectedId]);

  const updateButtonHandler = useCallback(() => {
    if (!isCompliance) return;
    if (!logicUpdate.reasonUpdateResponsiblePerson.length) {
      cogoToast.error("Укажите обоснование");
      return;
    }

    setSelectedIdUpdateResponsiblePersonModal("");

    updateResponsiblePerson({
      id: props.cid,
      responsible_person_user_id: updateResponsiblePersonModalSelectedId,
      body: {
        is_financial_operations_allowed: !selectedPerson[0].isFinancialOperationsAllowed,
        reason: logicUpdate.reasonUpdateResponsiblePerson,
        position: selectedPerson[0].position,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          getOrganizationHandle();
          cogoToast.success("Успешно обновлено!");
        } else {
          cogoToast.error("При обновлении произошла ошибка");
          window?.console.error(res?.data?.error);
        }
      })
      .catch(sentryCaptureError);
  }, [
    isCompliance,
    logicUpdate,
    getOrganizationHandle,
    props.cid,
    updateResponsiblePersonModalSelectedId,
    selectedPerson,
  ]);

  return (
    <div>
      {isCompliance && !!removeResponsiblePersonModalSelectedId.length && (
        <Modal
          radius={12}
          withCloseButton={false}
          title={<Title order={4}>Отвязать сотрудника от организации?</Title>}
          opened={!!removeResponsiblePersonModalSelectedId.length}
          onClose={() => setSelectedIdRemoveResponsiblePersonModal("")}>
          <Padding horizontal={0} vertical={0}>
            <Textarea
              size="lg"
              value={logicRemove.reasonRemoveResponsiblePerson}
              onChange={(event) => logicRemove.setReasonRemoveResponsiblePerson(event.currentTarget.value)}
              radius={8}
              label="Обоснование"
              sx={{
                label: { fontSize: "13px" },
              }}
              autosize
              placeholder="Введите текст"
            />
            <Spacer size={6} />
            <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
              <Button
                ml={16}
                radius={8}
                size="md"
                variant="default"
                onClick={() => dispatch(setRemoveResponsiblePersonModalSelectedId(""))}>
                Отмена
              </Button>
              <Button radius={8} color="red" size="md" onClick={removeButtonHandler}>
                Отвязать
              </Button>
            </Flex>
          </Padding>
        </Modal>
      )}

      {isCompliance && !!updateResponsiblePersonModalSelectedId.length && (
        <Modal
          withCloseButton={false}
          radius={12}
          title={
            <Title order={4}>
              {selectedPerson[0].isFinancialOperationsAllowed ? "Запретить" : "Разрешить"} доступ к фин. операциям юр.
              лица?
            </Title>
          }
          opened={!!updateResponsiblePersonModalSelectedId.length}
          onClose={() => {
            setSelectedIdUpdateResponsiblePersonModal("");
          }}>
          <Padding horizontal={0} vertical={0}>
            <Textarea
              size="lg"
              value={logicUpdate.reasonUpdateResponsiblePerson}
              onChange={(event) => logicUpdate.setReasonUpdateResponsiblePerson(event.currentTarget.value)}
              radius={8}
              label="Обоснование"
              sx={{
                label: { fontSize: "13px" },
              }}
              autosize
              placeholder="Введите текст"
            />
            <Spacer size={6} />
            <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
              <Button
                ml={16}
                radius={8}
                size="md"
                variant="default"
                onClick={() => dispatch(setUpdateResponsiblePersonModalSelectedId(""))}>
                Отмена
              </Button>
              <Button
                color={selectedPerson[0].isFinancialOperationsAllowed ? "red" : "green"}
                radius={8}
                size="md"
                onClick={updateButtonHandler}>
                {selectedPerson[0].isFinancialOperationsAllowed ? "Запретить" : "Разрешить"}
              </Button>
            </Flex>
          </Padding>
        </Modal>
      )}

      <Grid columns={12}>
        {props.data.map((item: TClientDetailsShortResponsiblePerson) => {
          return (
            <Grid.Col span={6} key={`TCDSRP_${item.id}`}>
              <ResponsiblePersonItem {...item} />
            </Grid.Col>
          );
        })}
      </Grid>
    </div>
  );
};
