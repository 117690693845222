import { useRoute } from "react-router5";
import {
  ClientOrganizationScreenSubRoutes,
  ClientScreenSubRoutes,
  ClientsScreenSubRoutes,
  FeesSubRoutes,
  Routes,
  TickerOperationsScreenSubRoutes,
  TransactionScreenSubRoutes,
  WalletsSubRoutes,
} from "shared/definitions";
import { DepositWithdrawTickerOperations } from "pages/Currencies/DepositWithdraw";
import { ExchangeTickerOperations } from "pages/Currencies/Exchange";
import { ClientServiceInfo } from "pages/Client/Tabs/ClientServiceInfo";
import { ClientOrganizationServiceInfo } from "pages/Organization/Tabs/ClientServiceInfo";
import NotWrapper from "./pages/wrappers/NotWrapper";
import { NotFound } from "./pages/NotFound";
import {
  MainWrapper,
  Transactions,
  Client,
  Transaction,
  ManageAssets,
  Playground,
  IndividualClients,
  OrganizationClients,
  NotypeClients,
} from "./pages";
import { ClientLogs, ClientDocuments, ClientFinances } from "./pages/Client/Tabs";
import {
  ClientOrganizationLogs,
  ClientOrganizationDocuments,
  ClientOrganizationFinances,
} from "./pages/Organization/Tabs";
import { RatesAndFees } from "./pages/RatesAndFees";

import { Deposit, Withdraw, Exchange } from "./pages/Fees";
import { Balances, JournalTransfers, Payin } from "./pages/Wallets";
import { Organization } from "./pages/Organization";

export default function RouteProvider() {
  const routeState = useRoute();
  let Component: any = NotFound;
  let Wrapper: any = MainWrapper;

  switch (routeState.route.name) {
    case Routes.CLIENTS_SCREEN:
      Wrapper = MainWrapper;
      Component = IndividualClients;
      break;
    case Routes.CLIENT_SCREEN:
      Wrapper = MainWrapper;
      Component = Client;
      break;

    case Routes.ORGANIZATION_SCREEN:
      Wrapper = MainWrapper;
      Component = Organization;
      break;

    case Routes.TRANSACTION_SCREEN:
      Wrapper = MainWrapper;
      Component = Transaction;
      break;

    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.LOGS}`:
      Wrapper = MainWrapper;
      Component = ClientLogs;
      break;

    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.COMMENTS}`:
      Wrapper = MainWrapper;
      Component = ClientServiceInfo;
      break;

    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.FINANCES}`:
      Wrapper = MainWrapper;
      Component = ClientFinances;
      break;

    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.DOCUMENTS}`:
      Wrapper = MainWrapper;
      Component = ClientDocuments;
      break;

    case `${Routes.ORGANIZATION_SCREEN}.${ClientOrganizationScreenSubRoutes.LOGS}`:
      Wrapper = MainWrapper;
      Component = ClientOrganizationLogs;
      break;

    case `${Routes.ORGANIZATION_SCREEN}.${ClientOrganizationScreenSubRoutes.FINANCES}`:
      Wrapper = MainWrapper;
      Component = ClientOrganizationFinances;
      break;

    case `${Routes.ORGANIZATION_SCREEN}.${ClientOrganizationScreenSubRoutes.DOCUMENTS}`:
      Wrapper = MainWrapper;
      Component = ClientOrganizationDocuments;
      break;

    case `${Routes.ORGANIZATION_SCREEN}.${ClientOrganizationScreenSubRoutes.COMMENTS}`:
      Wrapper = MainWrapper;
      Component = ClientOrganizationServiceInfo;
      break;

    case `${Routes.TICKER_OPERATIONS_SCREEN}.${TickerOperationsScreenSubRoutes.EXCHANGES}`:
      Wrapper = MainWrapper;
      Component = ExchangeTickerOperations;
      break;

    case `${Routes.TICKER_OPERATIONS_SCREEN}.${TickerOperationsScreenSubRoutes.WITHDRAW_DEPOSIT}`:
      Wrapper = MainWrapper;
      Component = DepositWithdrawTickerOperations;
      break;

    case `${Routes.CLIENTS_SCREEN}.${ClientsScreenSubRoutes.COMPANY}`:
      Wrapper = MainWrapper;
      Component = OrganizationClients;
      break;

    case `${Routes.CLIENTS_SCREEN}.${ClientsScreenSubRoutes.NOTYPE}`:
      Wrapper = MainWrapper;
      Component = NotypeClients;
      break;

    case Routes.TRANSACTIONS_SCREEN:
      Wrapper = MainWrapper;
      Component = Transactions;
      break;

    case `${Routes.TRANSACTIONS_SCREEN}.${TransactionScreenSubRoutes.EXCHANGES}`:
      Wrapper = MainWrapper;
      Component = Transactions;
      break;

    case Routes.MANAGE_ASSETS_SCREEN:
      Wrapper = MainWrapper;
      Component = ManageAssets;
      break;

    case Routes.RATES_AND_FEES_SCREEN:
      Wrapper = MainWrapper;
      Component = RatesAndFees;
      break;

    case `${Routes.FEES_SCREEN}.${FeesSubRoutes.DEPOSIT}`:
      Wrapper = MainWrapper;
      Component = Deposit;
      break;

    case `${Routes.FEES_SCREEN}.${FeesSubRoutes.WITHDRAW}`:
      Wrapper = MainWrapper;
      Component = Withdraw;
      break;

    case `${Routes.FEES_SCREEN}.${FeesSubRoutes.EXCHANGE}`:
      Wrapper = MainWrapper;
      Component = Exchange;
      break;

    case `${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.JOURNAL}`:
      Wrapper = MainWrapper;
      Component = JournalTransfers;
      break;

    case `${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.PAYIN}`:
      Wrapper = MainWrapper;
      Component = Payin;
      break;

    case `${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.BALANCES}`:
      Wrapper = MainWrapper;
      Component = Balances;
      break;

    case "playground":
      Wrapper = NotWrapper;
      Component = Playground;
      break;

    default:
      Wrapper = NotWrapper;
      Component = NotFound;
      break;
  }

  return (
    <Wrapper routeState={routeState}>
      <Component routeState={routeState} />
    </Wrapper>
  );
}
