import { Grid } from "@mantine/core";
import { FieldsWrapper, FieldWrapper, FieldsContentText } from "components/ui/fields";
import dayjs from "dayjs";
import { TPersonalDocument } from "shared/definitions";

type TProps = {
  document: TPersonalDocument;
};

export function ParsedDocDetails(props: TProps) {
  return (
    <Grid columns={12}>
      <Grid.Col span={6}>
        <FieldsWrapper>
          <FieldWrapper label="Тип документа">
            <FieldsContentText inline text={props?.document.typeExternal ?? (props.document.type || "Нет данных")} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
      <Grid.Col span={6}>
        <FieldsWrapper>
          <FieldWrapper label="№ документа">
            <FieldsContentText inline text={props.document.number || "Нет данных"} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
      <Grid.Col span={6}>
        <FieldsWrapper>
          <FieldWrapper label="Страна выдачи">
            <FieldsContentText inline text={props.document.country || "Нет данных"} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
      <Grid.Col span={6}>
        <FieldsWrapper>
          <FieldWrapper label="Годен до">
            <FieldsContentText
              inline
              text={
                props.document.validUntil
                  ? dayjs(props.document.validUntil.toString()).format("DD.MM.YYYY")
                  : "Нет данных"
              }
            />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
      <Grid.Col span={6}>
        <FieldsWrapper>
          <FieldWrapper label="Дата выдачи">
            <FieldsContentText
              inline
              text={
                props.document.issuedDate
                  ? dayjs(props.document.issuedDate.toString()).format("DD.MM.YYYY")
                  : "Нет данных"
              }
            />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
      <Grid.Col span={6}>
        <FieldsWrapper>
          <FieldWrapper label="Кем выдан">
            <FieldsContentText
              inline
              text={props.document.issueAuthorityCode ? props.document.issueAuthorityCode : "Нет данных"}
            />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
    </Grid>
  );
}
