import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export function updateTypeUser(params: {
  id: string;
  data: {
    comment: string;
    type: "individual" | "personal";
  };
}) {
  return axiosInstance(
    postConfig(`/users/${params.id}/type`, { comment: params.data.comment, type: params.data.type }),
  );
}
