import { ActionIcon, createStyles, getStylesRef, Group, Textarea } from "@mantine/core";
import React, { memo } from "react";
import { fieldMinHeight } from "components/ui/fields/config";
import { ReactComponent as CloseIcon } from "shared/static/icons/adapted/close.svg";
import { ReactComponent as OkIcon } from "shared/static/icons/adapted/ok.svg";
import classNames from "classnames";

type FieldsContentTextareaProps = {
  text: string;
  onChange: any;
  isErrored?: boolean;
  isFocused?: boolean;
  textareaProps?: any;
  handleClear: () => void;
  handleSave: () => void;
};

const useStyles = createStyles((theme, _params) => ({
  wrapper: {
    width: "100%",
    minHeight: fieldMinHeight,
    border: "1px solid transparent",
    background: "transparent",
    boxSizing: "border-box",
    display: "flex",
    position: "relative",
    [`&:hover .${getStylesRef("actionContent")}`]: {},
  },
  wrapperErrored: {
    background: "#FFF5F5",
    borderColor: "#FA5252",
    borderRadius: 4,
  },
  wrapperFocused: {
    borderColor: "#7950F2",
    borderRadius: 4,
  },
  textWrapper: {
    padding: "1px 16px",
    boxSizing: "border-box",
    fontSize: "14px",
    lineHeight: "20px",
    flex: 1,
    position: "absolute",
    background: "white",
    width: "calc(100% - 90px)",
    boxShadow: "0 0 0 1px #7950f2, 0 0 30px 0 rgba(0,0,0,0.1)",
    borderRadius: 3,
  },
  rightContent: {
    cursor: "pointer",
    ref: getStylesRef("actionContent"),
    borderRadius: 3,
    transition: "all 300ms ease",
    display: "flex",
    flex: 1,
    marginRight: 0,
    marginLeft: "auto",
    minHeight: fieldMinHeight,
    alignContent: "center",
    justifyContent: "center",
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "90px",
  },
}));

export const FieldsContentTextarea = memo((props: FieldsContentTextareaProps) => {
  const { classes } = useStyles();
  const { onChange, isErrored = false, isFocused = false, text = "", textareaProps = {} } = props;
  return (
    <div
      className={classNames(classes.wrapper, {
        [classes.wrapperFocused]: isFocused,
        [classes.wrapperErrored]: isErrored,
      })}>
      <div className={classes.textWrapper}>
        <Textarea
          value={text}
          autosize
          minRows={1}
          variant="unstyled"
          onChange={onChange}
          maxRows={6}
          {...textareaProps}
        />
      </div>
      <div className={classes.rightContent} onClick={() => {}}>
        <div className="margin_auto margin_left_auto margin_right_0">
          <Group>
            <ActionIcon variant="transparent" color="violet" size={20} ml={12} onClick={() => props.handleClear()}>
              <CloseIcon color="#868E96" />
            </ActionIcon>
            <ActionIcon variant="filled" color="violet" size={20} mr={12} onClick={() => props.handleSave()}>
              <OkIcon color="#fff" />
            </ActionIcon>
          </Group>
        </div>
      </div>
    </div>
  );
});
