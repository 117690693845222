import React, { FC, ReactNode } from "react";
import { Padding } from "components/ui/universal/PaddingWrapper";

interface IProps {
  children: ReactNode | ReactNode[];
}

export const ClientTabLayout: FC<IProps> = ({ children }) => {
  return (
    <Padding horizontal={4} vertical={3}>
      {children}
    </Padding>
  );
};
