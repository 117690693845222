import { Pagination } from "@mantine/core";
import { setNotypeClientsPage } from "store/slices/notypeClientsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

export function ClientsPagination() {
  const { notypeClientsPage, notypeClientsPageSize, notypeClientsCountAll } = useAppSelector(
    (state) => state.notypeClients,
  );
  const dispatch = useAppDispatch();
  
  return (
    <Pagination
      value={notypeClientsPage}
      total={Math.ceil(notypeClientsCountAll / notypeClientsPageSize)}
      onChange={(pageNumber) => dispatch(setNotypeClientsPage(pageNumber))}
      radius={8}
      size="md"
    />
  );
}
