import React, { useCallback, useState } from "react";
import { Button, Group, TextInput } from "@mantine/core";
import { updateTypeUser } from "shared/api";
import { switchMatch } from "shared/utils";
import { useRoles } from "shared/hooks/useRoles";
import cogoToast from "cogo-toast";

type TPropsUpdateClientTypeButton = {
  cid: string;
  type: "individual" | "company";
};

export const UpdateClientTypeButton = (props: TPropsUpdateClientTypeButton) => {
  const { isCompliance } = useRoles();
  const [isShownCommentInput, setIsShownCommentInput] = useState(false);
  const [updateClientTypeComment, setUpdateClientTypeComment] = useState<string>("");

  const updateClientTypeRequestHandler = useCallback(() => {
    updateTypeUser({
      id: props.cid,
      data: {
        comment: updateClientTypeComment,
        type: switchMatch(props.type, {
          individual: "company",
          company: "individual",
        }),
      },
    }).then((res) => {
      if (res.status === 200) {
        switchMatch(props.type, {
          individual: () => {
            window.location.href = `/organization/${props.cid}`;
          },
          company: () => {
            window.location.href = `/client/${props.cid}`;
          },
        });
      } else {
        cogoToast.error("Ошибка смены типа");
      }
    });
  }, [props, updateClientTypeComment]);

  if (!isCompliance) return <div />;

  return (
    <div>
      {!isShownCommentInput && (
        <Button onClick={() => setIsShownCommentInput(true)} variant="outline" compact>
          Сменить тип аккаунта
        </Button>
      )}
      {isShownCommentInput && (
        <Group mt={16} mb={16} className="display_flex">
          <Button
            variant="default"
            radius={4}
            onClick={() => {
              setUpdateClientTypeComment("");
              setIsShownCommentInput(false);
            }}>
            Отмена
          </Button>
          <Button radius={4} onClick={updateClientTypeRequestHandler} disabled={!updateClientTypeComment.length}>
            Сменить тип
          </Button>
          <TextInput
            style={{ flex: 1 }}
            placeholder="Комментарий"
            value={updateClientTypeComment}
            onChange={(event) => setUpdateClientTypeComment(event.currentTarget.value)}
          />
        </Group>
      )}
    </div>
  );
};
