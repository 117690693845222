// delete responsible person from organization

import { axiosInstance } from "../../definitions/api";
import { deleteConfig } from "../config";

export function deleteResponsiblePerson(params: {
  id: string;
  responsible_person_user_id: string;
  body: {
    reason: string;
  };
}) {
  return axiosInstance(
    deleteConfig(`/users/organizations/${params.id}/responsible_person/${params.responsible_person_user_id}`, {
      ...params.body,
    }),
  );
}
