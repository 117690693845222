import { TransactionsCommonFilters, TransactionsCommonListView } from "shared/features";
import React, { useEffect } from "react";
import { Title, Button, Group, Badge, Box } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { resetTransactionsFilters, setDepositStep, setTransactionsTypeFilter } from "store/slices";
import { TransactionsPagination } from "shared/features/Transactions/TransactionsPagination";
import { TransactionsCommonTabs } from "shared/features/Transactions/TransactionsCommonTabs";
import { TransactionsExchangeListView } from "shared/features/Transactions/TransactionsExchangeListView";
import { switchMatch } from "shared/utils";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useRoles } from "shared/hooks/useRoles";
import { ReactComponent as SCVIcon } from "shared/static/icons/adapted/scv.svg";
import { Currencies } from "shared/hoc";
import { Deposit } from "shared/features/Deposit";
import { getTransactionsCSV } from "shared/api/transactions/getTransactionsCSV";
import { useUpdateOnlyWithMouseActions } from "shared/hooks/useUpdateOnlyWithMouseActions";
import { useGetTransactions } from "./useGetTransactions";

export const Transactions = () => {
  const dispatch = useAppDispatch();
  const { isOperator } = useRoles();
  const { currentParamsOfGetTransactions, updateList } = useGetTransactions();

  useUpdateOnlyWithMouseActions(updateList);

  const {
    transactions: data,
    countAll,
    transactionsSegmentedControlValue,
  } = useAppSelector(({ transactions }) => transactions);

  useEffect(() => {
    return () => {
      dispatch(resetTransactionsFilters());
    };
  }, []);

  useEffect(() => {
    dispatch(
      setTransactionsTypeFilter(transactionsSegmentedControlValue === "exchanged" ? "exchange" : "withdraw,deposit"),
    );
  }, [transactionsSegmentedControlValue]);

  const rows = [...data];

  return (
    <Currencies>
      <Padding horizontal={4} vertical={3}>
        {isOperator && <Deposit />}
        <Spacer size={3} />
        <TransactionsCommonTabs />
        <Spacer size={6} />
        <TransactionsCommonFilters />
        <Spacer size={6} />
        <Group position="apart">
          <Group>
            <Title order={2} display="flex">
              {transactionsSegmentedControlValue === "exchanged" ? "Все обмены" : "Все выводы и пополнения"}
            </Title>
            <Badge variant="outline" color="violet" size="md" radius="sm">
              {countAll}
            </Badge>
          </Group>
          <Group>
            <Button
              variant="outline"
              size="xs"
              radius={8}
              disabled={currentParamsOfGetTransactions === null}
              color="#868E96"
              onClick={() => {
                getTransactionsCSV({ ...currentParamsOfGetTransactions! });
              }}
              leftIcon={
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                  }}>
                  <SCVIcon />
                </Box>
              }>
              Скачать CSV
            </Button>
            {isOperator && (
              <div>
                <Button
                  size="xs"
                  radius={8}
                  color="violet"
                  onClick={() => {
                    dispatch(setDepositStep(1));
                  }}>
                  Пополнить счет клиента
                </Button>
              </div>
            )}
          </Group>
        </Group>
        <Spacer size={3} />
        {switchMatch(transactionsSegmentedControlValue, {
          exchanged: <TransactionsExchangeListView updateList={updateList} data={rows} />,
          default: <TransactionsCommonListView updateList={updateList} data={rows} />,
        })}
        <Spacer size={6} />
        <TransactionsPagination />
        <Spacer size={6} />
      </Padding>
    </Currencies>
  );
};
