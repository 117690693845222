import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TWalletType } from "shared/types/wallets";

interface NewPayinTransferSlice {
  amount: string;
  currencyWithNetworkString: string;
  currency: string;
  network: string;
  comment: string;
  from: TWalletType;
  to: TWalletType | "";
  possibleToDirectionsResponse: TWalletType[];
  errors: string[];
  isApprove: boolean;
  step: "init" | "check" | "success" | "error";
  isChecked: boolean;
}

const initialState: NewPayinTransferSlice = {
  amount: "",
  currencyWithNetworkString: "",
  currency: "",
  network: "",
  comment: "",
  from: "payin" as TWalletType,
  to: "cold" as TWalletType,
  possibleToDirectionsResponse: [],
  errors: [],
  isApprove: false,
  step: "init",
  isChecked: false,
};

const newPayinTransferSlice = createSlice({
  name: "newPayinTransfer",
  initialState,
  reducers: {
    newPayinTransferSetIsChecked(state, action: PayloadAction<boolean>) {
      state.isChecked = action.payload;
    },
    newPayinTransferSetAmount(state, action: PayloadAction<string>) {
      state.amount = action.payload;
    },
    newPayinTransferSetStep(state, action: PayloadAction<"init" | "check" | "success" | "error">) {
      state.step = action.payload;
    },
    newPayinTransferSetComment(state, action: PayloadAction<string>) {
      state.comment = action.payload;
    },
    newPayinTransferSetFrom(state, action: PayloadAction<TWalletType>) {
      state.from = action.payload;
    },
    newPayinTransferSetTo(state, action: PayloadAction<TWalletType>) {
      state.to = action.payload;
    },
    newPayinTransferSetIsApprove(state, action: PayloadAction<boolean>) {
      state.isApprove = action.payload;
    },
    newPayinTransferSetErrors(state, action: PayloadAction<string[]>) {
      state.errors = action.payload;
    },
    newPayinTransferSetPossibleToDirectionsResponse(state, action: PayloadAction<TWalletType[]>) {
      state.possibleToDirectionsResponse = action.payload;
      state.to = action.payload[0];
    },
    newPayinTransferSetCurrencyWithNetwork(state, action: PayloadAction<string>) {
      state.currencyWithNetworkString = (action.payload ?? "").toLowerCase();
      if ((action.payload ?? []).includes("_")) {
        const [currency, network] = action.payload.split("_");
        state.currency = currency;
        state.network = network;
      } else {
        state.currency = action.payload;
        state.network = "";
      }
    },
    resetNewPayinTransferSlice: (state) => {
      state.amount = initialState.amount;
      state.comment = initialState.comment;
      state.isApprove = initialState.isApprove;
      state.errors = initialState.errors;
      state.step = initialState.step;
      state.isChecked = initialState.isChecked;
    },
  },
});

export const {
  newPayinTransferSetAmount,
  newPayinTransferSetCurrencyWithNetwork,
  newPayinTransferSetErrors,
  newPayinTransferSetIsChecked,
  newPayinTransferSetIsApprove,
  newPayinTransferSetPossibleToDirectionsResponse,
  newPayinTransferSetFrom,
  newPayinTransferSetComment,
  newPayinTransferSetTo,
  resetNewPayinTransferSlice,
  newPayinTransferSetStep,
} = newPayinTransferSlice.actions;
export const newPayinTransferReducer = newPayinTransferSlice.reducer;
