import { TRate } from "shared/types/currencies";

type TMatrixEntry = Record<string, number>;
export type TMatrix = Record<string, TMatrixEntry>;

// more information about ratesMatrix you can find here:
// https://docs.google.com/spreadsheets/d/1fIHMRsxDb0f_Xlo916Fk5MNfk9LxvVrMIZt_Ki-Rjq4/edit?usp=sharing

// example: 1 eur to usd: TMatrix["EUR"]["USD"]
export const getRatesMatrixFromRateEntries = (rates: TRate[]): TMatrix => {
  const currenciesList = Array.from(new Set(rates.map((r) => [r.tickerBase, r.tickerQuoted]).flat()));

  const matrix = Object.fromEntries(
    currenciesList.map((rowEntry) => {
      const colData = Object.fromEntries(currenciesList.map((colEntry) => [colEntry, NaN]));
      return [rowEntry, colData];
    }),
  );

  // from = tickerBase, to = tickerQuoted;
  rates.forEach((r) => {
    matrix[r.tickerBase][r.tickerBase] = 1;
    matrix[r.tickerQuoted][r.tickerQuoted] = 1;

    matrix[r.tickerBase][r.tickerQuoted] = +r.buy;
    matrix[r.tickerQuoted][r.tickerBase] = 1 / +r.sell;
  });

  return matrix;
};
