import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type GetNotypeClientssRequestProps = {
  search?: string;
  order_by?: string;
  is_asc_ordering?: boolean;
  page: number;
  page_size: number;
};

export function getNotypeUsers(params: GetNotypeClientssRequestProps) {
  return axiosInstance<{ items: any[], count: number }>(getConfig("/users/notype", params));
}
