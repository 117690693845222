import React, { ReactElement, useCallback, useMemo } from "react";
import { Grid } from "@mantine/core";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { FieldTreatment } from "components/common/FieldTreatment";
import dayjs from "dayjs";
import { useRoute } from "react-router5";
import { Routes } from "shared/definitions";
import { useAppSelector } from "store/store";
import { TJournalTransferDetails } from "shared/types/transfers";
import { useCheckIsFiat } from "shared/hooks/useCheckIsFiat";
import { formatCrypto } from "shared/utils/formatNumber";

export const useJournalTransfersViewBlocksLogic = () => {
  const { route } = useRoute();
  const { journalTransfersDetails } = useAppSelector(({ journalTransfers }) => journalTransfers);
  const detail = { ...journalTransfersDetails } as TJournalTransferDetails;
  const isDetailPage = route.name === Routes.JOURNAL_TRANSFER_SCREEN;
  const isFiat = useCheckIsFiat();

  const renderTypicalWrapper = useCallback((node: ReactElement) => {
    return (
      <Grid.Col span={12}>
        <FieldsWrapper>{node}</FieldsWrapper>
      </Grid.Col>
    );
  }, []);

  const renderTypicalTextField = useCallback(
    (label: string, text: string | null, isCopiable = false, inline = true) => {
      return (
        <FieldWrapper label={label}>
          <FieldsContentText inline={inline} text={text || "---"} isCopiable={isCopiable} />
        </FieldWrapper>
      );
    },
    [],
  );

  const blockSimplyTransactionStatus = useMemo(
    () => (
      <FieldWrapper label="Статус">
        <div className="w-100">
          <Padding horizontal={2} vertical={1}>
            <div>
              <div>
                <FieldTreatment name="journalTransferStatus">{detail.status}</FieldTreatment>
              </div>
            </div>
          </Padding>
        </div>
      </FieldWrapper>
    ),
    [detail.status],
  );

  const blockTransactionId = useMemo(() => renderTypicalTextField("ID перевода", detail?.id ?? "", true), [detail?.id]);

  const blockHash = useMemo(
    () => renderTypicalTextField("Hash транзакции", detail.transactionHash ?? "", true, false),
    [detail.transactionHash],
  );

  const blockNumberBlock = useMemo(
    () => renderTypicalTextField("# блока", detail.blockNumber ?? "", true),
    [detail.blockNumber],
  );

  const blockWalletTypeFrom = useMemo(
    () => renderTypicalTextField("Откуда", detail.walletTypeFrom ?? "", true),
    [detail.walletTypeFrom],
  );

  const blockWalletTypeTo = useMemo(
    () => renderTypicalTextField("Куда", detail.walletTypeTo ?? "", true),
    [detail.walletTypeTo],
  );

  const blockWalletFromAddress = useMemo(
    () => renderTypicalTextField("Адрес откуда", detail.walletAddressFrom ?? "", true),
    [detail.walletAddressFrom],
  );

  const blockWalletToAddress = useMemo(
    () => renderTypicalTextField("Адрес куда", detail.walletAddressTo ?? "", true),
    [detail.walletAddressTo],
  );

  const blockTickerNetwork = useMemo(
    () =>
      renderTypicalTextField(
        "Криптовалюта",
        `${detail.ticker.toUpperCase() ?? "---"} (${detail.network.toUpperCase() ?? "---"})`,
        true,
      ),
    [detail.network, detail.ticker],
  );

  const blockAmount = useMemo(
    () => renderTypicalTextField("Сумма", formatCrypto({ value: Number(detail.amount) })),
    [detail.amount],
  );

  const blockNetworkFee = useMemo(
    () =>
      renderTypicalTextField(
        "Network fee",
        formatCrypto({ value: Number(detail.fee), postfix: detail.network?.toUpperCase() }),
      ),
    [detail.fee],
  );

  const blockCreatedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Время создания",
        `${detail.createdAt ? dayjs(detail.createdAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.createdAt],
  );

  const blockUpdatedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Обновлена",
        `${detail.updatedAt ? dayjs(detail.updatedAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.updatedAt],
  );

  const blockFinishedAt = useMemo(
    () =>
      renderTypicalTextField(
        "Подтверждена",
        `${detail.finishedAt ? dayjs(detail.finishedAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : ""}`,
      ),
    [detail.finishedAt],
  );

  return {
    blockNumberBlock,
    blockFinishedAt,
    renderTypicalWrapper,
    renderTypicalTextField,
    blockCreatedAt,
    blockNetworkFee,
    blockUpdatedAt,
    blockSimplyTransactionStatus,
    isDetailPage,
    blockTransactionId,
    blockWalletFromAddress,
    blockWalletToAddress,
    blockTickerNetwork,
    blockWalletTypeTo,
    blockWalletTypeFrom,
    blockAmount,
    blockHash,
    detail,
  };
};
