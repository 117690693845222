import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

/* eslint-disable import/no-mutable-exports */
export let getCustodyTransactionAbortController = new AbortController() as AbortController;

export function getCustodyTransaction({ id }: { id: string }) {
  getCustodyTransactionAbortController = new AbortController();
  return axiosInstance(
    getConfig(`/custody/transactions/${id}`, {}, { signal: getCustodyTransactionAbortController.signal }),
  ).then((res: any) => {
    return res.data;
  });
}
