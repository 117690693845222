import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";

export enum TransactionStatus {
  new = "Не подтверждена",
  completed = "Успешно",
  rejected = "Отклонена",
  pending = "В обработке",
  hold = "Приостановлена",
  user_approving = "Подтверждение liveness",
  sending = "К отправке",
}

export const TransactionStatusKeys = {
  new: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.new),
  completed: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.completed),
  rejected: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.rejected),
  pending: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.pending),
  hold: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.hold),
  user_approving: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.user_approving),
  sending: getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.sending),
};
