import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";

export enum ClientStatus {
  new = "Новый",
  potential = "Потенциальный",
  active = "Активный",
  restricted = "Ограниченный",
}

export const ClientStatusKeys = {
  new: getEnumKeyByEnumValue(ClientStatus, ClientStatus.new),
  potential: getEnumKeyByEnumValue(ClientStatus, ClientStatus.potential),
  active: getEnumKeyByEnumValue(ClientStatus, ClientStatus.active),
  restricted: getEnumKeyByEnumValue(ClientStatus, ClientStatus.restricted),
};
