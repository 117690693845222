const DefaultLocale = "en";

export type IFmtParams = {
  value?: number;
  locale?: string;
  fractionDigits?: number;
  significantDigits?: number;
  prefix?: string;
  postfix?: string;
};

export type IFmtFunc<T extends IFmtParams> = (params: T) => string;

export const preFormatNumber = (value: number, fractionDigits: number, significantDigits: number) => {
  let [intPart, fracPart = ""] = value.toFixed(fractionDigits).split(".");
  intPart = intPart.slice(0, significantDigits);

  if (fracPart !== "") {
    const fracPartMaxLength = Math.max(0, Math.min(fractionDigits!, significantDigits - intPart.length));
    fracPart = fracPart.slice(0, fracPartMaxLength);
  }

  const truncatedValue = [intPart, fracPart].filter((v) => v.length).join(".");

  return { truncatedValue, fracPartLength: fracPart.length };
};

export const formatNumber: IFmtFunc<IFmtParams> = ({
  value,
  locale = DefaultLocale,
  fractionDigits = 2,
  significantDigits = 18,
  prefix,
  postfix,
}) => {
  if (value === undefined || !Number.isFinite(value)) {
    return "–";
  }

  const { truncatedValue, fracPartLength } = preFormatNumber(value, fractionDigits, significantDigits);

  const valueStr = new Intl.NumberFormat(locale, {
    minimumFractionDigits: fracPartLength,
    maximumFractionDigits: fractionDigits,
  }).format(+truncatedValue);

  return [prefix, valueStr, postfix].filter((v) => !!v).join(" ");
};

export const formatFiat = (params: Exclude<IFmtParams, "fractionDigits">) => {
  return formatNumber({ ...params, fractionDigits: 2 });
};

export const formatCrypto = (params: Exclude<IFmtParams, "fractionDigits">) => {
  return formatNumber({ ...params, fractionDigits: 6 });
};

type TFmtAmountParams = Exclude<IFmtParams, "fractionDigits"> & {
  isFiat: boolean;
}
export const formatAmount = (params: TFmtAmountParams) => {
  const { isFiat, ...restParams } = params;
  if (isFiat) {
    return formatFiat(restParams);
  }
  return formatCrypto(restParams);
};