import { ActionIcon, Button, createStyles, Grid, Group, Input, Menu, Select, Space, Switch } from "@mantine/core";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/store";
import { ReactComponent as SearchIcon } from "shared/static/icons/adapted/search.svg";
import { ReactComponent as SearchCancelIcon } from "shared/static/icons/adapted/searchCancel.svg";
import { FilterBadge } from "components/common/FilterBadge";
import { switchMatch } from "shared/utils";
import { ClientStatusKeys } from "shared/definitions/levels/clientStatus";
import { ReactComponent as FiltersIcon } from "shared/static/icons/adapted/filters.svg";
import {
  setOrganizationClientsIsOrderAsc,
  setOrganizationClientsSearch,
  setOrganizationClientsSortBy,
  setOrganizationClientsStatusFilter,
} from "store/slices/organizationClientsSlice";
import classNames from "classnames";

const useStyles = createStyles((theme) => ({
  inputWrapper: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    borderRadius: 8,
    backgroundColor: "white",
  },
  filtersButton: {
    "height": 42,
    "borderLeft": "1px solid rgba(0,0,0,0.07)",
    "borderRadius": "0 7px 7px 0",
    "color": "black",
    "&.isFiltered": {
      color: theme.colors.violet[6],
    },
  },
}));

export const Filters = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    organizationClientsSearch,
    organizationClientsSortBy,
    organizationClientsIsOrderAsc,
    organizationClientsStatusFilter,
  } = useAppSelector((state) => state.organizationClients);

  const isFiltered =
    organizationClientsSearch ||
    organizationClientsSortBy ||
    organizationClientsSearch ||
    organizationClientsStatusFilter;

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <div className={classes.inputWrapper}>
            <Input
              id="inputFirstNameFilter"
              placeholder="Поиск по названию, идентификационному номеру, ID клиента, электронной почте"
              radius={8}
              size="md"
              autoComplete="off"
              variant="unstyled"
              icon={
                <ActionIcon size={24} ml={16} mr={8}>
                  <SearchIcon color="#868E96" />
                </ActionIcon>
              }
              style={{
                flex: "1",
              }}
              value={organizationClientsSearch}
              rightSectionWidth={200}
              rightSection={
                organizationClientsSearch ? (
                  <ActionIcon
                    size={24}
                    ml="auto"
                    mr={8}
                    onClick={() => {
                      dispatch(setOrganizationClientsSearch(""));
                    }}>
                    <SearchCancelIcon color="#868E96" />
                  </ActionIcon>
                ) : (
                  <Button ml="auto" mr={8} compact radius={6} variant="light">
                    Найти
                  </Button>
                )
              }
              onChange={(event: { target: { value: string } }) =>
                dispatch(setOrganizationClientsSearch(event.target.value))
              }
            />
            <Menu shadow="md" radius={8}>
              <Menu.Target>
                <Button
                  leftIcon={<FiltersIcon width={22} />}
                  variant="gradient"
                  gradient={{ from: "#fff", to: "#fff" }}
                  className={classNames(classes.filtersButton, {
                    isFiltered,
                  })}>
                  Фильтры
                </Button>
              </Menu.Target>
              <Menu.Dropdown p="8px 16px 8px 16px">
                <div>
                  <Select
                    label="Статус"
                    placeholder="Статус клиента"
                    clearable
                    value={organizationClientsStatusFilter}
                    data={[
                      { value: ClientStatusKeys.potential, label: "Потенциальный" },
                      { value: ClientStatusKeys.new, label: "Новый" },
                      { value: ClientStatusKeys.active, label: "Активный" },
                      { value: ClientStatusKeys.restricted, label: "Ограниченный" },
                    ]}
                    onChange={(value) => {
                      dispatch(setOrganizationClientsStatusFilter(value || ""));
                    }}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <Select
                    label="Сортировка"
                    clearable
                    value={organizationClientsSortBy}
                    placeholder="Сортировать по"
                    data={[
                      { value: "created_at", label: "Дате регистрации" },
                      { value: "balance", label: "Балансу" },
                    ]}
                    onChange={(value) => {
                      dispatch(setOrganizationClientsSortBy(value || ""));
                    }}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <Switch
                    label="А-Я Порядок"
                    checked={organizationClientsIsOrderAsc}
                    onChange={(event) => dispatch(setOrganizationClientsIsOrderAsc(event.currentTarget.checked))}
                  />
                </div>
                <Space mt={8} />
              </Menu.Dropdown>
            </Menu>
          </div>
        </Grid.Col>
      </Grid>
      {isFiltered && (
        <Group ml={0} mt={16}>
          {organizationClientsStatusFilter && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setOrganizationClientsStatusFilter(""))}>
              <FilterBadge
                field="Статус"
                value={switchMatch(organizationClientsStatusFilter.toLowerCase(), {
                  new: "Новый",
                  active: "Активный",
                  restricted: "Ограниченный",
                  potential: "Потенциальный",
                  default: organizationClientsStatusFilter,
                })}
              />
            </div>
          )}
          {organizationClientsIsOrderAsc && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setOrganizationClientsIsOrderAsc(false))}>
              <FilterBadge field="Порядок" value="А-Я" />
            </div>
          )}
          {organizationClientsSortBy !== "" && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setOrganizationClientsSortBy(""))}>
              <FilterBadge
                field="Сортировка по"
                value={switchMatch(organizationClientsSortBy.toLowerCase(), {
                  created_at: "Дате регистрации",
                  balance: "Балансу",
                  default: organizationClientsSortBy,
                })}
              />
            </div>
          )}
        </Group>
      )}
    </div>
  );
};
