import { useMemo } from "react";
import { Table } from "@mantine/core";
import { EmptyBlock } from "shared/features";
import { ListViewJournalTransfersRow } from "shared/features/Wallets/JournalTransfers/JournalTransfersListView/components/ListViewJournalTransfersRow";
import { TJournalTransfer } from "shared/types/transfers";

const JournalTransfersListViewTableTitles = [
  "Дата и время",
  "ID транз.",
  "Хэш",
  "# Блок",
  "Откуда",
  "Куда",
  "Сумма",
  "Валюта",
  "Статус",
  " ",
];

export const JournalTransfersListView = ({
  data,
  listUpdateFn,
}: {
  data: TJournalTransfer[];
  listUpdateFn: () => void;
}) => {
  const rows = useMemo(
    () =>
      data.map((element) => (
        <ListViewJournalTransfersRow
          key={`JournalTransfersListView${element.id}`}
          el={element}
          listUpdateFn={listUpdateFn}
        />
      )),
    [data],
  );

  if (!data.length) return <EmptyBlock />;

  return (
    <div>
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="md"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            {JournalTransfersListViewTableTitles.map((title) => {
              return (
                <th key={`JTLVTT${title}`} className="break_word">
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
