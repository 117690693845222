import React from "react";
import { Center, LoadingOverlay, Paper } from "@mantine/core";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useClientCid } from "shared/hooks/useCid";
import { Verification } from "./sections/Verification";
import { ClientTabLayout } from "./Tabs/components/ClientsTabLayout";
import { ClientTabHeader } from "./Tabs/components/ClientTabHeader";
import { useGetOrganizationClientDetails } from "../Clients/Organizations/useGetOrganizationClientDetails";
import { ClientOrganizationViewDetails } from "../Clients/Organizations/details/OrganizationViewDetails";
import { ResponsiblePersons } from "./sections/ResponsiblePersons";

export const Organization = () => {
  const { cid } = useClientCid();
  const { organizationClientDetails } = useGetOrganizationClientDetails({ cid });

  if (organizationClientDetails === null) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={organizationClientDetails} tabName={"Анкета клиента"} />

      <Center display="block">
        <div>
          <Spacer size={6} />
          <Paper>
            <ClientOrganizationViewDetails cid={cid} gridColumns={24} />
          </Paper>
          <Spacer size={6} />
          <ResponsiblePersons personsData={organizationClientDetails.responsiblePersons} cid={cid} />
          <Verification cid={cid} />
        </div>
      </Center>
    </ClientTabLayout>
  );
};
