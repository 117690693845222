import { Button } from "@mantine/core";
import React, { FC } from "react";
import styles from "./TransferableValue.module.scss";
import { ReactComponent as Transfer } from "../../shared/static/icons/transfer.svg";

interface IProps {
  value?: string | number;
  onClick?: () => void;
}

export const TransferableValue: FC<IProps> = ({ value, onClick }) => {
  if (value === undefined) return null;

  return (
    <div className={styles.container}>
      {value}

      <Button variant="default" className={styles.button} onClick={onClick}>
        <Transfer />
      </Button>
    </div>
  );
};
