import React, { useCallback, useState } from "react";
import { Button, Drawer, ScrollArea, Title } from "@mantine/core";
import { FieldTreatment } from "components/common/FieldTreatment";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { setJournalTransfersDetail } from "store/slices";
import { useAppDispatch, useAppSelector } from "store/store";
import { getCustodyTransaction } from "shared/api";
import { journalTransferDetailDataTreatment } from "shared/utils";
import { TJournalTransfer } from "shared/types/transfers";
import {
  JournalTransferView,
  JournalTransferViewWrapper,
} from "shared/features/Wallets/JournalTransfers/JournalTransferView";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const ListViewJournalTransfersRow = ({
  el,
  listUpdateFn,
}: {
  el: TJournalTransfer;
  listUpdateFn: () => void;
}) => {
  const dispatch = useAppDispatch();
  const currentDetail = useAppSelector((state) => state.journalTransfers.journalTransfersDetails);
  const [isOpenedJournalTransferPreview, setIsOpenedJournalTransferPreview] = useState(false);

  const journalTransferOpenDetailHandler = useCallback(
    (tid: string) => {
      getCustodyTransaction({ id: tid })
        .then((detail) => {
          dispatch(setJournalTransfersDetail(journalTransferDetailDataTreatment(detail)));
        })
        .catch(sentryCaptureError)
        .finally(() => {
          setIsOpenedJournalTransferPreview(true);
        });
    },
    [currentDetail, el],
  );

  return (
    <tr>
      <Drawer
        size="700px"
        title={
          <Padding vertical={0} horizontal={2}>
            <Title order={5}>Детальная информация</Title>
          </Padding>
        }
        overlayProps={{ opacity: 0.65 }}
        position="right"
        opened={isOpenedJournalTransferPreview}
        onClose={() => setIsOpenedJournalTransferPreview(false)}>
        <Padding horizontal={2}>
          <ScrollArea style={{ height: "calc(100vh - 120px)" }} offsetScrollbars scrollbarSize={8}>
            <JournalTransferViewWrapper jtid={el.id}>
              <JournalTransferView listUpdateFn={listUpdateFn} />
            </JournalTransferViewWrapper>
          </ScrollArea>
        </Padding>
      </Drawer>
      <td>
        <FieldTreatment name="createdAt">{el.createdAt}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionId" onClick={() => journalTransferOpenDetailHandler(el?.id)}>
          {el.id}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionHash">{el?.transactionHash}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionType">{el?.blockNumber}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="from">{el?.walletTypeFrom}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="to">{el?.walletTypeTo}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="amount">{el?.amount}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="unknown">{el?.ticker}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="journalTransferStatus">{el?.status}</FieldTreatment>
      </td>
      <td>
        <div className="cursor_pointer no_select" onClick={() => journalTransferOpenDetailHandler(el?.id)}>
          <Button compact color="black" variant="subtle">
            Открыть
          </Button>
        </div>
      </td>
    </tr>
  );
};
