import { useAppDispatch } from "store/store";
import React, { useCallback } from "react";
import { TFeeDataItem } from "shared/types/fees";
import { TDepositKey } from "shared/definitions/fees";
import { saveFeeById } from "shared/api/fees";
import { getFees } from "shared/api/currencies/getFees";
import { setFeesData } from "store/slices";
import cogoToast from "cogo-toast";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { invalidField, minFeeGreater, minGtMax } from "./constants";

export const useCommonFeesLogic = () => {
  const dispatch = useAppDispatch();

  const handleValidate = useCallback((value: string, fieldKey: TDepositKey, item: TFeeDataItem) => {
    let hasError = false;
    if (item.customType === "withdrawFiatTransfer" && (fieldKey === "minFee" || fieldKey === "maxFee")) {
      return false;
    }
    if (value === undefined || value === null || !Number.isFinite(+value) || +value < 0) {
      hasError = true;
    }
    if (fieldKey === "percentFee") {
      if (!Number.isFinite(+value) || Number(value) <= 0 || Number(value) > 100) {
        hasError = true;
        cogoToast.error(invalidField);
      }
    } else if (fieldKey === "minFee" && item.customType !== "withdrawCrypto") {
      if (Number(value) > Number(item.maxFee)) {
        hasError = true;
        cogoToast.error(minGtMax);
      }
    } else if (fieldKey === "maxFee" && item.customType !== "withdrawCrypto") {
      if (Number(value) < Number(item.minFee)) {
        hasError = true;
        cogoToast.error(minGtMax);
      }
    }
    return hasError;
  }, []);

  const handleSubmit = useCallback(
    async (
      value: string,
      fieldKey: TDepositKey,
      item: TFeeDataItem,
      setFocusedInputId: React.Dispatch<React.SetStateAction<string | undefined>> = () => {},
    ) => {
      const dto: {
        max_fee: string;
        min_fee: string;
        percent_fee: string;
      } = {
        max_fee: item.customType === "withdrawCrypto" ? value : item.maxFee,
        min_fee: item.minFee,
        percent_fee: item.customType === "withdrawCrypto" ? "1" : item.percentFee,
      };

      switch (fieldKey) {
        case "minFee":
          dto.min_fee = value;
          break;
        case "maxFee":
          dto.max_fee = value;
          break;
        case "percentFee":
        default:
          dto.percent_fee = value;
          break;
      }

      if (handleValidate(value, fieldKey, item)) {
        return Promise.resolve(true);
      }

      return new Promise((resolve) => {
        saveFeeById({
          feeId: item.id,
          data: dto,
        })
          .then((res: any) => {
            if (res?.response?.status === 400) {
              if (res?.response?.data?.error.indexOf("min_fee must be less than max_fee") > -1) {
                cogoToast.error(minGtMax);
              } else if (
                res?.response?.data?.error.indexOf("Min fee greater or equal than minimum ticker limit") > -1
              ) {
                cogoToast.error(minFeeGreater);
              }
              resolve(false);
              return;
            }
            if (res?.data?.status === "ok") {
              cogoToast.success("Успешно!");
              resolve(true);
              setFocusedInputId(undefined);
            } else {
              cogoToast.error("Ошибка изменения");
              resolve(false);
            }
          })
          .catch((e) => {
            cogoToast.error("Ошибка изменения");
            sentryCaptureError(e);
            resolve(false);
          })
          .finally(() => {
            getFees().then((resp: any) => {
              dispatch(setFeesData(resp));
            });
          });
      });
    },
    [],
  );

  return { handleSubmit, handleValidate };
};
