import { Box, Button, Grid, Title } from "@mantine/core";
import { FieldsWrapper } from "components/ui/fields";
import { useTransactionViewBlocksLogic } from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { TTransactionDetails } from "shared/types/transactions";
import { ReactComponent as SCVIcon } from "shared/static/icons/adapted/scv.svg";
import { useCallback, useMemo } from "react";

type TProps = {
  detail: TTransactionDetails;
  bankDetails: any;
  updateList: () => void
};

export const BankDetails = ({ detail, bankDetails, updateList }: TProps) => {
  const { renderTypicalTextField } = useTransactionViewBlocksLogic(detail, updateList);

  const handleDownloadCSV = useCallback(() => {
    const date = new Date().toJSON();

    const dataObject = [
      {
        userFullName: detail?.userFullName ? `${detail?.userFullName} ${detail.userPublicId}` : "",
        bankName: bankDetails?.bankName ?? "",
        bankAddress: bankDetails?.bankAddress ?? "",
        bicSwift: bankDetails?.bicSwift ?? "",
        account: bankDetails?.account ?? "",
      },
    ];

    const tableHead = ["Получатель", "Название банка", "Адрес банка", "BIC/SWIFT", "Номер счета клиента"].join(", ");
    const tableContent = dataObject.map((o) => Object.values(o).join(", "));

    const csvString = [tableHead].concat(tableContent).join("\n ");

    const mediaType = "data:application/octet-stream,";

    const a = document.createElement("a");
    a.href = mediaType + encodeURI(csvString);
    a.download = `bankdetails_${date}.csv`;
    a.click();
  }, [detail, bankDetails]);

  const blockClientName = useMemo(() => renderTypicalTextField("Клиент", detail.userFullName ?? ""), [detail]);

  const blockBankName = useMemo(() => renderTypicalTextField("Банк", bankDetails.bankName ?? ""), [bankDetails]);

  const blockBankAccount = useMemo(() => renderTypicalTextField("Счёт", bankDetails.account ?? ""), [bankDetails]);

  const blockBankBicSwift = useMemo(
    () => renderTypicalTextField("BIC/SWIFT", bankDetails.bicSwift ?? ""),
    [bankDetails],
  );

  const blockBankAddress = useMemo(
    () => renderTypicalTextField("Адрес банка", bankDetails.bankAddress ?? ""),
    [bankDetails],
  );

  return (
    <Grid.Col span={12}>
      <div className="w-100 display_flex">
        <Title order={3} mb={12}>
          Реквизиты
        </Title>
        <Button
          variant="outline"
          size="md"
          radius={4}
          compact
          color="black"
          className="margin_left_auto"
          onClick={() => handleDownloadCSV()}
          leftIcon={
            <Box
              sx={{
                width: "15px",
                height: "15px",
              }}>
              <SCVIcon />
            </Box>
          }>
          Скачать CSV
        </Button>
      </div>
      <FieldsWrapper>
        {blockClientName}
        {blockBankName}
        {blockBankAddress}
        {blockBankBicSwift}
        {blockBankAccount}
      </FieldsWrapper>
    </Grid.Col>
  );
};
