import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setTickerOperations } from "store/slices";
import { getTickerOperations } from "shared/api/currencies/getTickerOperations";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useTickerOperations = () => {
  const dispatch = useAppDispatch();
  const { tickerOperations } = useAppSelector((state) => state.currencies);

  const updateData = useCallback(() => {
    getTickerOperations()
      .then((resp) => {
        dispatch(setTickerOperations(resp));
      })
      .catch(sentryCaptureError);
  }, []);

  useEffect(() => {
    if (tickerOperations.length === 0) {
      getTickerOperations()
        .then((resp) => {
          dispatch(setTickerOperations(resp));
        })
        .catch(sentryCaptureError);
    }
  }, []);

  return {
    isLoaded: !!tickerOperations.length,
    tickerOperations,
    updateData,
  };
};
