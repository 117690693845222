import { Button } from "@mantine/core";
import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { withdrawAll } from "store/slices";
import { IWithdrawAllModalProps } from "./types";
import styles from "./WithdrawAllModal.module.scss";

export const WithdrawAllModal: FC<IWithdrawAllModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const handleWithdraw = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      dispatch(withdrawAll());
      onClose();
    },
    [onClose],
  );

  return (
    <form className={styles.form} onSubmit={handleWithdraw}>
      <div className={styles.text}>
        Вы уверены, что хотите вывести все средства со следующих кошельков?
        <br />
        - User payins
        <br />
        - Payout
        <br />
        - Fan
        <br />
        <br />
        После подтверждения все средства будут списаны.
        <br />
        Отменить вывод будет невозможно.
      </div>

      <br />

      <div className={styles.controlButtons}>
        <Button variant="default" onClick={onClose}>
          Отмена
        </Button>
        <Button type="submit" color="red">
          Подтвердить
        </Button>
      </div>
    </form>
  );
};
