import React from "react";
import { PersonalClientViewCommon } from "shared/features";
import { Center, LoadingOverlay, Paper } from "@mantine/core";
import { PersonalClientExtendedInfo } from "shared/features/PersonalClients/PersonalClientExtendedInfo";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useClientCid } from "shared/hooks/useCid";
import { Verification } from "./sections/Verification";
import { useGetClientDetails } from "./useGetClientDetails";
import { ClientTabLayout } from "./Tabs/components/ClientsTabLayout";
import { ClientTabHeader } from "./Tabs/components/ClientTabHeader";
import { AffiliatedOrganizations } from "./sections/Organizations";

export const Client = () => {
  const { cid } = useClientCid();

  const { clientDetails, isLoadedClientDetails } = useGetClientDetails({ cid });

  if (!isLoadedClientDetails) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={clientDetails} tabName={"Анкета клиента"} />

      <Center display="block">
        <div>
          <Spacer size={6} />
          <Paper>
            <PersonalClientViewCommon cid={cid} gridColumns={24} />
          </Paper>
          <Spacer size={6} />
          <Paper>
            <PersonalClientExtendedInfo cid={cid} gridColumns={24} />
          </Paper>
          <Spacer size={6} />
          <AffiliatedOrganizations data={clientDetails.organizations} />
          <Verification cid={cid} />
        </div>
      </Center>
    </ClientTabLayout>
  );
};
