import { createStyles, getStylesRef } from "@mantine/core";

export const useStyles = createStyles((theme, _params) => {
  const icon = getStylesRef("icon");
  return {
    navbar: {
      ...theme.fn.focusStyles(),
      height: "100vh",
      backgroundColor: "transparent",
      borderRight: 0,
      transition: "all 100ms ease",
      padding: "12px",
    },

    header: {
      "marginBottom": "10px",
      "padding": "5px 10px 0 0",
      "display": "flex",
      "userSelect": "none",
      " .logo": {
        cursor: "pointer",
        height: "50px",
        marginRight: 0,
        marginLeft: 0,
        svg: {
          height: "50px",
        },
      },
      " .logoText": {
        cursor: "pointer",
        height: "50px",
        margin: "auto 0 auto 0",
        display: "flex",
        flex: "1",
        svg: {
          margin: "auto auto auto 10px",
        },
      },
      " .hug": {
        cursor: "pointer",
        height: "50px",
        marginRight: "auto",
        marginLeft: -8,
        svg: {
          height: "50px",
        },
      },
      " .hugRevert": {
        cursor: "pointer",
        height: "50px",
        marginRight: -10,
        marginLeft: "auto",
        svg: {
          height: "50px",
        },
      },
      " .name": {
        height: "30px",
        display: "flex",
        marginRight: "auto",
        span: {
          margin: "auto 12px",
          fontFamily: "Roboto",
          fontSize: 26,
          lineHeight: "26px",
        },
      },
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
    },

    footerCircle: {
      width: "40px",
      minWidth: "40px",
      backgroundColor: "rgba(255,255,255,0.1)",
      height: "40px",
      display: "flex",
      cursor: "pointer",
      marginLeft: 0,
      borderRadius: 20,
    },

    footerMenu: {
      position: "fixed",
      bottom: 16,
      left: 12,
      width: 250,
      background: "#fff",
      boxShadow: "0 0 25px 0 rgba(0,0,0,0.25)",
      borderRadius: 16,
      boxSizing: "border-box",
      padding: "8px 12px 14px",
      zIndex: 1,
    },

    link: {
      ...theme.fn.focusStyles(),
      "display": "flex",
      "alignItems": "center",
      "textDecoration": "none",
      "fontSize": "14px",
      "lineHeight": "20px",
      "padding": "10px 12px",
      "borderRadius": "4px",
      "fontWeight": 400,
      "marginTop": "8px",
      "color": "#868E96",
      "span": {
        marginLeft: 10,
        whiteSpace: "nowrap",
      },
      "&:hover": {
        backgroundColor: "rgba(255,255,255,.1)",
        color: "white",
        [`& .${icon}`]: {
          color: "white",
        },
      },
    },

    support: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color: theme.colors.gray[5],
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    },

    mailToLink: {
      color: theme.colors.violet[5],
    },

    linkIconWrapper: {
      display: "flex",
      height: 20,
    },

    linkIcon: {
      margin: "2px 0",
      height: 16,
      ref: icon,
      color: "#868E96",
    },

    linkActive: {
      "&, &:hover": {
        color: "white",
        backgroundColor: "#7950F2",
        [`& .${icon}`]: {
          color: "white",
        },
      },
    },
  };
});
