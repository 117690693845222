import React, { FC, ReactNode } from "react";
import { Padding } from "components/ui/universal/PaddingWrapper";
import styles from "./TabContentContainer.module.scss";

interface IProps {
  children: ReactNode;
}

export const TabContentContainer: FC<IProps> = ({ children }) => {
  return (
    <Padding vertical={2} className={styles.tabContentContainer}>
      {children}
    </Padding>
  );
};
