import { useAppDispatch, useAppSelector } from "store/store";
import { TClientDetails, TClientViewProps } from "shared/types/clients";
import { useCallback, useEffect } from "react";
import { getUser, getUserAbortController } from "shared/api";
import { setPersonalClientDetail, setPersonalClientDetailExtended } from "store/slices";
import { clientDetailDataTreatment, clientDetailExtendedDataTreatment, isEmptyObject } from "shared/utils";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

export const useGetClientDetails = ({ cid }: TClientViewProps) => {
  const dispatch = useAppDispatch();
  const clientDetails = useAppSelector((state) => state.personalClients.personalClientDetails as TClientDetails);

  const getUserHandle = useCallback(() => {
    getUser({ id: cid })
      .then(({ data: detail }) => {
        dispatch(setPersonalClientDetail(clientDetailDataTreatment(detail)));
        if (!isEmptyObject(detail?.additionalData)) {
          dispatch(setPersonalClientDetailExtended(clientDetailExtendedDataTreatment(detail?.additionalData)));
        }
      })
      .catch((e) => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
  }, [cid]);

  useEffect(() => {
    getUserAbortController.abort();
    getUserHandle();
  }, []);

  return { clientDetails, isLoadedClientDetails: !!Object.keys(clientDetails).length, getUserHandle };
};
