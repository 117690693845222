import React, { FC, useCallback, useEffect } from "react";
import {
  Flex,
  Paper,
  Table,
  Title,
  Input,
  Badge,
  Button,
  Popover,
  Pagination,
  LoadingOverlay,
  Select,
  Checkbox,
  TextInput,
  createStyles,
  ActionIcon,
} from "@mantine/core";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { ReactComponent as SearchIcon } from "shared/static/icons/adapted/search.svg";
import { useAppDispatch, useAppSelector } from "store/store";
import { ReactComponent as FiltersIcon } from "shared/static/icons/adapted/filters.svg";
import {
  setLogsFilterAction,
  setLogsFilterComment,
  setLogsFilterFromDate,
  setLogsFilterRole,
  setLogsFilterToDate,
  setLogsPage,
  setLogsUserId,
  setNewestFirst,
} from "store/slices/logsSlice";
import { DateTimePicker } from "@mantine/dates";
import { useLogsDropdowns } from "shared/hooks/useLogActionTypes";
import { useGetOrganizationUserId } from "shared/hooks/useGetOrganizationUserId";
import { useClientCid } from "shared/hooks/useCid";
import classNames from "classnames";
import styles from "./ClientLogs.module.scss";
import { useGetLogs } from "./useGetLogs";
import { AppliedFilters } from "./AppliedFilters";

import { ClientTabHeader } from "../components/ClientTabHeader";
import { ClientTabLayout } from "../components/ClientsTabLayout";
import { getLogsAdditionalData } from "./getLogsAdditionalData";
import { useGetOrganizationClientDetails } from "../../../Clients/Organizations/useGetOrganizationClientDetails";

interface IProps {}

export const columns = ["Время", "Роль", "Действие", "Комментарий", "IP", "Данные", ""];

function formatDate(s: string) {
  try {
    return new Date(s).toLocaleString();
  } catch (e) {
    return "–";
  }
}

const useStyles = createStyles((theme) => ({
  inputWrapper: {
    "width": "100%",
    "border": "1px solid rgba(0,0,0,0.1)",
    "display": "flex",
    "borderRadius": 8,
    "backgroundColor": "white",
    " input": {
      fontSize: "18px",
      marginTop: "2px;",
    },
  },
  filtersButton: {
    "height": 42,
    "borderLeft": "1px solid rgba(0,0,0,0.07)",
    "borderRadius": "0 7px 7px 0",
    "color": "black",
    "&.isFiltered": {
      color: theme.colors.violet[6],
    },
  },
}));

export const ClientOrganizationLogs: FC<IProps> = () => {
  const { organizationUserId: cid } = useGetOrganizationUserId();
  const { cid: organizationId } = useClientCid();
  const { actionTypesList } = useLogsDropdowns();
  const { organizationClientDetails } = useGetOrganizationClientDetails({
    cid: organizationId,
  });
  const logsState = useAppSelector(({ logs }) => logs);
  const dispatch = useAppDispatch();
  const { search } = useGetLogs();
  const { classes } = useStyles();

  const isFiltered =
    logsState.filterComment ||
    logsState.filterAction ||
    logsState.filterToDate ||
    logsState.filterFromDate ||
    logsState.filterRole;

  useEffect(() => {
    if (cid && cid.length) dispatch(setLogsUserId(cid));
  }, [cid]);

  const onFromDateChange = useCallback(
    (value: Date | null) => {
      dispatch(setLogsFilterFromDate(value?.toISOString() || ""));
    },
    [logsState],
  );

  const onToDateChange = useCallback(
    (value: Date | null) => {
      dispatch(setLogsFilterToDate(value?.toISOString() || null));
    },
    [logsState],
  );

  if (organizationClientDetails === null) return <LoadingOverlay visible />;

  return (
    <ClientTabLayout>
      <ClientTabHeader clientDetails={organizationClientDetails} tabName={"Логи"} />

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await search();
        }}
        className={classes.inputWrapper}>
        <Input
          value={logsState.filterComment}
          rightSection={
            <Button ml="auto" mr={40} compact radius={6} variant="light">
              Найти
            </Button>
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // TODO Strange build error without this "any"
            dispatch(setLogsFilterComment(e.target.value));
          }}
          placeholder="Поиск по действиям, комментарию"
          icon={
            <ActionIcon size={24} ml={16} mr={8}>
              <SearchIcon color="#868E96" />
            </ActionIcon>
          }
          radius={8}
          size="md"
          autoComplete="off"
          variant="unstyled"
          style={{
            flex: "1",
          }}
        />
        <Popover width="250px">
          <Popover.Target>
            <Button
              leftIcon={<FiltersIcon width={22} />}
              variant="gradient"
              gradient={{ from: "#fff", to: "#fff" }}
              className={classNames(classes.filtersButton, { isFiltered })}>
              Фильтры
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Flex gap="sm" direction="column">
              <Title order={5}>Период</Title>
              <Title order={6}>От</Title>
              <DateTimePicker
                value={logsState.filterFromDate ? new Date(logsState.filterFromDate) : null}
                withSeconds
                onChange={onFromDateChange}
              />
              <Title order={6}>До</Title>
              <DateTimePicker
                value={logsState.filterToDate ? new Date(logsState.filterToDate) : null}
                withSeconds
                onChange={onToDateChange}
              />
              <Select
                value={logsState.filterAction}
                clearable
                label="Действия"
                data={actionTypesList}
                onChange={(value) => dispatch(setLogsFilterAction(value || ""))}
              />
              <TextInput
                label="Роль"
                value={logsState.filterRole}
                onChange={(e) => dispatch(setLogsFilterRole(e.target.value))}
              />
              <Checkbox
                checked={logsState.newestFirst}
                label="Сначала новые"
                onChange={(e) => {
                  dispatch(setNewestFirst(e.target.checked));
                }}
              />
            </Flex>
          </Popover.Dropdown>
        </Popover>
      </form>
      <Spacer size={2} />
      <AppliedFilters />
      <Spacer size={6} />
      <Title order={4}>
        Все записи <Badge color="violet">{logsState.countAll}</Badge>
      </Title>
      <Spacer size={2} />
      <Paper>
        <Table>
          <thead>
            <tr>
              {columns.map((v) => (
                <th key={v}>{v}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {logsState.logs.map((el, key) => (
              <tr key={key}>
                <td>{formatDate(el.eventTs)}</td>
                <td>{el.actor}</td>
                <td>{el.action}</td>
                <td className={styles.commentColumn}>{el.payload?.comment || "–"}</td>
                <td className={styles.ipColumn}>{el.payload?.userIp || "–"}</td>
                <td className={styles.dataColumn}>{getLogsAdditionalData(el)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>
      <Spacer size={2} />
      <Pagination
        value={logsState.page}
        total={Math.ceil(logsState.countAll / logsState.pageSize)}
        onChange={(pageNumber) => {
          dispatch(setLogsPage(pageNumber));
        }}
        radius={8}
        size="md"
      />
    </ClientTabLayout>
  );
};
