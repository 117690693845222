import { useCallback, useMemo, useState } from "react";
import { Button, Drawer, Modal, ScrollArea, Title } from "@mantine/core";
import { TTransaction } from "shared/types/transactions";
import { Routes } from "shared/definitions";
import { PersonalClientViewCommon } from "shared/features";
import { FieldBadge, FieldTreatment } from "components/common/FieldTreatment";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import router from "router";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { TransactionView } from "shared/features/Transactions/TransactionView";
import { setIsShownTransactionPanel, setTransactionDetail } from "store/slices";
import { useAppDispatch, useAppSelector } from "store/store";
import { formatAmount } from "shared/utils/formatNumber";
import { getTransaction } from "shared/api";
import { transactionDetailDataTreatment } from "shared/utils";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { TransactionTypesKeys } from "shared/definitions/transactions/types";
import { useRoles } from "shared/hooks/useRoles";
import { useCheckIsFiat } from "shared/hooks/useCheckIsFiat";
import { sentryCaptureError } from "shared/utils/environment/sentry";

type TProps = {
  el: TTransaction;
  updateList: () => void;
};

export const ExchangeListTransactionRow = ({ el, updateList }: TProps) => {
  const dispatch = useAppDispatch();
  const { transactionDetails: currentDetail, isShownTransactionPanel: isTransactionPanelShown } = useAppSelector(
    (state) => state.transactions,
  );
  const checkIsFiat = useCheckIsFiat();
  const [isOpenedClientPreview, setIsOpenedClientPreview] = useState(false);

  const { isFinManager, isHeadOfFinManager } = useRoles();

  const openClientPageHandler = useCallback(() => {
    router.navigate(Routes.CLIENT_SCREEN, {
      cid: el.id,
    });
  }, []);

  const openTransactionPageHandler = useCallback(() => {
    window?.open(`/transaction/${el.transactionId}`, "_blank");
  }, []);

  const transactionOpenDetailHandler = useCallback(
    (tid: string) => {
      getTransaction({ id: tid })
        .then((detail) => {
          dispatch(setTransactionDetail(transactionDetailDataTreatment(detail)));
        })
        .catch(sentryCaptureError)
        .finally(() => {
          dispatch(setIsShownTransactionPanel(true));
        });
    },
    [currentDetail, el],
  );

  const fromInfo = useMemo(() => {
    if (!el.tickerFrom) {
      return { amount: el.amountFrom, ticker: el.tickerFrom };
    }
    const isFiat = checkIsFiat(el.tickerFrom);
    return {
      amount: formatAmount({ value: Number(el.amountFrom), isFiat }),
      ticker: el.tickerFrom,
    };
  }, [checkIsFiat, el]);

  const toInfo = useMemo(() => {
    if (!el.tickerTo) {
      return { amount: el.amountTo, ticker: el.tickerTo };
    }
    const isFiat = checkIsFiat(el.tickerTo);
    return {
      amount: formatAmount({ value: Number(el.amountTo), isFiat }),
      ticker: el.tickerTo,
    };
  }, [checkIsFiat, el]);

  const isLighted =
    (isFinManager || isHeadOfFinManager) &&
    el.transactionStatus === TransactionStatusKeys.sending &&
    el.type === TransactionTypesKeys.exchange;

  return (
    <tr style={isLighted ? { backgroundColor: "#ffeee1" } : {}}>
      <Modal
        size="lg"
        title={<Title order={3}>Информация о клиенте</Title>}
        overlayProps={{ opacity: 0.55 }}
        radius={16}
        opened={isOpenedClientPreview}
        onClose={() => setIsOpenedClientPreview(false)}>
        <Button size="sm" compact radius={4} variant="default" onClick={openClientPageHandler}>
          Открыть подробнее
        </Button>
        <Spacer size={6} />
        <PersonalClientViewCommon cid={el.id} />
      </Modal>
      <Drawer
        size="700px"
        title={
          <Padding vertical={0} horizontal={2}>
            <Title order={4}>Детальная информация</Title>
          </Padding>
        }
        overlayProps={{ opacity: 0.55 }}
        position="right"
        opened={(currentDetail as TTransaction).transactionId === el.transactionId && isTransactionPanelShown}
        onClose={() => dispatch(setIsShownTransactionPanel(false))}>
        <Padding horizontal={2}>
          <Button mb={16} size="sm" compact radius={6} variant="filled" onClick={openTransactionPageHandler}>
            Открыть во вкладке
          </Button>
          <ScrollArea style={{ height: "calc(100vh - 120px)" }} offsetScrollbars scrollbarSize={8}>
            <TransactionView tid={el.transactionId} updateList={updateList} />
          </ScrollArea>
        </Padding>
      </Drawer>
      <td>
        <FieldTreatment name="createdAt">{el.createdAt}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionId" onClick={() => transactionOpenDetailHandler(el?.transactionId)}>
          {el.transactionId}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="amount">{fromInfo.amount || "---"}</FieldTreatment>
      </td>
      <td>{fromInfo.ticker}</td>
      <td>➔</td>
      <td>
        <FieldTreatment name="amount">{toInfo.amount || "---"}</FieldTreatment>
      </td>
      <td>{toInfo.ticker}</td>
      <td>
        <FieldBadge value={el.transactionStatus!} color={isLighted ? "yellow" : undefined} />
      </td>
      <td>
        <FieldTreatment name="userPublicId" onClick={() => setIsOpenedClientPreview(true)}>
          {el.userPublicId.toString()}
        </FieldTreatment>
      </td>
      <td>
        <div className="cursor_pointer no_select" onClick={() => transactionOpenDetailHandler(el?.transactionId)}>
          <Button compact color="violet" variant="subtle">
            Открыть
          </Button>
        </div>
      </td>
    </tr>
  );
};
