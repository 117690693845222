import "dayjs/locale/ru";
import { DatesProvider, DateTimePicker } from "@mantine/dates";
import { ActionIcon, Button, createStyles, Grid, Group, Input, Menu, Select, Space, Switch } from "@mantine/core";
import { useDispatch } from "react-redux";
import {
  setJournalTransfersFromDateFilter,
  setJournalTransfersFromTypeFilter,
  setJournalTransfersIsAscOrderingFilter,
  setJournalTransfersOrderByFilter,
  setJournalTransfersSearchFilter,
  setJournalTransfersStatusFilter,
  setJournalTransfersToDateFilter,
  setJournalTransfersToTypeFilter,
} from "store/slices";
import { ReactComponent as SearchIcon } from "shared/static/icons/adapted/search.svg";
import { ReactComponent as SearchCancelIcon } from "shared/static/icons/adapted/searchCancel.svg";
import { useAppSelector } from "store/store";
import React, { useEffect, useMemo, useState } from "react";
import { FilterBadge } from "components/common/FilterBadge";
import { switchMatch } from "shared/utils";
import dayjs from "dayjs";
import { useDebouncedValue } from "@mantine/hooks";
import { ReactComponent as FiltersIcon } from "shared/static/icons/adapted/filters.svg";
import { TransferDirections } from "shared/definitions";
import classNames from "classnames";

const useStyles = createStyles((theme) => ({
  inputWrapper: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    borderRadius: 8,
    backgroundColor: "white",
  },
  filtersButton: {
    "height": 42,
    "borderLeft": "1px solid rgba(0,0,0,0.07)",
    "borderRadius": "0 7px 7px 0",
    "color": "black",
    "&.isFiltered": {
      color: theme.colors.violet[6],
    },
  },
}));

const journalTransfersStatuses = [
  { value: "new", label: "Новый" },
  { value: "processing", label: "Процессинг" },
  { value: "sending", label: "Отправка" },
  { value: "finished", label: "Успешно" },
];

export const JournalTransfersFilters = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const {
    journalTransfersSearchFilter,
    journalTransfersIsAscOrderingFilter,
    journalTransfersStatusFilter,
    journalTransfersOrderByFilter,
    journalTransfersFromTypeFilter,
    journalTransfersToTypeFilter,
    journalTransfersFromDateFilter,
    journalTransfersToDateFilter,
  } = useAppSelector(({ journalTransfers }) => journalTransfers);

  const isFiltered =
    !!journalTransfersOrderByFilter.length ||
    !!journalTransfersFromTypeFilter.length ||
    !!journalTransfersToTypeFilter.length ||
    journalTransfersIsAscOrderingFilter ||
    journalTransfersToDateFilter !== null ||
    journalTransfersFromDateFilter !== null ||
    journalTransfersStatusFilter !== null;

  const journalTransfersFilterStatusTitle = useMemo(
    () =>
      switchMatch(journalTransfersStatusFilter ?? "", {
        new: "Новый",
        processing: "Процессинг",
        sending: "Отправка",
        finished: "Успешно",
        default: journalTransfersStatusFilter,
      }),
    [journalTransfersStatusFilter],
  );

  const [valueDateFrom, setValueDateFrom] = useState<any>(null);
  const [debouncedValueDateFrom] = useDebouncedValue(valueDateFrom, 500);

  const [valueDateTo, setValueDateTo] = useState<any>(null);
  const [debouncedValueDateTo] = useDebouncedValue(valueDateTo, 500);

  useEffect(() => {
    dispatch(setJournalTransfersFromDateFilter(debouncedValueDateFrom ? (+debouncedValueDateFrom).toString() : null));
    dispatch(setJournalTransfersToDateFilter(debouncedValueDateTo ? (+debouncedValueDateTo).toString() : null));
  }, [debouncedValueDateFrom, debouncedValueDateTo]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search?.slice(1));
    const id = urlParams.get("id");

    if (id !== null) {
      dispatch(setJournalTransfersSearchFilter(id));
    }
  }, []);

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <div className={classes.inputWrapper}>
            <Input
              placeholder="Поиск по адресу, хэшу или ID транзакций"
              radius={8}
              size="md"
              variant="unstyled"
              icon={
                <ActionIcon size={24} ml={16} mr={8}>
                  <SearchIcon color="#868E96" />
                </ActionIcon>
              }
              style={{
                flex: "1",
              }}
              value={journalTransfersSearchFilter}
              rightSection={
                journalTransfersSearchFilter.length > 0 ? (
                  <ActionIcon
                    size={24}
                    ml="auto"
                    mr={8}
                    onClick={() => {
                      dispatch(setJournalTransfersSearchFilter(""));
                    }}>
                    <SearchCancelIcon color="#868E96" />
                  </ActionIcon>
                ) : (
                  <Button ml="auto" mr={40} compact radius={6} variant="light">
                    Найти
                  </Button>
                )
              }
              onChange={(event: { target: { value: string } }) =>
                dispatch(setJournalTransfersSearchFilter(event.target.value))
              }
            />
            <Menu shadow="md" radius={8} width={400}>
              <Menu.Target>
                <Button
                  leftIcon={<FiltersIcon width={22} />}
                  variant="gradient"
                  gradient={{ from: "#fff", to: "#fff" }}
                  className={classNames(classes.filtersButton, { isFiltered })}>
                  Фильтры
                </Button>
              </Menu.Target>
              <Menu.Dropdown p="8px 16px 16px 16px">
                <div>
                  <Select
                    label="Откуда"
                    placeholder="Wallet type FROM"
                    data={[...TransferDirections, "cold", "unknown"]}
                    onChange={(e) => dispatch(setJournalTransfersFromTypeFilter(e!))}
                  />
                </div>
                <div>
                  <Select
                    label="Куда"
                    placeholder="Wallet type TO"
                    data={[...TransferDirections, "cold", "unknown"]}
                    onChange={(e) => dispatch(setJournalTransfersToTypeFilter(e!))}
                  />
                </div>
                <div>
                  <Select
                    label="Статус"
                    placeholder="Статус транзакции"
                    data={journalTransfersStatuses}
                    onChange={(e) => dispatch(setJournalTransfersStatusFilter(e ?? ""))}
                  />
                </div>
                <div>
                  <Select
                    label="Сортировка"
                    placeholder="Сортировать по"
                    data={[{ value: "created_at", label: "Дате создания" }]}
                    onChange={(e) => dispatch(setJournalTransfersOrderByFilter(e ?? ""))}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <Switch
                    label="А-Я Порядок"
                    checked={journalTransfersIsAscOrderingFilter}
                    onChange={(event) => dispatch(setJournalTransfersIsAscOrderingFilter(event.currentTarget.checked))}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <DatesProvider settings={{ locale: "ru", firstDayOfWeek: 1, weekendDays: [0] }}>
                    <DateTimePicker
                      clearable
                      valueFormat="DD.MM.YYYY HH:mm:ss"
                      label="Созданы ОТ"
                      placeholder="Выберите время"
                      withSeconds
                      onFocus={() => {}}
                      value={valueDateFrom ? new Date(+valueDateFrom) : null}
                      mx="auto"
                      onChange={(e) => {
                        setValueDateFrom(e ? (+e).toString() : null);
                      }}
                    />
                  </DatesProvider>
                </div>
                <div>
                  <Space mt={12} />
                  <DatesProvider settings={{ locale: "ru", firstDayOfWeek: 0, weekendDays: [0] }}>
                    <DateTimePicker
                      clearable
                      valueFormat="DD.MM.YYYY HH:mm:ss"
                      label="Созданы ДО"
                      onFocus={() => {}}
                      placeholder="Выберите время"
                      withSeconds
                      value={valueDateTo ? new Date(+valueDateTo) : null}
                      mx="auto"
                      onChange={(e) => {
                        setValueDateTo(e ? (+e).toString() : null);
                      }}
                    />
                  </DatesProvider>
                </div>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Grid.Col>
      </Grid>
      {isFiltered && (
        <Group ml={0} mt={16}>
          {journalTransfersStatusFilter !== null && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setJournalTransfersStatusFilter(null))}>
              <FilterBadge field="Статус" value={journalTransfersFilterStatusTitle} />
            </div>
          )}
          {journalTransfersIsAscOrderingFilter && (
            <div
              className="cursor_pointer no_select"
              onClick={() => dispatch(setJournalTransfersIsAscOrderingFilter(false))}>
              <FilterBadge field="Порядок" value="А-Я" />
            </div>
          )}
          {journalTransfersFromDateFilter && (
            <div
              className="cursor_pointer no_select"
              onClick={() => {
                setValueDateFrom(null);
                dispatch(setJournalTransfersFromDateFilter(null));
              }}>
              <FilterBadge
                field="Созданы ОТ"
                value={dayjs(new Date(+journalTransfersFromDateFilter).toString())
                  .locale("ru")
                  .format("DD.MM.YYYY HH:mm")}
              />
            </div>
          )}
          {!!journalTransfersFromTypeFilter.length && (
            <div
              className="cursor_pointer no_select"
              onClick={() => {
                dispatch(setJournalTransfersFromTypeFilter(""));
              }}>
              <FilterBadge field="Откуда" value={journalTransfersFromTypeFilter} />
            </div>
          )}
          {!!journalTransfersToTypeFilter.length && (
            <div
              className="cursor_pointer no_select"
              onClick={() => {
                dispatch(setJournalTransfersToTypeFilter(""));
              }}>
              <FilterBadge field="Куда" value={journalTransfersToTypeFilter} />
            </div>
          )}
          {journalTransfersToDateFilter && (
            <div
              className="cursor_pointer no_select"
              onClick={() => {
                setValueDateTo(null);
                dispatch(setJournalTransfersToDateFilter(null));
              }}>
              <FilterBadge
                field="Созданы ДО"
                value={dayjs(new Date(+journalTransfersToDateFilter).toString())
                  .locale("ru")
                  .format("DD.MM.YYYY HH:mm")}
              />
            </div>
          )}
          {journalTransfersOrderByFilter.length && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setJournalTransfersOrderByFilter(""))}>
              <FilterBadge
                field="Сортировка по"
                value={switchMatch(journalTransfersOrderByFilter.toLowerCase(), {
                  created_at: "Дате создания",
                  default: journalTransfersOrderByFilter,
                })}
              />
            </div>
          )}
        </Group>
      )}
    </div>
  );
};
