import { useState } from "react";
import { Navbar, Button, Burger, Text } from "@mantine/core";
import { useJwt } from "react-jwt";
import { FeesSubRoutes, RolesKeys, Routes, WalletsSubRoutes } from "shared/definitions";
import { SelectRole } from "components/common/SelectRole";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { ReactComponent as HugIcon } from "shared/static/icons/hug.svg";
import { ReactComponent as HugRevertIcon } from "shared/static/icons/hugRevert.svg";
import { ReactComponent as UsersIcon } from "shared/static/icons/adapted/users.svg";
import { ReactComponent as FinanceIcon } from "shared/static/icons/adapted/finance.svg";
import { ReactComponent as RatesIcon } from "shared/static/icons/adapted/rates.svg";
import { ReactComponent as TransactionsIcon } from "shared/static/icons/adapted/transactions.svg";
import { useRoles } from "shared/hooks/useRoles";
import { logout } from "shared/utils/auth";
import { useAppDispatch, useAppSelector } from "store/store";
import { useClickOutside } from "@mantine/hooks";
import { toggleNavbar } from "store/slices";
import { useStyles } from "./styles";
import { NavLinks } from "./NavLinks";

const routes = {
  clients: { section: Routes.CLIENTS_SCREEN, link: Routes.CLIENTS_SCREEN, label: "Клиенты", icon: UsersIcon },
  transactions: {
    section: Routes.TRANSACTIONS_SCREEN,
    link: Routes.TRANSACTIONS_SCREEN,
    label: "Транзакции",
    icon: TransactionsIcon,
  },
  rates: { section: Routes.RATES_AND_FEES_SCREEN, link: Routes.RATES_AND_FEES_SCREEN, label: "Курсы", icon: RatesIcon },
  wallets: {
    section: Routes.WALLETS_SCREEN,
    link: `${Routes.WALLETS_SCREEN}.${WalletsSubRoutes.JOURNAL}`,
    label: "Управление кошельками",
    icon: RatesIcon,
  },
  fees: {
    section: Routes.FEES_SCREEN,
    link: `${Routes.FEES_SCREEN}.${FeesSubRoutes.DEPOSIT}`,
    label: "Комиссии",
    icon: FinanceIcon,
  },
  currencies: {
    section: Routes.TICKER_OPERATIONS_SCREEN,
    link: Routes.TICKER_OPERATIONS_SCREEN,
    label: "Управление валютами",
    icon: FinanceIcon,
  },
};

export const NavBar = () => {
  const dispatch = useAppDispatch();
  const [isOpenedNavSettings, setIsOpenedNavSettings] = useState(false);
  const refNavSettings = useClickOutside(() => setIsOpenedNavSettings(false));
  const { isOpenedNavbar, token } = useAppSelector(({ navbar, auth }) => ({
    isOpenedNavbar: navbar.isOpenedNavbar,
    token: auth.tokenData,
  }));
  const { decodedToken }: any = useJwt(token || "");
  const myEmail = decodedToken?.email ?? "";
  const { classes } = useStyles();
  const { currentRole, isFinManager, isHeadOfFinManager } = useRoles();

  let data = [];
  switch (currentRole) {
    case RolesKeys.operator:
      data.push(routes.transactions);
      break;
    default:
      data = [routes.clients, routes.transactions];
      break;
  }

  if (isFinManager || isHeadOfFinManager) {
    data = [...data, routes.rates, routes.fees, routes.wallets, routes.currencies];
  }

  return (
    <Navbar
      className={classes.navbar}
      style={{ width: isOpenedNavbar ? 240 : 64, minWidth: isOpenedNavbar ? 240 : 64 }}>
      <Navbar.Section grow>
        <a
          onClick={(e) => {
            e.preventDefault();
            dispatch(toggleNavbar());
          }}>
          <div className={classes.header}>
            <div style={{color: "#fff", marginTop: ".625rem"}}>
              {"Admin APP"}
            </div>
            {isOpenedNavbar ? (
              <div className="hugRevert">
                <HugRevertIcon />
              </div>
            ) : (
              <div className="hug">
                <HugIcon />
              </div>
            )}
          </div>
        </a>
        <NavLinks links={data} />
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        {isOpenedNavSettings && (
          <div className={classes.footerMenu} ref={refNavSettings}>
            <SelectRole />
            <Spacer size={3} />
            <div className="display_flex">
              <Burger
                color="gray"
                size="sm"
                opened={isOpenedNavSettings}
                onClick={() => setIsOpenedNavSettings(false)}
              />
              <Button compact className="margin_left_auto" onClick={logout} variant="light" radius={4} size="sm">
                Выход из системы
              </Button>
            </div>
          </div>
        )}
        <div className="w-100 display_flex">
          <div
            className={classes.footerCircle}
            onClick={() => {
              setIsOpenedNavSettings(!isOpenedNavSettings);
            }}>
            <Burger color="white" size="sm" className="margin_auto" opened={isOpenedNavSettings} />
          </div>
          {isOpenedNavbar && (
            <div>
              <Text ml={12} color="lightGray" style={{ whiteSpace: "nowrap", lineHeight: "20px" }}>
                {myEmail}
              </Text>
              <Text ml={12} color="lightGray" style={{ whiteSpace: "nowrap", lineHeight: "20px" }}>
                {currentRole}
              </Text>
            </div>
          )}
        </div>
      </Navbar.Section>
    </Navbar>
  );
};
