import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getCurrencies } from "shared/api";
import { setCurrencies } from "store/slices";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useCurrencies = () => {
  const dispatch = useAppDispatch();
  const { currencies } = useAppSelector((state) => state.currencies);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);

  useEffect(() => {
    if (!currencies.length && !fetchIsLoading) {
      setFetchIsLoading(true);
      getCurrencies()
        .then((resp: any) => {
          dispatch(setCurrencies(resp));
        })
        .catch(sentryCaptureError)
        .finally(() => setFetchIsLoading(false));
    }
  }, [currencies]);

  return {
    isLoadedCurrencies: !!currencies.length,
    currencies,
  };
};
