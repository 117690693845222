import { useCallback, useEffect, useMemo, useState } from "react";
import { TFiatFlow } from "shared/definitions";
import { TPersonalClient } from "shared/types/clients";
import {
  setDepositClient,
  setDepositStep as setDepositStepAction,
  TDepositFormState,
  resetDepositSlice,
  setFormAmount,
  setFormCurrency,
  setFormType,
  setFormFee,
  setFormBankName,
  setFormBankAddress,
  setFormAccountNumber,
  setFormBicOrSwift,
} from "store/slices";
import { useAppDispatch, useAppSelector } from "store/store";
import { useDebouncedValue } from "@mantine/hooks";
import { TOrganization } from "shared/types/organizations";

export const useDepositModalLogic = () => {
  const dispatch = useAppDispatch();
  const { client, formState, depositStep, currencies, fees } = useAppSelector((store) => ({
    ...store.modals,
    ...store.deposit,
    ...store.currencies,
  }));

  const [amount, setAmount] = useState<string>(formState.amount ?? "");
  const [debouncedAmountValue] = useDebouncedValue(amount, 1000);

  useEffect(() => {
    dispatch(setFormAmount(debouncedAmountValue));
  }, [debouncedAmountValue]);

  const fiatCurrencies = useMemo(() => {
    return currencies.filter((c) => c.network === null).map((el) => el.ticker);
  }, [currencies]);

  const setClient = useCallback((value: TPersonalClient | TOrganization | null) => {
    dispatch(setDepositClient(value));
  }, []);

  const setDepositStep = useCallback((value: typeof depositStep) => {
    dispatch(setDepositStepAction(value));
  }, []);

  const setCurrency = useCallback(
    (value: string) => {
      dispatch(setFormCurrency(value));
    },
    [formState],
  );

  const setType = useCallback(
    (value: TFiatFlow) => {
      dispatch(setFormType(value));
    },
    [formState],
  );

  const setFee = useCallback(
    (value: number) => {
      dispatch(setFormFee(value));
    },
    [formState],
  );

  const resetDeposit = useCallback(() => {
    dispatch(resetDepositSlice());
  }, []);

  const setBankName = useCallback(
    (value: string) => {
      dispatch(setFormBankName(value));
    },
    [formState],
  );

  const setBankAddress = useCallback(
    (value: string) => {
      dispatch(setFormBankAddress(value));
    },
    [formState],
  );

  const setAccountNumber = useCallback(
    (value: string) => {
      dispatch(setFormAccountNumber(value));
    },
    [formState],
  );

  const setBicOrSwift = useCallback(
    (value: string) => {
      dispatch(setFormBicOrSwift(value));
    },
    [formState],
  );

  const handleValidateDepositFormState = useCallback((fs: TDepositFormState) => {
    let result = true;
    let reason = "";
    if (Number(fs.amount) === 0) {
      result = false;
      reason = "Заполните поле суммы";
      return { result, reason };
    }
    if (Number(fs.amount) <= Number(fs.fee)) {
      result = false;
      reason = "Сумма должна привышать комиссию";
      return { result, reason };
    }
    if (fs.type === TFiatFlow.transfer) {
      if (!fs.bankAddress.length || !fs.bankName.length || !fs.bicOrSwift.length || !fs.accountNumber.length) {
        result = false;
        reason = "Заполните все поля";
        return { result, reason };
      }
    }
    return { result, reason };
  }, []);

  return {
    client,
    formState,
    depositStep,
    handleValidateDepositFormState,
    currencies: fiatCurrencies,
    fees,
    amount,
    setAmount,
    setCurrency,
    setType,
    setFee,
    setClient,
    setAccountNumber,
    setBankAddress,
    setBankName,
    setBicOrSwift,
    setDepositStep,
    resetDeposit,
  };
};
