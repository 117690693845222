import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPayin } from "shared/types/payin";

interface PayinSlice {
  payinPage: number;
  payinCountAll: number;
  payinPageSize: number;
  payinData: [] | TPayin[];
  payinSearchFilter: string;
  payinByExistFilter: string[];
  payinIsAscOrderingFilter: boolean;
  payinOrderByFilter: string;
  payinSelectionAddresses: TPayin[];
}

const initialState: PayinSlice = {
  payinPage: 1 as number,
  payinCountAll: 100 as number,
  payinPageSize: 20 as number,
  payinData: [] as [] | TPayin[],
  payinByExistFilter: [],
  payinSearchFilter: "",
  payinIsAscOrderingFilter: false,
  payinOrderByFilter: "",
  payinSelectionAddresses: [],
};

const payinSlice = createSlice({
  name: "payin",
  initialState,
  reducers: {
    setPayinCountAll(state, action: PayloadAction<number>) {
      state.payinCountAll = action.payload;
    },
    setPayinPage(state, action: PayloadAction<number>) {
      state.payinPage = action.payload;
    },
    setPayinPageSize(state, action: PayloadAction<number>) {
      state.payinPageSize = action.payload;
    },
    setPayinData(state, action: PayloadAction<[] | TPayin[]>) {
      state.payinData = action.payload;
    },
    setPayinByExistFilter(state, action: PayloadAction<string[]>) {
      state.payinByExistFilter = action.payload;
      state.payinPage = 1;
    },
    setPayinSearchFilter(state, action: PayloadAction<string>) {
      state.payinSearchFilter = action.payload;
      state.payinPage = 1;
    },
    setPayinIsAscOrderingFilter(state, action: PayloadAction<boolean>) {
      state.payinIsAscOrderingFilter = action.payload;
    },
    setPayinOrderByFilter(state, action: PayloadAction<string>) {
      state.payinOrderByFilter = action.payload;
    },
    setPayinSelectionAddresses(state, action: PayloadAction<TPayin[]>) {
      state.payinSelectionAddresses = action.payload;
    },
    resetPayinFilters(state) {
      state.payinByExistFilter = [];
      state.payinIsAscOrderingFilter = false;
      state.payinSearchFilter = "";
      state.payinOrderByFilter = "";
      state.payinPage = 1;
    },
  },
});

export const {
  setPayinData,
  setPayinPage,
  setPayinOrderByFilter,
  setPayinByExistFilter,
  setPayinIsAscOrderingFilter,
  setPayinSearchFilter,
  setPayinPageSize,
  setPayinCountAll,
  resetPayinFilters,
  setPayinSelectionAddresses,
} = payinSlice.actions;
export const payinReducer = payinSlice.reducer;
