import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUsers, GetPersonalClientsRequestProps } from "shared/api";
import { setPersonalClients, setPersonalClientsCountAll } from "store/slices";
import { treatmentPersonalClientData } from "shared/utils";

export const useGetUsers = () => {
  const dispatch = useAppDispatch();
  const {
    personalClientsSearchFilter,
    personalClientsLastNameFilter,
    personalClientsOrderBy,
    personalClientsIsAscOrdering,
    personalClientsStatusFilter,
    personalClientPage,
    personalClientsPageSize,
  } = useAppSelector(({ personalClients }) => personalClients);

  const validatedSearchFilter = personalClientsSearchFilter.length > 2 ? personalClientsSearchFilter : "";

  const getUsersRequest = useCallback(() => {
    const params: GetPersonalClientsRequestProps = {
      is_asc_ordering: personalClientsIsAscOrdering,
      page: personalClientPage,
      page_size: personalClientsPageSize,
    };

    if (personalClientsOrderBy) {
      params.order_by = personalClientsOrderBy;
    }

    if (personalClientsStatusFilter !== null) {
      params.status = personalClientsStatusFilter;
    }

    if (validatedSearchFilter.length > 2) {
      params.search = validatedSearchFilter;
    }

    return getUsers(params);
  }, [
    personalClientPage,
    validatedSearchFilter,
    personalClientsLastNameFilter,
    personalClientsOrderBy,
    personalClientsIsAscOrdering,
    personalClientsStatusFilter,
  ]);

  const treatmentUsersData = useCallback((data: any) => [...data.map(treatmentPersonalClientData)], []);

  useEffect(() => {
    getUsersRequest().then((response) => {
      if (response.data.items === null) {
        dispatch(setPersonalClients([]));
        dispatch(setPersonalClientsCountAll(0));
      } else {
        dispatch(setPersonalClients(treatmentUsersData(response.data.items)));
        dispatch(setPersonalClientsCountAll(response.data.count));
      }
    });
  }, [getUsersRequest]);

  return {
    getUsersRequest,
  };
};
