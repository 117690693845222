import { Table } from "@mantine/core";
import { useMemo } from "react";
import { TransactionTypesKeys } from "shared/definitions/transactions/types";
import { EmptyBlock } from "shared/features";
import { TTransaction } from "shared/types/transactions";
import { ExchangeListTransactionRow } from "./components/ExchangeListTransactionRow";

const TransactionsExchangeListViewTableTitles = [
  "Дата и время",
  "ID Транзакции",
  "Сумма",
  "Валюта",
  "➔",
  "Сумма",
  "Валюта",
  "Статус",
  "Public ID",
  " ",
];

type TProps = {
  data: TTransaction[];
  updateList: () => void;
};

export const TransactionsExchangeListView = ({ data, updateList }: TProps) => {
  const rows = useMemo(
    () =>
      data
        .filter((el) => el.type === TransactionTypesKeys.exchange)
        .map((element) => (
          <ExchangeListTransactionRow
            updateList={updateList}
            key={`TransactionsExchangeListView${element.transactionId}`}
            el={element}
          />
        )),
    [data],
  );

  if (!data.length) return <EmptyBlock />;

  return (
    <div>
      <Table
        sx={{ borderRadius: "16px" }}
        horizontalSpacing="sm"
        verticalSpacing="sm"
        fontSize="xs"
        highlightOnHover
        withBorder>
        <thead>
          <tr>
            {TransactionsExchangeListViewTableTitles.map((title, i) => {
              return (
                <th key={`TELVTT${title}${i}`} className="break_word">
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};
