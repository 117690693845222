import React, { FC, useMemo } from "react";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { Flex, Title } from "@mantine/core";
import { PersonalClientViewTabs } from "shared/features/PersonalClients/PersonalClientViewTabs";
import type { TClientDetails } from "shared/types/clients";

interface IProps {
  clientDetails: TClientDetails;
  tabName: string;
}

export const ClientTabHeader: FC<IProps> = ({ clientDetails, tabName }) => {
  const name = useMemo(() => {
    const nameArr = [clientDetails.lastName, clientDetails.firstName, clientDetails.patronymic];
    if (nameArr.every((e) => !e)) return "Новый клиент";
    return nameArr.filter((v) => v).join(" ");
  }, [clientDetails]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: "Клиенты", link: "/" },
          { label: tabName, link: "" },
        ]}
      />
      <Spacer size={2} />
      <Flex gap="md" align="center">
        <Title order={2}>{name}</Title>
      </Flex>
      <Spacer size={6} />
      <PersonalClientViewTabs />
      <Spacer size={5} />
    </>
  );
};
