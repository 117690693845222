import React, { useCallback, useState } from "react";
import { Tabs } from "@mantine/core";
import { State } from "router5";
import { useRoute } from "react-router5";
import { ClientScreenSubRoutes, Routes } from "shared/definitions";
import { ReactComponent as DocumentsIcon } from "shared/static/icons/adapted/docs.svg";
import { ReactComponent as FinanceIcon } from "shared/static/icons/adapted/finance_2.svg";
import { ReactComponent as LogsIcon } from "shared/static/icons/adapted/logs.svg";
import { ReactComponent as PersonalIcon } from "shared/static/icons/adapted/personal.svg";
import { ReactComponent as CommentsIcon } from "shared/static/icons/adapted/comments.svg";

type SubRouteTabValue = `${ClientScreenSubRoutes}`;

function getInitialRoute(route: State): ClientScreenSubRoutes {
  switch (route.name) {
    case `${Routes.CLIENT_SCREEN}`:
      return ClientScreenSubRoutes.ROOT;
    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.LOGS}`:
      return ClientScreenSubRoutes.LOGS;
    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.COMMENTS}`:
      return ClientScreenSubRoutes.COMMENTS;
    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.FINANCES}`:
      return ClientScreenSubRoutes.FINANCES;
    case `${Routes.CLIENT_SCREEN}.${ClientScreenSubRoutes.DOCUMENTS}`:
      return ClientScreenSubRoutes.DOCUMENTS;
    default:
      return ClientScreenSubRoutes.ROOT;
  }
}

function getRouteName(value: SubRouteTabValue) {
  if (value === ClientScreenSubRoutes.ROOT) {
    return Routes.CLIENT_SCREEN;
  }
  return [Routes.CLIENT_SCREEN, value].filter((v) => !!v).join(".");
}

export const PersonalClientViewTabs = React.memo(() => {
  const { route, router } = useRoute();
  const [valuePageContextSegmentedControl, setValuePageContextSegmentedControl] = useState<SubRouteTabValue>(
    getInitialRoute(route),
  );

  const handleTabClick = useCallback(
    (value: SubRouteTabValue) => {
      const routeName = getRouteName(value);
      router.navigate(routeName, { cid: route.params.cid });
      setValuePageContextSegmentedControl(value);
    },
    [router, route],
  );

  return (
    <Tabs color="violet" value={valuePageContextSegmentedControl} onTabChange={handleTabClick} defaultValue="test">
      <Tabs.List>
        <Tabs.Tab value={`${ClientScreenSubRoutes.ROOT}`} icon={<PersonalIcon height={16} />}>
          Персональные данные
        </Tabs.Tab>
        <Tabs.Tab value={`${ClientScreenSubRoutes.FINANCES}`} icon={<FinanceIcon height={16} />}>
          Финансы
        </Tabs.Tab>
        <Tabs.Tab value={`${ClientScreenSubRoutes.LOGS}`} icon={<LogsIcon height={16} />}>
          Логи клиента
        </Tabs.Tab>
        <Tabs.Tab value={`${ClientScreenSubRoutes.COMMENTS}`} icon={<CommentsIcon height={16} />}>
          Служебная информация
        </Tabs.Tab>
        <Tabs.Tab value={`${ClientScreenSubRoutes.DOCUMENTS}`} icon={<DocumentsIcon height={16} />}>
          Документы
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
});
