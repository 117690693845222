// update responsible person from organization

import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export function updateResponsiblePerson(params: {
  id: string;
  responsible_person_user_id: string;
  body: {
    reason: string;
    is_financial_operations_allowed: boolean;
    position: string;
  };
}) {
  return axiosInstance(
    postConfig(`/users/organizations/${params.id}/responsible_person/${params.responsible_person_user_id}`, {
      ...params.body,
    }),
  );
}
