import React, { useCallback, useEffect, useState } from "react";
import { TClientDetails } from "shared/types/clients";
import { TOrganizationDetails } from "shared/types/organizations";
import { addResponsiblePerson, getUser, TPropsAddResponsiblePerson } from "shared/api";
import { Button, Checkbox, createStyles, Divider, Grid, Modal, Space, TextInput, Title } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "store/store";
import { setAddResponsiblePersonModalIsOpened } from "store/slices";
import { switchMatch } from "shared/utils";
import cogoToast from "cogo-toast";
import { ClientStatusKeys } from "shared/definitions/levels/clientStatus";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { useGetOrganizationClientDetails } from "../../../../Clients/Organizations/useGetOrganizationClientDetails";

const useStyles = createStyles((theme) => ({
  mainTitle: {
    backgroundColor: theme.colors.violet[1],
    color: theme.colors.violet[6],
    padding: "12px 24px",
    marginBottom: "24px",
  },
  rejectResponse: {
    backgroundColor: theme.colors.red[1],
    color: theme.colors.red[6],
    padding: "12px 24px",
    marginBottom: "24px",
  },
  successResponse: {
    backgroundColor: theme.colors.green[1],
    color: theme.colors.green[6],
    padding: "12px 24px",
    marginBottom: "24px",
  },
}));

const personAddHandle = (props: TPropsAddResponsiblePerson) => {
  return addResponsiblePerson(props).catch(sentryCaptureError);
};

const useAddResponsiblePersonModalLogic = (organizationDetails: TOrganizationDetails) => {
  const { addResponsiblePersonModalIsOpened } = useAppSelector((state) => ({
    addResponsiblePersonModalIsOpened: state.modals.addResponsiblePersonModalIsOpened,
  }));

  const [modalStep, setModalStep] = useState<"init" | "approve" | "success" | "reject">("init");
  const [loadedUsers, setLoadedUsers] = useState<TClientDetails[]>([]);
  const [usersFindInputValue, setUsersFindInputValue] = useState("");
  const [positionInputValue, setPositionInputValue] = useState("");
  const [reasonInputValue, setReasonInputValue] = useState("");
  const [isApproved, setIsApproved] = useState(false);

  const setUserHandle = useCallback(
    (id: string) => {
      return getUser({ id })
        .then((res) => {
          if (res.status === 200) {
            if (organizationDetails.responsiblePersons.filter((person) => person.id === id).length) {
              cogoToast.error("Уже привязан. Попробуйте обновить страницу.");
              return;
            }
            if ((res.data as TClientDetails).status === ClientStatusKeys.new) {
              cogoToast.error("Невозможно привязать пользователя, у которого status=new");
              return;
            }
            setLoadedUsers([{ ...res.data }]);
            setModalStep("approve");
          } else {
            cogoToast.error("Пользователь не найден");
          }
        })
        .catch(sentryCaptureError);
    },
    [organizationDetails],
  );

  const addPersonHandler = useCallback(() => {
    if (!loadedUsers.length) return;

    return personAddHandle({
      id: organizationDetails.id,
      body: {
        position: positionInputValue,
        reason: reasonInputValue,
        responsible_person_user_id: loadedUsers[0].id,
      },
    });
  }, [organizationDetails, reasonInputValue, positionInputValue, loadedUsers]);

  const clearFilters = useCallback(() => {
    setModalStep("init");
    setLoadedUsers([]);
    setUsersFindInputValue("");
    setPositionInputValue("");
    setReasonInputValue("");
    setIsApproved(false);
  }, []);

  useEffect(() => {
    return () => clearFilters();
  }, []);

  return {
    addResponsiblePersonModalIsOpened,
    modalStep,
    setModalStep,
    clearFilters,
    loadedUsers,
    usersFindInputValue,
    setUsersFindInputValue,
    positionInputValue,
    setPositionInputValue,
    reasonInputValue,
    setReasonInputValue,
    isApproved,
    setUserHandle,
    setIsApproved,
    setLoadedUsers,
    addPersonHandler,
  };
};

export const AddResponsiblePersonModal = (props: { detail: TOrganizationDetails }) => {
  const { classes } = useStyles();
  const { getOrganizationHandle } = useGetOrganizationClientDetails({ cid: props.detail.id });
  const dispatch = useAppDispatch();
  const logic = useAddResponsiblePersonModalLogic(props.detail);
  return (
    <div>
      <Modal
        size="lg"
        padding="xl"
        title={switchMatch(logic.modalStep, {
          approve: "Проверьте и подтвердите привязку ОДЛ",
          success: "Успешно",
          reject: "Не удалось привязать сотрудника к организации",
          default: "Привязать ОДЛ к организации",
        })}
        opened={logic.addResponsiblePersonModalIsOpened}
        onClose={() => {
          dispatch(setAddResponsiblePersonModalIsOpened(false));
          logic.clearFilters();
        }}>
        {(logic.modalStep === "init" || logic.modalStep === "approve") && (
          <div className={classes.mainTitle}>
            <Title order={3}>
              {props.detail.fullName}
              {"  "}
              {props.detail.registrationNumber}
            </Title>
          </div>
        )}
        {logic.modalStep === "init" && (
          <div>
            <Grid columns={12}>
              <Grid.Col span={4}>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  Internal_id клиента
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  value={logic.usersFindInputValue}
                  onChange={({ target: { value } }) => logic.setUsersFindInputValue(value)}
                  size="xs"
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  Должность
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  value={logic.positionInputValue}
                  onChange={({ target: { value } }) => logic.setPositionInputValue(value)}
                  size="xs"
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  Комментарий
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  value={logic.reasonInputValue}
                  onChange={({ target: { value } }) => logic.setReasonInputValue(value)}
                  size="xs"
                />
              </Grid.Col>
            </Grid>
            <div>
              <Space mt={24} />

              <Grid justify="flex-end">
                <Button
                  mr={8}
                  disabled={
                    logic.usersFindInputValue.length !== 36 ||
                    !logic.reasonInputValue.length ||
                    !logic.positionInputValue.length
                  }
                  onClick={() => {
                    if (!logic.loadedUsers.length) {
                      logic.setUserHandle(logic.usersFindInputValue);
                    }
                  }}
                  color="violet">
                  Продолжить
                </Button>
              </Grid>
            </div>
          </div>
        )}
        {logic.modalStep === "approve" && (
          <div>
            <Grid columns={12}>
              <Grid.Col span={4}>{logic.loadedUsers[0].publicId ?? "---"}</Grid.Col>
              <Grid.Col span={8}>
                {logic.loadedUsers[0].firstName ?? "---"}
                {"  "}
                {logic.loadedUsers[0].lastName ?? "---"}
                {"  "}
                {logic.loadedUsers[0].patronymic}
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider my="xs" />
                <Checkbox
                  label="Подтверждаю привязку данных сотрудников"
                  checked={logic.isApproved}
                  onChange={() => logic.setIsApproved(!logic.isApproved)}
                />
              </Grid.Col>
            </Grid>
            <Space mt={24} />
            <Grid justify="flex-end">
              <Button
                mr={8}
                variant="subtle"
                onClick={() => {
                  logic.setIsApproved(false);
                  logic.setLoadedUsers([]);
                  logic.setModalStep("init");
                }}
                color="gray">
                Назад
              </Button>
              <Button
                mr={8}
                disabled={!logic.loadedUsers.length || !logic.isApproved}
                onClick={() => {
                  logic.addPersonHandler()?.then((res: any) => {
                    if (res.status === 200) {
                      logic.setModalStep("success");
                      getOrganizationHandle();
                    } else {
                      if (res?.data?.error) {
                        cogoToast.error(res.data.error);
                      }
                      logic.setModalStep("reject");
                    }
                  });
                }}
                color="violet">
                Привязать
              </Button>
            </Grid>
          </div>
        )}
        {logic.modalStep === "reject" && (
          <div>
            <div className={classes.rejectResponse}>Ошибка привязки. Попробуйте снова.</div>
            <Space mt={24} />
            <Grid justify="flex-end">
              <Button
                mr={8}
                variant="subtle"
                onClick={() => {
                  dispatch(setAddResponsiblePersonModalIsOpened(false));
                  logic.clearFilters();
                }}
                color="gray">
                Закрыть
              </Button>
            </Grid>
          </div>
        )}
        {logic.modalStep === "success" && (
          <div>
            <div className={classes.successResponse}>Сотрудники привязаны к организации!</div>
            <Space mt={24} />
            <Grid justify="flex-end">
              <Button
                mr={8}
                variant="subtle"
                onClick={() => {
                  dispatch(setAddResponsiblePersonModalIsOpened(false));
                }}
                color="gray">
                Закрыть
              </Button>
            </Grid>
          </div>
        )}
      </Modal>
    </div>
  );
};
