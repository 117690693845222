import React, { FC, ReactNode } from "react";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { useRoles } from "shared/hooks/useRoles";
import { useFees } from "shared/hooks/useFees";
import { LoadingOverlay } from "@mantine/core";

interface IProps {
  children?: ReactNode | ReactNode[];
}

export const FeesPageContainer: FC<IProps> = ({ children }) => {
  const { isFinManager, isHeadOfFinManager } = useRoles();
  const { isLoadedCurrencies } = useFees();

  if (!(isFinManager || isHeadOfFinManager))
    return (
      <Padding horizontal={3} vertical={3}>
        Доступ запрещён
      </Padding>
    );

  if (!isLoadedCurrencies) {
    return <LoadingOverlay visible />;
  }

  return (
    <Padding horizontal={4} vertical={3}>
      {children}
    </Padding>
  );
};
