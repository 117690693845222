import { createStyles, Divider, SegmentedControl, Title, Paper, ActionIcon, Space, Select } from "@mantine/core";
import React, { useState } from "react";
import { switchMatch } from "shared/utils";
import {
  FieldsContentText,
  FieldsContentTextarea,
  FieldsEditable,
  FieldsWrapper,
  FieldWrapper,
} from "components/ui/fields";
import { ReactComponent as EditIcon } from "shared/static/icons/adapted/edit.svg";
import cogoToast from "cogo-toast";

const FieldsPlayground = () => {
  const [textareaTextExample, setTextareaTextExample] = useState("");

  return (
    <div>
      <Paper p="24px" w={700} radius={12} shadow="lg">
        <FieldsWrapper>
          <FieldWrapper
            label="Длинный лейбл"
            labelAfterContent={
              <ActionIcon variant="transparent" size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <div>123</div>
          </FieldWrapper>
          <FieldWrapper
            label="Длинный лейбл"
            labelAfterContent={
              <ActionIcon variant="transparent" size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <FieldsContentText isFocused text="Тестовый текст" />
          </FieldWrapper>
          <FieldWrapper
            label="Фамилия"
            labelAfterContent={
              <ActionIcon size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <FieldsContentText isCopiable={false} isErrored text="Тестовый текст" />
          </FieldWrapper>
        </FieldsWrapper>
        <Space mt={24} />
        <FieldsWrapper>
          <FieldWrapper
            label="Длинный лейбл"
            labelAfterContent={
              <ActionIcon size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <FieldsContentText text="Миллионы людей пользуются этой картой как единственным в мире ресурсом содержащим полный обьем информации о космических объектах " />
          </FieldWrapper>
        </FieldsWrapper>
        <Space mt={24} />
        <FieldsWrapper>
          <FieldWrapper
            label="Текст"
            labelAfterContent={
              <ActionIcon size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <FieldsContentText
              inline
              text="Миллионы людей пользуются этой картой как единственным в мире ресурсом содержащим полный обьем информации о космических объектах "
            />
          </FieldWrapper>
        </FieldsWrapper>
        <Space mt={24} />
        <FieldsWrapper>
          <FieldWrapper
            label="Текст"
            labelAfterContent={
              <ActionIcon size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <FieldsContentTextarea
              isFocused
              handleClear={() => {
                setTextareaTextExample("");
              }}
              handleSave={() => {}}
              text={textareaTextExample}
              onChange={(v: any) => {
                setTextareaTextExample(v.currentTarget.value);
              }}
            />
          </FieldWrapper>
        </FieldsWrapper>

        <Space mt={24} />

        <FieldsWrapper>
          <FieldWrapper
            label="Текст"
            labelAfterContent={
              <ActionIcon size={20} ml={12} mr={12}>
                <EditIcon color="#868E96" />
              </ActionIcon>
            }>
            <Select
              variant="unstyled"
              data={["React", "Angular", "Svelte", "Vue"]}
              placeholder="Pick one"
              radius={0}
              size="md"
              sx={{
                "width": "100%",
                "& input": {
                  height: "46px",
                  fontSize: "16px !important",
                  fontFamily: "Roboto",
                  lineHeight: "46px",
                  boxSizing: "border-box",
                  padding: "0 16px",
                },
              }}
            />
          </FieldWrapper>
        </FieldsWrapper>

        <Space mt={50} />
        <FieldsWrapper>
          <FieldsEditable
            text={textareaTextExample}
            inline={false}
            setTextFn={setTextareaTextExample}
            label="label"
            handleSave={() => {
              cogoToast.success("save");
            }}
          />
        </FieldsWrapper>
      </Paper>
    </div>
  );
};

const useStyles = createStyles(() => ({
  wrapper: {
    background: "#bbb",
    minHeight: "100vh",
    width: "100%",
    boxSizing: "border-box",
    padding: "30px 100px",
  },
}));

export const Playground = () => {
  const initialSegmentedData = [
    { label: "Fields", value: "fields" },
    { label: "Filters", value: "filters" },
    { label: "Tables", value: "tables" },
    { label: "Docs", value: "docs" },
    { label: "Knobs", value: "knobs" },
    { label: "Tabs", value: "tabs" },
  ];
  const [segment, setSegment]: any = useState(initialSegmentedData[0].value);
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Title>Dev test playground</Title>
      <SegmentedControl
        mt={16}
        value={segment}
        onChange={setSegment}
        size="md"
        radius={8}
        data={initialSegmentedData}
      />
      <Divider mb={30} mt={24} />
      {switchMatch(segment, {
        fields: <FieldsPlayground />,
        default: <FieldsPlayground />,
      })}
    </div>
  );
};
