import { FieldsContentText, FieldsContentTextarea, FieldWrapper } from "components/ui/fields";
import { ActionIcon } from "@mantine/core";
import { ReactComponent as EditIcon } from "shared/static/icons/adapted/edit.svg";
import React, { useState } from "react";

type FieldsEditableProps = {
  text: string;
  label: string;
  setTextFn: (val: string) => void;
  onChangeFn?: (val: string) => void;
  handleSave: any;
  inline: boolean;
  textareaProps?: any;
  fieldWrapperProps?: any;
};

export const FieldsEditable = (props: FieldsEditableProps) => {
  const { onChangeFn, text, setTextFn, label, textareaProps = {}, fieldWrapperProps = {}, inline = false } = props;
  const [isEdited, setIsEdited] = useState(false);
  const [initialText, setInitialText] = useState(text);
  return (
    <FieldWrapper
      label={label}
      labelAfterContent={
        <ActionIcon size={20} ml={12} mr={12} onClick={() => setIsEdited(true)}>
          <EditIcon color="#868E96" />
        </ActionIcon>
      }
      {...fieldWrapperProps}>
      {isEdited ? (
        <FieldsContentTextarea
          isFocused
          text={text}
          handleClear={() => {
            setTextFn(initialText);
            setIsEdited(false);
          }}
          handleSave={() => {
            setIsEdited(false);
            setInitialText(text);
            props.handleSave();
          }}
          onChange={(e: any) => {
            if (onChangeFn) {
              onChangeFn(e.currentTarget.value);
              setTextFn(e.currentTarget.value);
            } else {
              setTextFn(e.currentTarget.value);
            }
          }}
          textareaProps={{ ...textareaProps, autosize: !inline, maxRows: inline ? 1 : 6 }}
        />
      ) : (
        <FieldsContentText inline={inline} text={text} />
      )}
    </FieldWrapper>
  );
};
