type TAppEnv = {
  REACT_APP_BAPI_URL: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_SENTRY_ENVIRONMENT: string;
};

// @ts-ignore
const SERVER_ENV = typeof window.SERVER_ENV === "object" ? window.SERVER_ENV : {} as Record<string, string>;

export const APP_ENV: TAppEnv = {
  REACT_APP_BAPI_URL: SERVER_ENV.REACT_APP_BAPI_URL ?? process.env.REACT_APP_BAPI_URL,
  REACT_APP_SENTRY_DSN: SERVER_ENV.REACT_APP_SENTRY_DSN ?? process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT: SERVER_ENV.REACT_APP_SENTRY_ENVIRONMENT ?? process.env.REACT_APP_SENTRY_ENVIRONMENT,
};

