import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TRate } from "shared/types/currencies";
import cn from "classnames";
import { ActionIcon, Tooltip, Loader, TextInput } from "@mantine/core";
import { Check, X } from "tabler-icons-react";
import { numberInputFormatToPrecision } from "shared/utils/numberInputFormatToPrecision";
import styles from "./styles.module.scss";
import { TFocusedInput, TRateInputType, TSubmitFN } from "./types";

// todo: push backend devs to add currency type to currencies
const FIAT_CURRENCIES = new Set(["USD", "BYN", "RUB"]);

interface IProps {
  type: TRateInputType;
  rateData: TRate;
  defaultValue: string;
  focusedInput: TFocusedInput;
  setFocusedInput: React.Dispatch<SetStateAction<TFocusedInput>>;
  submit: TSubmitFN;
}
// todo: replace TextInput with SubmittableNumberInput
export const RateGroup: FC<IProps> = ({ type, rateData, defaultValue, focusedInput, setFocusedInput, submit }) => {
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const numValue = useMemo(() => (value !== undefined && Number.isFinite(+value) ? +value : undefined), [value]);

  const prevValueRef = useRef(defaultValue);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isFocusedFromInput, setFocusedFromInput] = useState(false);

  const handleSubmitRef = useRef<() => void | Promise<void>>(() => undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  // memo constants
  const isActive = useMemo(() => {
    return isFocusedFromInput && focusedInput?.type === type && focusedInput.id === rateData.id;
  }, [focusedInput, rateData]);

  const precision = useMemo(() => {
    const isFiatPair = FIAT_CURRENCIES.has(rateData.tickerQuoted);
    return isFiatPair ? 5 : 9;
  }, [rateData.tickerQuoted]);

  const isSubmitButtonDisabled = useMemo(() => {
    return loading || numValue === +prevValueRef.current;
  }, [numValue, loading]);

  // callbacks
  const handleCancel = useCallback(() => {
    setFocusedInput(undefined);
    setFocusedFromInput(false);

    inputRef.current?.blur();

    if (!(focusedInput?.type === type && focusedInput.id === rateData.id)) {
      setValue(prevValueRef.current);
    }
  }, [setFocusedInput]);

  const handleFocus = useCallback(() => {
    setFocusedFromInput(true);

    setFocusedInput((v) => {
      if (v?.type === type && v?.id === rateData.id) return v;
      return { type, id: rateData.id };
    });
  }, [setFocusedInput, type, rateData]);

  // effects
  // submit function effect
  useEffect(() => {
    handleSubmitRef.current = async () => {
      if (isSubmitButtonDisabled) return;

      if (!focusedInput || !numValue || !Number.isFinite(numValue) || numValue <= 0) {
        setFocusedInput(undefined);
        return setError("Ошибка в поле");
      }

      setLoading(true);
      const res = await submit(type, numValue);
      setLoading(false);

      if (res) {
        prevValueRef.current = numValue?.toString();
        setFocusedInput(undefined);
        inputRef.current?.blur();
      }
    };
  }, [type, numValue, setFocusedInput, submit, focusedInput, isSubmitButtonDisabled]);

  // keyboard event listener to handle enter and escape keys
  useEffect(() => {
    if (!isActive || !focusedInput) return;

    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSubmitRef.current();
      } else if (e.key === "Escape") {
        handleCancel();
        window.removeEventListener("keydown", keyPressHandler);
      }
    };

    window.addEventListener("keydown", keyPressHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, [isActive, focusedInput, handleCancel]);

  // clearing the error if the value has changed
  useEffect(() => setError(null), [value]);

  return (
    <div className={styles.rateRowButtonContainer}>
      <div>{type === "buy" ? "Покупка" : "Продажа"}</div>
      <div>{rateData.tickerQuoted}</div>

      <Tooltip label={error} position="right" withArrow opened={!!error && !isActive}>
        <Tooltip
          label={"Значение не сохранено"}
          position="top"
          withArrow
          opened={!error && !isActive && +prevValueRef.current !== numValue}>
          <div
            className={cn(styles.inputContainer, {
              [styles.inputContainerActive]: isActive,
              [styles.inputContainerError]: !!error && !isActive,
            })}>
            <TextInput
              ref={inputRef}
              value={value}
              onChange={({ target }) => setValue(numberInputFormatToPrecision(target.value, precision))}
              onFocus={handleFocus}
              onBlur={() => setFocusedFromInput(false)}
              styles={{ root: { height: "100%" }, wrapper: { height: "100%" } }}
            />

            <div className={styles.inputControls} onClick={(e) => e.stopPropagation()}>
              <ActionIcon size="xs" onClick={() => handleCancel()}>
                <X />
              </ActionIcon>

              <ActionIcon
                disabled={isSubmitButtonDisabled}
                variant="filled"
                color="violet"
                size="xs"
                onClick={handleSubmitRef.current}>
                <Check />
              </ActionIcon>
            </div>

            <div className={cn(styles.loader, { [styles.loaderActive]: loading })}>
              <Loader size="xs" />
            </div>
          </div>
        </Tooltip>
      </Tooltip>
    </div>
  );
};
