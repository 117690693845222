import { useAppSelector } from "store/store";
import { RolesKeys } from "shared/definitions";

export const useRoles = () => {
  return useAppSelector(({ auth: { currentRole } }) => {
    return {
      isCompliance: RolesKeys.compliance === currentRole,
      isOperator: RolesKeys.operator === currentRole,
      isFinManager: RolesKeys.finManager === currentRole || RolesKeys.headOfFinManager === currentRole,
      isHeadOfFinManager: RolesKeys.headOfFinManager === currentRole,
      isSupport: RolesKeys.support === currentRole,
      currentRole,
    };
  });
};
