import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Select, Table, Title, Text, Box } from "@mantine/core";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { formatAmount } from "shared/utils/formatNumber";
import { useCurrencyRates } from "shared/hooks/useCurrencyRates";
import { ReactComponent as SCVIcon } from "shared/static/icons/adapted/scv.svg";
import { useGetClientBalances } from "shared/hooks/useGetClientBalances";
import { FinanceCard } from "components/common/FinanceCard";
import { useCheckIsFiat } from "shared/hooks/useCheckIsFiat";
import styles from "./ClientFinances.module.scss";

const tableCols = ["Валюта", "Баланс", "Сумма в"];

export const ClientBalances = (props: { cid: string }) => {
  const { cid } = props;
  const { clientBalances, isClientBalancesLoaded } = useGetClientBalances(cid);
  const { rates, ratePricesMatrix } = useCurrencyRates();
  const isFiat = useCheckIsFiat();
  const [selectedCurrency, setSelectedCurrency] = useState<string>();

  const currencyOptions = useMemo(() => {
    if (!isClientBalancesLoaded) return [];
    return (clientBalances ?? []).map(({ ticker }) => ({ value: ticker, label: ticker }));
  }, [clientBalances, isClientBalancesLoaded]);

  const tableData = useMemo(() => {
    if (!clientBalances || !ratePricesMatrix || !selectedCurrency || rates.length === 0) return [];

    return clientBalances.map(({ ticker, balance }) => {
      const price = ratePricesMatrix[ticker][selectedCurrency];

      return {
        currency: ticker,
        balance: parseFloat(balance),
        balanceInSelectedCurrency: price ? parseFloat(balance) * price! : NaN, // NaN means "–" for formatter
      };
    });
  }, [rates, clientBalances, ratePricesMatrix, selectedCurrency]);

  const totalUserBalance = useMemo(() => {
    if (!clientBalances || !selectedCurrency || rates.length === 0 || !ratePricesMatrix) {
      return NaN; // NaN means "–" for formatter
    }

    return clientBalances.reduce((acc, { ticker, balance }) => {
      const price = ratePricesMatrix[ticker][selectedCurrency];

      if (price === undefined || Number.isNaN(parseFloat(balance))) return acc;

      return acc + parseFloat(balance) * price;
    }, 0);
  }, [selectedCurrency, rates, ratePricesMatrix, clientBalances, selectedCurrency]);

  const canDownloadCSV = useMemo(() => {
    return clientBalances && ratePricesMatrix;
  }, [tableData]);

  const handleDownloadCSV = useCallback(() => {
    if (!canDownloadCSV) return;

    const date = new Date().toJSON();

    const dataObject = clientBalances!.map(({ ticker, balance }) => ({
      id: cid,
      ticker,
      balance,
      balanceUSD: +balance * ratePricesMatrix![ticker].USD,
      balanceBYN: +balance * ratePricesMatrix![ticker].BYN,
      date,
    }));

    const tableHead = Object.keys(dataObject[0]).join(", ");
    const tableContent = dataObject.map((o) => Object.values(o).join(", "));

    const csvString = [tableHead].concat(tableContent).join("\n ");

    const mediaType = "data:application/octet-stream,";

    const a = document.createElement("a");
    a.href = mediaType + encodeURI(csvString);
    a.download = `balances_${cid}_${date}.csv`;
    a.click();
  }, [cid, tableData, canDownloadCSV]);

  useEffect(() => {
    if (currencyOptions.length > 0) {
      setSelectedCurrency(currencyOptions[0].value);
    }
  }, [currencyOptions]);

  return (
    <FinanceCard>
      <div className={styles.headContainer}>
        <Title order={4} className={styles.headTitle}>
          Балансы по валютам
        </Title>

        <div className={styles.headContainerRightColumn}>
          <Select
            size={"sm"}
            className={styles.headSelect}
            variant={"default"}
            data={currencyOptions}
            value={selectedCurrency}
            onChange={(v) => {
              if (v) setSelectedCurrency(v);
            }}
          />
          <Button
            leftIcon={
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                }}>
                <SCVIcon />
              </Box>
            }
            variant={"default"}
            onClick={handleDownloadCSV}
            disabled={!canDownloadCSV}>
            Скачать CSV
          </Button>
        </div>
      </div>

      <Spacer size={4} />

      {selectedCurrency && (
        <div>
          <Text fz={"sm"} c={"grayUI.6"} fw={500}>
            Общий баланс
          </Text>
          <Text fz={32} c={"black"} fw={700}>
            {formatAmount({
              value: totalUserBalance,
              postfix: selectedCurrency,
              isFiat: isFiat(selectedCurrency),
            })}
          </Text>
        </div>
      )}

      <Spacer size={4} />

      <Table className={styles.table} horizontalSpacing="md" verticalSpacing="sm" fontSize="sm" withBorder={false}>
        <thead>
          <tr>
            <th>{tableCols[0]}</th>
            <th>{tableCols[1]}</th>
            <th>{`${tableCols[2]} ${selectedCurrency ?? ""}`}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(({ currency, balance, balanceInSelectedCurrency }, index) => (
            <tr key={`table-row#${currency}#${index}`}>
              <td>{currency}</td>
              <td>{formatAmount({ value: balance, prefix: currency, isFiat: isFiat(currency) })}</td>
              <td>
                {selectedCurrency &&
                  formatAmount({
                    value: balanceInSelectedCurrency,
                    prefix: selectedCurrency,
                    isFiat: isFiat(selectedCurrency),
                  })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </FinanceCard>
  );
};
