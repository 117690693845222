import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrenciesSlice {
  isOpenedNavbar: boolean;
}

const initialState: CurrenciesSlice = {
  isOpenedNavbar: !!localStorage.getItem("isOpenedNavbar"),
};

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    openNavbar(state) {
      state.isOpenedNavbar = true;
      localStorage.setItem("isOpenedNavbar", "1");
    },
    closeNavbar(state) {
      state.isOpenedNavbar = false;
      localStorage.removeItem("isOpenedNavbar");
    },
    toggleNavbar(state) {
      if (state.isOpenedNavbar) {
        localStorage.removeItem("isOpenedNavbar");
      } else {
        localStorage.setItem("isOpenedNavbar", "1");
      }
      state.isOpenedNavbar = !state.isOpenedNavbar;
    },
  },
});

export const { openNavbar, closeNavbar, toggleNavbar } = navbarSlice.actions;
export const navbarReducer = navbarSlice.reducer;
