import React, { memo, useMemo } from "react";
import { TClientViewProps } from "shared/types/clients";
import { Grid, Group, LoadingOverlay, Title } from "@mantine/core";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import { FieldTreatment } from "components/common/FieldTreatment";
import { TypicalTextField } from "components/common/TypicalTextField";
import dayjs from "dayjs";
import { useGetOrganizationClientDetails } from "../useGetOrganizationClientDetails";

export const ClientOrganizationViewDetails = memo(({ cid, gridColumns = 12 }: TClientViewProps) => {
  const { organizationClientDetails } = useGetOrganizationClientDetails({ cid });

  const blockIds = useMemo(
    () => (
      <Grid.Col span={12}>
        <FieldsWrapper>
          <FieldWrapper label="Public ID">
            <FieldsContentText inline text={organizationClientDetails?.publicId || ""} />
          </FieldWrapper>
          <FieldWrapper label="Internal ID">
            <FieldsContentText inline text={organizationClientDetails?.id || ""} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
    ),
    [organizationClientDetails],
  );

  const blockEmail = useMemo(
    () => <TypicalTextField label="E-mail" text={organizationClientDetails?.email || ""} />,
    [organizationClientDetails],
  );

  const blockNames = useMemo(
    () => (
      <Grid.Col span={12}>
        <FieldsWrapper>
          <FieldWrapper label="Наименование">
            <FieldsContentText inline text={organizationClientDetails?.fullName || "---"} />
          </FieldWrapper>
        </FieldsWrapper>
      </Grid.Col>
    ),
    [organizationClientDetails],
  );

  const blockRegistrationDate = useMemo(
    () => (
      <TypicalTextField
        label="Дата регистрации"
        text={dayjs(organizationClientDetails?.createdAt.toString()).format("DD.MM.YYYY HH:mm:ss")}
      />
    ),
    [organizationClientDetails],
  );

  const blockLastVisit = useMemo(
    () => (
      <TypicalTextField
        label="Последний вход"
        text={
          organizationClientDetails?.lastVisitedAt
            ? dayjs(organizationClientDetails?.lastVisitedAt.toString()).format("DD.MM.YYYY HH:mm:ss")
            : "---"
        }
      />
    ),
    [organizationClientDetails],
  );

  const blockPageHeader = useMemo(
    () => (
      <div>
        <div>
          <Title order={3}>Общая информация о клиенте</Title>
        </div>
        <Group mb={16} mt={12}>
          <FieldTreatment name="clientStatus">{organizationClientDetails?.status || ""}</FieldTreatment>
        </Group>
      </div>
    ),
    [organizationClientDetails],
  );

  if (organizationClientDetails === null) return <LoadingOverlay visible />;

  return gridColumns === 24 ? (
    <div>
      <div>{blockPageHeader}</div>
      <Grid columns={2}>
        <Grid.Col span={1}>
          <Grid columns={gridColumns / 2}>
            {blockIds}
            {blockNames}
            {blockRegistrationDate}
          </Grid>
        </Grid.Col>
        <Grid.Col span={1}>
          <Grid columns={gridColumns / 2}>
            {blockEmail}
            {blockLastVisit}
          </Grid>
        </Grid.Col>
      </Grid>
    </div>
  ) : (
    <Grid columns={gridColumns}>
      {blockIds}
      {blockEmail}
      {blockNames}
    </Grid>
  );
});
