import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";

export enum TransactionTypes {
  withdraw = "Вывод",
  deposit = "Пополнение",
  exchange = "Обмен",
}

export enum TransactionFiatFlowTypes {
  transfer = "Перевод",
  cash_register = "Касса",
}

export const TransactionTypesKeys = {
  withdraw: getEnumKeyByEnumValue(TransactionTypes, TransactionTypes.withdraw),
  deposit: getEnumKeyByEnumValue(TransactionTypes, TransactionTypes.deposit),
  exchange: getEnumKeyByEnumValue(TransactionTypes, TransactionTypes.exchange),
};