import { DocumentFile } from "components/common/DocumentFile";

type TProps = {
  list: { name: string, isOld: boolean }[];
  cid: string;
  showEmptyPlaceholder?: boolean;
};

export function FilesList(props: TProps) {
  if (props.showEmptyPlaceholder && props.list.length === 0) {
    return <DocumentFile empty cid="" link="" isOld={false} />;
  }

  return (
    <>
      {props.list.map((el, key) => {
        return <DocumentFile key={key} cid={props.cid} link={el.name} isOld={el.isOld} />;
      })}
    </>
  );
}
