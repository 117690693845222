// add responsible person to organization

import { axiosInstance } from "../../definitions/api";
import { postConfig } from "../config";

export type TPropsAddResponsiblePerson = {
  id: string;
  body: {
    position: string;
    reason: string;
    responsible_person_user_id: string;
  };
};

export function addResponsiblePerson(params: TPropsAddResponsiblePerson) {
  return axiosInstance(
    postConfig(`/users/organizations/${params.id}/responsible_person`, {
      ...params.body,
    }),
  );
}
