import { Button, Flex, Modal, Space, Textarea, Text } from "@mantine/core";
import { useState } from "react";
import { updateTickerOperations } from "shared/api/currencies/updateTickerOperations";
import { useTickerOperations } from "shared/hooks/useTickerOperations";

type TProps = {
  ids: number[];
  allowed: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void;
};

export function UpdateDialog(props: TProps) {
  const [text, setText] = useState("");
  const { updateData } = useTickerOperations();

  return (
    <Modal padding="lg" title={<Text fw={500}>{props.title}</Text>} onClose={props.onClose} opened>
      <Textarea label="Обоснование" onChange={(e) => setText(e.target.value)} value={text} />
      <Space h="md" />
      <Flex gap="sm" justify="flex-end">
        <Button onClick={props.onClose} variant="default">
          Отмена
        </Button>
        <Button
          disabled={!text}
          onClick={async () => {
            await updateTickerOperations(props.ids, props.allowed, text);
            await updateData();
            props.onSubmit();
          }}
          color="green">
          Подтвердить
        </Button>
      </Flex>
    </Modal>
  );
}
