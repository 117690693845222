import { Badge, Paper, Text, Title } from "@mantine/core";
import { TDepositField, TDepositKey } from "shared/definitions/fees";
import React, { useState } from "react";
import { TFeeDataItem } from "shared/types/fees";
import { getCurrencyNameByTicker } from "shared/utils/currencyTreatment";
import styles from "./FeesSection.module.scss";
import { Input } from "./Input";
import { useCommonFeesLogic } from "../useCommonFeesLogic";

export type TFeesSectionProps = {
  sectionTitle: string;
  rows: TFeeDataItem[];
};

export const FeesSection = (props: TFeesSectionProps) => {
  const { sectionTitle, rows } = props;
  const [focusedInputId, setFocusedInputId] = useState<string>();
  const { handleSubmit, handleValidate } = useCommonFeesLogic();

  return (
    <div>
      <Paper shadow="xs" radius={16} p="xl" key={sectionTitle}>
        <Title size={20}>{sectionTitle}</Title>
        {rows.map((item) => (
          <div key={`FeeItem${item.id}`} className={styles.groupContainer}>
            <div className={styles.groupTitleContainer}>
              <Text fz={"20px"} fw={500}>
                {item.ticker}
              </Text>
              {item?.network && (
                <Badge color="black" variant="filled" radius={4}>
                  {item.network}
                </Badge>
              )}

              <Text fz={"14px"} fw={500} c={"grayUI.6"}>
                {getCurrencyNameByTicker(item.ticker)}
              </Text>
            </div>

            <div className={styles.groupInputGroup}>
              {Object.entries(
                (() => {
                  const fields: Record<string, TDepositField> = {
                    percentFee: {
                      id: "percent" + item.id.toString(),
                      value: Number(item.percentFee),
                      error: undefined,
                      label: "%",
                    },
                    minFee: {
                      id: "min" + item.id.toString(),
                      value: Number(item.minFee),
                      error: undefined,
                      label:
                        item.customType === "withdrawCrypto" || item.customType === "depositCrypto" ? "Fix" : "Мин",
                    },
                    maxFee: {
                      id: "max" + item.id.toString(),
                      value: Number(item.maxFee),
                      error: undefined,
                      label: "Макс",
                    },
                  };
                  return item.customType === "withdrawCrypto" || item.customType === "depositCrypto"
                    ? { minFee: fields.minFee }
                    : fields;
                })() as Record<TDepositKey, TDepositField>,
              ).map(([fieldKey, field]) => (
                <Input
                  key={[item.customType, item.id.toString(), fieldKey].join("-")}
                  id={[item.customType, item.id.toString(), fieldKey].join("-")}
                  handleSubmit={(v) => {
                    return handleSubmit(v, fieldKey as TDepositKey, item, setFocusedInputId);
                  }}
                  validate={(v) => {
                    return handleValidate(v, fieldKey as TDepositKey, item);
                  }}
                  focusedInputId={focusedInputId}
                  setFocusedInputId={setFocusedInputId}
                  label={field.label}
                  value={field.value.toString()}
                  error={field.error}
                  ticker={
                    item.customType === "withdrawCrypto" || item.customType === "depositCrypto"
                      ? item.ticker
                      : undefined
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </Paper>
    </div>
  );
};
