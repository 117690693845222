import { useAppDispatch, useAppSelector } from "store/store";
import React, { useCallback, useEffect, useMemo } from "react";
import { Routes } from "shared/definitions";
import { ActionIcon, Button, Drawer, Group, Loader, ScrollArea, Skeleton } from "@mantine/core";
import { setPersonalClientDetailId } from "store/slices";
import { ReactComponent as UserIcon } from "shared/static/icons/adapted/user.svg";
import { FieldTreatment } from "components/common/FieldTreatment";
import { PersonalClientViewCommon } from "shared/features";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import router from "../../../../router";

export const PersonalDrawerDetails = () => {
  const dispatch = useAppDispatch();
  const { personalClientDetailsId: cid, personalClientsList: list } = useAppSelector(
    ({ personalClients: { personalClientDetailsId, personalClientsList } }) => ({
      personalClientDetailsId,
      personalClientsList,
    }),
  );

  const shortedClientDetails = useMemo(() => list.find(({ id }) => id === cid), [cid, list]);

  const openClientPageHandler = useCallback(() => {
    router.navigate(Routes.CLIENT_SCREEN, {
      cid,
    });
  }, [cid]);

  useEffect(() => {
    return () => {
      dispatch(setPersonalClientDetailId(""));
    };
  }, []);

  return (
    <Drawer
      padding="lg"
      position="right"
      size={650}
      title="Общая информация о клиенте"
      overlayProps={{ opacity: 0.55 }}
      opened={!!cid.length}
      onClose={() => dispatch(setPersonalClientDetailId(""))}>
      <ScrollArea h="calc(100vh - 90px)">
        <Group position="left">
          {!cid.length && <Skeleton height={16} mt={5} width={100} />}
          {shortedClientDetails && (
            <FieldTreatment name="clientStatus">{shortedClientDetails.status || ""}</FieldTreatment>
          )}
          <Button
            leftIcon={
              <ActionIcon size={14} color="violet">
                <UserIcon color="#7950F2" />
              </ActionIcon>
            }
            size="xs"
            radius={12}
            compact
            variant="light"
            color="violet"
            onClick={openClientPageHandler}
            sx={{ fontSize: 12 }}>
            Открыть анкету
          </Button>
        </Group>
        <Spacer size={6} />
        <div style={{ width: "calc(100% - 50px)" }}>
          {cid ? <PersonalClientViewCommon cid={cid} /> : <Loader w="100%" />}
        </div>
      </ScrollArea>
    </Drawer>
  );
};
