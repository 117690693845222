import React, { FC, useMemo } from "react";
import { Button, Select } from "@mantine/core";
import { ITransferOption } from "shared/types/manageAssets";
import { useTransferModal } from "./useTransferModal";
import styles from "./TransferModal.module.scss";
import { ITransferModalProps, TransferModalStep } from "./types";
import { AmountInput } from "../../components/common/AmountInput/AmountInput";

export const TransferModal: FC<ITransferModalProps> = (props) => {
  const { state, actions } = useTransferModal(props);

  const stepTitle = useMemo(() => {
    if (state.step === TransferModalStep.Initial) return "Шаг 1: Параметры";
    if (state.step === TransferModalStep.Final) return "Шаг 2: Перевод";
    return undefined;
  }, [state.step]);

  if (state.step === TransferModalStep.Initial) {
    return (
      <form className={styles.form} onSubmit={actions.handleNextStep}>
        <div className={styles.stepTitle}>{stepTitle}</div>

        <div className={styles.fieldRow}>
          <span>Min amount</span>
          <AmountInput value={state.minAmount} onChange={actions.changeMinAmount} currencyNetwork={state.network} />
        </div>

        <div className={styles.fieldRow}>
          <span>Max комиссия</span>
          <AmountInput value={state.maxFee} onChange={actions.changeMaxFee} currencyNetwork={state.network} />
        </div>

        <div className={styles.fieldRow}>
          <Button type="submit" color="violet" className={styles.button}>
            Установить
          </Button>
        </div>
      </form>
    );
  }

  return (
    <form className={styles.form} onSubmit={actions.handleSubmit}>
      <div className={styles.stepTitle}>{stepTitle}</div>

      <div className={styles.fieldRow}>
        <span>Сумма перевода</span>
        <AmountInput
          value={state.transferAmount}
          onChange={actions.changeTransferAmount}
          currencyNetwork={state.network}
        />
      </div>

      <div className={styles.fieldRow}>
        <span>Откуда</span>
        <Select
          value={state.transferFromValue}
          onChange={(v: ITransferOption["value"] | null) => actions.setTransferFromValue(v)}
          data={state.transferFromOptions}
          className={styles.select}
          variant="unstyled"
          clearable
        />
      </div>

      <div className={styles.fieldRow}>
        <span>Куда</span>
        <Select
          value={state.transferToValue}
          onChange={(v: ITransferOption["value"] | null) => actions.setTransferToValue(v)}
          data={state.transferToOptions}
          className={styles.select}
          variant="unstyled"
          clearable
        />
      </div>

      <div className={styles.limits}>
        Min amount: {state.minAmount} {state.network?.toUpperCase()}, Max комиссия: {state.maxFee}{" "}
        {state.network?.toUpperCase()}
      </div>

      <div className={styles.buttonsContainer}>
        <Button variant="default" onClick={props.onClose}>
          Отмена
        </Button>

        <Button type="submit" color="violet">
          Подтвердить
        </Button>
      </div>
    </form>
  );
};
