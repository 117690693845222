import { useCallback, useEffect, useState } from "react";
import { getIndividualDocs } from "shared/api";
import { TPersonalDocument } from "shared/definitions";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useGetInvididualDocuments = ({ cid }: { cid: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [docs, setDocs] = useState<TPersonalDocument[]>([]);

  const getUserDocsHandle = useCallback(() => {
    getIndividualDocs({ id: cid })
      .then((response) => {
        setDocs(response.data);
      })
      .catch(sentryCaptureError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [cid]);

  useEffect(() => {
    getUserDocsHandle();
  }, []);

  return { docs, isLoaded, getUserDocsHandle };
};
