import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TFeeDataItem, TFeeDataItemCustomType } from "shared/types/fees";

type TFeesByType = {
  [key: string]: TFeeDataItem[];
};

interface IFeesSlice {
  feesData: TFeeDataItem[];
  feesByType: TFeesByType;
}

const initialState: IFeesSlice = {
  feesData: [],
  feesByType: {
    depositFiatCashRegister: [],
    depositFiatTransfer: [],
    withdrawFiatCashRegister: [],
    withdrawFiatTransfer: [],
    withdrawCrypto: [],
    depositCrypto: [],
    exchange: [],
  },
};

const feesSlice = createSlice({
  name: "fees",
  initialState,
  reducers: {
    setFeesData(state, action: PayloadAction<TFeeDataItem[]>) {
      state.feesData = action.payload;
      const sorted: TFeesByType = {
        depositFiatCashRegister: [],
        depositFiatTransfer: [],
        withdrawFiatCashRegister: [],
        withdrawFiatTransfer: [],
        withdrawCrypto: [],
        depositCrypto: [],
        exchange: [],
      };
      action.payload
        .sort((a, b) => a.id - b.id)
        .forEach((item) => {
          if (item.transactionType === "exchange") {
            item.customType = "exchange";
            item.customQuoted = `${item.ticker}_${item.tickerQuoted}`;
          } else if (item.fiatFlowType === null) {
            if (item.transactionType === "deposit") {
              item.customType = "depositCrypto";
            } else {
              item.customType = "withdrawCrypto";
            }
          } else if (item.transactionType === "deposit") {
            if (item.fiatFlowType === "cash_register") {
              item.customType = "depositFiatCashRegister";
            } else {
              item.customType = "depositFiatTransfer";
            }
          } else if (item.transactionType === "withdraw") {
            if (item.fiatFlowType === "cash_register") {
              item.customType = "withdrawFiatCashRegister";
            } else {
              item.customType = "withdrawFiatTransfer";
            }
          }
          sorted[item.customType as TFeeDataItemCustomType].push(item);
        });
      state.feesByType = sorted;
    },
  },
});

export const { setFeesData } = feesSlice.actions;
export const feesReducer = feesSlice.reducer;
