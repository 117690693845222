import React from "react";
import { Badge, Space, Table, Title, Group, Text, Skeleton, createStyles, Button } from "@mantine/core";
import { EmptyBlock } from "shared/features";
import { getCurrencyNameByTicker, getCurrencyNameByTickerWithNetwork } from "shared/utils/currencyTreatment";
import { CurrencyIcon } from "components/common/CurrencyIcon";
import { setNewTransferModalIsOpened } from "store/slices";
import { useAppDispatch } from "store/store";
import { TransferDirections } from "shared/definitions";
import { useRoles } from "shared/hooks/useRoles";
import { formatCrypto } from "shared/utils/formatNumber";
import { useUpdateOnlyWithMouseActions } from "shared/hooks/useUpdateOnlyWithMouseActions";
import { WalletsPageWrapper } from "../Wrapper";
import { Tabs } from "./components/Tabs";
import { useGetWalletsBalances } from "./useGetWalletsBalances";
import { ReactComponent as CirclePlusIcon } from "../../../shared/static/icons/adapted/circle_plus.svg";
import { NewTransferModal } from "./components/NewTransferModal";

const WalletsBalancesTableTitles = ["Валюта", "Payin", "Payout", "Exchange"];

const useStyles = createStyles(() => ({
  iconWrapper: {
    width: "24px",
    height: "24px",
    display: "flex",
    backgroundColor: "black",
    borderRadius: "5px",
    margin: "2px 8px 2px 0",
    svg: {
      margin: "auto",
      color: "white",
    },
  },
}));

const getTableColumnTdContent = (sum: string, ticker: string, count: string) => (
  <div>
    <div>
      <strong>{formatCrypto({ value: Number(sum), postfix: ticker.toUpperCase() })}</strong>
    </div>
    <div>
      <Text>
        {count} address{Number(count) > 1 ? "es" : ""}
      </Text>
    </div>
  </div>
);

export const Balances = () => {
  const { classes } = useStyles();
  const { isHeadOfFinManager } = useRoles();
  const dispatch = useAppDispatch();
  const { walletsBalancesDataByTicker: data, updateWalletsBalances } = useGetWalletsBalances();
  useUpdateOnlyWithMouseActions(updateWalletsBalances);

  const hasBalances = !!Object.keys(data).length;

  return (
    <WalletsPageWrapper>
      {hasBalances && <NewTransferModal />}
      <Tabs />
      <Space mt={24} />
      {!hasBalances && <EmptyBlock />}
      {hasBalances && (
        <div>
          <Group position="apart">
            <Group>
              <Title order={2} display="flex">
                Балансы
              </Title>
            </Group>
            {isHeadOfFinManager && (
              <Group>
                <Button
                  leftIcon={<CirclePlusIcon height="16px" color="white" />}
                  size="xs"
                  radius={4}
                  color="violet"
                  onClick={() => dispatch(setNewTransferModalIsOpened(true))}>
                  Создать перевод
                </Button>
              </Group>
            )}
          </Group>
          <Space mt={24} />
          <Table sx={{ borderRadius: "16px" }} horizontalSpacing="md" verticalSpacing="sm" fontSize="xs" withBorder>
            <thead>
              <tr style={{ backgroundColor: "#F1F3F5" }}>
                {WalletsBalancesTableTitles.map((title, k) => {
                  return (
                    <th key={`WBTT${title}`} className="break_word" style={k === 0 ? { width: "50%" } : {}}>
                      {title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((keyTicker: string) => {
                const [ticker, network] = [...keyTicker.split("_")];
                return (
                  <tr key={`WBTTRow${keyTicker}`}>
                    <td key="WBTTRowTdTypeTitle" className="display_flex cursor_default">
                      <div className={classes.iconWrapper}>
                        <CurrencyIcon height={16} icon={getCurrencyNameByTickerWithNetwork(keyTicker)} />
                      </div>
                      <div>
                        <div className="display_flex">
                          <Text fz={18} c="black" fw={700}>
                            {getCurrencyNameByTicker(ticker)}
                          </Text>
                          {network && (
                            <Badge color="black" ml={12} mt={4} variant="filled" size="sm" radius="3px">
                              <strong>{network ?? ""}</strong>
                            </Badge>
                          )}
                        </div>
                        <div>
                          <Text fz={13} c="#888" fw={300}>
                            {ticker.toUpperCase()}
                          </Text>
                        </div>
                      </div>
                    </td>
                    {TransferDirections.map((keyType) => (
                      <td key={`WBTTRowTdType${keyType}`}>
                        {!(keyType in data[keyTicker]) ? (
                          <Skeleton height={16} mt={5} width={100} />
                        ) : (
                          getTableColumnTdContent(data[keyTicker][keyType].sum, ticker, data[keyTicker][keyType].count)
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Space mt={24} />
        </div>
      )}
    </WalletsPageWrapper>
  );
};
