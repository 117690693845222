import { useCallback, useState } from "react";
import { Button, Drawer, ScrollArea, Title } from "@mantine/core";
import { TTransaction } from "shared/types/transactions";
import { Routes } from "shared/definitions";
import { PersonalClientViewCommon } from "shared/features";
import { TransactionView } from "shared/features/Transactions/TransactionView";
import { FieldTreatment } from "components/common/FieldTreatment";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import router from "router";
import { setIsShownTransactionPanel, setTransactionDetail } from "store/slices";
import { useAppDispatch, useAppSelector } from "store/store";
import { formatAmount } from "shared/utils/formatNumber";
import { getTransaction } from "shared/api";
import { transactionDetailDataTreatment } from "shared/utils";
import { useRoles } from "shared/hooks/useRoles";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { TransactionTypesKeys } from "shared/definitions/transactions/types";
import { useCheckIsFiat } from "shared/hooks/useCheckIsFiat";
import { sentryCaptureError } from "shared/utils/environment/sentry";

type TProps = {
  el: TTransaction;
  updateList: () => void;
};

export const CommonListTransactionRow = ({ el, updateList }: TProps) => {
  const dispatch = useAppDispatch();
  const { transactionDetails: currentDetail, isShownTransactionPanel: isTransactionPanelShown } = useAppSelector(
    (state) => state.transactions,
  );
  const [isOpenedClientPreview, setIsOpenedClientPreview] = useState(false);
  const { isOperator } = useRoles();
  const isFiat = useCheckIsFiat();

  const openClientPageHandler = useCallback(() => {
    router.navigate(Routes.CLIENT_SCREEN, {
      cid: el.id,
    });
  }, [el]);

  const openTransactionPageHandler = useCallback(() => {
    router.navigate(Routes.TRANSACTION_SCREEN, {
      tid: el.transactionId,
    });
  }, [el]);

  const transactionOpenDetailHandler = useCallback(
    (tid: string) => {
      getTransaction({ id: tid })
        .then((detail) => {
          dispatch(setTransactionDetail(transactionDetailDataTreatment(detail)));
        })
        .catch(sentryCaptureError)
        .finally(() => {
          dispatch(setIsShownTransactionPanel(true));
        });
    },
    [currentDetail, el],
  );

  const isLighted =
    isOperator && el.transactionStatus === TransactionStatusKeys.sending && el.type === TransactionTypesKeys.withdraw;

  return (
    <tr style={isLighted ? { backgroundColor: "#ffeee1" } : {}}>
      <Drawer
        size="700px"
        title={
          <Padding vertical={0} horizontal={2}>
            <Title order={5}>Детальная информация</Title>
          </Padding>
        }
        overlayProps={{ opacity: 0.65 }}
        position="right"
        opened={el.transactionId === (currentDetail as TTransaction).transactionId && isTransactionPanelShown}
        onClose={() => dispatch(setIsShownTransactionPanel(false))}>
        <Padding horizontal={2}>
          <Button mb={16} size="sm" compact radius={6} variant="filled" onClick={openTransactionPageHandler}>
            Открыть во вкладке
          </Button>
          <ScrollArea style={{ height: "calc(100vh - 120px)" }} offsetScrollbars scrollbarSize={8}>
            <TransactionView updateList={updateList} tid={el.transactionId} />
          </ScrollArea>
        </Padding>
      </Drawer>
      <Drawer
        padding="lg"
        position="right"
        size={650}
        overlayProps={{ opacity: 0.65 }}
        title={<Title order={5}>Информация о клиенте</Title>}
        opened={isOpenedClientPreview}
        onClose={() => setIsOpenedClientPreview(false)}>
        <Button size="sm" compact radius={4} variant="default" onClick={openClientPageHandler}>
          Открыть подробнее
        </Button>
        <Spacer size={6} />
        <PersonalClientViewCommon cid={el.id} />
      </Drawer>
      <td>
        <FieldTreatment name="createdAt">{el.createdAt}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionId" onClick={() => transactionOpenDetailHandler(el?.transactionId)}>
          {el.transactionId}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionType">{el.type}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="fiatFlowType">{el?.fiatFlowType ?? ""}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="amount">
          {formatAmount({ value: Number(el.amountFrom), isFiat: isFiat(el.currencyString) })}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionCurrencyString">{el.currencyString}</FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="userPublicId" onClick={() => setIsOpenedClientPreview(true)}>
          {el.userPublicId.toString()}
        </FieldTreatment>
      </td>
      <td>
        <FieldTreatment name="transactionStatus">{el.transactionStatus}</FieldTreatment>
      </td>
      <td>
        <div className="cursor_pointer no_select" onClick={() => transactionOpenDetailHandler(el?.transactionId)}>
          <Button compact color="black" variant="subtle">
            Открыть
          </Button>
        </div>
      </td>
    </tr>
  );
};
