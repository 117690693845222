import { useAppSelector } from "store/store";
import { useCallback, useEffect, useState } from "react";
import { TFeeDataItem } from "shared/types/fees";

export const useExchangeFeesLogic = () => {
  const { exchangeFees } = useAppSelector(({ fees }) => ({ exchangeFees: fees.feesByType.exchange }));
  const [columnTitlesExchangeFees, setColumnTitlesExchangeFees] = useState<string[]>([]);
  const [exchangeFeesByTickers, setExchangeFeesByTickers] = useState<Record<string, TFeeDataItem[]>>({});
  const sortableByMinArgValue = useCallback(
    (a: TFeeDataItem, b: TFeeDataItem) => Number(a.minArgValue) - Number(b.minArgValue),
    [],
  );

  useEffect(() => {
    if (columnTitlesExchangeFees.length) return;
    const tempColumnTitlesExchangeFees: string[] = [];
    const tempExchangeFeesByTickers: Record<string, TFeeDataItem[]> = {};
    [...exchangeFees]?.sort(sortableByMinArgValue).forEach((item) => {
      if (!tempColumnTitlesExchangeFees.includes(item.minArgValue.toString())) {
        tempColumnTitlesExchangeFees.push(item.minArgValue.toString());
      }
      if (item.customQuoted! in tempExchangeFeesByTickers) {
        tempExchangeFeesByTickers[item.customQuoted!].push(item);
      } else {
        tempExchangeFeesByTickers[item.customQuoted!] = [item];
      }
    });
    setColumnTitlesExchangeFees(tempColumnTitlesExchangeFees);
    setExchangeFeesByTickers(tempExchangeFeesByTickers);
  }, [exchangeFees]);

  return { exchangeFees, exchangeFeesByTickers, columnTitlesExchangeFees };
};
