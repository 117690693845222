import { ScrollArea, Button, Drawer, Group, Skeleton } from "@mantine/core";
import { setOrganizationClientDetailsId } from "store/slices/organizationClientsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { FieldTreatment } from "components/common/FieldTreatment";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import React, { useCallback, useEffect } from "react";
import { Routes } from "shared/definitions";
import router from "../../../../router";
import { ClientOrganizationViewDetails } from "./OrganizationViewDetails";

export function DetailsDrawer() {
  const dispatch = useAppDispatch();
  const { organizationClientDetailsId: cid, organizationClientsList } = useAppSelector(
    (state) => state.organizationClients,
  );
  const shortDetails = organizationClientsList.find((el) => el.id === cid);

  const openClientPageHandler = useCallback(() => {
    router.navigate(Routes.ORGANIZATION_SCREEN, {
      cid,
    });
  }, [cid]);

  useEffect(() => {
    return () => {
      dispatch(setOrganizationClientDetailsId(""));
    };
  }, []);

  return (
    <Drawer
      padding="lg"
      position="right"
      size={650}
      title="Общая информация о клиенте"
      overlayProps={{ opacity: 0.55 }}
      onClose={() => {
        dispatch(setOrganizationClientDetailsId(""));
      }}
      opened={!!cid}>
      <ScrollArea h="calc(100vh - 90px)">
        <Group position="left">
          {!shortDetails && <Skeleton height={16} mt={5} width={100} />}
          {shortDetails && <FieldTreatment name="clientStatus">{shortDetails.status || ""}</FieldTreatment>}
          <Button
            size="xs"
            radius={12}
            compact
            variant="light"
            color="violet"
            onClick={openClientPageHandler}
            sx={{ fontSize: 12 }}>
            Открыть анкету
          </Button>
        </Group>
        <Spacer size={6} />
        <div style={{ width: "calc(100% - 50px)" }}>{!!cid && <ClientOrganizationViewDetails cid={cid} />}</div>
      </ScrollArea>
    </Drawer>
  );
}
