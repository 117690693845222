import React from "react";
import { Grid } from "@mantine/core";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";

export const TypicalTextField = React.memo((props: { label: string; text: string | null }) => {
  const { label = "", text = "" } = props;
  return (
    <Grid.Col span={12}>
      <FieldsWrapper>
        <FieldWrapper label={label}>
          <FieldsContentText inline={false} text={text || "---"} />
        </FieldWrapper>
      </FieldsWrapper>
    </Grid.Col>
  );
});
