import { TTransactionDetails } from "shared/types/transactions";
import { Grid } from "@mantine/core";
import { useTransactionViewBlocksLogic } from "shared/features/Transactions/TransactionView/hooks/useTransactionViewBlocksLogic";
import { FieldsWrapper } from "components/ui/fields";
import { useMemo } from "react";
import { useRoles } from "shared/hooks/useRoles";
import { TransactionStatusKeys } from "shared/definitions/transactions/statuses";
import { TransactionViewStatusBlock } from "shared/features/Transactions/TransactionView/components/TransactionViewStatusBlock";
import { formatFiat } from "shared/utils/formatNumber";

export const DepositFiatTransfer = ({
  detail,
  updateList,
}: {
  detail: TTransactionDetails;
  updateList: () => void;
}) => {
  const {
    blockSimplyTransactionStatus,
    renderTypicalTextField,
    blockUpdatedAt,
    blockComment,
    blockServiceFee,
    blockCreatedAt,
  } = useTransactionViewBlocksLogic(detail, updateList);

  const { isHeadOfFinManager, isFinManager } = useRoles();
  const isAllowChangeTransactionStatus =
    (isHeadOfFinManager || isFinManager) && detail.transactionStatus === TransactionStatusKeys.hold;

  const blockAmountWithoutFee = useMemo(
    () =>
      renderTypicalTextField(
        "Сумма до вычета",
        formatFiat({
          value: Number(detail.amountFrom),
          postfix: detail.tickerTo,
        }),
      ),
    [detail],
  );

  const blockAmountWithFee = useMemo(
    () =>
      renderTypicalTextField("К зачислению", formatFiat({ value: Number(detail.amountTo), postfix: detail.tickerTo })),
    [detail],
  );

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <FieldsWrapper>
            {isAllowChangeTransactionStatus ? (
              <TransactionViewStatusBlock
                updateList={updateList}
                transactionStatus={detail.transactionStatus}
                transactionId={detail.transactionId}
              />
            ) : (
              blockSimplyTransactionStatus
            )}
            {blockAmountWithoutFee}
            {blockAmountWithFee}
            {blockServiceFee}
            {blockCreatedAt}
            {blockUpdatedAt}
            {blockComment}
          </FieldsWrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
