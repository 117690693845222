import { ReactElement, useEffect, useLayoutEffect } from "react";
import { getRolesTypes } from "shared/api";
import { setCurrentRole, setListOfMyRoles, setListOfRoles } from "store/slices";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, LoadingOverlay, Text } from "@mantine/core";
import { useJwt } from "react-jwt";
import { getAccessToken } from "shared/utils/auth";
import { useRoute } from "react-router5";
import { Routes } from "shared/definitions";
import { useRoles } from "shared/hooks/useRoles";
import styles from "./MainWrapper.module.scss";
import { NavBar } from "./Components/NavBar";
import router from "../../../router";

type TProps = {
  children: ReactElement;
};

const MainWrapperHOC = (props: TProps) => {
  const dispatch = useAppDispatch();
  const { route } = useRoute();
  const { listOfRoles, listOfMyRoles, currentRole } = useAppSelector(({ auth }) => ({
    listOfRoles: auth.listOfRoles,
    listOfMyRoles: auth.listOfMyRoles,
    currentRole: auth.currentRole,
  }));
  const { isOperator } = useRoles();
  const { decodedToken }: any = useJwt(getAccessToken() ?? "");

  useEffect(() => {
    if (listOfMyRoles.length || !(decodedToken?.groups ?? []).length) return;
    const currentRoleFromStorage = window?.localStorage?.getItem("role");
    if (currentRoleFromStorage !== null) {
      if (!decodedToken?.groups.includes(currentRoleFromStorage)) {
        window?.localStorage.removeItem("role");
        dispatch(setCurrentRole(decodedToken?.groups[0]));
      }
    }
    dispatch(setListOfMyRoles(decodedToken?.groups));
  }, [decodedToken]);

  useEffect(() => {
    if (listOfRoles.length) return;
    getRolesTypes().then((data) => {
      dispatch(setListOfRoles(data.roles));
    });
  }, []);

  useEffect(() => {
    if (currentRole === null) {
      if (listOfMyRoles.length) {
        dispatch(setCurrentRole(listOfMyRoles[0]));
      }
    }
  }, [listOfMyRoles]);

  useLayoutEffect(() => {
    if (isOperator && !(route.name === Routes.TRANSACTION_SCREEN || route.name === Routes.TRANSACTIONS_SCREEN)) {
      router.navigate(Routes.TRANSACTIONS_SCREEN);
    }
  }, [listOfMyRoles, route.name]);

  if (!listOfRoles.length || !(listOfMyRoles ?? []).length || currentRole === null) return <LoadingOverlay visible />;

  if (isOperator && !(route.name === Routes.TRANSACTION_SCREEN || route.name === Routes.TRANSACTIONS_SCREEN)) {
    return (
      <div>
        <Text m={20} size={30} color="white">
          Доступ запрещен
        </Text>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}>
          На главную
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <NavBar />
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export const MainWrapper = MainWrapperHOC;
