import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";

export enum RiskLevel {
  low = "Низкий",
  high = "Высокий",
}

export const RiskLevelKeys = {
  low: getEnumKeyByEnumValue(RiskLevel, RiskLevel.low),
  high: getEnumKeyByEnumValue(RiskLevel, RiskLevel.high),
};
