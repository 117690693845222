import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITableData, ITransferOption, ManageAssetsAmountField, TransferOptionValue } from "shared/types/manageAssets";

const tableDataMock: ITableData = {
  columns: [
    { key: "currency", label: "Валюта" },
    { key: "payin", label: "User payins" },
    { key: "payout", label: "Payout" },
    { key: "fan", label: "Fan" },
  ],
  rows: [
    { currency: "USDT_ETH", network: "eth", payin: 6022, payout: 14148, fan: undefined },
    { currency: "USDT_TRX", network: "trx", payin: 12133, payout: 12534, fan: undefined },
    { currency: "USDT_BSC", network: "bsc", payin: 7258, payout: 10181, fan: undefined },
    { currency: "USDC_ETH", network: "eth", payin: 6984, payout: 18783, fan: undefined },
    { currency: "USDC_TRX", network: "trx", payin: 18259, payout: 405, fan: undefined },
    { currency: "USDC_BSC", network: "bsc", payin: 9189, payout: 17791, fan: undefined },
    { currency: "ETH", network: "eth", payin: 19205, payout: 4708, fan: 5408 },
    { currency: "TRX", network: "trx", payin: 2109, payout: 16501, fan: 18990 },
    { currency: "BSC", network: "bsc", payin: 4063, payout: 15786, fan: 16166 },
  ],
};

const transferOptionsMock: ITransferOption[] = [
  { id: "0", value: "payout", label: "Payout" },
  { id: "1", value: "payin", label: "User payins" },
  { id: "2", value: "wallet", label: "Cold wallet" },
];

const initialState = {
  tableData: tableDataMock,
  transferOptions: transferOptionsMock,
};

interface ITransferPayload {
  rowIndex: number;
  value: number | undefined;
  from: TransferOptionValue;
  to: TransferOptionValue;
}

const manageAssetsSlice = createSlice({
  name: "manageAssets",
  initialState,
  reducers: {
    transfer(state, { payload: { rowIndex, from, to, value } }: PayloadAction<ITransferPayload>) {
      if (typeof state.tableData.rows[rowIndex] !== "object") return state;

      const row = state.tableData.rows[rowIndex];
      if (from !== "wallet") row[from as ManageAssetsAmountField] -= value ?? 0;
      if (to !== "wallet") row[to as ManageAssetsAmountField] += value ?? 0;
      return state;
    },
    withdrawAll(state) {
      state.tableData.rows.forEach((row) => {
        row.payin = 0;
        row.payout = 0;
        row.fan = row.fan !== undefined ? 0 : undefined;
      });

      return state;
    },
  },
});

export const { transfer, withdrawAll } = manageAssetsSlice.actions;
export const manageAssetsReducer = manageAssetsSlice.reducer;
