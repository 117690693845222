import React, { useEffect } from "react";
import { Badge, Group, Space, Title } from "@mantine/core";
import { JournalTransfersFilters, JournalTransfersPagination } from "shared/features";
import { useAppDispatch, useAppSelector } from "store/store";
import { resetJournalTransfersFilters } from "store/slices";
import { JournalTransfersListView } from "shared/features/Wallets/JournalTransfers/JournalTransfersListView";
import { useUpdateOnlyWithMouseActions } from "shared/hooks/useUpdateOnlyWithMouseActions";
import { AxiosError } from "axios";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { WalletsPageWrapper } from "../Wrapper";
import { Tabs } from "../Balances/components/Tabs";
import { useGetJournalTransfers } from "./useGetJournalTransfers";

export const JournalTransfers = () => {
  const dispatch = useAppDispatch();
  const { journalTransfersData, journalTransfersCountAll } = useAppSelector(({ journalTransfers }) => journalTransfers);

  const { getJournalTransfersRequest } = useGetJournalTransfers();

  useUpdateOnlyWithMouseActions(() => {
    getJournalTransfersRequest().catch((e) => {
      if (e.code !== AxiosError.ERR_CANCELED) {
        sentryCaptureError(e);
      }
    });
  });

  useEffect(() => {
    return () => {
      dispatch(resetJournalTransfersFilters());
    };
  }, []);

  return (
    <WalletsPageWrapper>
      <Tabs />
      <Space mt={24} />
      <JournalTransfersFilters />
      <Space mt={24} />
      <Group position="apart">
        <Group>
          <Title order={2} display="flex">
            Все переводы
          </Title>
          <Badge variant="outline" color="violet" size="md" radius="sm">
            {journalTransfersCountAll}
          </Badge>
        </Group>
      </Group>
      <Space mt={24} />
      <JournalTransfersListView
        data={journalTransfersData}
        listUpdateFn={() => {
          getJournalTransfersRequest().catch((e) => {
            if (e.code !== AxiosError.ERR_CANCELED) {
              sentryCaptureError(e);
            }
          });
        }}
      />
      <Space mt={24} />
      <JournalTransfersPagination />
    </WalletsPageWrapper>
  );
};
