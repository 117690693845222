import { useAppDispatch, useAppSelector } from "store/store";
import { useCallback, useEffect, useState } from "react";
import { setServiceInfoComments, setServiceInfoCountAll } from "store/slices/serviceInfoSlice";
import { getServiceInfoComments } from "shared/api/users/getServiceInfoComments";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useServiceComments = (cid: string) => {
  const dispatch = useAppDispatch();
  const serviceInfoState = useAppSelector((state) => state.serviceInfo);
  const [isLoaded, setIsLoaded] = useState(false);

  const getServiceInfoHandle = useCallback(async () => {
    getServiceInfoComments({ id: cid, pageSize: serviceInfoState.pageSize, page: serviceInfoState.page })
      .then((comments) => {
        dispatch(setServiceInfoComments(comments.data.items));
        dispatch(setServiceInfoCountAll(comments.data.count));
      })
      .catch(sentryCaptureError);
  }, [cid]);

  useEffect(() => {
    getServiceInfoHandle().finally(() => {
      setIsLoaded(true);
    });
  }, []);

  return {
    comments: serviceInfoState.comments,
    isLoaded,
    getServiceInfoHandle,
  };
};
