import { useCallback, useEffect, useMemo, useState } from "react";
import { RingProgress, Title, Text, Table, Button } from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { formatFiat } from "shared/utils/formatNumber";
import { useRoles } from "shared/hooks/useRoles";
import { useGetClientLimits } from "shared/hooks/useGetClientLimits";
import { FinanceCard } from "components/common/FinanceCard";
import styles from "./ClientFinances.module.scss";
import "dayjs/locale/ru";
import { ResetLimitsModal } from "./ResetLimitsModal";

const InfinitySymbol = "∞";
const InitialDate = new Date();

const tableCols = ["Пополнение", "Исчерпано", "Осталось", "Всего"];

const getTableRow = (rowName: string, totalTurnover?: string, limit?: string | null) => {
  const row = { rowName, exhausted: NaN, left: NaN, total: NaN, progressValue: NaN };

  if (totalTurnover === undefined || limit === undefined) return row;

  row.exhausted = +totalTurnover;
  row.left = (limit === null ? Infinity : +limit) - +totalTurnover;
  row.total = limit === null ? Infinity : +limit;
  row.progressValue = limit === null ? Infinity : row.exhausted / row.total;

  return row;
};

export const ClientLimits = (props: { cid: string }) => {
  const { cid } = props;
  const { clientLimits, getUserLimits, clientLimitsLoading } = useGetClientLimits(cid);
  const [date, setDate] = useState<Date | null>(InitialDate);
  const [isModalOpen, setModalOpen] = useState(false);
  const { isCompliance } = useRoles();

  const tableData = useMemo(() => {
    return [
      { rowName: "Без PoF", totalTurnover: clientLimits?.pofTurnoverTotal, limit: clientLimits?.pofLimit },
    ].map(({ rowName, totalTurnover, limit }) => getTableRow(rowName, totalTurnover, limit));
  }, [clientLimits]);

  const fetchUserLimits = useCallback(() => {
    const params = date !== null ? { month: date.getMonth(), year: date.getFullYear() } : undefined;
    getUserLimits(params);
  }, [date, getUserLimits]);

  useEffect(() => {
    fetchUserLimits();
  }, [fetchUserLimits]);

  return (
    <FinanceCard>
      <div className={styles.headContainer}>
        <Title order={4} className={styles.headTitle}>
          Лимиты клиента
        </Title>
        <div className={styles.headContainerRightColumn}>
          <MonthPickerInput
            disabled={clientLimitsLoading}
            locale={"ru"}
            className={styles.datePicker}
            variant={"default"}
            value={date}
            onChange={(d) => setDate(d)}
          />
        </div>
      </div>

      <Spacer size={4} />

      <div className={styles.ringFlexRow}>
        {tableData.map((r) => {
          let progress = 0;
          let progressStr = "–";

          if (Number.isFinite(r.progressValue)) {
            progress = r.progressValue * 100;
            progressStr = `${progress.toFixed(2)}%`;
          } else if (r.progressValue === Infinity) {
            progressStr = InfinitySymbol;
          }

          return (
            <RingProgress
              key={`ring#${r.rowName}`}
              size={176}
              thickness={5}
              sections={[{ value: progress, color: "indigo" }]}
              label={
                <>
                  <Spacer size={1} />
                  <Text align={"center"} size={"xs"} color={"grayUI.6"}>
                    {r.rowName}
                  </Text>
                  <Text align={"center"} fz={"24px"}>
                    {progressStr}
                  </Text>
                </>
              }
            />
          );
        })}
      </div>

      <Spacer size={3} />

      <Table className={styles.table} horizontalSpacing="md" verticalSpacing="sm" fontSize="sm" withBorder={false}>
        <thead>
          <tr>
            {tableCols.map((colName) => (
              <th key={colName}>{colName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map(({ rowName, exhausted, left, total }) => (
            <tr key={`table-row#${rowName}`}>
              <td>{rowName}</td>
              <td>{formatFiat({ value: exhausted })}</td>
              <td>{left === Infinity ? "∞" : formatFiat({ value: left })}</td>
              <td>{total === Infinity ? "∞" : formatFiat({ value: total })}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Spacer size={3} />

      {isCompliance && (
        <Button variant="light" color="indigo" onClick={() => setModalOpen(true)}>
          Сбросить лимит PoF
        </Button>
      )}

      <ResetLimitsModal
        cid={cid}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        onTurnoverReset={fetchUserLimits}
      />
    </FinanceCard>
  );
};
