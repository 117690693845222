import {
  Alert,
  Badge,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  NativeSelect,
  Text,
  TextInput,
} from "@mantine/core";
import { LimitedTransferDirections } from "shared/definitions";
import { removeTrailingZeroes } from "shared/utils/removeTrailingZeroes";
import {
  newTransferSetCurrencyWithNetwork,
  newTransferSetFrom,
  newTransferSetIsApprove,
  newTransferSetStep,
  newTransferSetTo,
  resetNewTransferSlice,
  setNewTransferModalIsOpened,
} from "store/slices";
import { TWalletType } from "shared/types/wallets";
import { ReactComponent as ArrowRightIcon } from "shared/static/icons/adapted/arrowRight2.svg";
import {
  getCurrencyLabel,
  getCurrencyNameByTicker,
  getCurrencyNameByTickerWithNetwork,
} from "shared/utils/currencyTreatment";
import { IconAlertCircle, IconThumbDown, IconThumbUp } from "@tabler/icons";
import React, { useEffect } from "react";
import { CurrencyIcon } from "components/common/CurrencyIcon";
import { useRoles } from "shared/hooks/useRoles";
import { useTransferModalLogic } from "./useTransferModalLogic";

const useStyles = createStyles(() => ({
  icon: {
    "height": "42px",
    "display": "flex",
    "margin": "auto",
    "svg ": {
      margin: "auto",
      width: "24px",
      color: "black",
    },
  },
  currencyIconWrapper: {
    width: "24px",
    height: "24px",
    display: "flex",
    backgroundColor: "black",
    borderRadius: "5px",
    margin: "2px 8px 2px 0",
    svg: {
      margin: "auto",
      color: "white",
    },
  },
}));

export const NewTransferModal = () => {
  const { classes } = useStyles();
  const {
    dispatch,
    isFilledForm,
    isFilledFormForCheck,
    setErrors,
    newTransferModalIsOpened,
    newTransferState,
    walletsBalancesDataByTicker,
    newTransferRequest,
    amount,
    setAmount,
    comment,
    isPendingCheckRequest,
    setComment,
    newTransferFinalCheck,
    setNewTransferFinalCheck,
  } = useTransferModalLogic();
  const { isHeadOfFinManager } = useRoles();

  const isFinalStep = newTransferState.step === "success" || newTransferState.step === "error";

  useEffect(() => {
    if (isFilledForm && newTransferFinalCheck === "success") {
      newTransferRequest();
    }
  }, [isFilledForm, newTransferFinalCheck]);

  useEffect(() => {
    if (!isHeadOfFinManager) {
      dispatch(setNewTransferModalIsOpened(false));
      dispatch(resetNewTransferSlice());
    }
  }, [isHeadOfFinManager]);

  if (!isHeadOfFinManager) return null;
  if (!newTransferState.possibleToDirectionsResponse.length) return <LoadingOverlay visible />;

  return (
    <Modal
      size="lg"
      padding="xl"
      title="Перевод средств"
      opened={newTransferModalIsOpened}
      onClose={() => {
        dispatch(setNewTransferModalIsOpened(false));
        dispatch(resetNewTransferSlice());
      }}>
      {isFinalStep ? (
        <div>
          <div>
            <Alert
              radius={8}
              icon={newTransferState.step === "success" ? <IconThumbUp size="1rem" /> : <IconThumbDown size="1rem" />}
              color={newTransferState.step === "success" ? "green" : "red"}
              mb={16}>
              {newTransferState.step === "success" ? "Операция на перевод успешно создана!" : "Операция не создана"}
            </Alert>
          </div>
          <div>
            <Group position="right">
              <Button
                variant="outline"
                onClick={() => {
                  dispatch(setNewTransferModalIsOpened(false));
                }}>
                Закрыть
              </Button>
            </Group>
          </div>
        </div>
      ) : (
        <div>
          {newTransferState.errors.length > 0 && newTransferState.step === "init" && (
            <Alert
              withCloseButton
              onClose={() => setErrors([])}
              radius={8}
              icon={<IconAlertCircle size="1rem" />}
              title="Что-то пошло не так"
              color="red"
              mb={16}>
              {newTransferState.errors.map((k, i) => (
                <div key={`ntse${i}`}>{k}</div>
              ))}
            </Alert>
          )}
          <Grid>
            {newTransferState.step === "check" && (
              <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                Валюта
              </Grid.Col>
            )}
            {newTransferState.step === "check" && (
              <Grid.Col span={8}>
                <div className="w-100 display_flex">
                  <div className={classes.currencyIconWrapper}>
                    <CurrencyIcon
                      height={16}
                      icon={getCurrencyNameByTickerWithNetwork(newTransferState.currencyWithNetworkString)}
                    />
                  </div>
                  <div>
                    <div className="display_flex">
                      <Text fz={18} c="black" fw={700}>
                        {getCurrencyNameByTicker(newTransferState.currency)}
                      </Text>
                      {!!newTransferState.network.length && (
                        <Badge color="black" ml={12} mt={4} variant="filled" size="sm" radius="3px">
                          <strong>{newTransferState.network ?? ""}</strong>
                        </Badge>
                      )}
                    </div>
                    <div>
                      <Text fz={13} c="#888" fw={300}>
                        {newTransferState.currency.toUpperCase()}
                      </Text>
                    </div>
                  </div>
                </div>
              </Grid.Col>
            )}
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Откуда → Куда
            </Grid.Col>
            <Grid.Col span={8}>
              <Group grow={newTransferState.step === "init"}>
                {newTransferState.step === "check" && (
                  <div>
                    <Text fz={"20px"} fw={500}>
                      {newTransferState.from.toUpperCase()}
                    </Text>
                  </div>
                )}
                {newTransferState.step === "init" && (
                  <NativeSelect
                    value={newTransferState.from}
                    onChange={({ target: { value } }) => dispatch(newTransferSetFrom(value as TWalletType))}
                    size="xs"
                    data={LimitedTransferDirections}
                  />
                )}
                <div className={classes.icon}>
                  <ArrowRightIcon />
                </div>
                {newTransferState.step === "check" && (
                  <div>
                    <Text fz={"20px"} fw={500}>
                      {newTransferState.to.toUpperCase()}
                    </Text>
                  </div>
                )}
                {newTransferState.step === "init" && (
                  <NativeSelect
                    value={newTransferState.to}
                    onChange={({ target: { value } }) => dispatch(newTransferSetTo(value as TWalletType))}
                    size="xs"
                    data={newTransferState.possibleToDirectionsResponse}
                  />
                )}
              </Group>
            </Grid.Col>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Сумма перевода
            </Grid.Col>
            <Grid.Col span={8}>
              {newTransferState.step === "init" && (
                <Group grow>
                  <TextInput
                    value={amount}
                    onBlur={({ target: { value } }) => setAmount(removeTrailingZeroes(value))}
                    onChange={({ target: { value } }) => setAmount(value)}
                    size="xs"
                    type="number"
                  />
                  <NativeSelect
                    value={newTransferState.currencyWithNetworkString}
                    onChange={(e) => dispatch(newTransferSetCurrencyWithNetwork(e.target.value))}
                    size="xs"
                    data={Object.keys(walletsBalancesDataByTicker)
                      // TODO: временно используется один
                      .filter((k) => k === "usdt_trx")
                      .map((k) => {
                        return {
                          label: getCurrencyLabel(k),
                          value: k,
                        };
                      })}
                  />
                </Group>
              )}
              {newTransferState.step === "check" && (
                <div>
                  <Text fz={"20px"} fw={500}>
                    {newTransferState.amount} {newTransferState.currency.toUpperCase()}
                    {newTransferState.network && ` (${newTransferState.network.toUpperCase()})`}
                  </Text>
                </div>
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                Комментарий
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={8}>
              {newTransferState.step === "init" && (
                <TextInput value={comment} onChange={({ target: { value } }) => setComment(value)} size="xs" />
              )}
              {newTransferState.step === "check" && (
                <div>
                  <Text fz={"20px"} fw={500}>
                    {newTransferState.comment}
                  </Text>
                </div>
              )}
            </Grid.Col>
            {newTransferState.step === "check" && (
              <Grid.Col span={12}>
                <Divider my="xs" />
                <Checkbox
                  label="Подтверждаю, что проверил детали перевода"
                  checked={newTransferState.isApprove}
                  onChange={() => dispatch(newTransferSetIsApprove(!newTransferState.isApprove))}
                />
              </Grid.Col>
            )}
            <Grid.Col span={12}>
              <div>
                <Group position="right">
                  {newTransferState.step === "check" && (
                    <Button
                      variant="default"
                      onClick={() => {
                        dispatch(newTransferSetStep("init"));
                      }}>
                      Назад
                    </Button>
                  )}
                  {newTransferState.step === "init" && (
                    <Button
                      color="violet"
                      disabled={isPendingCheckRequest || !newTransferState.isChecked || !isFilledFormForCheck}
                      onClick={() => {
                        dispatch(newTransferSetStep("check"));
                      }}>
                      Продолжить
                    </Button>
                  )}
                  {newTransferState.step === "check" && (
                    <Button
                      color="violet"
                      disabled={!isFilledForm || newTransferFinalCheck !== "none"}
                      onClick={() => {
                        if (isFilledForm) {
                          setNewTransferFinalCheck("init");
                        }
                      }}>
                      Подтвердить
                    </Button>
                  )}
                </Group>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      )}
    </Modal>
  );
};
