import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLogsActionTypes } from "shared/api/logs/getLogsTypes";
import { setActionTypesList, setRolesList } from "store/slices/logsSlice";
import { getLogsRoles } from "shared/api/logs/getLogsRoles";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export const useLogsDropdowns = () => {
  const dispatch = useAppDispatch();
  const { actionTypesList, rolesList } = useAppSelector((state) => state.logs);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);

  useEffect(() => {
    if (!actionTypesList.length && !fetchIsLoading) {
      setFetchIsLoading(true);
      Promise.allSettled([
        getLogsActionTypes()
          .then((resp) => {
            dispatch(setActionTypesList(resp.data.sort()));
          })
          .catch(sentryCaptureError),
        getLogsRoles()
          .then((resp) => {
            dispatch(setRolesList(resp.data.sort()));
          })
          .catch(sentryCaptureError),
      ]).finally(() => setFetchIsLoading(false));
    }
  }, [actionTypesList]);

  return {
    isLoadedActionTypesList: !!actionTypesList.length,
    actionTypesList,
    rolesList,
  };
};
