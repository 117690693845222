import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setClientBalances } from "store/slices";
import { fetchUserBalances, fetchUserBalancesAbortController } from "shared/api/users/getUserBalances";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { AxiosError } from "axios";

export const useGetClientBalances = (cid: string) => {
  const dispatch = useAppDispatch();

  const clientBalances = useAppSelector((state) => state.clientFinances.clientBalances);

  const getUserBalances = useCallback(() => {
    fetchUserBalances({ id: cid })
      .then((data) => {
        dispatch(setClientBalances(data));
      })
      .catch(e => {
        if (e.code !== AxiosError.ERR_CANCELED) {
          sentryCaptureError(e);
        }
      });
  }, [cid]);

  useEffect(() => {
    fetchUserBalancesAbortController.abort();
    getUserBalances();
  }, [getUserBalances]);

  return { clientBalances, isClientBalancesLoaded: clientBalances !== null };
};
