import { Modal, Text } from "@mantine/core";
import { ReactComponent as SuccessIcon } from "shared/static/icons/success.svg";
import { useDepositModalLogic } from "./useDepositModalLogic";
import styles from "./Result.module.scss";

export const Result = () => {
  const { setDepositStep, resetDeposit } = useDepositModalLogic();
  return (
    <Modal
      size="lg"
      title={<SuccessIcon className={styles.success} />}
      padding="xl"
      opened
      onClose={() => {
        setDepositStep(null);
        resetDeposit();
      }}>
      <Text fw="500" fz="xl">
        Операция на пополнение счета
        <br /> успешно создана!
      </Text>
    </Modal>
  );
};
