import "dayjs/locale/ru";
import React from "react";
import {
  ActionIcon,
  Button,
  Checkbox,
  createStyles,
  Grid,
  Group,
  Input,
  Menu,
  Select,
  Space,
  Switch,
} from "@mantine/core";
import { useDispatch } from "react-redux";
import {
  setPayinByExistFilter,
  setPayinIsAscOrderingFilter,
  setPayinOrderByFilter,
  setPayinSearchFilter,
} from "store/slices";
import { ReactComponent as SearchIcon } from "shared/static/icons/adapted/search.svg";
import { ReactComponent as SearchCancelIcon } from "shared/static/icons/adapted/searchCancel.svg";
import { useAppSelector } from "store/store";
import { FilterBadge } from "components/common/FilterBadge";
import { switchMatch } from "shared/utils";
import { ReactComponent as FiltersIcon } from "shared/static/icons/adapted/filters.svg";
import classNames from "classnames";
import { useIsFiltered } from "../../useIsFiltered";

const useStyles = createStyles((theme) => ({
  inputWrapper: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    borderRadius: 8,
    backgroundColor: "white",
  },
  filtersButton: {
    "height": 42,
    "borderLeft": "1px solid rgba(0,0,0,0.07)",
    "borderRadius": "0 7px 7px 0",
    "color": "black",
    "&.isFiltered": {
      color: theme.colors.violet[6],
    },
  },
}));

export const PayinListFilters = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { payinSearchFilter, payinIsAscOrderingFilter, payinOrderByFilter, payinByExistFilter } = useAppSelector(
    ({ payin }) => payin,
  );

  const { isFiltered } = useIsFiltered();

  return (
    <div>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <div className={classes.inputWrapper}>
            <Input
              placeholder="Поиск по адресу"
              radius={8}
              size="md"
              variant="unstyled"
              icon={
                <ActionIcon size={24} ml={16} mr={8}>
                  <SearchIcon color="#868E96" />
                </ActionIcon>
              }
              style={{
                flex: "1",
              }}
              value={payinSearchFilter}
              rightSection={
                payinSearchFilter.length > 0 ? (
                  <ActionIcon
                    size={24}
                    ml="auto"
                    mr={8}
                    onClick={() => {
                      dispatch(setPayinSearchFilter(""));
                    }}>
                    <SearchCancelIcon color="#868E96" />
                  </ActionIcon>
                ) : (
                  <Button ml="auto" mr={40} compact radius={6} variant="light">
                    Найти
                  </Button>
                )
              }
              onChange={(event: { target: { value: string } }) => dispatch(setPayinSearchFilter(event.target.value))}
            />
            <Menu shadow="md" radius={8} width={400}>
              <Menu.Target>
                <Button
                  leftIcon={<FiltersIcon width={22} />}
                  variant="gradient"
                  gradient={{ from: "#fff", to: "#fff" }}
                  className={classNames(classes.filtersButton, { isFiltered })}>
                  Фильтры
                </Button>
              </Menu.Target>
              <Menu.Dropdown p="8px 16px 16px 16px">
                <div>Показывать адреса с балансом</div>
                {["USDT", "TRX"].map((ticker, k) => (
                  <div key={`TICKER_FILTER_${ticker}_${k}`}>
                    <Checkbox
                      mt={8}
                      color="violet"
                      label={ticker}
                      checked={payinByExistFilter.includes(ticker)}
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          dispatch(setPayinByExistFilter([...payinByExistFilter, ticker]));
                        } else {
                          const newFilter = [...payinByExistFilter];
                          newFilter.splice(payinByExistFilter.indexOf(ticker), 1);
                          dispatch(setPayinByExistFilter([...newFilter]));
                        }
                      }}
                    />
                  </div>
                ))}
                <div>
                  <Select
                    mt={8}
                    label="Сортировка"
                    placeholder="Сортировать по"
                    value={payinOrderByFilter ?? undefined}
                    data={[{ value: "created_at", label: "Дате создания" }]}
                    onChange={(e) => dispatch(setPayinOrderByFilter(e ?? ""))}
                  />
                </div>
                <div>
                  <Space mt={12} />
                  <Switch
                    label="А-Я Порядок"
                    checked={payinIsAscOrderingFilter}
                    onChange={(event) => dispatch(setPayinIsAscOrderingFilter(event.currentTarget.checked))}
                  />
                </div>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Grid.Col>
      </Grid>
      {isFiltered && (
        <Group ml={0} mt={16}>
          {!!payinByExistFilter.length && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setPayinByExistFilter([]))}>
              <FilterBadge field="Тикеры" value={payinByExistFilter.join(", ")} />
            </div>
          )}
          {payinIsAscOrderingFilter && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setPayinIsAscOrderingFilter(false))}>
              <FilterBadge field="Порядок" value="А-Я" />
            </div>
          )}
          {payinOrderByFilter.length && (
            <div className="cursor_pointer no_select" onClick={() => dispatch(setPayinOrderByFilter(""))}>
              <FilterBadge
                field="Сортировка по"
                value={switchMatch(payinOrderByFilter.toLowerCase(), {
                  created_at: "Дате создания",
                  default: payinOrderByFilter,
                })}
              />
            </div>
          )}
        </Group>
      )}
    </div>
  );
};
