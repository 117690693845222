import { Button, Checkbox, Grid, Group, Modal, Space, Text } from "@mantine/core";
import { useState } from "react";
import { subtractCommission } from "shared/utils/subtractCommission";
import { createDeposit } from "shared/api/transactions/depositFiat";
import { TFiatFlow } from "shared/definitions";
import { TPersonalClient } from "shared/types/clients";
import { formatFiat } from "shared/utils/formatNumber";
import { TOrganization } from "shared/types/organizations";
import { sentryCaptureError } from "shared/utils/environment/sentry";
import { useDepositModalLogic } from "./useDepositModalLogic";
import styles from "./Confirm.module.scss";

function getCreateTransactionErrorText(response: any, client: TPersonalClient | TOrganization) {
  if (response.response?.data?.error === "User has low verification level") {
    return `Недостаточный уровень верификации у пользователя ${client.publicId}`;
  }
  if (response.response?.data?.error === "User is blocked") {
    return `Пользователь ${client.publicId} заблокирован`;
  }
  return response.response?.data?.error;
}

export const Confirm = () => {
  const { client, formState, setDepositStep, resetDeposit } = useDepositModalLogic();
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState("");

  return (
    <Modal
      size="lg"
      padding="xl"
      title="Пополнение счета клиента"
      opened
      onClose={() => {
        setDepositStep(null);
        resetDeposit();
      }}>
      {client && (
        <>
          <Grid>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              ID клиента
            </Grid.Col>
            <Grid.Col span={8}>
              <Text size="xl" fw="500">
                {client.publicId}
              </Text>
            </Grid.Col>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Тип
            </Grid.Col>
            <Grid.Col span={8}>
              <Text size="xl" fw="500">
                {formState.type === TFiatFlow.cash_register ? "Наличные" : "Банковский перевод"}
              </Text>
            </Grid.Col>
            {formState.type === TFiatFlow.transfer && (
              <>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  Наименование банка
                </Grid.Col>
                <Grid.Col span={8}>
                  <Text size="xl" fw="500">
                    {formState.bankName}
                  </Text>
                </Grid.Col>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  Адрес банка
                </Grid.Col>
                <Grid.Col span={8}>
                  <Text size="xl" fw="500">
                    {formState.bankAddress}
                  </Text>
                </Grid.Col>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  BIC/SWIFT
                </Grid.Col>
                <Grid.Col span={8}>
                  <Text size="xl" fw="500">
                    {formState.bicOrSwift}
                  </Text>
                </Grid.Col>
                <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
                  Номер счета
                </Grid.Col>
                <Grid.Col span={8}>
                  <Text size="xl" fw="500">
                    {formState.accountNumber}
                  </Text>
                </Grid.Col>
              </>
            )}
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Комиссия
            </Grid.Col>
            <Grid.Col span={8}>
              <Text size="xl" fw="500">
                {formatFiat({ value: Number(formState.fee), postfix: formState.currency })}
              </Text>
            </Grid.Col>
            <Grid.Col sx={{ display: "flex", alignItems: "center" }} fz="sm" span={4}>
              Сумма к начислению
            </Grid.Col>
            <Grid.Col span={8}>
              <Text size="xl" fw="500">
                {formatFiat({
                  value: subtractCommission(formState.amount, formState.fee),
                  postfix: formState.currency,
                })}
              </Text>
            </Grid.Col>
          </Grid>
          <Space h="lg" />
          <Checkbox
            checked={confirmed}
            onChange={(e) => setConfirmed(e.target.checked)}
            label="Подтверждаю, что все проверил"
          />
          <Space h="lg" />
          {error.length > 1 && (
            <>
              <Text size="sm" className={styles.error} align="center">
                <p>Ошибка при пополнении:</p>
                {error}
              </Text>
              <Space h="lg" />
            </>
          )}
          <Group position="right">
            <Button
              onClick={() => {
                setDepositStep(2);
                setError("");
              }}
              variant="default">
              Назад
            </Button>
            <Button
              disabled={!confirmed || !!error.length}
              onClick={() => {
                createDeposit({
                  amount: formState.amount,
                  bankDetails: {
                    bankAddress: formState.bankAddress,
                    bankName: formState.bankName,
                    bicSwift: formState.bicOrSwift,
                    account: formState.accountNumber,
                  },
                  createdAt: new Date().toISOString(),
                  fiatFlowType: formState.type,
                  ticker: formState.currency,
                  userId: client.id,
                })
                  .then((res) => {
                    if (res.status === 200) {
                      setDepositStep(4);
                      resetDeposit();
                    } else {
                      setError(getCreateTransactionErrorText(res, client) ?? "Ошибка при создании транзакции");
                    }
                  })
                  .catch((res) => {
                    if (res?.response.data?.error.length) {
                      setError(getCreateTransactionErrorText(res, client) ?? "Ошибка при создании транзакции");
                    } else {
                      sentryCaptureError(res);
                    }
                  });
              }}
              color="violet">
              Продолжить
            </Button>
          </Group>
        </>
      )}
    </Modal>
  );
};
