import { TClientDetailsShortOrganization } from "shared/types/clients";
import { FieldsContentText, FieldsWrapper, FieldWrapper } from "components/ui/fields";
import React from "react";
import { FieldTreatment } from "components/common/FieldTreatment";
import { Routes } from "shared/definitions";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Space } from "@mantine/core";
import router from "../../../../../router";

const toOrganizationScreenHandle = (cid: string) =>
  router.navigate(Routes.ORGANIZATION_SCREEN, {
    cid,
  });

export const AffiliatedOrganizationItem = ({ id: cid, fullName, position }: TClientDetailsShortOrganization) => {
  return (
    <FieldsWrapper>
      <FieldWrapper labelWidth={230} label="Internal ID">
        <div>
          <Space mt={12} />
          <Padding horizontal={2}>
            <FieldTreatment name="internalId" onClick={() => toOrganizationScreenHandle(cid)}>
              {cid}
            </FieldTreatment>
          </Padding>
        </div>
      </FieldWrapper>
      <FieldWrapper labelWidth={230} label="Компания">
        <FieldsContentText isCopiable={false} inline text={fullName} />
      </FieldWrapper>
      <FieldWrapper labelWidth={230} label="Должность">
        <FieldsContentText isCopiable={false} inline text={position} />
      </FieldWrapper>
    </FieldsWrapper>
  );
};
