import { useCallback, useEffect, useState } from "react";
import { getOrganizationDocs } from "shared/api/users/getOrganizationDocs";
import { TOrganizationDocument } from "shared/definitions";
import { sentryCaptureError } from "shared/utils/environment/sentry";

export type TClientDocType = "identity_proof" | "finance_proof" | "other";

export const useGetOrganizationDocuments = ({ cid }: { cid: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [docs, setDocs] = useState<TOrganizationDocument[]>([]);

  const getUserDocsHandle = useCallback(() => {
    getOrganizationDocs({ id: cid })
      .then((response) => {
        setDocs(response.data);
      })
      .catch(sentryCaptureError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [cid]);

  useEffect(() => {
    getUserDocsHandle();
  }, []);

  return { docs, isLoaded, getUserDocsHandle };
};
