export enum Routes {
  CLIENTS_SCREEN = "clients",
  CLIENT_SCREEN = "client",
  ORGANIZATION_SCREEN = "organization",

  TRANSACTIONS_SCREEN = "transactions",
  TRANSACTION_SCREEN = "transaction",
  JOURNAL_TRANSFER_SCREEN = "transfer",
  RATES_AND_FEES_SCREEN = "rates_and_fees",
  MANAGE_ASSETS_SCREEN = "manage_assets",
  FEES_SCREEN = "fees",
  TICKER_OPERATIONS_SCREEN = "ticker-operations",
  WALLETS_SCREEN = "wallets",
  JOURNAL_TRANSFERS_SCREEN = "journal",
  NOT_FOUND = "not_found",
}

export enum ClientScreenSubRoutes {
  ROOT = "personal",
  LOGS = "logs",
  FINANCES = "finances",
  COMMENTS = "comments",
  DOCUMENTS = "documents",
}

export enum ClientOrganizationScreenSubRoutes {
  ROOT = "personal",
  LOGS = "logs",
  FINANCES = "finances",
  COMMENTS = "comments",
  DOCUMENTS = "documents",
}

export enum ClientsScreenSubRoutes {
  INDIVIDUAL = "individual",
  COMPANY = "company",
  NOTYPE = "notype",
}

export enum TransactionScreenSubRoutes {
  EXCHANGES = "exchanges",
}

export enum TickerOperationsScreenSubRoutes {
  EXCHANGES = "operations-exchanges",
  WITHDRAW_DEPOSIT = "operations-withdraw-deposit",
}

export enum FeesSubRoutes {
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw",
  EXCHANGE = "exchange",
}

export enum WalletsSubRoutes {
  JOURNAL = "journal",
  WHITELISTS = "whitelists",
  PAYIN = "payin",
  BALANCES = "balances",
}
