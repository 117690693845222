import dayjs from "dayjs";
import { getEnumKeyByEnumValue } from "shared/utils/getEnumKeyByEnumValue";
import { TransactionStatus } from "shared/definitions/transactions/statuses";
import { RiskLevelKeys } from "shared/definitions/levels/risk";
import { ClientStatus } from "shared/definitions/levels/clientStatus";
import { TJournalTransferDetails } from "shared/types/transfers";
import { TPersonalClient } from "shared/types/clients";
import { addressesTreatment } from "shared/utils/addresses";
import { TNotypeClient } from "shared/types/notypeUsers";
import { TOrganization } from "shared/types/organizations";

export const clientDetailExtendedDataTreatment = (props: any) => {
  return {
    taxpayerNumber: props?.taxId ?? "",
    workPlace: props?.work?.workplace ?? "",
    workPosition: props?.work?.position ?? "",
    workPhone: props?.work?.phone ?? "",
    previousSurname: props?.prevLastName ?? "",
    socialNetworkLinkVK: props?.social?.vk ?? "",
    socialNetworkLinkINST: props?.social?.ok ?? "",
    socialNetworkLinkOK: props?.social?.inst ?? "",
    guardianPhone: props?.quardian?.phone ?? "",
    guardianFirstName: props?.quardian?.firstName ?? "",
    guardianLastName: props?.quardian?.lastName ?? "",
    guardianMiddleName: props?.quardian?.middleName ?? "",
    guardianDocType: props?.quardian?.docType ?? "",
    guardianDocNumber: props?.quardian?.docId ?? "",
  };
};

export const organizationClientDetailDataTreatment = (props: any) => {
  return {
    ...props,
    commonRiskLevel: props?.riskLevel?.final ?? RiskLevelKeys.low,
    riskLevelByProfile: props?.riskLevel?.profile ?? RiskLevelKeys.low,
    riskLevelByRegion: props?.riskLevel?.region ?? RiskLevelKeys.low,
    riskLevelByOperations: props?.riskLevel?.operations ?? RiskLevelKeys.low,
  };
};
export const clientDetailDataTreatment = (props: any) => {
  let addresses: { registrationaddress: string; livingaddress: string } = {
    registrationaddress: "",
    livingaddress: "",
  };

  if ("addresses" in props && props.addresses) {
    if (props.addresses.length) {
      addresses = addressesTreatment(props.addresses);
    }
  }

  return {
    id: props.id,
    publicId: props.publicId ?? "",
    accountType: props.type,
    firstName: props.firstName || "",
    lastName: props.lastName || "",
    status: props.status,
    docNumber: props?.docNumber || "",
    bDay: props.birthday || "",
    email: props.email || "",
    phone: props.phone || "",
    organizations: props?.organizations ?? [],
    registrationDate: dayjs(props.createdAt!.toString()).format("DD.MM.YYYY HH:mm:ss"),
    registrationAddress: addresses.registrationaddress,
    actualAddress: addresses.livingaddress,
    emailContact: "",
    citizenship: props.country,
    nationality: props?.nationality ?? "",
    lastVisit: props.lastVisitedAt ? dayjs(props.lastVisitedAt!.toString()).format("DD.MM.YYYY HH:mm:ss") : "",
    patronymic: props.middleMame || "",
    checks: props?.checks || {},
    isKycApproved: props.calculatedChecks.isKycApproved,
    isBlocked: props.calculatedChecks.isBlocked,
    passedTheTest: props?.isTestCompleted ?? false,
    commonRiskLevel: props?.riskLevel?.final ?? RiskLevelKeys.low,
    riskLevelByProfile: props?.riskLevel?.profile ?? RiskLevelKeys.low,
    riskLevelByRegion: props?.riskLevel?.region ?? RiskLevelKeys.low,
    riskLevelByOperations: props?.riskLevel?.operations ?? RiskLevelKeys.low,
    verificationLevel: props.verificationLevel,
    calculatedChecks: props?.calculatedChecks,
    taxpayerNumber: "",
    workPlace: "",
    workPosition: "",
    workPhone: "",
    previousSurname: "",
    socialNetworkLinks: "",
    guardianPhone: "",
    guardianDocType: "",
    guardianDocNumber: "",
  };
};

export const journalTransferDetailDataTreatment = (item: TJournalTransferDetails) => ({
  // common
  id: item.id,
  createdAt: item.createdAt,
  blockNumber: item?.blockNumber ?? "",
  blockchainCreatedAt: item?.blockchainCreatedAt ?? "",
  network: item?.network ?? "",
  status: item?.status ?? "",
  ticker: item?.ticker ?? "",
  transactionDirection: item?.transactionDirection ?? "",
  transactionHash: item?.transactionHash ?? "",
  walletTypeFrom: item?.walletTypeFrom ?? "",
  walletTypeTo: item?.walletTypeTo ?? "",
  amount: item?.amount ?? "",
  // details
  failedAt: item?.failedAt ?? "",
  fee: item?.fee ?? "",
  finishedAt: item?.finishedAt ?? "",
  lastError: item?.lastError ?? "",
  processingAt: item?.processingAt ?? "",
  requestId: item?.requestId ?? "",
  sendingAt: item?.sendingAt ?? "",
  updatedAt: item?.updatedAt ?? "",
  walletAddressFrom: item?.walletAddressFrom ?? "",
  walletAddressTo: item?.walletAddressTo ?? "",
});

export const transactionDetailDataTreatment = (item: any) => ({
  transactionId: item.id,
  type: item.type,
  userPublicId: item?.userPublicId ?? "",
  currencyString: item.tickerFrom ?? item.tickerTo ?? "",
  amount: item.amountFrom ?? "",
  amountFrom: item.amountFrom ?? "",
  fiatFlowType: item?.fiatFlowType ?? "",
  amountTo: item.amountTo ?? "",
  tickerFrom: item.tickerFrom ?? "",
  tickerTo: item.tickerTo ?? "",
  transactionHash: item?.transactionHash ?? "",
  commonFeeString: `${item?.fee ?? ""} ${item?.tickerFee}`,
  createdAt: item.createdAt,
  updatedAt: item.updatedAt ?? "",
  confirmedAt: item?.additionalData?.confirmedAt !== null ? item?.additionalData?.confirmedAt : "",
  networkFee: item?.additionalData?.networkFee !== null ? item?.additionalData?.networkFee : "",
  walletRiskScore: item?.additionalData?.walletRiskScore !== null ? item?.additionalData?.walletRiskScore : "",
  custodyTransactionId:
    item?.additionalData?.custodyTransactionId !== null ? item?.additionalData?.custodyTransactionId : "",
  transactionStatus: item?.status ?? (getEnumKeyByEnumValue(TransactionStatus, TransactionStatus.rejected) as any),
  id: item.userId ?? "",
  fee: item.fee ?? "",
  network: item.network ?? "",
  networkFeeTicker: item.networkFeeTicker ?? "",
  comment: item.comment && item.comment.join ? item.comment.join("; ") : "",
  usedRate: item?.usedRate ?? "",
  justification: item.justification && item.justification.join ? item.justification.join("; ") : "",
  userFullName: item.userFullName ?? "",
  tickerFee: item.tickerFee ?? "",
  clientAddressFrom: item.addressFrom ?? "",
  clientAddressTo: item.addressTo ?? "",
  bankDetails: item?.additionalData?.bankDetails !== null ? item?.additionalData?.bankDetails : null,
});

export const treatmentPersonalClientData = (user: any): TPersonalClient => ({
  id: user.id,
  publicId: user.publicId ?? "",
  accountType: user.accountType,
  firstName: user.firstName,
  lastName: user.lastName,
  status: user.status ?? getEnumKeyByEnumValue(ClientStatus, ClientStatus.new),
  docNumber: user.docNumber ?? "",
  bDay: user.birthday ? dayjs(user.birthday!.toString()).format("DD.MM.YYYY") : "",
  email: user.email ?? "",
  phone: user.phone ?? "",
});

export const treatmentNotypeClientsData = (data: any): TNotypeClient => {
  return {
    id: data.id || "",
    publicId: data.publicId || "",
    email: data.email || "",
    status: data.status || "",
    createdAt: data.createdAt || "",
  };
};

export const treatmentOrganizationClientsData = (data: any): TOrganization => {
  return {
    id: data.id,
    publicId: data.publicId,
    email: data.email,
    phone: data.phone,
    fullName: data.fullName,
    shortName: data.shortName,
    registrationNumber: data.registrationNumber,
    country: data.country,
    status: data.status,
    userId: data.id,
    createdAt: data.createdAt,
  };
};
