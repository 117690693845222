import { axiosInstance } from "shared/definitions/api";
import { postConfig } from "shared/api/config";

export function saveFeeById(params: {
  feeId: string | number;
  data: {
    max_fee: number | string;
    min_fee: number | string;
    percent_fee: number | string;
  };
}) {
  return axiosInstance(postConfig(`/currencies/fees/${params.feeId}`, params.data));
}
