import React, { FC } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const Tabs: FC = () => {
  return (
    <ul className={styles.tabs}>
      <li>
        <a>Валюты</a>
      </li>
      <li>
        <a className={classNames({ [styles.tabActiveLink]: true })}>Управление кошельками</a>
      </li>
      <li>
        <a>Вайтлисты</a>
      </li>
    </ul>
  );
};
