import { useAppSelector } from "store/store";
import { Button, Flex, Space, Switch, Table, Text, Title } from "@mantine/core";
import { TDepositTickerOperation, TWithdrawTickerOperation } from "shared/types/currencies";
import { getWithdrawDepositState } from "shared/utils/currencies/tickerOperations";
import { useCallback, useState } from "react";
import classNames from "classnames";
import { TCryptoCurrency } from "shared/definitions";
import { CurrencyIcon } from "components/common/CurrencyIcon";
import styles from "../Components/DataTables/DataTables.module.scss";
import { UpdateDialog } from "../Components/DataTables/UpdateDialog";

type TDialogParams = {
  allowed: boolean;
  ids: number[];
  title: string;
};

const SwitchProps = {
  size: "md",
  onLabel: "ON",
  offLabel: "OFF",
  color: "green.5",
};

export function DataTables() {
  const [dialogParams, setDialogParams] = useState<null | TDialogParams>(null);
  const allOperations = useAppSelector((state) => state.currencies.tickerOperations);
  const currencies = useAppSelector((state) => state.currencies.currencies);
  const cryptoCurrencies = currencies.filter((el): el is TCryptoCurrency => !!el.network);
  const fiatCurrencies = currencies.filter((el) => el.isFiat);
  const operations = allOperations.filter(
    (el): el is TDepositTickerOperation | TWithdrawTickerOperation =>
      el.transactionType === "deposit" || el.transactionType === "withdraw",
  );
  const { withdrawDepositState, allCryptoOperations, allFiatOperations } = getWithdrawDepositState(
    currencies,
    operations,
  );

  const getOnChangeHandler = useCallback((el: { id: number | number[]; allowed: boolean; title: string }) => {
    return async () => {
      setDialogParams({
        ids: Array.isArray(el.id) ? el.id : [el.id],
        allowed: !el.allowed,
        title: el.title,
      });
    };
  }, []);

  return (
    <>
      {dialogParams && (
        <UpdateDialog {...dialogParams} onClose={() => setDialogParams(null)} onSubmit={() => setDialogParams(null)} />
      )}
      <Flex justify="space-between">
        <Title order={2}>Фиат</Title>
        <Button
          onClick={getOnChangeHandler({
            id: allFiatOperations,
            allowed: true,
            title: "Весь фиат будет выключен для пополнения и вывода",
          })}
          variant="light"
          color="red">
          Выключить весь фиат
        </Button>
      </Flex>
      <Space h="sm" />
      <Table verticalSpacing="xs">
        <thead>
          <tr>
            <td className={classNames(styles.headerCell, styles.header)}>Валюта</td>
            <td className={styles.headerCell}>Пополнение<br/> наличными</td>
            <td className={styles.headerCell}>Пополнение<br/> по реквизитам</td>
            <td className={styles.headerCell}>Вывод<br/> наличными</td>
            <td className={styles.headerCell}>Вывод<br/> по реквизитам</td>
          </tr>
        </thead>
        <tbody>
          {fiatCurrencies.map((el) => {
            const tickerState = withdrawDepositState.fiat[el.ticker];
            return (
              <tr key={el.ticker}>
                <td>
                  <Flex align="flex-start">
                    <CurrencyIcon className={styles.currencyIcon} icon={el.ticker} />
                    <Space w="xs" />
                    <div>
                      <div>
                        <Text fz="lg" fw={500}>{el.name}</Text>
                      </div>
                      <Text color="gray.6" fz="xs">
                        {el.ticker}
                      </Text>
                    </div>
                  </Flex>
                </td>
                <td>
                  <Switch
                    {...SwitchProps}
                    onChange={getOnChangeHandler({
                      ...tickerState.cashDeposit,
                      title: `Пополнение наличными для ${el.ticker} будет ${
                        tickerState.cashDeposit.allowed ? "отключено" : "включено"
                      }`,
                    })}
                    className={styles.switch}
                    checked={tickerState.cashDeposit.allowed}
                  />
                </td>
                <td>
                  <Switch
                    {...SwitchProps}
                    onChange={getOnChangeHandler({
                      ...tickerState.transferDeposit,
                      title: `Пополнение по реквизитам для ${el.ticker} будет ${
                        tickerState.transferDeposit.allowed ? "отключено" : "включено"
                      }`,
                    })}
                    className={styles.switch}
                    checked={tickerState.transferDeposit.allowed}
                  />
                </td>
                <td>
                  <Switch
                    {...SwitchProps}
                    disabled
                    onChange={getOnChangeHandler({
                      ...tickerState.cashWithdraw,
                      title: `Вывод наличными для ${el.ticker} будет ${
                        tickerState.cashWithdraw.allowed ? "отключен" : "включен"
                      }`,
                    })}
                    className={styles.switch}
                    checked={tickerState.cashWithdraw.allowed}
                  />
                </td>
                <td>
                  <Switch
                    {...SwitchProps}
                    onChange={getOnChangeHandler({
                      ...tickerState.transferWithdraw,
                      title: `Вывод по реквизитам для ${el.ticker} будет ${
                        tickerState.transferWithdraw.allowed ? "отключен" : "включен"
                      }`,
                    })}
                    className={styles.switch}
                    checked={tickerState.transferWithdraw.allowed}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Space h="md" />
      <Flex justify="space-between">
        <Title order={2}>Крипта</Title>
        <Button
          onClick={getOnChangeHandler({
            id: allCryptoOperations,
            allowed: true,
            title: "Вся крипта будет выключена для пополнения и вывода",
          })}
          variant="light"
          color="red">
          Выключить всю крипту
        </Button>
      </Flex>
      <Space h="sm" />
      <Table verticalSpacing="xs">
        <thead>
          <tr>
            <td className={classNames(styles.headerCell, styles.header)}>Валюта</td>
            <td className={styles.headerCell}>Пополнение</td>
            <td className={styles.headerCell}>Вывод</td>
          </tr>
        </thead>
        <tbody>
          {cryptoCurrencies.map((el) => {
            const tickerState = withdrawDepositState.crypto[el.ticker][el.network];
            return (
              <tr key={el.ticker}>
                <td>
                  <Flex align="flex-start">
                    <CurrencyIcon className={styles.currencyIcon} icon={el.ticker} />
                    <Space w="xs" />
                    <div>
                      <div>
                        <Text fz="lg" fw={500}>
                          {el.name} ({el.network})
                        </Text>
                      </div>
                      <Text color="gray.6" fz="xs">
                        {el.ticker}
                      </Text>
                    </div>
                  </Flex>
                </td>
                <td>
                  <Switch
                    {...SwitchProps}
                    onChange={getOnChangeHandler({
                      ...tickerState.deposit,
                      title: `Вывод для ${el.ticker} будет ${tickerState.deposit.allowed ? "отключен" : "включен"}`,
                    })}
                    className={styles.switch}
                    checked={tickerState.deposit.allowed}
                  />
                </td>
                <td>
                  <Switch
                    {...SwitchProps}
                    onChange={getOnChangeHandler({
                      ...tickerState.withdraw,
                      title: `Пополнение для ${el.ticker} будет ${
                        tickerState.withdraw.allowed ? "отключено" : "включено"
                      }`,
                    })}
                    className={styles.switch}
                    checked={tickerState.withdraw.allowed}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
