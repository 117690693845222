import { TWalletType } from "shared/types/wallets";
import { axiosInstance } from "../../definitions/api";
import { getConfig } from "../config";

export type NewTransferCheckResponseErrorType = {
  key: string;
  message: string;
};

export type CheckNewTransferRequestProps = {
  network?: string;
  wallet_type_from: TWalletType;
  wallet_type_to: TWalletType | "";
  amount: string;
  ticker: string;
  transaction_comment: string;
};

/* eslint-disable import/no-mutable-exports */
export let checkNewTransferAbortController = new AbortController() as AbortController;

export function checkNewTransfer(params: CheckNewTransferRequestProps) {
  checkNewTransferAbortController = new AbortController();
  return axiosInstance(
    getConfig("/custody/transfers/check_new_transfer", { ...params, network: "trx" }, {
      signal: checkNewTransferAbortController.signal,
    }),
  );
}
