import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { getCustodyTransaction, updateTransferStatus } from "shared/api";
import { setJournalTransfersDetail, setUpdateTransferStatusModalSelectedId } from "store/slices";
import { journalTransferDetailDataTreatment, switchMatch } from "shared/utils";
import { TJournalTransferViewProps } from "shared/types/transfers";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, Flex, Grid, LoadingOverlay, Modal, SimpleGrid, Space, Textarea, Title } from "@mantine/core";
import { useJournalTransfersViewBlocksLogic } from "shared/features/Wallets/JournalTransfers/JournalTransferView/useJournalTransfersViewBlocksLogic";
import { FieldsWrapper } from "components/ui/fields";
import cogoToast from "cogo-toast";
import { ReactComponent as ResendIcon } from "shared/static/icons/adapted/resend.svg";
import { ReactComponent as RefreshIcon } from "shared/static/icons/adapted/refresh2.svg";
import { Padding } from "components/ui/universal/PaddingWrapper";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { sentryCaptureError } from "shared/utils/environment/sentry";

const useUpdateTransferStatusModalLogic = () => {
  const dispatch = useAppDispatch();
  const [reasonUpdateTransferStatus, setReasonUpdateTransferStatus] = useState("");
  const [newStatusUpdateTransfer, setNewStatusUpdateTransfer] = useState<"processing" | "sending" | null>(null);
  const { updateTransferStatusModalSelectedId } = useAppSelector((store) => ({
    ...store.modals,
  }));

  const setSelectedIdUpdateTransferStatusModal = (id: string) => {
    dispatch(setUpdateTransferStatusModalSelectedId(id));
  };

  useEffect(() => {
    if (updateTransferStatusModalSelectedId === "" && !!reasonUpdateTransferStatus.length) {
      setReasonUpdateTransferStatus("");
      setNewStatusUpdateTransfer(null);
    }
  }, [updateTransferStatusModalSelectedId]);

  return {
    newStatusUpdateTransfer,
    reasonUpdateTransferStatus,
    setNewStatusUpdateTransfer,
    setReasonUpdateTransferStatus,
    updateTransferStatusModalSelectedId,
    setSelectedIdUpdateTransferStatusModal,
  };
};

export const JournalTransferViewWrapper = (props: TJournalTransferViewProps & { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const journalTransfersDetails = useAppSelector(({ journalTransfers }) => journalTransfers.journalTransfersDetails);

  const fetchDetailsHandler = useCallback((id: string) => {
    getCustodyTransaction({ id })
      .then((detail) => {
        dispatch(setJournalTransfersDetail(journalTransferDetailDataTreatment(detail)));
      })
      .catch(sentryCaptureError);
  }, []);

  useEffect(() => {
    const updateInterval = setInterval(() => {
      fetchDetailsHandler(props.jtid);
    }, 10000);
    fetchDetailsHandler(props.jtid);
    return () => {
      clearInterval(updateInterval);
    };
  }, [props.jtid]);

  if (!Object.keys(journalTransfersDetails).length) return <LoadingOverlay visible />;
  return props.children;
};
export const JournalTransferView = ({ listUpdateFn = () => {} }: { listUpdateFn?: () => void }) => {
  const {
    blockCreatedAt,
    blockAmount,
    blockTickerNetwork,
    blockWalletToAddress,
    blockWalletFromAddress,
    blockTransactionId,
    blockNetworkFee,
    blockUpdatedAt,
    blockFinishedAt,
    blockSimplyTransactionStatus,
    blockNumberBlock,
    blockWalletTypeTo,
    blockWalletTypeFrom,
    blockHash,
    detail,
  } = useJournalTransfersViewBlocksLogic();

  const { updateTransferStatusModalSelectedId, setSelectedIdUpdateTransferStatusModal, ...updateStatusModalLogic } =
    useUpdateTransferStatusModalLogic();

  const [hiddenStatusActionButton, setHiddenStatusActionButton] = useState(false);

  const updateStatusHandler = useCallback(() => {
    if (!updateStatusModalLogic.reasonUpdateTransferStatus.length) {
      cogoToast.error("Укажите обоснование");
      return;
    }

    setSelectedIdUpdateTransferStatusModal("");

    updateTransferStatus({
      id: updateTransferStatusModalSelectedId,
      status: updateStatusModalLogic.newStatusUpdateTransfer!,
      reason: updateStatusModalLogic.reasonUpdateTransferStatus,
    })
      .then(() => {
        cogoToast.success("Статус перевода успешно изменён");
        setHiddenStatusActionButton(true);
        listUpdateFn();
      })
      .catch(sentryCaptureError)
      .finally(() => {
        updateStatusModalLogic.setNewStatusUpdateTransfer(null);
        updateStatusModalLogic.setReasonUpdateTransferStatus("");
      });
  }, [updateTransferStatusModalSelectedId, updateStatusModalLogic]);

  const handleCloseUpdateStatusModal = useCallback(() => {
    setSelectedIdUpdateTransferStatusModal("");
    updateStatusModalLogic.setNewStatusUpdateTransfer(null);
    updateStatusModalLogic.setReasonUpdateTransferStatus("");
  }, []);

  return (
    <div style={{ maxWidth: "600px" }}>
      {!!updateTransferStatusModalSelectedId.length && (
        <Modal
          radius={12}
          title={
            <Title order={4}>
              {switchMatch(updateStatusModalLogic.newStatusUpdateTransfer!, {
                sending: "Переотправить транзакцию?",
                processing: "Обновить транзакцию?",
              })}
            </Title>
          }
          opened={!!updateTransferStatusModalSelectedId.length}
          onClose={handleCloseUpdateStatusModal}>
          <Padding horizontal={0} vertical={0}>
            <Textarea
              size="lg"
              value={updateStatusModalLogic.reasonUpdateTransferStatus}
              onChange={(event) => updateStatusModalLogic.setReasonUpdateTransferStatus(event.currentTarget.value)}
              radius={12}
              label="Обоснование"
              sx={{
                label: { fontSize: "13px" },
              }}
              autosize
              placeholder="Введите текст"
            />
            <Spacer size={6} />
            <Flex gap="xs" justify="flex-end" direction="row" wrap="wrap">
              <Button radius={12} size="md" onClick={updateStatusHandler}>
                Обновить статус
              </Button>
              <Button ml={16} radius={12} size="md" variant="default" onClick={handleCloseUpdateStatusModal}>
                Отмена
              </Button>
            </Flex>
          </Padding>
        </Modal>
      )}
      <Grid columns={12}>
        <Grid.Col span={12}>
          <FieldsWrapper>
            {blockTransactionId}
            {blockWalletFromAddress}
            {blockWalletToAddress}
            {blockTickerNetwork}
            {blockAmount}
            {blockNetworkFee}
          </FieldsWrapper>
          <Space mt={16} />
          <FieldsWrapper>
            {blockCreatedAt}
            {blockUpdatedAt}
            {blockFinishedAt}
          </FieldsWrapper>
          <Space mt={16} />
          <FieldsWrapper>
            {blockNumberBlock}
            {blockHash}
            {blockWalletTypeFrom}
            {blockWalletTypeTo}
            {blockSimplyTransactionStatus}
            {detail?.status === "hold" && !hiddenStatusActionButton && (
              <div>
                <SimpleGrid cols={2} p={24}>
                  <Button
                    size="lg"
                    compact
                    variant="light"
                    color="violet"
                    leftIcon={<ResendIcon height={18} />}
                    onClick={() => {
                      updateStatusModalLogic.setNewStatusUpdateTransfer("processing");
                      setSelectedIdUpdateTransferStatusModal(detail?.id);
                    }}>
                    Переотправить
                  </Button>
                  <Button
                    size="lg"
                    compact
                    variant="light"
                    color="violet"
                    leftIcon={<RefreshIcon height={18} />}
                    onClick={() => {
                      updateStatusModalLogic.setNewStatusUpdateTransfer("sending");
                      setSelectedIdUpdateTransferStatusModal(detail?.id);
                    }}>
                    Обновить
                  </Button>
                </SimpleGrid>
              </div>
            )}
          </FieldsWrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
