import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/store";
import { ITransferOption } from "shared/types/manageAssets";
import { transfer } from "store/slices";
import { useDispatch } from "react-redux";
import { ITransferModalProps, TransferModalStep } from "./types";

export const useTransferModal = ({ rowIndex, dataKey, onClose }: ITransferModalProps) => {
  const dispatch = useDispatch();
  const manageAssetsStore = useAppSelector((state) => state.manageAssets);

  const [step, setStep] = useState(TransferModalStep.Initial);
  const [minAmount, setMinAmount] = useState<string | undefined>("0");
  const [maxFee, setMaxFee] = useState<string | undefined>("0");
  const [transferAmount, setTransferAmount] = useState<string | undefined>("0");
  const [transferFromValue, setTransferFromValue] = useState<ITransferOption["value"] | null | undefined>();
  const [transferToValue, setTransferToValue] = useState<ITransferOption["value"] | null | undefined>();

  useEffect(() => {
    setTransferFromValue(dataKey);
  }, [dataKey]);

  const network = useMemo(() => {
    if (rowIndex === undefined) return undefined;
    return manageAssetsStore.tableData.rows[rowIndex].network;
  }, [rowIndex, manageAssetsStore]);

  const transferOptions = useMemo(
    () =>
      manageAssetsStore.transferOptions.map((opt) => ({
        ...opt,
        label: [network?.toUpperCase(), opt.label].filter((v) => v !== undefined).join(" "),
      })),
    [manageAssetsStore.transferOptions, network],
  );

  const transferFromOptions = useMemo(
    () => transferOptions.filter(({ value }) => value !== transferToValue),
    [transferOptions, transferToValue],
  );

  const transferToOptions = useMemo(
    () => transferOptions.filter(({ value }) => value !== transferFromValue),
    [transferOptions, transferFromValue],
  );

  const handleNextStep = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const isValid = [minAmount, maxFee].every(
        (v) => !!v && v?.length > 0 && !Number.isNaN(parseFloat(v)) && parseFloat(v) !== 0,
      );

      if (isValid) {
        setStep(TransferModalStep.Final);
      }
    },
    [minAmount, maxFee],
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (rowIndex === undefined || !transferFromValue || !transferToValue || transferAmount === undefined) {
        // todo: showError
        return;
      }

      dispatch(transfer({ rowIndex, value: parseFloat(transferAmount), from: transferFromValue, to: transferToValue }));
      onClose();
    },
    [rowIndex, transferToValue, transferFromValue, transferAmount, onClose],
  );

  return {
    state: {
      step,
      network,
      minAmount,
      maxFee,
      transferAmount,

      transferFromValue,
      transferToValue,

      transferFromOptions,
      transferToOptions,
    },
    actions: {
      changeMinAmount: setMinAmount,
      changeMaxFee: setMaxFee,
      changeTransferAmount: setTransferAmount,

      setTransferFromValue,
      setTransferToValue,

      handleNextStep,
      handleCancel,
      handleSubmit,
    },
  };
};
