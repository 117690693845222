import React from "react";
import { useAppSelector } from "store/store";
import { FeesPageContainer } from "../components/FeesPageContainer";
import { Tabs } from "../components/Tabs";
import { TabContentContainer } from "../components/TabContentContainer";
import { FeesSection } from "../components/FeesSection";

export const Withdraw = () => {
  const { feesByType } = useAppSelector(({ fees }) => ({ feesByType: fees.feesByType }));

  return (
    <FeesPageContainer>
      <Tabs />
      <TabContentContainer>
        <FeesSection sectionTitle="Вывод переводом" rows={feesByType.withdrawFiatTransfer} />
        <FeesSection sectionTitle="Вывод криптовалюты" rows={feesByType.withdrawCrypto} />
      </TabContentContainer>
    </FeesPageContainer>
  );
};
